class Counter {
  counts: Map<string, number>;

  constructor() {
    this.counts = new Map<string, number>();
  }

  get(key: string): number {
    return this.counts.get(key) ?? 0;
  }

  has(key: string): boolean {
    return this.get(key) > 0;
  }

  add(key: string): void {
    this.counts.set(key, this.get(key) + 1);
  }

  remove(key: string): void {
    this.counts.set(key, this.get(key) - 1);
  }
}

export default Counter;
