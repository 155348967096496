import { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { LandingPage, PageName, UserPreference, UserPreferenceType } from 'shared/lib/types/postgres/users';
import ProceduresGrid from '../components/Home/ProceduresGrid';
import RunningProceduresGrid from '../components/Home/RunningProceduresGrid';
import { MenuContextAction } from '../components/MenuContext';
import NotificationPanel from '../components/Notifications/NotificationPanel';
import OperationsGrid from '../components/Operations/OperationsGrid';
import { useDatabaseServices } from '../contexts/DatabaseContext';
import { useMixpanel } from '../contexts/MixpanelContext';
import { useSettings } from '../contexts/SettingsContext';
import { useUserInfo } from '../contexts/UserContext';
import Avatar, { AvatarSize } from '../elements/Avatar';
import SearchInputControlled from '../elements/SearchInputControlled';
import ThreeDotMenu from '../elements/ThreeDotMenu';
import OpenIssuesGridWithCount from '../issues/components/OpenIssuesGridWithCount';
import {
  inventoryIndexPath,
  issuesPath,
  operationsDashboardPath,
  proceduresPath,
  runsPath,
  testingPlansPath,
} from '../lib/pathUtil';
import InventoryGrid from '../manufacturing/components/InventoryGrid';
import ActivePlansGrid from '../testing/components/Plans/ActivePlansGrid';
import homeUtil from '../lib/homeUtil';
import usePersistedView from '../components/Home/usePersistedView';
import ViewTabToggle from '../components/Home/ViewTabToggle';
import DefaultTitle from '../components/DefaultTitle';
import apm from '../lib/apm';
import { useNavState } from '../contexts/NavContext';

const ISSUES_GRID_VERTICAL_PADDING = 220;

const Landing = () => {
  const { projectId } = useNavState();
  const { currentTeamId } = useDatabaseServices();
  const { userInfo } = useUserInfo();
  const { mixpanel } = useMixpanel();
  const {
    defaultView,
    userPreferences,
    updateUserPreference,
    isIssuesEnabled,
    isManufacturingEnabled,
    isTestConditionsMatrixEnabled,
    projects,
  } = useSettings();
  const { viewTab, setViewTab, searchTerm, setSearchTerm } = usePersistedView();
  const [page, setPage] = useState(PageName.Runs);

  const setLandingPage = (page: PageName) => {
    mixpanel?.track('Set Landing Page', { Page: page });
    setPage(page);
    const updatedUserPreference: UserPreference = {
      name: UserPreferenceType.LandingPage,
      value: { page_name: page },
    };
    updateUserPreference(updatedUserPreference).catch((err) => apm.captureError(err));
  };

  const options: PageName[] = useMemo(
    () => [
      ...(isManufacturingEnabled && isManufacturingEnabled() ? [PageName.Inventory] : []),
      ...(isIssuesEnabled && isIssuesEnabled() ? [PageName.Issues] : []),
      PageName.Operations,
      PageName.Procedures,
      PageName.Runs,
      ...(isTestConditionsMatrixEnabled && isTestConditionsMatrixEnabled() ? [PageName.TestPlans] : []),
    ],
    [isIssuesEnabled, isManufacturingEnabled, isTestConditionsMatrixEnabled]
  );

  const MenuItems: Array<MenuContextAction> = options.map((option) => ({
    type: 'label',
    label: option.toString(),
    data: {
      onClick: () => setLandingPage(option),
    },
  }));

  useEffect(() => {
    const pageName = (userPreferences?.preferences?.landing_page as LandingPage)?.page_name;
    if (pageName && options.includes(pageName)) {
      setPage(pageName);
    } else {
      setPage(PageName.Runs);
    }
  }, [options, userPreferences?.preferences?.landing_page]);

  const Header = ({ name, link, subtitle }: { name: string; link: string; subtitle?: string }) => {
    return (
      <div className="flex justify-between">
        <Link className="flex flex-row items-center gap-x-4" to={link}>
          <div>{name}</div>
          <div className="text-blue-500 text-sm">{subtitle}</div>
        </Link>
        <ThreeDotMenu menuActions={MenuItems} menuLabel="Change Panel" />
      </div>
    );
  };

  return (
    <div className="w-full mt-4 px-5">
      {/* Sets the default document title */}
      <DefaultTitle />
      <div className="flex flex-row justify-between mb-4">
        <div className="flex flex-row gap-2 items-center">
          <Avatar size={AvatarSize.lg} userId={userInfo.session.user_id} />
          <h1>Welcome {userInfo.session.user_id}</h1>
        </div>
      </div>
      <div className="flex flex-col lg:flex-row gap-4 w-full">
        <div className="basis-1/4 min-w-[340px] h-fit rounded-md border border-gray-200 bg-white px-3 py-2 overflow-x-auto">
          <div className="flex flex-col gap-4 justify-between">
            <NotificationPanel />
          </div>
        </div>
        <div className="basis-3/4 min-w-[340px] rounded-md border border-gray-200 bg-white px-3 py-2 overflow-x-auto">
          <div>
            {page === PageName.Issues && (
              <>
                <Header link={issuesPath(currentTeamId)} name="Issues" />
                <SearchInputControlled
                  placeholder="Search Issues"
                  searchTerm={searchTerm}
                  setSearchTerm={setSearchTerm}
                />
                <OpenIssuesGridWithCount
                  searchTerm={searchTerm}
                  setSearchTerm={setSearchTerm}
                  verticalPadding={ISSUES_GRID_VERTICAL_PADDING}
                />
              </>
            )}
            {page === PageName.Inventory && (
              <>
                <Header link={inventoryIndexPath(currentTeamId)} name="Inventory" />
                <SearchInputControlled
                  placeholder="Search inventory"
                  searchTerm={searchTerm}
                  setSearchTerm={setSearchTerm}
                />
                <div className="mt-2">
                  <InventoryGrid searchTerm={searchTerm} showSelectRows={false} projects={projects} />
                </div>
              </>
            )}
            {page === PageName.Operations && (
              <>
                <Header link={operationsDashboardPath(currentTeamId)} name="Operations" />
                <OperationsGrid />
              </>
            )}
            {page === PageName.TestPlans && (
              <>
                <Header link={testingPlansPath(currentTeamId)} name="Test Plans" />
                <div className="flex flex-row justify-start mb-2">
                  {!projectId && <ViewTabToggle viewTab={viewTab} setViewTab={setViewTab} />}
                  <SearchInputControlled
                    placeholder="Search Plans"
                    searchTerm={searchTerm}
                    setSearchTerm={setSearchTerm}
                  />
                </div>
                <ActivePlansGrid searchTerm={searchTerm} setSearchTerm={setSearchTerm} viewTab={viewTab} />
              </>
            )}
            {page === PageName.Runs && (
              <>
                <Header
                  link={runsPath(currentTeamId)}
                  name="Procedure Runs"
                  subtitle={homeUtil.hasCustomizedDefaultView(defaultView, 'runs') ? '(Default View Applied)' : ''}
                />
                <div className="flex flex-row justify-start">
                  {!projectId && <ViewTabToggle viewTab={viewTab} setViewTab={setViewTab} />}
                  <SearchInputControlled
                    placeholder="Search Runs"
                    searchTerm={searchTerm}
                    setSearchTerm={setSearchTerm}
                  />
                </div>
                <RunningProceduresGrid searchTerm={searchTerm} setSearchTerm={setSearchTerm} viewTab={viewTab} />
              </>
            )}
            {page === PageName.Procedures && (
              <>
                <Header
                  link={proceduresPath(currentTeamId)}
                  name="Procedures"
                  subtitle={
                    homeUtil.hasCustomizedDefaultView(defaultView, 'procedures') ? '(Default View Applied)' : ''
                  }
                />
                <div className="flex flex-row justify-start">
                  {!projectId && <ViewTabToggle viewTab={viewTab} setViewTab={setViewTab} />}
                  <SearchInputControlled
                    placeholder="Search Procedures"
                    searchTerm={searchTerm}
                    setSearchTerm={setSearchTerm}
                  />
                </div>
                <ProceduresGrid searchTerm={searchTerm} setSearchTerm={setSearchTerm} viewTab={viewTab} />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Landing;
