import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Field, Form, Formik } from 'formik';
import TextLinkify from '../TextLinkify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AttachmentPreview from '../Attachments/AttachmentPreview';
import { useDatabaseServices } from '../../contexts/DatabaseContext';
import Select from 'react-select';
import { reactSelectStyles, selectValueSelectedStyles } from '../../lib/styles';
import FieldSetMultiSelectCreatable from '../FieldSetMultiSelectCreatable';
import RadioGroupFieldSet from './RadioGroupFieldSet';
import { useRunContext } from '../../contexts/RunContext';
import { isEmptyValue } from 'shared/lib/text';
import { useSettings } from '../../contexts/SettingsContext';
import { RUN_STATE } from 'shared/lib/runUtil';
import runUtil from '../../lib/runUtil';
import FieldInputExternalItem from './FieldInputExternalItem';
import FieldInputExternalSearch from './FieldInputExternalSearch';
import { ExternalDataItem, RecordedString, RecordedValue } from './BlockTypes';
import {
  AttachmentValue,
  ExternalDataValue,
  FieldInputBlock,
  FieldInputExternalDataBlock,
  FieldInputExternalSearchBlock,
  FieldInputMultipleChoiceBlock,
  FieldInputNumberBlock,
  FieldInputNumberBlockDiffElement,
  FieldInputTextBlock,
  ReleaseFieldInputBlock,
  RunFieldInputRecorded,
  RunFieldInputRecordedValue,
  RunFieldInputTimestampBlock,
  SketchValue,
} from 'shared/lib/types/views/procedures';
import isNil from 'lodash.isnil';
import isEqual from 'lodash.isequal';
import TextAreaAutoHeight from '../TextAreaAutoHeight';
import { cloneDeep, isEmpty } from 'lodash';
import Button from '../Button';
import ModalSketch from '../ModalSketch';
import MarkdownView from '../Markdown/MarkdownView';
import ProcedureFieldDiff from '../ProcedureFieldDiff';
import TimestampFieldInput from './FieldInput/TimestampFieldInput';
import TimestampFieldInputDisplay from './FieldInput/TimestampFieldInputDisplay';
import FieldInputNameDisplay from './FieldInput/FieldInputNameDisplay';
import { DatabaseServices } from '../../contexts/proceduresSlice';
import UnitDisplay from '../Settings/Units/UnitDisplay';
import { MAX_FILE_SIZE } from 'shared/lib/types/api/files/requests';
import { MAX_FILE_SIZE_EXCEEDED_MESSAGE } from '../../attachments/service';
import NumberFieldInput from './FieldInput/NumberFieldInput';
import NumberFieldInputDisplay from './FieldInput/NumberFieldInputDisplay';
import diffUtil from '../../lib/diffUtil';
import ReviewNumberFieldInput from '../Review/Blocks/FieldInput/ReviewNumberFieldInput';
import PhotoCaptureButton from '../PhotoCaptureButton';

/*
 * Component for rendering a Block of type FieldInputReview.
 * Conforms to TypedBlockInterface, see comments in useBlockComponents.js
 */
interface FieldInputProps {
  block: FieldInputBlock;
  redlinedBlock?: ReleaseFieldInputBlock;
  isEnabled: boolean;
  recorded?: { value?: RunFieldInputRecordedValue };
  onRecordValuesChanged?: (recorded: { value?: RunFieldInputRecordedValue }) => void;
  onRecordErrorsChanged?: (errors: { text?: string }) => void;
  onRecordUploadingChanged?: (boolean) => void;
  onContentRefChanged?: (id: string, element: HTMLElement) => void;
  scrollMarginTopValueRem?: number;
  blockId?: string;
}

const FieldInput = React.memo(
  ({
    block,
    redlinedBlock,
    recorded,
    isEnabled,
    onRecordValuesChanged,
    onRecordErrorsChanged,
    onRecordUploadingChanged,
    onContentRefChanged,
    scrollMarginTopValueRem,
    blockId = block.id,
  }: FieldInputProps) => {
    const inputRef = useRef(null);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [fileUploadFailed, setFileUploadFailed] = useState(false);
    const [isFileUploading, setIsFileUploading] = useState(false);
    const [isSketching, setIsSketching] = useState(false);
    const { services }: { services: DatabaseServices } = useDatabaseServices();
    const { run } = useRunContext();
    const { getListValues } = useSettings();

    const isRunningProcedure = useMemo(
      () => Boolean(run && (runUtil.isRunStateActive(run.state) || run.state === RUN_STATE.COMPLETED)),
      [run]
    );

    const blockName = block.name;

    const blockDiff = useMemo(() => {
      if (!redlinedBlock) {
        return null;
      }

      return diffUtil.getFieldInputBlockDiff(block, redlinedBlock);
    }, [block, redlinedBlock]);

    const isAttachmentType = useMemo(() => {
      return block.inputType === 'attachment';
    }, [block]);

    const isSketchType = useMemo(() => {
      return block.inputType === 'sketch';
    }, [block]);

    const hasRecordedAttachment = useMemo(() => {
      return Boolean(isAttachmentType && recorded && recorded.value);
    }, [isAttachmentType, recorded]);

    const hasRecordedSketch = useMemo(() => {
      return Boolean(isSketchType && recorded && (recorded.value as SketchValue)?.attachment_id);
    }, [isSketchType, recorded]);

    const isSelectType = useMemo(() => {
      // TODO (jon): EPS-1471 create constants/enums for all field input types
      return block.inputType === 'select' || block.inputType === 'list';
    }, [block]);

    const isMultipleChoiceType = useMemo(() => {
      return block.inputType === 'multiple_choice';
    }, [block]);

    const onValidate = useCallback(
      (values) => {
        const errors = {};
        const text = values.text;

        switch (block.inputType) {
          case 'number':
            if (text && text !== '' && isNaN(parseFloat(text))) {
              errors['text'] = 'Enter a valid number';
            }
            break;
          default:
            break;
        }

        // Notify observers that errors changed
        if (typeof onRecordErrorsChanged === 'function') {
          onRecordErrorsChanged(errors);
        }

        return errors;
      },
      [block.inputType, onRecordErrorsChanged]
    );

    const updateInputValue = (e, value, formikField, setFieldValue) => {
      let validated = value;
      switch (block.inputType) {
        case 'number':
          if (!isNaN(parseFloat(value))) {
            validated = parseFloat(value);
          }
          break;
        default:
          break;
      }
      setFieldValue('text', validated);

      // Notify observers that value changed
      if (onRecordValuesChanged) {
        const _recorded = recorded ? cloneDeep(recorded) : {};
        _recorded.value = validated;
        if (!isEqual(recorded, _recorded)) {
          onRecordValuesChanged(_recorded);
        }
      }

      formikField.onBlur(e);
    };

    const updateSketchTextInputValue = (e, value, formikField, setFieldValue) => {
      setFieldValue('text', value);

      // Notify observers that value changed
      if (onRecordValuesChanged) {
        const _recorded = (recorded ? cloneDeep(recorded) : { value: {} }) as { value: SketchValue };
        _recorded.value = {
          ..._recorded.value,
          text: value,
        };
        if (!isEqual(recorded, _recorded)) {
          onRecordValuesChanged(_recorded);
        }
      }

      formikField.onBlur(e);
    };

    const onFileInputChange = (file: File): Promise<void> => {
      if (!services.attachments) {
        return Promise.reject('Services unavailable');
      }
      onRecordUploadingChanged && onRecordUploadingChanged(true);
      setIsFileUploading(true);
      return services.attachments
        .uploadFile(file, 'procedures:field_input:attachment', { remote: false })
        .then((attachment) => {
          // Save attachment in recorded struct and reset error message.
          const _recorded = (recorded ? cloneDeep(recorded) : { value: {} }) as {
            value: SketchValue | AttachmentValue;
          };
          _recorded.value = {
            ..._recorded.value,
            ...attachment,
          };
          onRecordValuesChanged && onRecordValuesChanged(_recorded);
          setFileUploadFailed(false);
        })
        .catch(() => {
          // Reset file input and show error message.
          if (fileInputRef && fileInputRef.current) {
            fileInputRef.current.value = '';
          }
          setFileUploadFailed(true);
        })
        .finally(() => {
          // Set local and global "is uploading" state to false.
          onRecordUploadingChanged && onRecordUploadingChanged(false);
          setIsFileUploading(false);
        });
    };

    const onCancelFileInput = () => onRecordValuesChanged && onRecordValuesChanged({});

    const selectOptionsList = useMemo(() => {
      if (block.inputType === 'select') {
        return block.options ? block.options.filter((option) => option !== '') : [];
      } else if (block.inputType === 'list') {
        return getListValues(block.list);
      }
    }, [block, getListValues]);

    const selectOptions = useMemo(() => {
      if (!Array.isArray(selectOptionsList)) {
        return [];
      }

      return selectOptionsList.map((value) => ({
        value,
        label: value,
      }));
    }, [selectOptionsList]);

    const updateSelectValue = (option) => {
      if (onRecordValuesChanged) {
        const value = option.value;
        const _recorded = { value };
        if (!isEqual(recorded, _recorded)) {
          onRecordValuesChanged(_recorded);
        }
      }
    };

    const setRecordedValue = useCallback(
      (value) => {
        if (onRecordValuesChanged) {
          const _recorded = { value };
          if (!isEqual(recorded, _recorded)) {
            onRecordValuesChanged(_recorded);
          }
        }
      },
      [onRecordValuesChanged, recorded]
    );

    const valueSelected = useMemo(() => {
      if (!recorded || !recorded.value) {
        return null;
      }
      return selectOptions.find((option) => option.value === recorded.value);
    }, [recorded, selectOptions]);

    const onFieldInputRefChanged = useCallback(
      (element) => {
        return typeof onContentRefChanged === 'function' && onContentRefChanged(blockId, element);
      },
      [blockId, onContentRefChanged]
    );

    const recordedValue = useMemo(() => {
      const startingEmptyValue = isSketchType ? undefined : '';
      return recorded && !isNil(recorded.value) ? recorded.value : startingEmptyValue;
    }, [isSketchType, recorded]);

    const initialValues = useMemo(() => {
      if (isSketchType) {
        return { text: (recordedValue as SketchValue)?.text };
      }

      return { text: recordedValue };
    }, [isSketchType, recordedValue]);

    const initialErrors = useMemo(
      () => (!isEmpty(initialValues?.text) ? onValidate(initialValues) : {}),
      [initialValues, onValidate]
    );

    if (block.inputType === 'external_item') {
      return (
        <FieldInputExternalItem
          block={block as FieldInputExternalDataBlock}
          recorded={recorded as { value: ExternalDataValue }}
          isEnabled={isEnabled}
          onRecordValuesChanged={onRecordValuesChanged as (recorded: RecordedValue<ExternalDataItem>) => void}
          onContentRefChanged={onContentRefChanged}
          scrollMarginTopValueRem={scrollMarginTopValueRem}
          redline={redlinedBlock as FieldInputExternalDataBlock}
        />
      );
    }

    return (
      <div
        ref={(element) => onFieldInputRefChanged(element)}
        style={{ scrollMarginTop: `${scrollMarginTopValueRem}rem` }}
        className="grow"
      >
        {/* Render live field input form */}
        {isEnabled && (
          <Formik
            initialValues={initialValues}
            initialErrors={initialErrors}
            validate={onValidate}
            onSubmit={() => {
              /* no-op */
            }}
            enableReinitialize
          >
            {({ errors, setFieldValue }) => (
              <Form>
                <div className="flex flex-nowrap items-start w-full gap-x-2 py-1">
                  {block.inputType === 'timestamp' && (
                    <TimestampFieldInput
                      block={block}
                      recorded={recorded as RunFieldInputTimestampBlock['recorded']}
                      onRecordValuesChanged={onRecordValuesChanged}
                    />
                  )}
                  {block.inputType === 'checkbox' && (
                    <Field name="text">
                      {/* Checkbox workaround: https://github.com/formium/formik/issues/1050 */}
                      {({ field: formikField }) => (
                        <>
                          {/* Wrap the label around <input> and the following text in order to make both the checkbox and text clickable to toggle the check*/}
                          <label className="flex flex-nowrap">
                            <input
                              {...formikField}
                              checked={formikField.value}
                              type="checkbox"
                              ref={inputRef}
                              className="mr-2 w-6 h-6 text-gray-500 border border-gray-400 rounded-sm disabled:bg-gray-300 disabled:bg-opacity-50"
                              onChange={(e) => updateInputValue(e, e.target.checked, formikField, setFieldValue)}
                              disabled={!isEnabled}
                            />
                            <MarkdownView text={blockName} />
                          </label>
                        </>
                      )}
                    </Field>
                  )}
                  {/* Text for checkboxes is within the checkbox label, so do not add it here */}
                  {block.inputType !== 'checkbox' &&
                    block.inputType !== 'timestamp' &&
                    block.inputType !== 'number' && (
                      <div className="self-start mt-1.5">
                        <MarkdownView text={blockName} />
                      </div>
                    )}
                  {isSelectType && (
                    <div>
                      <Select
                        classNamePrefix="react-select"
                        className="w-64"
                        onChange={updateSelectValue}
                        options={selectOptions}
                        styles={reactSelectStyles}
                        value={valueSelected}
                        aria-label="Field Input Select"
                      />
                    </div>
                  )}
                  {block.inputType === 'text' && (
                    <>
                      <div className="self-start mt-1.5">=</div>
                      <div className="flex flex-nowrap grow justify-between">
                        <div className="flex flex-no-wrap">
                          <Field name="text">
                            {({ field: formikField }) => {
                              return (
                                <TextAreaAutoHeight
                                  {...formikField}
                                  aria-label="Enter Value"
                                  ref={inputRef}
                                  onBlur={(e) => updateInputValue(e, e.target.value, formikField, setFieldValue)}
                                  disabled={!isEnabled}
                                  style={{ minWidth: '12rem' }}
                                />
                              );
                            }}
                          </Field>
                          {block.units && (
                            <span className="ml-2 self-center">
                              <UnitDisplay unit={block.units} />
                            </span>
                          )}
                          {errors.text && (
                            <span className="whitespace-nowrap  ml-2 self-center text-red-700">{errors.text}</span>
                          )}
                        </div>
                      </div>
                    </>
                  )}
                  {block.inputType === 'number' && (
                    <div>
                      {/* The name of the formik field is intentionally left as "text" here. */}
                      <Field name="text">
                        {({ field: formikField }) => {
                          return (
                            <NumberFieldInput
                              block={block}
                              recorded={recorded as RunFieldInputRecorded<number>}
                              isEnabled={isEnabled}
                              errors={errors}
                              fieldName={formikField.name}
                              fieldValue={formikField.value}
                              onChange={formikField.onChange}
                              onBlur={(e) => updateInputValue(e, e.target.value, formikField, setFieldValue)}
                            />
                          );
                        }}
                      </Field>
                    </div>
                  )}

                  {isSketchType && !hasRecordedSketch && (
                    <>
                      <div className="flex flex-nowrap grow justify-between">
                        <div className="flex flex-no-wrap items-center space-x-2">
                          {!isSketching && !hasRecordedSketch && (
                            <Button type="primary" onClick={() => setIsSketching(true)}>
                              <FontAwesomeIcon icon="paintbrush" />
                              <span>Add Sketch</span>
                            </Button>
                          )}
                          {isSketching && !hasRecordedSketch && (
                            <ModalSketch
                              label={blockName}
                              onFileInputChange={onFileInputChange}
                              setIsSketching={setIsSketching}
                              updateInputValue={(e, value, formikField) =>
                                updateSketchTextInputValue(e, value, formikField, setFieldValue)
                              }
                            />
                          )}
                        </div>
                      </div>
                    </>
                  )}
                  {hasRecordedSketch && (
                    <div className="flex flex-nowrap grow justify-between">
                      <div className="flex flex-no-wrap items-center">
                        {(recorded?.value as SketchValue)?.text && (
                          <div
                            style={{ minWidth: '12rem' }}
                            className="h-fit  p-2 border border-gray-400 rounded bg-gray-300 bg-opacity-50 whitespace-pre-wrap"
                          >
                            {(recorded?.value as SketchValue)?.text as RecordedString}
                          </div>
                        )}
                        <div className="ml-2">
                          <AttachmentPreview
                            attachment={recorded?.value as AttachmentValue | undefined}
                            size="sm"
                            crop={false}
                            canDownload={false}
                          />
                        </div>
                        <Button
                          type="tertiary"
                          onClick={onCancelFileInput}
                          title="Cancel File Input"
                          ariaLabel="Cancel File Input"
                        >
                          <FontAwesomeIcon icon="times-circle" className="text-gray-400 hover:text-gray-500" />
                        </Button>
                      </div>
                    </div>
                  )}
                  {isAttachmentType && !hasRecordedAttachment && (
                    <>
                      <div className="flex gap-x-1">
                        <PhotoCaptureButton onPhotoCapture={onFileInputChange} />
                        <input
                          ref={fileInputRef}
                          type="file"
                          onChange={(e) => {
                            const file = e.currentTarget.files?.[0];
                            if (!file) {
                              return;
                            }
                            if (file.size > MAX_FILE_SIZE) {
                              window.alert(MAX_FILE_SIZE_EXCEEDED_MESSAGE);
                              if (fileInputRef && fileInputRef.current) {
                                fileInputRef.current.value = '';
                              }
                              return;
                            }
                            return onFileInputChange(file);
                          }}
                          data-testid="file_attachment_field_input"
                        />
                      </div>
                      {fileUploadFailed && <div className="text-red-600">File upload failed. Please try again.</div>}
                      {isFileUploading && <div>Uploading...</div>}
                    </>
                  )}
                  {hasRecordedAttachment && (
                    <div className="flex items-center">
                      <div className="flex grow">
                        <div className="bg-white ml-2">
                          <AttachmentPreview attachment={recorded?.value as AttachmentValue | undefined} />
                        </div>
                      </div>
                      <button
                        className="ml-2 rounded-full w-5 h-5 flex justify-center items-center disabled:bg-gray-100"
                        onClick={onCancelFileInput}
                        title="Cancel File Input"
                        aria-label="Cancel File Input"
                      >
                        <FontAwesomeIcon icon="times-circle" className="text-gray-400 hover:text-gray-500" />
                      </button>
                    </div>
                  )}
                </div>
              </Form>
            )}
          </Formik>
        )}

        {/* Render recorded field input */}
        {!isEnabled && (
          <div className="flex items-start w-full py-1 gap-x-2">
            {block.inputType === 'timestamp' && (
              <TimestampFieldInputDisplay
                type={block.dateTimeType}
                recorded={recorded as RunFieldInputTimestampBlock['recorded']}
              >
                <FieldInputNameDisplay blockName={block?.name ?? ''} newBlockName={redlinedBlock?.name ?? ''} />
              </TimestampFieldInputDisplay>
            )}
            {block.inputType === 'checkbox' && (
              <div className="flex items-start">
                <input
                  type="checkbox"
                  className="w-6 h-6 mt-1.5 border text-gray-500 bg-gray-300 rounded-sm"
                  checked={!!recorded?.value}
                  disabled
                />
              </div>
            )}

            {/* Using flex: 2 for the label and flex: 1 for the value, allows us to give priority to the label before wrapping. */}
            {block.inputType !== 'timestamp' && block.inputType !== 'number' && (
              <TextLinkify>
                <div className="flex self-center max-w-max">
                  {redlinedBlock && (
                    <div className="min-w-0 whitespace-pre-line break-words">
                      <ProcedureFieldDiff original={blockName ?? ''} redlined={redlinedBlock?.name ?? ''} />
                    </div>
                  )}
                  {!redlinedBlock && <MarkdownView text={block.name} />}
                </div>
              </TextLinkify>
            )}

            {block.inputType === 'text' && (
              <>
                <div className="self-start mt-1.5">=</div>
                <div style={{ flex: '1' }} className="flex flex-nowrap flex-grow justify-between">
                  <div className="flex flex-nowrap">
                    {(!recorded || isEmptyValue(recorded.value)) && (
                      // hardcoded width and height to match TextAreaAutoHeight styling
                      <div className="w-48 h-[38px] border border-gray-400 rounded bg-gray-300 bg-opacity-50"></div>
                    )}
                    {recorded && !isEmptyValue(recorded.value) && (
                      <div
                        style={{ minWidth: '12rem' }}
                        className=" p-2 border border-gray-400 rounded bg-gray-300 bg-opacity-50 whitespace-pre-wrap"
                      >
                        <MarkdownView text={recorded?.value as RecordedString} />
                      </div>
                    )}
                    {!isNil(block.units) && (
                      <span className="ml-2 self-center whitespace-pre-wrap">
                        {redlinedBlock && (
                          <ProcedureFieldDiff
                            original={(block as FieldInputTextBlock | FieldInputNumberBlock).units ?? ''}
                            redlined={(redlinedBlock as FieldInputTextBlock | FieldInputNumberBlock).units ?? ''}
                          />
                        )}
                        {!redlinedBlock && block.units && <UnitDisplay unit={block.units} />}
                      </span>
                    )}
                  </div>
                </div>
              </>
            )}

            {block.inputType === 'number' && (
              <>
                {!blockDiff && (
                  <NumberFieldInputDisplay block={block} recorded={recorded as RunFieldInputRecorded<number>} />
                )}
                {blockDiff && <ReviewNumberFieldInput block={blockDiff as FieldInputNumberBlockDiffElement} />}
              </>
            )}

            {isSelectType && isRunningProcedure && (
              <div>
                <Select
                  classNamePrefix="react-select"
                  className="w-64"
                  styles={selectValueSelectedStyles}
                  value={valueSelected}
                  aria-label="Field Input Select"
                  isDisabled={true}
                />
              </div>
            )}
            {isSelectType && !isRunningProcedure && (
              <div className="flex flex-none max-w-full pr-14 w-64">
                <Formik
                  initialValues={{}}
                  onSubmit={() => {
                    /* no-op */
                  }}
                >
                  <Field
                    value={selectOptions}
                    component={FieldSetMultiSelectCreatable}
                    options={selectOptionsList}
                    placeholder="Create options*"
                    isDisabled={true}
                  />
                </Formik>
              </div>
            )}

            {isSketchType && !hasRecordedSketch && (
              <Button type="primary" isDisabled={true}>
                <FontAwesomeIcon icon="paintbrush" />
                <span>Add Sketch</span>
              </Button>
            )}

            {hasRecordedSketch && (
              <div className="flex">
                {(recorded?.value as SketchValue)?.text && (
                  <div
                    style={{ minWidth: '12rem' }}
                    className="h-fit  p-2 border border-gray-400 rounded bg-gray-300 bg-opacity-50 whitespace-pre-wrap"
                  >
                    {(recorded?.value as SketchValue)?.text as RecordedString}
                  </div>
                )}

                <div className="ml-2">
                  <AttachmentPreview
                    attachment={recorded?.value as AttachmentValue | undefined}
                    size="sm"
                    crop={false}
                    canDownload={false}
                  />
                </div>
              </div>
            )}

            {hasRecordedAttachment && (
              <div className="mt-0.5">
                <AttachmentPreview attachment={recorded?.value as AttachmentValue | undefined} />
              </div>
            )}
            {isAttachmentType && !hasRecordedAttachment && (
              <div className="mt-1">
                <input type="file" disabled />
              </div>
            )}
          </div>
        )}
        {/* Multiple choice options are rendered in a vertical list */}
        {isMultipleChoiceType && (
          <div className="flex mb-2">
            <Formik
              initialValues={{}}
              onSubmit={() => {
                /* no-op */
              }}
            >
              <Field
                name={blockId}
                onChange={setRecordedValue}
                options={(block as FieldInputMultipleChoiceBlock).options}
                value={recorded && recorded.value}
                component={RadioGroupFieldSet}
                isDisabled={!isEnabled}
              />
            </Formik>
          </div>
        )}

        {block.inputType === 'external_search' && (
          <FieldInputExternalSearch
            block={block as FieldInputExternalSearchBlock}
            recorded={recorded as { value: ExternalDataValue }}
            isEnabled={isEnabled}
            onRecordValuesChanged={onRecordValuesChanged as (recorded: RecordedValue<ExternalDataItem>) => void}
            onContentRefChanged={onContentRefChanged}
            scrollMarginTopValueRem={scrollMarginTopValueRem}
          />
        )}
      </div>
    );
  }
);

export default FieldInput;
