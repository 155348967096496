import { PERM } from '../../lib/auth';
import { useAuth } from '../../contexts/AuthContext';
import { useNavState } from '../../contexts/NavContext';

type ManufacturingAuth = {
  canEditTools: boolean;
  canEditPurchaseOrders: boolean;
  hasAdminPermission: boolean;
  hasEditPermission: boolean;
  hasOperatorPermission: boolean;
  hasWorkspaceEditPermission: boolean;
  hasWorkspaceViewerPermission: boolean;
  hasEditPermissionForProject: (projectId: string | null) => boolean;
  hasOperatorPermissionForProject: (projectId?: string) => boolean;
};

const useManufacturingAuth = (): ManufacturingAuth => {
  const { auth } = useAuth();
  const { projectId } = useNavState();

  const hasWorkspaceAccess = !auth.noWorkspaceAccess();
  const hasAdminPermission =
    hasWorkspaceAccess && auth.hasPermission(PERM.BUILDS_IMPORT_PARTS);
  const hasWorkspaceEditPermission =
    hasWorkspaceAccess && auth.hasPermission(PERM.BUILDS_EDIT);
  const hasWorkspaceOperatorPermission =
    hasWorkspaceAccess && auth.hasPermission(PERM.BUILDS_ADD_INVENTORY);
  const hasWorkspaceViewerPermission =
    hasWorkspaceAccess && auth.hasPermission(PERM.VIEW_SETTINGS);

  const hasEditPermission =
    hasWorkspaceEditPermission ||
    auth.hasPermission(PERM.BUILDS_EDIT, projectId);

  const hasOperatorPermission =
    hasWorkspaceOperatorPermission ||
    auth.hasPermission(PERM.BUILDS_ADD_INVENTORY, projectId);

  const hasEditPermissionForProject = (
    projectId: string | undefined | null
  ) => {
    if (auth.hasPermission(PERM.BUILDS_EDIT, projectId)) {
      return true;
    }
    if (!projectId) {
      return false;
    }
    const editorPermissionProjects = auth.projectsWithEditPermission();
    return editorPermissionProjects.includes(projectId);
  };

  const hasOperatorPermissionForProject = (projectId?: string): boolean =>
    hasWorkspaceOperatorPermission ||
    auth.hasPermission(PERM.BUILDS_ADD_INVENTORY, projectId);

  const canEditTools =
    hasWorkspaceEditPermission || auth.hasProjectsWithEditPermission();
  const canEditPurchaseOrders = hasWorkspaceEditPermission;

  return {
    canEditTools,
    canEditPurchaseOrders,
    hasAdminPermission,
    hasEditPermission,
    hasOperatorPermission,
    hasWorkspaceEditPermission,
    hasWorkspaceViewerPermission,
    hasEditPermissionForProject,
    hasOperatorPermissionForProject,
  };
};

export default useManufacturingAuth;
