import sharedDiffUtil from 'shared/lib/diffUtil';
import { useCallback, useMemo } from 'react';
import PlaceholderBlock from '../Diff/PlaceholderBlock';
import ReviewBlockText from './ReviewBlockText';
import ReviewBlockTelemetry from './ReviewBlockTelemetry';
import ReviewBlockCommanding from './Blocks/ReviewBlockCommanding';
import ProcedureBlockReview from '../Blocks/ProcedureBlockReview';
import ReviewTableInput from '../TableInput/ReviewTableInput';
import ReviewBlockProcedureLink from './ReviewBlockProcedureLink';
import ReviewBlockAttachment from './Blocks/ReviewBlockAttachment';
import ReviewJumpTo from './ReviewJumpTo';
import ReviewReferenceBlock from './ReviewReferenceBlock';
import ReviewExpressionBlock from './ReviewExpressionBlock';
import ReviewPartKit from '../../manufacturing/components/Review/ReviewPartKit';
import ReviewPartBuild from '../../manufacturing/components/Review/ReviewPartBuild';
import ReviewInventoryDetailInput from '../../manufacturing/components/Review/ReviewInventoryDetailInput';
import ReviewToolCheckOutIn from '../../manufacturing/components/Tools/ReviewToolCheckOutIn';
import ReviewPartUsage from '../../manufacturing/components/Review/ReviewPartUsage';
import ReviewToolUsage from '../../manufacturing/components/Tools/ReviewToolUsage';
import ReviewTestCasesBlock, { TestCasesBlockDiffElement } from '../../testing/components/Review/ReviewTestCasesBlock';
import ReviewFieldInputTable from './ReviewFieldInputTable';
import {
  AttachmentBlockDiffElement,
  CommandingBlockDiffElement,
  ExpressionBlockDiffElement,
  FieldInputTableBlockDiffElement,
  InventoryDetailInputBlockDiffElement,
  JumpToBlockDiffElement,
  ProcedureLinkBlockDiffElement,
  ReviewToolCheckInBlock,
  ReviewToolCheckOutBlock,
  ReviewToolUsageBlock,
  StepBlockDiffElement,
  TelemetryBlockDiffElement,
  TextBlockDiffElement,
} from 'shared/lib/types/views/procedures';
import { useDatabaseServices } from '../../contexts/DatabaseContext';
import { ProcedureContentBlockTypes } from 'shared/lib/types/blockTypes';
import VirtualizedElement from '../../elements/Virtualized/VirtualizedElement';
import { MainScrollPanelId } from '../../screens/NavigationV3';

interface ReviewBlocksProps {
  block: StepBlockDiffElement;
  onClickPlaceholder?: () => void;
  substepKeys?: { [blockId: string]: string };
  isHidden?: boolean;
  onRefChanged?: (element: HTMLElement, blockId: string) => void;
  scrollToBufferRem;
  docState;
  sourceName;
  isValid: boolean;
  onlyHasToTheSideImages: boolean;
  isMobile: boolean;
  toTheSideImages?: Array<unknown>;
  isStepComplete: boolean;
  optimize?: boolean;
}

const ReviewBlock = ({
  block,
  onClickPlaceholder = () => null,
  substepKeys = {},
  isHidden = false,
  onRefChanged,
  scrollToBufferRem,
  docState,
  sourceName,
  isValid = true,
  onlyHasToTheSideImages,
  isMobile,
  toTheSideImages = [],
  isStepComplete = false,
  optimize = false,
}: ReviewBlocksProps) => {
  const { currentTeamId } = useDatabaseServices();
  const type = useMemo(
    () => (sharedDiffUtil.getDiffValue<string>(block, 'type', 'new') ?? 'placeholder') as StepBlockDiffElement['type'],
    [block]
  );

  const canOptimize = useMemo(() => {
    return optimize && type.toLowerCase() !== 'attachment';
  }, [optimize, type]);

  const blockId = useMemo(() => sharedDiffUtil.getDiffValue<string>(block, 'id', 'new'), [block]);

  const _onRefChanged = useCallback(
    (element) => {
      if (typeof onRefChanged === 'function') {
        onRefChanged(element, blockId);
      }
    },
    [onRefChanged, blockId]
  );

  return (
    <tr aria-label="Content Block" role="region">
      <td />
      <td colSpan={2}>
        <div className="ml-4">
          <VirtualizedElement
            scrollElementId={MainScrollPanelId}
            optimize={canOptimize}
            namespace="review_virtualized_elements"
            overrideVirtualization={false}
            onRefChanged={_onRefChanged}
          >
            {type.toLowerCase() === 'placeholder' && <PlaceholderBlock onClick={onClickPlaceholder} />}
            {type.toLowerCase() === 'text' && (
              <ReviewBlockText
                block={block as TextBlockDiffElement}
                blockLabel={substepKeys[blockId]}
                isHidden={isHidden}
                scrollMarginTopValueRem={scrollToBufferRem}
              />
            )}
            {/* Render telemetry content row */}
            {/* TODO: Refactor to use ProcedureBlocReview */}
            {type.toLowerCase() === 'telemetry' && (
              <ReviewBlockTelemetry
                blockLabel={substepKeys[blockId]}
                telemetry={block as TelemetryBlockDiffElement}
                docState={docState}
                isHidden={isHidden}
                isSpacerHidden={false}
              />
            )}
            {/* Render commanding content row */}
            {type.toLowerCase() === 'commanding' && (
              <ReviewBlockCommanding
                blockLabel={substepKeys[blockId]}
                commanding={block as CommandingBlockDiffElement}
                isHidden={isHidden}
                isSpacerHidden={false}
              />
            )}
            {/* Render procedure block */}
            {(type.toLowerCase() === 'input' ||
              type.toLowerCase() === 'alert' ||
              type.toLowerCase() === 'requirement' ||
              type.toLowerCase() === 'external_item') && (
              <ProcedureBlockReview
                block={block}
                blockLabel={substepKeys[blockId]}
                isEnabled={false}
                isHidden={isHidden}
                scrollMarginTopValueRem={scrollToBufferRem}
                recorded={undefined}
                isSpacerHidden={undefined}
                isDark={undefined}
              />
            )}
            {/* Render Table input */}
            {type.toLowerCase() === 'table_input' && (
              <ReviewTableInput
                content={block}
                blockLabel={substepKeys[blockId]}
                isHidden={isHidden}
                isSpacerHidden={false}
                onSelectCell={undefined}
                selectedCell={undefined}
              />
            )}
            {/* Render procedure link row */}
            {type.toLowerCase() === 'procedure_link' && (
              <ReviewBlockProcedureLink
                blockLabel={substepKeys[blockId]}
                link={block as ProcedureLinkBlockDiffElement}
                isHidden={isHidden}
                sourceName={sourceName}
              />
            )}
            {/* Render attachments row */}
            {type.toLowerCase() === 'attachment' &&
              (onlyHasToTheSideImages || isMobile || !toTheSideImages.includes(block)) && (
                <ReviewBlockAttachment
                  attachment={block as AttachmentBlockDiffElement}
                  blockLabel={substepKeys[blockId]}
                  isHidden={isHidden}
                  isSpacerHidden={false}
                />
              )}
            {/* Render jump to row */}
            {type.toLowerCase() === ProcedureContentBlockTypes.JumpTo && (
              <ReviewJumpTo
                content={block as JumpToBlockDiffElement}
                blockLabel={substepKeys[blockId]}
                isHidden={isHidden}
                isValid={isValid}
              />
            )}
            {/* Render reference row */}
            {type.toLowerCase() === ProcedureContentBlockTypes.Reference && (
              <ReviewReferenceBlock
                originalReferencedContentId={sharedDiffUtil.getDiffValue(block, 'reference', 'new')}
                originalReferencedSubtype={sharedDiffUtil.getDiffValue(block, 'sub_reference', 'new')}
                originalReferencedFieldIndex={sharedDiffUtil.getDiffValue(block, 'field_index', 'new')}
                blockLabel={substepKeys[blockId]}
                isHidden={isHidden}
                isValid={isValid}
                diffChangeState={block.diff_change_state}
              />
            )}
            {/* Render expression row */}
            {type.toLowerCase() === ProcedureContentBlockTypes.Expression && (
              <ReviewExpressionBlock
                name={sharedDiffUtil.getDiffValue(block, 'name', 'new')}
                id={blockId}
                tokens={(block as ExpressionBlockDiffElement).tokens}
                blockLabel={substepKeys[blockId]}
                isHidden={isHidden}
                isValid={isValid}
                diffChangeState={block.diff_change_state}
              />
            )}
            {/* Render part kit */}
            {type.toLowerCase() === ProcedureContentBlockTypes.PartKit && (
              <ReviewPartKit
                content={block}
                onRecordValuesChanged={() => null}
                recorded={null}
                isHidden={isHidden}
                isEnabled={false}
                blockLabel={substepKeys[blockId]}
                isStepComplete={isStepComplete}
                teamId={currentTeamId}
              />
            )}
            {/* Render part kit */}
            {type.toLowerCase() === ProcedureContentBlockTypes.PartBuild && (
              <ReviewPartBuild
                content={block}
                recorded={null}
                isHidden={isHidden}
                isEnabled={false}
                blockLabel={substepKeys[blockId]}
                teamId={currentTeamId}
              />
            )}
            {/* Render inventory detail input */}
            {type.toLowerCase() === ProcedureContentBlockTypes.InventoryDetailInput && (
              <ReviewInventoryDetailInput
                content={block as InventoryDetailInputBlockDiffElement}
                onRecordValuesChanged={() => null}
                blockLabel={substepKeys[blockId]}
                isEnabled={false}
              />
            )}
            {/* Render tool check-out */}
            {type.toLowerCase() === ProcedureContentBlockTypes.ToolCheckOut && (
              <ReviewToolCheckOutIn
                content={block as ReviewToolCheckOutBlock | ReviewToolCheckInBlock}
                type="out"
                teamId={currentTeamId}
              />
            )}
            {/* Render tool check-in */}
            {type.toLowerCase() === ProcedureContentBlockTypes.ToolCheckIn && (
              <ReviewToolCheckOutIn
                content={block as ReviewToolCheckOutBlock | ReviewToolCheckInBlock}
                type="in"
                teamId={currentTeamId}
              />
            )}
            {/* Render tool usage */}
            {type.toLowerCase() === ProcedureContentBlockTypes.ToolUsage && (
              <ReviewToolUsage content={block as ReviewToolUsageBlock} teamId={currentTeamId} />
            )}
            {/* Render part usage */}
            {type.toLowerCase() === ProcedureContentBlockTypes.PartUsage && (
              <ReviewPartUsage
                content={block}
                isHidden={isHidden}
                blockLabel={substepKeys[blockId]}
                teamId={currentTeamId}
              />
            )}
            {type.toLowerCase() === ProcedureContentBlockTypes.TestCases && (
              <ReviewTestCasesBlock
                content={block as TestCasesBlockDiffElement}
                isHidden={isHidden}
                blockLabel={substepKeys[blockId]}
              />
            )}
            {type.toLowerCase() === ProcedureContentBlockTypes.FieldInputTable && (
              <ReviewFieldInputTable
                content={block as FieldInputTableBlockDiffElement}
                blockLabel={substepKeys[blockId]}
              />
            )}
          </VirtualizedElement>
        </div>
      </td>
    </tr>
  );
};

export default ReviewBlock;
