import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dialog } from 'primereact/dialog';
import { useCallback, useRef, useState } from 'react';
import { Camera, CameraType } from 'react-camera-pro';

const base64ToBlob = (base64: string, mimeType: string) => {
  const binaryString = window.atob(base64.split(',')[1]); // Remove data URL prefix
  const bytes = Uint8Array.from(binaryString, (char) => char.charCodeAt(0));
  return new Blob([bytes], { type: mimeType });
};

type CaptureModeFooterProps = {
  canSwitchCameras: boolean;
  onSwitchCamera: () => void;
  onCapture: () => void;
};

const CaptureModeFooter = ({ canSwitchCameras, onSwitchCamera, onCapture }: CaptureModeFooterProps) => (
  <div className="!-mt-4 flex w-full justify-between">
    <div className="h-8 w-8" />
    <button
      onClick={onCapture}
      className="flex justify-center items-center rounded-full !m-0 !h-8 !w-8 bg-blue-500 text-white"
    >
      <FontAwesomeIcon icon="camera" />
    </button>
    {canSwitchCameras ? (
      <button onClick={onSwitchCamera} className="flex justify-end items-center !m-0 !h-8 !w-8">
        <FontAwesomeIcon icon="rotate" />
      </button>
    ) : (
      <div className="h-8 w-8" />
    )}
  </div>
);

type ConfirmModeFooterProps = {
  onReject: () => void;
  onConfirm: () => void;
};

const ConfirmModeFooter = ({ onReject, onConfirm }: ConfirmModeFooterProps) => (
  <div className="!-mt-4 flex w-full justify-between">
    <button onClick={onReject} className="flex items-center justify-start gap-x-1 !m-0 !h-8 !w-8">
      <FontAwesomeIcon icon="rotate-left" />
      <span>Retake</span>
    </button>
    <button
      onClick={onConfirm}
      className="flex justify-center items-center rounded-full !m-0 !h-8 !w-8 bg-blue-500 text-white"
    >
      <FontAwesomeIcon icon="arrow-up" />
    </button>
  </div>
);

type PhotoCaptureModalProps = {
  onPhotoCapture: (photo: File) => void;
  onClose: () => void;
};

const PhotoCaptureModal = ({ onPhotoCapture, onClose }: PhotoCaptureModalProps) => {
  const camera = useRef<CameraType>(null);
  const [photo, setPhoto] = useState<string | null>(null);
  const [canSwitchCameras, setCanSwitchCameras] = useState(false);

  const switchCamera = useCallback(() => {
    if (camera.current) {
      camera.current.switchCamera();
    }
  }, []);

  const capturePhoto = useCallback(() => {
    if (camera.current) {
      const newPhoto = camera.current.takePhoto() as string;
      setPhoto(newPhoto);
    }
  }, []);

  const onCaptureConfirm = useCallback(() => {
    if (!photo) {
      return;
    }
    const blob = base64ToBlob(photo, 'image/jpeg');
    const file = new File([blob], 'photo.jpg', { type: 'image/jpeg' });
    onPhotoCapture(file);
  }, [photo, onPhotoCapture]);

  const getFooter = useCallback(
    () =>
      photo === null ? (
        <CaptureModeFooter canSwitchCameras={canSwitchCameras} onSwitchCamera={switchCamera} onCapture={capturePhoto} />
      ) : (
        <ConfirmModeFooter onReject={() => setPhoto(null)} onConfirm={onCaptureConfirm} />
      ),
    [photo, canSwitchCameras, switchCamera, capturePhoto, onCaptureConfirm]
  );

  return (
    <Dialog header="Capture Photo" footer={getFooter} onHide={onClose} visible={true} className="w-2/3">
      {photo !== null ? (
        <img src={photo} alt="Captured Preview" className="w-full rounded shadow" />
      ) : (
        <Camera
          ref={camera}
          aspectRatio={16 / 9}
          numberOfCamerasCallback={(num) => setCanSwitchCameras(num > 1)}
          errorMessages={{
            noCameraAccessible: 'No camera device accessible. Please connect your camera or try a different browser.',
            permissionDenied: 'Permission denied. Please refresh and give camera permission.',
            switchCamera:
              'It is not possible to switch camera to different one because there is only one video device accessible.',
            canvas: 'Canvas is not supported.',
          }}
        />
      )}
    </Dialog>
  );
};

export default PhotoCaptureModal;
