import { Part, UsageType } from 'shared/lib/types/postgres/manufacturing/types';
import PartUsageTypeSelect from './PartUsageTypeSelect';
import PartAndRevisionSelect from './PartAndRevisionSelect';
const PartAndUsageTypeSelect = ({ content, onChangePartDuringRun, onChangeUsage, recorded, isEnabled }) => {
  const onChangePart = (part: Part | null) => {
    onChangePartDuringRun && onChangePartDuringRun(part);
  };

  const usageTypes: Array<UsageType> = [];
  if (content?.part?.usage_types?.length > 0) {
    usageTypes.push(...content.part.usage_types);
  }

  if (recorded && recorded[0] && recorded[0].part && recorded[0].part.usage_types?.length > 0) {
    const { usage_types } = recorded[0].part;
    if (usage_types) {
      usageTypes.push(...usage_types);
    }
  }

  const onChangeUsageType = (option) => {
    if (!option) {
      return;
    }
    const usageType = usageTypes.find((t) => t.name === option.value);

    onChangeUsage && onChangeUsage(usageType);
  };

  let usageTypeSelected = null;
  if ((content && content.usage_types && content.usage_types.length > 0) || recorded) {
    usageTypeSelected = content.usage_types[0] || recorded[0]?.usage_type;
  }

  let partSelected = undefined;
  if ((content && content.part) || recorded) {
    partSelected = content?.part || recorded[0]?.part;
  }

  return (
    <div className="flex grow gap-x-3 items-end">
      <div className="flex flex-col">
        <PartAndRevisionSelect
          selectedPart={partSelected}
          onSelect={onChangePart}
          isEnabled={isEnabled}
          partSelectLabel="Part for Recording Usage"
        />
      </div>
      <div>
        <div className="w-56">
          <span className="field-title">Usage Type</span>
          <PartUsageTypeSelect
            selected={usageTypeSelected}
            usageTypes={usageTypes}
            onChange={onChangeUsageType}
            canCreate={false}
            isEnabled={partSelected ? isEnabled && true : false}
          />
        </div>
      </div>
    </div>
  );
};

export default PartAndUsageTypeSelect;
