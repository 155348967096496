import { faCaretDown, faFilter } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { OverlayPanel } from 'primereact/overlaypanel';
import { useRef } from 'react';
import Button, { BUTTON_TYPES } from './Button';
import FilterItems, { Filter } from './Home/FilterItems';
import { usePersistedViewReturns } from './Home/usePersistedView';
import { FilterOption } from './lib/SearchFilter';

export type FiltersButtonProps = {
  filters: ReadonlySet<Filter>;
  tagOptions: Array<FilterOption<string>>;
  priorityOptions?: Array<FilterOption<string>>;
  typeOptions?: Array<FilterOption<string>>;
  includeEndedOperations?: boolean;
  persistedView: usePersistedViewReturns;
};

const FiltersButton = ({
  persistedView,
  filters,
  tagOptions,
  includeEndedOperations = false,
  priorityOptions,
  typeOptions,
}: FiltersButtonProps) => {
  const op = useRef<OverlayPanel>(null);

  return (
    <>
      <Button
        trailingIcon={faCaretDown}
        type={BUTTON_TYPES.SECONDARY}
        size="sm"
        ariaLabel="Show Filters"
        onClick={(e) => op?.current?.toggle(e)}
      >
        <div className="flex items-center gap-x-2">
          <div className="flex items-center relative">
            <FontAwesomeIcon className="text-blue-500" icon={faFilter} />
            {persistedView.areAnyFiltersSet && (
              <div className="absolute -top-2.5 -right-2.5 text-red-500 mr-1">
                <FontAwesomeIcon icon="plus-circle" size="sm" />
              </div>
            )}
          </div>
          <span>Filters</span>
        </div>
      </Button>

      <OverlayPanel className="p-1" ref={op}>
        <div className="flex flex-row gap-x-2 text-sm font-medium items-center">
          <FilterItems
            persistedView={persistedView}
            filters={filters}
            tagOptions={tagOptions}
            priorityOptions={priorityOptions}
            typeOptions={typeOptions}
            includeEndedOperations={includeEndedOperations}
          />
        </div>
      </OverlayPanel>
    </>
  );
};

export default FiltersButton;
