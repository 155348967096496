import React from 'react';
import { generateHiddenClassString } from '../../lib/styles';
import SubstepNumber from '../SubstepNumber';
import Spacer from '../Spacer';
import ReferenceTextViewDiff from '../ReferenceTextViewDiff';
import ProcedureDiffText from '../ProcedureDiffText';
import { TextBlockDiffElement } from 'shared/lib/types/views/procedures';

interface BlockTextProps {
  block: TextBlockDiffElement;
  blockLabel?: string;
  isHidden?: boolean;
  scrollMarginTopValueRem?: number;
}

const ReviewBlockText = ({ block, blockLabel, isHidden, scrollMarginTopValueRem }: BlockTextProps) => {
  return (
    <div
      aria-label="Text Content"
      className={generateHiddenClassString('mt-2 mr-8 flex page-break', isHidden)}
      style={{ scrollMarginTop: `${scrollMarginTopValueRem}rem` }}
    >
      <Spacer />
      <SubstepNumber blockLabel={blockLabel} hasExtraVerticalSpacing={false} />
      {/* Content */}
      <div className="min-w-0 w-full self-start">
        {block && block.text && (
          <div className="max-w-full break-words">
            {block.tokens && <ReferenceTextViewDiff block={block} />}
            {!block.tokens && (
              <ProcedureDiffText
                diffValue={block.text}
                diffChangeState={block.diff_change_state}
                useMarkdownWhenNoDiff={true}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ReviewBlockText;
