import { ViewTab } from 'shared/lib/types/postgres/users';
import { useNavState } from '../../contexts/NavContext';
import Icon from '../../elements/Icon';
import SearchInputControlled from '../../elements/SearchInputControlled';
import { RUN_HISTORY_KEY } from '../../screens/RunList';
import FiltersButton from '../FiltersButton';
import FlashMessage from '../FlashMessage';
import LoadingBadge from '../LoadingBadge';
import TabBar, { TabProps } from '../TabBar/TabBar';
import { FilterOption } from '../lib/SearchFilter';
import GridExpandCollapseButton, { RowWithProjectName } from './GridExpandCollapseButton';
import ViewTabToggle from './ViewTabToggle';
import { usePersistedViewReturns } from './usePersistedView';
import { faFolderClosed } from '@fortawesome/free-solid-svg-icons';
import ThreeDotMenu from '../../elements/ThreeDotMenu';
import { MenuContextAction } from '../MenuContext';
import { faSave } from '@fortawesome/free-regular-svg-icons';
import { Helmet } from 'react-helmet-async';
import { Filter } from './FilterItems';

interface ListHeaderProps {
  name: string;
  tabs?: ReadonlyArray<TabProps<string>>;
  rows?: ReadonlyArray<RowWithProjectName>;
  isLoading: boolean;
  persistedView: usePersistedViewReturns;
  filters: ReadonlySet<Filter>;
  /** because procedures and runs use different tags... */
  tagOptions: Array<FilterOption<string>>;
  actions?: JSX.Element;

  navigatedSection?: string;
  updateTab?: (tab: string) => void;
  canSaveDefaultView?: boolean;
  showViewTabToggle?: boolean;
  forceShowViewTabs?: boolean;
  isProjectAgnostic?: boolean;
  viewTabLabelOverride?: Record<ViewTab, string>;

  // temporarily until search bar revamp
  priorityOptions?: Array<FilterOption<string>>;
  typeOptions?: Array<FilterOption<string>>;
}

const ListHeader = ({
  name,
  tabs,
  rows,
  isLoading,
  persistedView,
  filters,
  tagOptions,
  actions,
  navigatedSection,
  updateTab,
  canSaveDefaultView = false,
  showViewTabToggle = true,
  forceShowViewTabs = false,
  isProjectAgnostic = false,
  priorityOptions,
  typeOptions,
  viewTabLabelOverride,
}: ListHeaderProps) => {
  const { projectId, projectName } = useNavState();

  const menuActions: Array<MenuContextAction> = canSaveDefaultView
    ? [
        {
          type: 'label',
          label: 'Save Default View',
          data: {
            icon: faSave,
            disabled: !persistedView.isSaveDefaultViewEnabled,
            onClick: () => {
              persistedView.saveDefaultView();
            },
          },
        },
      ]
    : [];

  return (
    <>
      <Helmet>
        <title>{name} - List</title>
      </Helmet>
      <FlashMessage message={persistedView.saveError} messageUpdater={persistedView.setSaveError} type="warning" />
      <FlashMessage message={persistedView.saveSuccess} messageUpdater={persistedView.setSaveSuccess} />

      {isLoading && (
        <div className="absolute z-200 inset-y-1/2 inset-x-1/2 flex items-center justify-center h-16">
          <LoadingBadge />
        </div>
      )}

      <div className="pt-4 flex flex-col w-full top-0">
        <div className="flex flex-row justify-between items-start dark:text-white">
          <div className="text-md mb-0 pb-0 whitespace-nowrap flex flex-row gap-x-1 items-center">
            {showViewTabToggle && !isProjectAgnostic && (
              <>
                {(!projectId || forceShowViewTabs) && (
                  <ViewTabToggle
                    viewTab={persistedView.viewTab}
                    setViewTab={persistedView.setViewTab}
                    viewTabLabelOverride={viewTabLabelOverride}
                  />
                )}
              </>
            )}
            {projectId && !isProjectAgnostic && (
              <>
                <Icon className="text-slate-500 text-xs" element={faFolderClosed} />
                <div className="text-slate-600 text-xs truncate max-w-32">{projectName}</div>
                <div className="text-slate-500 text-xs">/</div>
              </>
            )}
            <h1 className="text-base font-normal">{name}</h1>
            <div className="flex flex-row gap-x-2 pl-2">
              <SearchInputControlled
                placeholder={`Search ${name}`}
                searchTerm={persistedView.searchTerm}
                setSearchTerm={persistedView.setSearchTerm}
              />
              {filters?.size > 0 && (
                <FiltersButton
                  persistedView={persistedView}
                  filters={filters}
                  tagOptions={tagOptions}
                  priorityOptions={priorityOptions}
                  typeOptions={typeOptions}
                  includeEndedOperations={navigatedSection === RUN_HISTORY_KEY}
                />
              )}
            </div>
          </div>
          <div className="flex flex-row gap-x-2">
            {actions}
            <ThreeDotMenu boxSize="narrow" menuActions={menuActions} menuLabel="View Options" />
          </div>
        </div>
        <div className="flex flex-row justify-between my-2 mr-2">
          {tabs?.length && navigatedSection && updateTab ? (
            <TabBar tabs={tabs} selectedTab={navigatedSection} setSelectedTab={updateTab} />
          ) : (
            <div />
          )}

          {persistedView.viewTab === ViewTab.Tree && showViewTabToggle && rows && !forceShowViewTabs && (
            <GridExpandCollapseButton
              rows={rows as Array<RowWithProjectName>}
              expandedProjectNames={persistedView.expandedProjectNames}
              setExpandedProjectNames={persistedView.setExpandedProjectNames}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default ListHeader;
