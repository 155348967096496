import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { useSettings } from '../../contexts/SettingsContext';
import { useDatabaseServices } from '../../contexts/DatabaseContext';
import { ViewTab } from 'shared/lib/types/postgres/users';
import { isEqual } from 'lodash';
import { useMixpanel } from '../../contexts/MixpanelContext';
import { PurchaseOrderState } from 'shared/lib/types/postgres/manufacturing/orders';

export interface usePersistedViewReturns {
  saveSuccess: string | null;
  setSaveSuccess: Dispatch<SetStateAction<string | null>>;
  saveError: string | null;
  setSaveError: Dispatch<SetStateAction<string | null>>;
  isSaveDefaultViewEnabled: boolean;
  saveDefaultView: () => void;
  areAnyFiltersSet: boolean;

  // TODO: make these more generic during search revamp
  selectedProjectIds: ReadonlySet<string | null>;
  setSelectedProjectIds: Dispatch<SetStateAction<ReadonlySet<string | null>>>;
  selectedTagKeys: ReadonlySet<string | null>;
  setSelectedTagKeys: Dispatch<SetStateAction<ReadonlySet<string | null>>>;
  selectedRunTagKeys: ReadonlySet<string | null>;
  setSelectedRunTagKeys: Dispatch<SetStateAction<ReadonlySet<string | null>>>;
  viewTab: ViewTab;
  setViewTab: Dispatch<SetStateAction<ViewTab>>;
  searchTerm: string;
  setSearchTerm: Dispatch<SetStateAction<string>>;
  expandedProjectNames: ReadonlySet<string>;
  setExpandedProjectNames: Dispatch<SetStateAction<ReadonlySet<string>>>;
  selectedOperationKeys: ReadonlySet<string | null>;
  setSelectedOperationKeys: Dispatch<SetStateAction<ReadonlySet<string | null>>>;
  selectedPriorityIds: ReadonlySet<string | null>;
  setSelectedPriorityIds: Dispatch<SetStateAction<ReadonlySet<string | null>>>;
  selectedTypeIds: ReadonlySet<string | null>;
  setSelectedTypeIds: Dispatch<SetStateAction<ReadonlySet<string | null>>>;
  selectedLocationIds: Array<string>;
  setSelectedLocationIds: Dispatch<SetStateAction<Array<string>>>;
  selectedPOTags: Array<string>;
  setSelectedPOTags: Dispatch<SetStateAction<Array<string>>>;
  selectedPOStatuses: Array<PurchaseOrderState>;
  setSelectedPOStatuses: Dispatch<SetStateAction<Array<PurchaseOrderState>>>;
}

const usePersistedView = (): usePersistedViewReturns => {
  const { currentTeamId } = useDatabaseServices();
  const { mixpanel } = useMixpanel();
  const { defaultView, currentView, updateDefaultView, updateCurrentView } = useSettings();

  const [saveSuccess, setSaveSuccess] = useState<string | null>(null);
  const [saveError, setSaveError] = useState<string | null>(null);
  const [initializedViewTeamId, setInitializedViewTeamId] = useState(currentTeamId);
  const [selectedProjectIds, setSelectedProjectIds] = useState<ReadonlySet<string | null>>(
    new Set(currentView?.project_ids || [])
  );
  const [selectedTagKeys, setSelectedTagKeys] = useState<ReadonlySet<string | null>>(new Set(currentView?.tags || []));
  const [selectedRunTagKeys, setSelectedRunTagKeys] = useState<ReadonlySet<string | null>>(
    new Set(currentView?.run_tags || [])
  );

  // These are not yet persisted to a default view
  const [selectedOperationKeys, setSelectedOperationKeys] = useState<ReadonlySet<string | null>>(new Set());
  const [selectedPriorityIds, setSelectedPriorityIds] = useState<ReadonlySet<string | null>>(new Set());
  const [selectedTypeIds, setSelectedTypeIds] = useState<ReadonlySet<string | null>>(new Set());
  const [selectedLocationIds, setSelectedLocationIds] = useState<Array<string>>([]);
  const [selectedPOTags, setSelectedPOTags] = useState<Array<string>>([]);
  const [selectedPOStatuses, setSelectedPOStatuses] = useState<Array<PurchaseOrderState>>([]);

  const [viewTab, setViewTab] = useState<ViewTab>(currentView?.view_tab || ViewTab.List);
  const [searchTerm, setSearchTerm] = useState(currentView?.search_term || '');
  const [expandedProjectNames, setExpandedProjectNames] = useState<ReadonlySet<string>>(
    currentView?.expanded_project_names || new Set()
  );

  useEffect(() => {
    updateCurrentView({
      _id: 'default_view',
      project_ids: Array.from(selectedProjectIds),
      run_tags: Array.from(selectedRunTagKeys),
      tags: Array.from(selectedTagKeys),
      search_term: searchTerm,
      view_tab: viewTab,
      expanded_project_names: expandedProjectNames,
    });
  }, [
    expandedProjectNames,
    searchTerm,
    selectedProjectIds,
    selectedRunTagKeys,
    selectedTagKeys,
    updateCurrentView,
    viewTab,
  ]);

  useEffect(() => {
    /*
     * When the team changes, the default view will eventually become the current view
     * but not on the first invocation of this function, so we have to wait for them to
     * be the same to know that we can initialize the filter selections.  Otherwise
     * we can get into an infinite loop of setting filters and updating the currentView
     */
    if (currentTeamId !== initializedViewTeamId && currentView === defaultView) {
      setSelectedProjectIds(new Set(currentView?.project_ids || []));
      setSelectedTagKeys(new Set(currentView?.tags || []));
      setSelectedRunTagKeys(new Set(currentView?.run_tags || []));
      setInitializedViewTeamId(currentTeamId);
      if (currentView?.search_term) {
        setSearchTerm(currentView.search_term);
      }
      if (currentView?.view_tab) {
        setViewTab(currentView.view_tab);
      }
    }
  }, [currentView, currentTeamId, initializedViewTeamId, defaultView]);

  const isSaveDefaultViewEnabled = useMemo(() => {
    if (!currentView) {
      return !defaultView;
    }

    if (isEqual(defaultView, { _id: 'default_view' })) {
      // default view is in an empty state.  Persist current only if it's modified
      return Boolean(
        currentView?.project_ids?.length ||
          currentView?.tags?.length ||
          currentView?.run_tags?.length ||
          currentView?.search_term?.trim() ||
          currentView?.view_tab !== ViewTab.List
      );
    }

    // excluding expanded project names from persisted default view for now
    const { expanded_project_names, ...currentWithoutExpanded } = currentView;
    return !isEqual(currentWithoutExpanded, defaultView);
  }, [currentView, defaultView]);

  const saveDefaultView = () => {
    if (!defaultView) {
      return;
    }

    if (mixpanel) {
      mixpanel.track('Save Default View');
    }

    updateDefaultView({
      _id: 'default_view',
      project_ids: Array.from(selectedProjectIds),
      tags: Array.from(selectedTagKeys),
      run_tags: Array.from(selectedRunTagKeys),
      search_term: searchTerm,
      view_tab: viewTab,
    })
      .then(() => {
        setSaveError(null);
        setSaveSuccess('Updated your default view');
      })
      .catch((error) => {
        setSaveError('Error saving your default view');
      });
  };

  const areAnyFiltersSet = useMemo(() => {
    return (
      selectedProjectIds.size > 0 ||
      selectedTagKeys.size > 0 ||
      selectedRunTagKeys.size > 0 ||
      selectedOperationKeys.size > 0 ||
      selectedPriorityIds.size > 0 ||
      selectedTypeIds.size > 0 ||
      selectedLocationIds.length > 0 ||
      selectedPOTags.length > 0 ||
      selectedPOStatuses.length > 0
    );
  }, [
    selectedProjectIds,
    selectedTagKeys,
    selectedRunTagKeys,
    selectedOperationKeys,
    selectedPriorityIds,
    selectedTypeIds,
    selectedLocationIds,
    selectedPOTags,
    selectedPOStatuses,
  ]);

  return {
    saveSuccess,
    setSaveSuccess,
    saveError,
    setSaveError,
    isSaveDefaultViewEnabled,
    saveDefaultView,
    areAnyFiltersSet,
    selectedProjectIds,
    setSelectedProjectIds,
    selectedTagKeys,
    setSelectedTagKeys,
    selectedRunTagKeys,
    setSelectedRunTagKeys,
    viewTab,
    setViewTab,
    searchTerm,
    setSearchTerm,
    expandedProjectNames,
    setExpandedProjectNames,
    selectedOperationKeys,
    setSelectedOperationKeys,
    selectedPriorityIds,
    setSelectedPriorityIds,
    selectedTypeIds,
    setSelectedTypeIds,
    selectedLocationIds,
    setSelectedLocationIds,
    selectedPOTags,
    setSelectedPOTags,
    selectedPOStatuses,
    setSelectedPOStatuses,
  };
};

export default usePersistedView;
