import {
  Procedure,
  ReleaseStep,
  Section,
} from 'shared/lib/types/views/procedures';
import procedureUtil from './procedureUtil';

/**
 * This function is intended to be called right after batch steps have been materialized in a procedure that is being instantiated into a run. This function will go through and fix all references between batch steps to follow tab<->tab correspondence.
 *
 * @param procedure
 */
const fixBatchStepReferences = (procedure: Procedure): void => {
  const fixerData = getFixerData(procedure);

  for (const section of procedure.sections) {
    for (const step of section.steps) {
      fixBatchStepConditionals(step, fixerData);
      fixBatchStepDependencies(step, fixerData);
      fixBatchStepInputReferences(step, fixerData);
    }
  }
};

export default fixBatchStepReferences;

// internal
type FixerData = {
  stepsById: Record<string, ReleaseStep>;
  batchesById: Record<string, Array<ReleaseStep>>;
  contentIdToStepIdMap: Record<string, string>;
};

const getFixerData = (procedure: Procedure): FixerData => ({
  stepsById: procedureUtil.getStepIdToStepMap(procedure),
  batchesById: mapBatchesById(procedure),
  contentIdToStepIdMap: mapContentIdsToStepIds(procedure),
});

const mapBatchesById = (
  procedure: Procedure
): Record<string, Array<ReleaseStep>> => {
  const map: Record<string, Array<ReleaseStep>> = {};
  procedure.sections.forEach((section: Section) => {
    section.steps.forEach((step: ReleaseStep) => {
      if (step.batchProps) {
        const { batchId, index: batchIndex } = step.batchProps;
        map[batchId] ??= [];
        if (map[batchId].length !== batchIndex) {
          throw new Error(
            `Unexpected index ${batchIndex} for step ${step.id} in batch ${batchId}`
          );
        }
        map[batchId].push(step);
      }
    });
  });
  return map;
};

const mapContentIdsToStepIds = (
  procedure: Procedure
): Record<string, string> => {
  const map = {};
  procedure.sections.forEach((section: Section) => {
    for (const step of section.steps) {
      for (const content of step.content) {
        map[content.id] = step.id;
      }
    }
  });
  return map;
};

const fixBatchStepConditionals = (step: ReleaseStep, fixerData: FixerData) => {
  if (!step.batchProps || !step.conditionals) {
    return;
  }

  for (const conditional of step.conditionals) {
    if (conditional.target_type !== 'step') {
      continue;
    }
    conditional.target_id = findBatchCorrectReferenceStepId(
      conditional.target_id,
      step.batchProps.index,
      fixerData
    );
  }
};

const fixBatchStepDependencies = (step: ReleaseStep, fixerData: FixerData) => {
  if (!step.batchProps || !step.dependencies) {
    return;
  }

  for (const dependency of step.dependencies) {
    for (let i = 0; i < dependency.dependent_ids.length; i++) {
      dependency.dependent_ids[i] = findBatchCorrectReferenceStepId(
        dependency.dependent_ids[i],
        step.batchProps.index,
        fixerData
      );
    }
  }
};

const fixBatchStepInputReferences = (
  step: ReleaseStep,
  fixerData: FixerData
) => {
  if (!step.batchProps) {
    return;
  }

  const { contentIdToStepIdMap, stepsById } = fixerData;

  for (const content of step.content) {
    if (content.type !== 'reference') {
      continue;
    }

    const refContentId = content.reference;
    const refStepId = contentIdToStepIdMap[refContentId];
    const refStep = stepsById[refStepId];
    const refContentIndex = getContentIndex(refContentId, refStep);
    if (refContentIndex === undefined) {
      continue;
    }

    const correctRefStepId = findBatchCorrectReferenceStepId(
      refStepId,
      step.batchProps.index,
      fixerData
    );
    const correctRefStep = stepsById[correctRefStepId];
    if (!correctRefStep || correctRefStepId === refStepId) {
      continue;
    }

    const correctRefContent = correctRefStep.content[refContentIndex];
    if (correctRefContent) {
      content.reference = correctRefContent.id;
    }
  }
};

const findBatchCorrectReferenceStepId = (
  originalReferenceStepId: string,
  correctBatchIndex: number,
  fixerData: FixerData
): string => {
  const { stepsById, batchesById } = fixerData;
  const originalReferenceStep = stepsById[originalReferenceStepId];

  if (originalReferenceStep?.batchProps) {
    const batchId = originalReferenceStep.batchProps.batchId;
    const batch = batchesById[batchId];

    if (batch) {
      const correctReferenceStep = batch[correctBatchIndex];
      if (correctReferenceStep) {
        return correctReferenceStep.id;
      }
    }
  }

  return originalReferenceStepId;
};

const getContentIndex = (
  contentId: string,
  step?: ReleaseStep
): number | undefined => {
  const index = step?.content.findIndex((content) => content.id === contentId);
  return index !== -1 ? index : undefined;
};
