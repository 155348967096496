import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useRef, useCallback, useEffect, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import { Mention } from 'primereact/mention';

import { OverlaySize, OverlayUpload } from '../OverlayUpload';
import procedureUtil from '../../lib/procedureUtil';
import { useSettings } from '../../contexts/SettingsContext';
import { AttachmentValue } from 'shared/lib/types/views/procedures';
import { useDatabaseServices } from '../../contexts/DatabaseContext';
import { DatabaseServices } from '../../contexts/proceduresSlice';
import { UploadFileResponse } from '../../attachments/types';
import AttachmentsContainer from './AttachmentsContainer';
import Button from '../Button';

export type SuggestionType = {
  username: string;
  email: string;
};

export type FormCommentStateType = {
  text: string;
  mentions: Array<SuggestionType>;
  attachments: Array<AttachmentValue>;
};

const itemTemplate = (suggestion) => {
  return (
    <div className="flex">
      <span className="flex flex-col text-sm text-black">
        {suggestion.username}
        <small className="text-xs text-slate-500">{suggestion.email}</small>
      </span>
    </div>
  );
};

const getPastedFiles = function (e) {
  const items = (e.clipboardData || e.originalEvent.clipboardData).items;
  const files: Array<File> = [];

  for (const item of items) {
    if (item.kind === 'file') {
      const file = item.getAsFile();
      files.push(file);
    }
  }

  return files;
};

const getInitialState = () => {
  return {
    text: '',
    mentions: [],
    attachments: [],
  };
};

type FormCommentProps = {
  initialComment?: FormCommentStateType;
  onSubmit: (FormCommentStateType) => Promise<void>;
  focusOnMount?: boolean;
  commentButtonLabel?: string;
};

const FormComment = ({
  initialComment,
  onSubmit,
  focusOnMount = false,
  commentButtonLabel = 'Comment',
}: FormCommentProps) => {
  const { services }: { services: DatabaseServices } = useDatabaseServices();
  const [_state, setState] = useState<FormCommentStateType>(() => {
    const initialState = getInitialState();

    if (initialComment) {
      return {
        ...initialState,
        ...initialComment,
      };
    } else {
      return initialState;
    }
  });
  const containerRef = useRef<HTMLDivElement>(null);
  const mentionRef = useRef<Mention>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [isFocused, setIsFocused] = useState(focusOnMount);
  const [suggestions, setSuggestions] = useState<SuggestionType[]>([]);
  const { users } = useSettings();

  const userSuggestions = useMemo(() => {
    if (!users) {
      return [];
    }
    return Object.keys(users.users).map((email) => {
      const username = email.split('@')[0].replace(/[^a-zA-Z0-9-]/g, '-');

      return {
        email,
        username,
      };
    });
  }, [users]);

  const uploadFiles = useCallback(
    async (files: Array<File>) => {
      return Promise.all(
        files.map((file) => {
          return services.attachments.uploadFile(file, 'runs:comments', { remote: false });
        })
      );
    },
    [services?.attachments]
  );

  const filterSuggestions = useCallback(
    (e) => {
      const query = e.query;
      const filteredSuggestions = userSuggestions.filter((mention) =>
        mention.email.toLowerCase().includes(query.toLowerCase())
      );
      setSuggestions(filteredSuggestions);
    },
    [userSuggestions]
  );

  const addToAttachments = useCallback((attachments: Array<AttachmentValue>) => {
    const _attachments = [...attachments];

    setState((previous) => {
      const updatedAttachments = [...previous.attachments, ..._attachments];

      return {
        ...previous,
        attachments: updatedAttachments,
      };
    });
  }, []);

  const onPaste = useCallback(
    (e) => {
      const files = getPastedFiles(e);

      if (!files.length) {
        return;
      }

      return uploadFiles(files).then((attachments) => {
        addToAttachments(attachments as Array<UploadFileResponse>);
      });
    },
    [uploadFiles, addToAttachments]
  );

  useEffect(() => {
    if (!isFocused) {
      return;
    }

    const blurInput = (e) => {
      if (containerRef.current && !containerRef.current.contains(e.target)) {
        // Add a small delay to prevent screen jumping and not registering clicks on elements below this element.
        setTimeout(() => {
          setIsFocused(false);
        }, 100);
      }
    };

    document.addEventListener('mousedown', blurInput);

    return () => {
      document.removeEventListener('mousedown', blurInput);
    };
  }, [isFocused]);

  useEffect(() => {
    if (!inputRef.current) {
      return;
    }

    const input = inputRef.current;
    input.setSelectionRange(input.value.length, input.value.length);
    input.focus();

    input.addEventListener('paste', onPaste);

    return () => {
      input.removeEventListener('paste', onPaste);
    };
  }, [isFocused, onPaste]);

  const { getRootProps, isDragActive } = useDropzone({
    onDrop: async (acceptedFiles: File[]) => {
      await uploadFiles(acceptedFiles).then((attachments) => {
        addToAttachments(attachments as Array<UploadFileResponse>);
        setIsFocused(true);
      });
    },
    noClick: true,
    noKeyboard: true,
  });

  const isSubmitButtonDisabled = useMemo(() => {
    const text = _state.text && _state.text.trim();

    if (text === '' && !_state.attachments.length) {
      return true;
    }

    return false;
  }, [_state]);

  const openFilePicker = useCallback(() => {
    fileInputRef.current?.click();
  }, []);

  const onFileInputChange = useCallback(
    async (e) => {
      if (e.currentTarget.files) {
        await uploadFiles([...e.currentTarget.files]).then((attachments) => {
          addToAttachments(attachments as Array<UploadFileResponse>);
          if (fileInputRef.current) {
            fileInputRef.current.value = '';
          }
        });
      }
    },
    [uploadFiles, addToAttachments]
  );

  const onRemoveAttachment = useCallback((attachment) => {
    setState((previous) => {
      const removeIndex = previous.attachments.findIndex(
        (_attachment) => attachment.attachment_id === _attachment.attachment_id
      );
      const updatedAttachments: Array<AttachmentValue> = [];

      previous.attachments.forEach((_attachment, _attachmentIndex) => {
        if (removeIndex !== _attachmentIndex) {
          updatedAttachments.push(_attachment);
        }
      });

      return {
        ...previous,
        attachments: updatedAttachments,
      };
    });
  }, []);

  const onInputChange = useCallback((e) => {
    setState((previous) => ({
      ...previous,
      text: e.target?.value,
      mentions: [...procedureUtil.getValidMentions(previous.mentions, previous.text || '')],
    }));
  }, []);

  const onSuggestionSelect = useCallback((e) => {
    setState((previous) => ({ ...previous, mentions: [...previous.mentions, e.suggestion] }));
  }, []);

  const _onSubmit = useCallback(
    (e) => {
      e.preventDefault();

      if (isSubmitButtonDisabled) {
        return Promise.resolve();
      }

      const trimmedState = {
        ..._state,
        text: _state.text.trim(),
      };

      return onSubmit(trimmedState).then(() => {
        setState(getInitialState());
        setIsFocused(false);
      });
    },
    [onSubmit, _state, isSubmitButtonDisabled]
  );

  const onMentionKeyDown = useCallback(
    (e) => {
      if (e.key === 'Enter' && !e.shiftKey) {
        if (mentionRef.current?.getOverlay()) {
          return;
        }
        void _onSubmit(e);
      }
    },
    [_onSubmit]
  );

  const isDraft = useMemo(() => {
    return !isSubmitButtonDisabled;
  }, [isSubmitButtonDisabled]);

  const focus = useCallback(() => {
    setIsFocused(true);
  }, []);

  if (!isFocused) {
    return (
      <Button type="tertiary" size="sm" ariaLabel="Comment button" onClick={focus}>
        <div className="flex flex-row items-center gap-x-1 text-blue-500 hover:brightness-75">
          <FontAwesomeIcon icon="comment" />
          <div>{commentButtonLabel}</div>
          {isDraft && <FontAwesomeIcon className="text-gray-400" icon="pencil" />}
        </div>
      </Button>
    );
  }

  return (
    <div
      {...getRootProps({ role: 'none' })}
      id="form-comment"
      aria-label="Comment Input"
      ref={containerRef}
      className="grow"
    >
      {/** Drag and Drop upload files */}
      <OverlayUpload isDragActive={isDragActive} size={OverlaySize.small} />

      {isFocused && (
        <div
          className={`flex flex-col grow gap-y-1 rounded-md bg-white p-2 ring-2 ${
            isFocused ? 'ring-blue-500 shadow-lg' : 'ring-app-gray-400'
          }`}
        >
          {/** Comment Input */}
          <Mention
            ref={mentionRef}
            inputRef={inputRef}
            placeholder="Add comment"
            inputStyle={{ border: 'none' }}
            autoResize
            rows={1}
            trigger="@"
            value={_state.text}
            onChange={onInputChange}
            field="username"
            suggestions={suggestions}
            itemTemplate={itemTemplate}
            onSearch={filterSuggestions}
            onSelect={onSuggestionSelect}
            onKeyDown={onMentionKeyDown}
          />

          {/** Attachment previews */}
          <AttachmentsContainer attachments={_state.attachments} onRemove={onRemoveAttachment} />

          {/** Footer toolbar */}
          <div className="flex flex-row justify-between">
            {/* Left actions */}
            <div className="flex flex-row gap-x-2">
              <div>
                <button
                  type="button"
                  aria-label="Attach file"
                  className="text-slate-700 bg-slate-200 hover:bg-slate-300 rounded-full px-2 py-1"
                  onClick={openFilePicker}
                >
                  <FontAwesomeIcon icon="paperclip" />
                </button>
                {/** Hidden file input for file picker */}
                <input
                  ref={fileInputRef}
                  type="file"
                  multiple={true}
                  className="hidden"
                  onChange={onFileInputChange}
                  data-testid="file_attachment_input"
                />
              </div>
            </div>
            {/** Right actions */}
            <div>
              <button
                aria-label="Submit comment"
                type="button"
                className="rounded-md px-3 py-1 bg-blue-500 text-white hover:brightness-75 disabled:bg-white disabled:text-slate-300 disabled:pointer-events-none"
                disabled={isSubmitButtonDisabled}
                onClick={_onSubmit}
              >
                <FontAwesomeIcon icon="paper-plane" />
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default React.memo(FormComment);
