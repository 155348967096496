import { useMemo } from 'react';
import { Part } from 'shared/lib/types/postgres/manufacturing/types';
import { Item } from '../types';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDatabaseServices } from '../../contexts/DatabaseContext';
import { inventoryItemPath } from '../../lib/pathUtil';
import useItems from '../hooks/useItems';
import { getTrackingValue } from '../lib/items';
import Revision from './Revision';
import Badge from './Badge';

type InventoryItemBadgeProps = {
  itemId: string;
  isCheckedOut?: boolean;
};

const InventoryItemBadge = ({ itemId, isCheckedOut }: InventoryItemBadgeProps) => {
  const { getItem } = useItems();

  const item = useMemo(() => getItem(itemId), [itemId, getItem]);
  if (!item) {
    return null;
  }

  return (
    <Badge
      attachment={item.part.image}
      title={<Title part={item.part} item={item} />}
      subtitle={<Subtitle part={item.part} isCheckedOut={isCheckedOut} />}
    />
  );
};

type TitleProps = {
  part: Part;
  item: Item;
};

const Title = ({ part, item }: TitleProps) => {
  const { currentTeamId } = useDatabaseServices();

  return (
    <div className="flex items-center gap-x-1">
      <Link className="text-blue-600" to={inventoryItemPath(currentTeamId, item.id)}>
        <div>{getTrackingValue(item)}</div>
      </Link>
      <div className="flex items-center gap-x-0.5 text-gray-500">
        <div>{part.part_no}</div>
        <Revision revision={part.rev} prefix={false} ml={0} />
      </div>
    </div>
  );
};

type SubtitleProps = {
  part: Part;
  isCheckedOut?: boolean;
};

const Subtitle = ({ part, isCheckedOut }: SubtitleProps) => {
  return (
    <div className="flex items-center gap-x-1">
      <div className="text-gray-700">{part.name}</div>
      {isCheckedOut && <FontAwesomeIcon className="text-green-500" size="sm" icon="upload" />}
    </div>
  );
};

export default InventoryItemBadge;
