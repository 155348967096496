import { EntityType, Tag as GlobalTag } from 'shared/lib/types/api/settings/tags/models';
import { useDatabaseServices } from '../../../contexts/DatabaseContext';
import { DatabaseServices } from '../../../contexts/proceduresSlice';
import TagsDisplay from './TagsDisplay';
import { useCallback, useMemo, useState } from 'react';
import TagSelector from './TagSelector';
import TagModal from './TagModal';

interface TagManagerProps {
  entityTags: GlobalTag[];
  readOnlyEntityTags?: GlobalTag[];
  globalTags: GlobalTag[];
  entityId: string;
  entityType: EntityType;
  isDisabled?: boolean;
}

const TagManager = ({
  entityTags,
  globalTags,
  entityId,
  entityType,
  readOnlyEntityTags = [],
  isDisabled = false,
}: TagManagerProps) => {
  const { services }: { services: DatabaseServices } = useDatabaseServices();
  const [tagModalState, setTagModalState] = useState<{
    newTagName: string | undefined;
    selectedTag: GlobalTag | null;
    showModal: boolean;
  }>({
    newTagName: undefined,
    selectedTag: null,
    showModal: false,
  });

  const tagOptions = useMemo(() => {
    return globalTags.filter(
      (tag) => ![...entityTags, ...readOnlyEntityTags].some((entityTag) => entityTag.id === tag.id)
    );
  }, [globalTags, entityTags, readOnlyEntityTags]);

  const onSelectTag = useCallback(
    (tagId: string) => {
      return services.entityTags.addTagToEntity(entityType, entityId, tagId);
    },
    [entityId, entityType, services]
  );

  const onRemoveTag = useCallback(
    (tagId: string) => {
      return services.entityTags.removeTagFromEntity(entityType, entityId, tagId);
    },
    [entityId, entityType, services]
  );

  const onCreateTag = useCallback((tagName: string) => {
    setTagModalState({
      newTagName: tagName,
      showModal: true,
      selectedTag: null,
    });
  }, []);

  const onEditTag = useCallback((tag: GlobalTag) => {
    setTagModalState({
      newTagName: undefined,
      showModal: true,
      selectedTag: tag,
    });
  }, []);

  return (
    <div className="flex flex-row flex-wrap gap-y-1 items-center min-h-[3rem]">
      {tagModalState.showModal && (
        <TagModal
          selectedTag={tagModalState.selectedTag}
          newTagName={tagModalState.newTagName}
          tags={globalTags}
          onClose={() => setTagModalState({ newTagName: undefined, selectedTag: null, showModal: false })}
          entityId={entityId}
          entityType={entityType}
        />
      )}
      <TagsDisplay tags={readOnlyEntityTags} isDisabled={true} />
      <TagsDisplay
        tags={entityTags}
        onTagAction={!isDisabled ? (tag) => onRemoveTag(tag.id) : undefined}
        isDisabled={isDisabled}
      />
      {!isDisabled && (
        <TagSelector
          tagOptions={tagOptions}
          disallowedTags={[...entityTags, ...readOnlyEntityTags].map((tag) => tag.name)}
          onCreateTag={onCreateTag}
          onSelectTag={onSelectTag}
          onEditTag={onEditTag}
        />
      )}
    </div>
  );
};

export default TagManager;
