import { DEFAULT_DICTIONARY_ID } from './types/telemetry';

enum SocketIoActions {
  joinRoom = 'joinRoom',
  leaveRoom = 'leaveRoom',
}

const getTelemetryStreamId = ({
  teamId,
  parameterName,
  runId,
  variables,
  dictionaryId = DEFAULT_DICTIONARY_ID,
}: {
  teamId: string;
  parameterName: string;
  runId: string;
  variables?: object;
  dictionaryId?: number;
}): string => {
  let streamId = `${teamId}_${runId}_${dictionaryId}_${parameterName}`;
  if (variables) {
    const variablesHash = hashCode(JSON.stringify(variables));
    streamId += `_${variablesHash}`;
  }
  return streamId;
};

const hashCode = (stringInput) => {
  let hash = 0;
  let i;
  let chr: number;
  if (stringInput.length === 0) return hash;
  for (i = 0; i < stringInput.length; i++) {
    chr = stringInput.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
};

type GetRoomEventNameProps = {
  teamId: string;
  entityType: string;
  entityId?: string | number;
};

const getRoomEventName = ({
  teamId,
  entityType,
  entityId,
}: GetRoomEventNameProps): string => {
  let eventName = `${teamId}:${entityType}`;
  if (entityId) {
    eventName += `:${entityId}`;
  }
  return eventName;
};

type GetRebroadcastEventNameProps = {
  teamId: string;
  entityType: string;
  entityId?: string | number;
};

const getRebroadcastEventName = ({
  teamId,
  entityType,
  entityId,
}: GetRebroadcastEventNameProps): string => {
  const roomEventName = getRoomEventName({ teamId, entityType, entityId });
  return `${roomEventName}:rebroadcast`;
};

type GetEmitEventNameProps = {
  entityType: string;
  entityId?: string | number;
};

const getEmitEventName = ({
  entityType,
  entityId,
}: GetEmitEventNameProps): string => {
  let eventName = `${entityType}`;
  if (entityId) {
    eventName += `:${entityId}`;
  }
  return `${eventName}:event`;
};

export {
  SocketIoActions,
  getRoomEventName,
  getRebroadcastEventName,
  getEmitEventName,
  getTelemetryStreamId,
};
