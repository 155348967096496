import React, { useCallback } from 'react';
import ChildrenComments from './ChildrenComments';
import { RunStepComment } from 'shared/lib/types/views/procedures';
import useCommentHandler from '../hooks/useCommentHandler';
import { CommentInterface } from 'shared/lib/comment';
import Comment from './Comments/Comment';

import { FormCommentStateType } from './Comments/FormComment';

/*
 * Component for rendering all Parent Child Comments.
 *
 */

interface ParentCommentProps {
  childComments?: Array<RunStepComment>;
  parentId: string;
  parentComment: RunStepComment;
  sectionId: string;
  stepId: string;
  editComment: (comment: CommentInterface, commentId?: string) => Promise<void>;
  saveNewComment?: (comment) => Promise<void>;
  isRun?: boolean;
  isCommentingDisabled?: boolean;
}

const ParentComment = ({
  childComments,
  parentId,
  parentComment,
  sectionId,
  stepId,
  saveNewComment,
  editComment,
  isRun,
  isCommentingDisabled,
}: ParentCommentProps) => {
  const { isSubmittingComment, editCommentContext, submitCommentWithAttachments, handleEditClick, handleCancel } =
    useCommentHandler({
      onEditComment: editComment,
      autoFocus: true,
    });

  const onSubmit = useCallback(
    async (formCommentState: FormCommentStateType) => {
      const values = {
        comment: formCommentState.text,
        mentions: formCommentState.mentions,
        attachments: formCommentState.attachments,
        edited: true,
      };

      return submitCommentWithAttachments(values).then(() => handleCancel());
    },
    [submitCommentWithAttachments, handleCancel]
  );

  const onEdit = useCallback(() => {
    return handleEditClick(parentComment);
  }, [parentComment, handleEditClick]);

  return (
    <>
      <div aria-label="Comment Parent">
        <Comment
          comment={parentComment}
          isEditing={parentComment.id === editCommentContext.id}
          canEdit={!isCommentingDisabled}
          onEdit={onEdit}
          onSubmit={onSubmit}
          onCancel={handleCancel}
        />

        {/* Render Children Comments handling backwards compatibility for older run comments */}
        {parentComment.id && (
          <ChildrenComments
            childComments={childComments}
            parentId={parentId}
            sectionId={sectionId}
            stepId={stepId}
            saveNewComment={saveNewComment}
            editComment={editComment}
            isRun={isRun}
            isCommentingDisabled={isCommentingDisabled}
          />
        )}
      </div>
      {isSubmittingComment && <div>Saving Comment...</div>}
    </>
  );
};

export default React.memo(ParentComment);
