import { SuperLoginClient } from 'superlogin-client';
import { Revision, RevisionSummary } from '../lib/views/revisions';
import { API_URL } from '../config';
import superlogin from './superlogin';
import e3Login from './e3Login';

class RevisionsService {
  teamId: string;

  constructor(teamId: string, superlogin: SuperLoginClient | e3Login) {
    this.teamId = teamId;
  }

  /**
   * Check if a released procedure has a given version.
   */
  async isReleasedVersion(
    procedureId: string,
    version: string
  ): Promise<boolean> {
    const revisions = await this.getProcedureRevisions(procedureId);
    return revisions.some((revision) => revision.version === version);
  }

  /**
   * Get all procedure released revisions for a given procedure.
   *
   */
  getProcedureRevisions(procedureId: string): Promise<Array<RevisionSummary>> {
    const url = `${API_URL}/teams/${this.teamId}/procedures/${procedureId}/revisions`;
    return superlogin
      .getHttp()
      .get(url)
      .then((resp) => resp.data.data);
  }

  /**
   * Get a procedure revision document for the given procedure and rev number.
   */
  getProcedureRevision(
    procedureId: string,
    procedureRevNum: number
  ): Promise<Revision> {
    const url = `${API_URL}/teams/${this.teamId}/procedures/${procedureId}/revisions/${procedureRevNum}`;
    return superlogin
      .getHttp()
      .get(url)
      .then((resp) => resp.data);
  }
}

export default RevisionsService;
