import React, { useCallback, useMemo, useState } from 'react';

import { AttachmentValue } from 'shared/lib/types/views/procedures';
import Modal from '../components/Modal';
import AttachmentPreview from '../components/Attachments/AttachmentPreview';
import Button from '../components/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type AttachmentCarouselModalProps = {
  startIndex?: number;
  attachments: Array<AttachmentValue>;
  onClose: () => void;
};

const AttachmentCarouselModal = ({ startIndex = 0, attachments, onClose }: AttachmentCarouselModalProps) => {
  const [previewIndex, setPreviewIndex] = useState(startIndex);

  const currentAttachment = useMemo(() => {
    return attachments[previewIndex % attachments.length];
  }, [attachments, previewIndex]);

  const nextAttachment = useCallback(() => {
    setPreviewIndex((currentIndex) => {
      return currentIndex + 1;
    });
  }, []);

  const previousAttachment = useCallback(() => {
    setPreviewIndex((currentIndex) => {
      return Math.abs(currentIndex - 1);
    });
  }, []);

  const showPreviousAndNextButtons = useMemo(() => {
    return attachments.length > 1;
  }, [attachments]);

  return (
    <Modal size="md" primaryActionTitle="Close" onPrimaryAction={onClose}>
      <div className="flex flex-row grow items-center justify-center gap-x-2">
        {showPreviousAndNextButtons && (
          <Button type="secondary" onClick={previousAttachment}>
            <FontAwesomeIcon className="text-gray-500 hover:brightness-75" icon="caret-left" size="2xl" />
          </Button>
        )}
        <AttachmentPreview
          attachment={currentAttachment}
          onShouldRenderImageChange={() => undefined}
          file={undefined}
          size="xl"
        />
        {showPreviousAndNextButtons && (
          <Button type="secondary" onClick={nextAttachment}>
            <FontAwesomeIcon className="text-gray-500 hover:brightness-75" icon="caret-right" size="2xl" />
          </Button>
        )}
      </div>
    </Modal>
  );
};

export default React.memo(AttachmentCarouselModal);
