import React, { createContext, useState, useEffect, ReactNode } from 'react';
import superlogin from '../api/superlogin';
import { API_URL } from '../config';
import apm from '../lib/apm';

export type LicenseStatusInfo = {
  valid: boolean;
  warn: boolean;
};

const defaultStatus: LicenseStatusInfo = {
  valid: true,
  warn: false,
};

const StatusContext = createContext<LicenseStatusInfo>(defaultStatus);

const getStatus = async () => {
  const url = `${API_URL}/status/license`;
  const response = await superlogin.getHttp().get(url);
  return response.data;
};

export const StatusProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [status, setStatus] = useState<LicenseStatusInfo>(defaultStatus);

  useEffect(() => {
    const fetchStatus = async () => {
      try {
        const statusFromApi = await getStatus();
        setStatus(statusFromApi);
      } catch (error) {
        apm.captureError(error);
      }
    };
    void fetchStatus();
  }, []);

  return <StatusContext.Provider value={status}>{children}</StatusContext.Provider>;
};

export default StatusContext;
