import React, { useCallback, useMemo } from 'react';
import procedureUtil from '../lib/procedureUtil';
import DateTimeDisplay from './DateTimeDisplay';
import Tag from './Tag';
import { VIEW_MODE_LABELS, DEFAULT_VIEW_MODE_DEFAULT } from './FieldSetViewModeEditSelect';
import AvatarStack from './AvatarStack';
import { useSettings } from '../contexts/SettingsContext';
import Label from './Label';
import ProcedureFieldDiff from './ProcedureFieldDiff';
import diffUtil from '../lib/diffUtil';
import sharedDiffUtil, { ARRAY_CHANGE_SYMBOLS } from 'shared/lib/diffUtil';
import DiffContainer from './Diff/DiffContainer';
import { useReviewContext } from '../contexts/ReviewContext';
import useDiff from '../hooks/useDiff';
import {
  faBoltLightning,
  faShieldHalved,
  faRedo,
  faStepForward,
  faStrikethrough,
} from '@fortawesome/free-solid-svg-icons';
import ReviewSettingBadge from './Review/ReviewSettingBadge';
import { isStepSettingEnabled, isReleased } from 'shared/lib/procedureUtil';
import EntityTagManager from './Settings/Tags/EntityTagManager';
import { EntityType } from 'shared/lib/types/api/settings/tags/models';
import { useAuth } from '../contexts/AuthContext';
import { PERM } from '../lib/auth';

const PROCEDURE_LEVEL_SETTINGS = [
  {
    setting: 'automation_enabled',
    icon: faBoltLightning,
    tooltipText: 'Automation',
  },
  {
    setting: 'is_strict_signoff_enabled',
    icon: faShieldHalved,
    tooltipText: 'Strict Signoff',
  },
];
const PROCEDURE_LEVEL_STEP_SETTINGS = [
  {
    setting: 'skip_step_enabled',
    icon: faStepForward,
    tooltipText: 'Skip Step',
  },
  {
    setting: 'repeat_step_enabled',
    icon: faRedo,
    tooltipText: 'Repeat Step',
  },
  {
    setting: 'step_suggest_edits_enabled',
    icon: faStrikethrough,
    tooltipText: 'Suggest Edits',
  },
];

/**
 * Renders procedure name and metadata, including the code, version, etc.
 *
 * @param {Object} props
 * @param {Object} props.procedure
 * @param {number} [props.scrollToBufferRem]
 * @param {boolean} [props.isLatestReleasedVersion]
 */
const ProcedureDetails = ({ procedure, scrollToBufferRem = 0, isLatestReleasedVersion = true }) => {
  const { projects, isGlobalTagsEnabled } = useSettings();
  const { auth } = useAuth();
  const { onScrollToDiffRefChanged } = useReviewContext();
  const { handleOnScrollToDiffRefChanged } = useDiff({ onScrollToDiffRefChanged });

  // Only show approvers that are also reviewers.
  const displayApprovers = useMemo(() => {
    if (!procedure.reviewer_groups) {
      return [];
    }
    const approvalUserIdsSet = new Set();

    procedure.reviewer_groups.forEach((reviewerGroup) => {
      reviewerGroup.actions
        ?.filter((action) => action.type === 'approval')
        .forEach((action) => {
          if (action.operator_role) {
            approvalUserIdsSet.add(`${action.operator_role} ${action.user_id}`);
          } else {
            approvalUserIdsSet.add(`${action.user_id}`);
          }
        });
    });

    return Array.from(approvalUserIdsSet);
  }, [procedure.reviewer_groups]);

  const tagsDisplay = useMemo(() => diffUtil.getTags(procedure.tags, 'new'), [procedure.tags]);

  const getDefaultViewModeLabel = useCallback((defaultViewMode) => {
    if (defaultViewMode) {
      return VIEW_MODE_LABELS[defaultViewMode];
    }
    return VIEW_MODE_LABELS[DEFAULT_VIEW_MODE_DEFAULT];
  }, []);

  const defaultViewModeLabelOld = useMemo(() => {
    const defaultViewModeOld = sharedDiffUtil.getDiffValue(procedure, 'default_view_mode', 'old');
    return getDefaultViewModeLabel(defaultViewModeOld);
  }, [getDefaultViewModeLabel, procedure]);

  const defaultViewModeLabel = useMemo(() => {
    const defaultViewMode = sharedDiffUtil.getDiffValue(procedure, 'default_view_mode', 'new');
    return getDefaultViewModeLabel(defaultViewMode);
  }, [getDefaultViewModeLabel, procedure]);

  /**
   * @type {(version: 'old' | 'new') => string}
   */
  const getEndRunSignoffOperators = useCallback(
    (version) => {
      return diffUtil.getEndRunSignoffOperators(procedure.end_run_signoffs_groups, version);
    },
    [procedure]
  );

  const endRunSignoffOperatorsOld = useMemo(() => getEndRunSignoffOperators('old'), [getEndRunSignoffOperators]);
  const endRunSignoffOperators = useMemo(() => getEndRunSignoffOperators('new'), [getEndRunSignoffOperators]);

  const oldPrintHeader = useMemo(() => {
    return diffUtil.getPrintHeader(procedure, 'old');
  }, [procedure]);

  const printHeader = useMemo(() => {
    return diffUtil.getPrintHeader(procedure, 'new');
  }, [procedure]);

  const oldPrintFooter = useMemo(() => {
    return diffUtil.getPrintFooter(procedure, 'old');
  }, [procedure]);

  const printFooter = useMemo(() => {
    return diffUtil.getPrintFooter(procedure, 'new');
  }, [procedure]);

  const getProject = useCallback(
    (projectId) => {
      if (!projects || !projectId) {
        return '';
      }
      return Object.values(projects.projects).find((project) => project.id === projectId)?.name || '';
    },
    [projects]
  );

  const projectOld = useMemo(() => {
    const projectIdOld = sharedDiffUtil.getDiffValue(procedure, 'project_id', 'old');
    return getProject(projectIdOld);
  }, [procedure, getProject]);

  const project = useMemo(() => {
    const projectId = sharedDiffUtil.getDiffValue(procedure, 'project_id', 'new');
    return getProject(projectId);
  }, [getProject, procedure]);

  const procedureOwnerOld = useMemo(() => sharedDiffUtil.getDiffValue(procedure, 'owner', 'old'), [procedure]);
  const procedureOwner = useMemo(() => sharedDiffUtil.getDiffValue(procedure, 'owner', 'new'), [procedure]);

  const canEditProcedure = useMemo(
    () => auth.hasPermission(PERM.PROCEDURES_EDIT, procedure.project_id),
    [auth, procedure]
  );

  const procedureId = useMemo(() => {
    if (procedure.procedure_id) {
      return procedure.procedure_id;
    }
    return sharedDiffUtil.getDiffValue(procedure, '_id', 'old');
  }, [procedure]);

  return (
    <div className="grow min-w-0" data-testid="procedure-details">
      {/* min-w-0 (above) on flex child fixes long words pushing the size of this element
        out past the parent container. */}
      <div className="flex">
        <h1 className="mb-0 break-words">
          <ProcedureFieldDiff
            original={procedureUtil.getProcedureTitle(
              sharedDiffUtil.getDiffValue(procedure, 'code', 'old'),
              sharedDiffUtil.getDiffValue(procedure, 'name', 'old')
            )}
            redlined={procedureUtil.getProcedureTitle(
              sharedDiffUtil.getDiffValue(procedure, 'code', 'new'),
              sharedDiffUtil.getDiffValue(procedure, 'name', 'new')
            )}
            onScrollToDiffRefChanged={(element) => handleOnScrollToDiffRefChanged('title', element)}
          />
        </h1>
      </div>
      {/* Tags */}
      <div className="min-h-8">
        {isGlobalTagsEnabled() && (
          <EntityTagManager
            entityId={procedureId}
            entityType={EntityType.PROCEDURE}
            isDisabled={!canEditProcedure || !isLatestReleasedVersion}
          />
        )}
        {!isGlobalTagsEnabled() &&
          tagsDisplay &&
          tagsDisplay.map((tag) => <Tag key={tag.key} id={tag.key} name={tag.name} />)}
      </div>

      <div className="flex flex-col  gap-y-0.5">
        <div className="flex items-center">
          <span className="font-medium">
            <ProcedureFieldDiff
              original={procedureUtil.getVersionLabel(
                sharedDiffUtil.getDiffValue(procedure, 'version', 'old'),
                sharedDiffUtil.getDiffValue(procedure, 'state', 'new')
              )}
              redlined={procedureUtil.getVersionLabel(
                sharedDiffUtil.getDiffValue(procedure, 'version', 'new'),
                sharedDiffUtil.getDiffValue(procedure, 'state', 'new')
              )}
              onScrollToDiffRefChanged={(element) => handleOnScrollToDiffRefChanged('version', element)}
            />
          </span>
          {procedure.editedUserId && procedure.editedAt && (
            <span className="ml-4" aria-label={procedure.archived ? 'Archived Details' : 'Edited Details'}>
              <span className="font-medium">
                {procedure.archived ? 'Archived:' : isReleased(procedure) ? 'Released:' : 'Last Edited:'}
              </span>
              <span className="ml-0.5" />
              <AvatarStack userIds={[procedure.editedUserId]} />
              <span className="ml-0.5">
                <DateTimeDisplay timestamp={procedure.editedAt} />
              </span>
            </span>
          )}
        </div>
        <div className="mt-2 font-semibold uppercase">Procedure Settings</div>
        <div className="flex flex-wrap items-center gap-x-2">
          <div className="flex flex-row gap-x-1">
            {PROCEDURE_LEVEL_SETTINGS.map(({ setting, tooltipText, icon }) => {
              return (
                <div
                  key={setting}
                  style={{ scrollMarginTop: `${scrollToBufferRem}rem` }}
                  ref={(element) => handleOnScrollToDiffRefChanged(setting, element)}
                >
                  <ReviewSettingBadge
                    tooltipText={tooltipText}
                    icon={icon}
                    oldSettingValue={Boolean(sharedDiffUtil.getDiffValue(procedure, setting, 'old'))}
                    newSettingValue={Boolean(sharedDiffUtil.getDiffValue(procedure, setting, 'new'))}
                  />
                </div>
              );
            })}
            {PROCEDURE_LEVEL_STEP_SETTINGS.map(({ setting, tooltipText, icon }) => {
              return (
                <div
                  key={setting}
                  style={{ scrollMarginTop: `${scrollToBufferRem}rem` }}
                  ref={(element) => handleOnScrollToDiffRefChanged(setting, element)}
                >
                  <ReviewSettingBadge
                    key={setting}
                    tooltipText={tooltipText}
                    icon={icon}
                    oldSettingValue={isStepSettingEnabled(
                      undefined,
                      sharedDiffUtil.getDiffValue(procedure, setting, 'old')
                    )}
                    newSettingValue={isStepSettingEnabled(
                      undefined,
                      sharedDiffUtil.getDiffValue(procedure, setting, 'new')
                    )}
                  />
                </div>
              );
            })}
          </div>
          {(procedureOwnerOld || procedureOwner) && (
            <DiffContainer
              diffChangeState={
                procedureOwnerOld !== procedureOwner ? ARRAY_CHANGE_SYMBOLS.MODIFIED : ARRAY_CHANGE_SYMBOLS.UNCHANGED
              }
              label="Owner"
              width="fit"
              onScrollToDiffRefChanged={(element) => handleOnScrollToDiffRefChanged('owner', element)}
            >
              <div>
                <span className="font-medium">Owner:</span>
                <span className="ml-0.5" />
                <AvatarStack userIds={[procedureOwner]} />
                <span className="ml-4" />
              </div>
            </DiffContainer>
          )}
          {(project || projectOld) && (
            <div className="flex flex-nowrap">
              <span className="font-medium">Project:</span>
              <span className="ml-0.5" />
              <Label
                color="bg-gray-200"
                oldText={projectOld}
                text={project}
                onScrollToDiffRefChanged={(element) => handleOnScrollToDiffRefChanged('project', element)}
                scrollToBufferRem={scrollToBufferRem}
              />
              <span className="ml-4" />
            </div>
          )}
          <span className="font-medium">
            <span>Default View: </span>
            <span className="font-normal">
              <ProcedureFieldDiff
                original={defaultViewModeLabelOld}
                redlined={defaultViewModeLabel}
                onScrollToDiffRefChanged={(element) => handleOnScrollToDiffRefChanged('defaultView', element)}
              />
            </span>
          </span>
        </div>
        {(endRunSignoffOperatorsOld || endRunSignoffOperators) && (
          <span className="font-medium">
            End Procedure Permissions:{' '}
            <span className="font-normal">
              <ProcedureFieldDiff
                original={endRunSignoffOperatorsOld}
                redlined={endRunSignoffOperators}
                onScrollToDiffRefChanged={(element) =>
                  handleOnScrollToDiffRefChanged('endRunSignoffOperators', element)
                }
              />
            </span>
          </span>
        )}
        {(printHeader || oldPrintHeader) && (
          <span className="font-medium">
            <span>Print Header: </span>
            <span className="font-normal">
              <ProcedureFieldDiff
                original={oldPrintHeader}
                redlined={printHeader}
                onScrollToDiffRefChanged={(element) => handleOnScrollToDiffRefChanged('printHeader', element)}
              />
            </span>
          </span>
        )}
        {(printFooter || oldPrintFooter) && (
          <span className="font-medium">
            <span>Print Footer: </span>
            <span className="font-normal">
              <ProcedureFieldDiff
                original={oldPrintFooter}
                redlined={printFooter}
                onScrollToDiffRefChanged={(element) => handleOnScrollToDiffRefChanged('printFooter', element)}
              />
            </span>
          </span>
        )}
        {/* Spacer for approvals */}
        {displayApprovers.length === 0 && <div className="last:mb-3 h-4" />}
        {displayApprovers.length > 0 && (
          <div className="last:mb-3">
            <span>Approved by {displayApprovers.join(', ')}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProcedureDetails;
