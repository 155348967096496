import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ComponentPart, Part } from 'shared/lib/types/postgres/manufacturing/types';
import Tooltip from '../../elements/Tooltip';
import PartSelect from './PartSelect';
import RevisionSelect from './RevisionSelect';
import './styles/TreeSelect.css';

export type PartSelection = {
  part: Part;
  partRevisionId: string;
  nodeKeyId?: string;
};

type PartAndRevisionIdSelectProps = {
  selected: PartSelection | null;
  projectId?: string;
  onSelect: (selection: PartSelection | null) => void;
  /** @todo use this property once primereact is upgraded to v10 */
  canClearPart?: boolean;
  isDisabled?: boolean;
  partSelectLabel?: string;
  partSelectToolTip?: string;
  revisionSelectLabel?: string;
  shouldDisablePartSelect?: (part: Part | ComponentPart) => boolean;
};

const PartAndRevisionIdSelect = ({
  selected,
  projectId,
  onSelect,
  canClearPart = true,
  isDisabled = false,
  partSelectLabel = 'Part',
  partSelectToolTip,
  revisionSelectLabel = 'Revision',
  shouldDisablePartSelect = undefined,
}: PartAndRevisionIdSelectProps) => {
  return (
    <div className="flex">
      <div className="w-72 flex-col items-start">
        <div className="flex flex-row items-center">
          <div className="field-title">{partSelectLabel}</div>
          {partSelectToolTip && (
            <Tooltip content={partSelectToolTip}>
              <FontAwesomeIcon icon="circle-info" className="text-sm text-gray-400 ml-1" />
            </Tooltip>
          )}
        </div>
        <PartSelect
          selected={selected}
          projectId={projectId}
          isDisabled={isDisabled}
          shouldDisablePartSelect={shouldDisablePartSelect}
          onSelect={onSelect}
          canClearPart={canClearPart}
        />
      </div>
      <div className="w-48 ml-2 items-start">
        <div className="field-title">{revisionSelectLabel}</div>
        <RevisionSelect selected={selected} onSelect={onSelect} isDisabled={isDisabled} />
      </div>
    </div>
  );
};

export default PartAndRevisionIdSelect;
