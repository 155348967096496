import PromptRedline from '../../PromptRedline';
import React, { useCallback, useMemo } from 'react';
import {
  DraftTextBlock,
  ReleaseTextBlock,
  RunHeaderBlockRedline,
  RunStepBlockRedline,
} from 'shared/lib/types/views/procedures';
import revisionsUtil from '../../../lib/revisions';
import ProcedureFieldDiff from '../../ProcedureFieldDiff';
import { TextBlockContentErrors } from '../../../lib/types';

interface BlockRedlineBlockProps {
  block: DraftTextBlock;
  path: string;
  runRedline: RunHeaderBlockRedline | RunStepBlockRedline;
  contentErrors?: TextBlockContentErrors;
  acceptRedline: (path: string, block: ReleaseTextBlock, redline: RunHeaderBlockRedline | RunStepBlockRedline) => void;
  rejectRedline: (redline: RunHeaderBlockRedline | RunStepBlockRedline) => void;
  contextUrl?: string;
}

const TextBlockRedlineBlock = ({
  block,
  path,
  runRedline,
  contentErrors,
  acceptRedline,
  rejectRedline,
  contextUrl,
}: BlockRedlineBlockProps) => {
  const createdBy = useMemo(() => revisionsUtil.createdBy(runRedline), [runRedline]);
  const timestamp = useMemo(() => revisionsUtil.createdAt(runRedline), [runRedline]);
  const redlineFieldValue = useMemo(() => {
    const redlineBlock = (runRedline as RunHeaderBlockRedline).header
      ? revisionsUtil.getHeaderRedlineBlock(runRedline as RunHeaderBlockRedline)
      : revisionsUtil.getStepRedlineBlock(runRedline as RunStepBlockRedline);
    if (!redlineBlock) {
      return;
    }
    return redlineBlock.text;
  }, [runRedline]);

  const onAcceptRedline = useCallback(
    () => acceptRedline(path, block, runRedline),
    [acceptRedline, block, path, runRedline]
  );
  const onRejectRedline = useCallback(() => rejectRedline(runRedline), [rejectRedline, runRedline]);

  return (
    <PromptRedline
      allRedlineUserIds={[createdBy]} // TODO EPS-2141: Refactor to pass in a single string when the keep-all-redlines work is done.
      timestamp={timestamp}
      onAccept={onAcceptRedline}
      onReject={onRejectRedline}
      error={contentErrors?.redline}
      contextUrl={contextUrl}
    >
      <ProcedureFieldDiff original={block.text} redlined={redlineFieldValue} />
    </PromptRedline>
  );
};

export default TextBlockRedlineBlock;
