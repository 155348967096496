import { Link, useHistory } from 'react-router-dom';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import NavigationSubMenu, { SubNavItem } from './NavigationSubMenu';

interface Props {
  label: string;
  title?: string;
  icon: IconDefinition;
  hideLabels: boolean;
  currentScreen: string;
  items?: Array<SubNavItem>;
  isNavigationExpanded?: boolean;
  settingsPath?: string;
}

const NavigationItemWithMenu = ({
  label,
  title,
  icon,
  hideLabels,
  currentScreen,
  items,
  isNavigationExpanded = false,
  settingsPath,
}: Props) => {
  const [isFloatingMenuDisplayed, setIsFloatingMenuDisplayed] = useState(false);
  const history = useHistory();

  const isExpanded = useMemo(
    () => currentScreen === label || items?.some((item) => currentScreen === (item.key ?? item.label)),
    [currentScreen, items, label]
  );

  const [showSubItems, setShowSubItems] = useState(isExpanded);

  useEffect(() => {
    setShowSubItems(isExpanded);
  }, [isExpanded]);

  const onClick = useCallback(() => {
    if (isNavigationExpanded) {
      setShowSubItems((shown) => {
        if (!shown && items?.[0]) {
          history.push(items[0].to);
        }
        return !shown;
      });
    } else {
      setIsFloatingMenuDisplayed(true);
    }
  }, [history, isNavigationExpanded, items]);

  const shouldHighlight = currentScreen === label || (isExpanded && !isNavigationExpanded);

  /*
   * A combination of javascript and CSS is used to hide the settings icon.
   * The icon should be shown either when the row is selected or on hover,
   * but never when the navigation menu is collapsed.
   */
  const settingsHiddenClass = showSubItems ? '' : 'hidden';

  return (
    <>
      <div
        className={`group focus:bg-slate-700 focus:outline-none hover:bg-slate-700 mx-1 my-0.5 rounded-md ${
          shouldHighlight && 'bg-slate-700'
        }`}
      >
        <div className={`flex flex-row  items-center `}>
          <button onClick={onClick} className="w-full py-2 px-4 grid grid-cols-6 gap-2 items-center">
            <div className="flex flex-row justify-start col-span-6 items-center">
              <div title={title || label} className="flex flex-row gap-2">
                <div className="text-left">
                  <FontAwesomeIcon fixedWidth={true} icon={icon} size="lg" />
                </div>
                {!hideLabels && <div className="text-left whitespace-nowrap">{label}</div>}
              </div>
            </div>
          </button>
          {settingsPath && isNavigationExpanded && (
            <div className={`${settingsHiddenClass} group-hover:block`}>
              <Link to={settingsPath} className={` ${shouldHighlight && 'bg-slate-700'} flex items-center pr-1`}>
                <FontAwesomeIcon fixedWidth={true} icon="gear" />
              </Link>
            </div>
          )}
          {!hideLabels && items && (
            <button onClick={onClick} className="flex items-center pr-2">
              <FontAwesomeIcon fixedWidth={true} icon={showSubItems ? 'angle-down' : 'angle-right'} />
            </button>
          )}
        </div>
        {hideLabels && items && isFloatingMenuDisplayed && (
          <div className="absolute">
            <NavigationSubMenu
              title={label}
              items={items}
              currentScreen={currentScreen}
              settingsPath={settingsPath}
              closeMenu={() => setIsFloatingMenuDisplayed(false)}
            />
          </div>
        )}
      </div>
      <div className="w-full flex flex-col">
        {!hideLabels && showSubItems && items && (
          <>
            {items.map((item) => (
              <div key={item.label} className="flex flex-row">
                <div className="w-9 border-r border-slate-500" />
                <Link
                  className={`w-full  text-gray-300 rounded-md mx-3 my-0.5 ${
                    currentScreen === (item.key ?? item.label) && 'text-gray-50 bg-slate-700'
                  } focus:bg-slate-700 focus:outline-none py-1.5 px-4 items-center hover:bg-slate-700`}
                  to={item.to}
                >
                  {item.label}
                </Link>
              </div>
            ))}
          </>
        )}
      </div>
    </>
  );
};

export default NavigationItemWithMenu;
