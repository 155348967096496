import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

interface NavigationItemProps {
  label: string;
  title?: string;
  icon: IconDefinition;
  hideLabels: boolean;
  currentScreen: string;
  to?: string;
  onClick: () => void;
  settingsPath?: string;
}

export default function NavigationItem({
  label,
  title,
  icon,
  hideLabels,
  currentScreen,
  to,
  onClick,
  settingsPath,
}: NavigationItemProps) {
  const isCurrentScreen = currentScreen === label;
  const backgroundColorClass = isCurrentScreen ? 'bg-slate-700' : '';

  /*
   * A combination of javascript and CSS is used to hide the settings icon.
   * The icon should be shown either when the row is selected or on hover,
   * but never when the navigation menu is collapsed.
   */
  const settingsHiddenClass = isCurrentScreen ? '' : 'hidden';
  const itemButtonProps: NavigationItemButtonProps = { label, title, icon, hideLabels, onClick };
  return (
    <>
      <div
        className={`group flex flex-row  items-center mx-1 my-0.5 rounded-md focus:bg-slate-700 focus:outline-none hover:bg-slate-700 ${backgroundColorClass}`}
      >
        {to && <NavigationItemLink {...itemButtonProps} to={to} />}
        {!to && <NavigationItemButton {...itemButtonProps} />}
        {settingsPath && !hideLabels && (
          <div className={`${settingsHiddenClass} group-hover:block`}>
            <SettingsIcon settingsPath={settingsPath} />
          </div>
        )}
      </div>
    </>
  );
}

type NavigationItemLinkProps = NavigationItemButtonProps & { to: string };

const NavigationItemLink = ({ label, title, icon, hideLabels, to }: NavigationItemLinkProps) => {
  return (
    <Link to={to} className="focus:outline-none w-full py-2 px-4 grid grid-cols-6 gap-2 items-center">
      <div className="flex flex-row justify-between col-span-6 items-center">
        <div title={title || label} className="flex flex-row gap-2">
          <div className="text-left">
            <FontAwesomeIcon fixedWidth={true} icon={icon} size="lg" />
          </div>
          {!hideLabels && <div className="text-left">{label}</div>}
        </div>
      </div>
    </Link>
  );
};

interface NavigationItemButtonProps {
  label: string;
  title?: string;
  icon: IconDefinition;
  hideLabels: boolean;
  onClick: () => void;
}

const NavigationItemButton = ({ label, title, icon, hideLabels, onClick }: NavigationItemButtonProps) => {
  return (
    <button onClick={onClick} className="w-full py-2 px-4 grid grid-cols-6 gap-2 items-center">
      <div title={title || label} className="flex flex-row gap-2">
        <div className="text-left">
          <FontAwesomeIcon fixedWidth={true} icon={icon} size="lg" />
        </div>
        {!hideLabels && <div className="text-left col-span-5">{label}</div>}
      </div>
    </button>
  );
};

type SettingsIconProps = {
  settingsPath: string;
};

const SettingsIcon = ({ settingsPath }: SettingsIconProps) => {
  return (
    <Link to={settingsPath} className="flex items-center pr-2">
      <FontAwesomeIcon fixedWidth={true} icon="gear" />
    </Link>
  );
};
