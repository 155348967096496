/**
 * Returns a de-duped array of userIds that are assigned as reviewers via
 * an explicit user_id or being part of an assigned operator_role.
 * @returns array of userIds.
 */
import { Users } from './types/couch/settings';
import lodash from 'lodash';
import {
  ApproveAction,
  Reviewer,
  ReviewerAction,
  ReviewerGroup,
  ReviewerId,
  RevokeApprovalAction,
  DraftAction,
} from './types/couch/procedures';
import {
  generateReviewerGroupId,
  generateReviewerId,
  generateReviewTypeId,
} from './idUtil';
import { Operator, ReviewType } from './types/settings';
import { getOperatorRoleToUsersMap } from './settingsUtil';
import { Draft } from './types/views/procedures';

export type ReviewSettings = {
  reviewTypeOrGroupId: string | null;
  reviewerGroupMap: { [reviewTypeOrGroupId: string]: Array<ReviewerGroup> };
  reviewTypes?: Array<Pick<ReviewType, 'id' | 'name'>>;
};
const INITIAL_REVIEWER = { reviewer_ids: [] };

const INITIAL_REVIEWER_GROUP = {
  reviewers: [],
  actions: [],
};

const INITIAL_REVIEW_TYPE = {
  name: '',
  reviewer_groups: [],
};

export const getUserIdsFromReviewerGroups = (
  reviewerGroups: Array<ReviewerGroup>,
  usersDoc: Users | null
): Array<string> => {
  const userIds: Array<string> = [];
  const usersByOperatorRoles = getOperatorRoleToUsersMap(usersDoc?.users);

  reviewerGroups.forEach((reviewerGroup) => {
    reviewerGroup.reviewers.forEach((reviewer) => {
      reviewer.reviewer_ids.forEach((reviewerId) => {
        if (reviewerId.type === 'user_id') {
          userIds.push(reviewerId.value);
        } else if (reviewerId.type === 'operator_role') {
          // Use only the user_ids property if it is present and has value(s).
          if (reviewerId.user_ids && reviewerId.user_ids.length > 0) {
            userIds.push(...reviewerId.user_ids);
          } else {
            // Otherwise use all user ids that have the operator role.
            const usersByOperatorRole: Array<string> = Array.from(
              usersByOperatorRoles[reviewerId.value] ?? []
            );
            userIds.push(...usersByOperatorRole);
          }
        }
      });
    });
  });

  return lodash.uniq(userIds);
};

const _partitionApprovedReviewers = (
  reviewerGroups: Array<ReviewerGroup>,
  users: Users
): {
  approvedReviewers: Array<string>;
  unapprovedReviewers: Array<string>;
} => {
  const approvedReviewerIdSet = new Set(
    reviewerGroups.flatMap((rg) =>
      rg.actions
        .filter((action): action is ApproveAction => action.type === 'approval')
        .map((action) => action.reviewer_id)
    )
  );

  // Create pseudo reviewer groups to pass into getUserIdsFromReviewerGroups

  const approvedReviewGroups = lodash.cloneDeep(reviewerGroups).map((rg) => {
    rg.reviewers = rg.reviewers.filter((reviewer) =>
      approvedReviewerIdSet.has(reviewer.id)
    );
    return rg;
  });

  const unapprovedReviewGroups = lodash.cloneDeep(reviewerGroups).map((rg) => {
    rg.reviewers = rg.reviewers.filter(
      (reviewer) => !approvedReviewerIdSet.has(reviewer.id)
    );
    return rg;
  });

  const [approvedReviewers, unapprovedReviewers] = [
    approvedReviewGroups,
    unapprovedReviewGroups,
  ].map((rg) => getUserIdsFromReviewerGroups(rg, users));

  return {
    approvedReviewers,
    unapprovedReviewers,
  };
};

/**
 * Get a list of user ids that have a potential signoff for a review.
 *
 * For example, if the signoffs were [user1] [user2 | OP1], and user1 has the OP1 role,
 * then user1 signs off, then this function would return [user1, user2], because
 * user1 still has a role that is not signed off.
 */
export const getUnapprovedUserIds = (
  reviewerGroups: Array<ReviewerGroup>,
  users: Users
): Array<string> => {
  const { unapprovedReviewers } = _partitionApprovedReviewers(
    reviewerGroups,
    users
  );

  return unapprovedReviewers;
};

export const getNewUnapprovedUserIds = (
  oldReviewerGroups: Array<ReviewerGroup>,
  newReviewerGroups: Array<ReviewerGroup>,
  users: Users
): Array<string> => {
  const { unapprovedReviewers: oldUnapprovedReviewers } =
    _partitionApprovedReviewers(oldReviewerGroups, users);
  const { unapprovedReviewers: newUnapprovedReviewers } =
    _partitionApprovedReviewers(newReviewerGroups, users);

  // Return all unapproved userIds that are new and are not old.
  return lodash.difference(newUnapprovedReviewers, oldUnapprovedReviewers);
};

export const getHasReviewers = (
  reviewerGroups: Array<ReviewerGroup>
): boolean => reviewerGroups?.some((group) => group?.reviewers?.length > 0);

export const getIsRequiredApproval = (
  action: ApproveAction,
  reviewerGroups: Array<ReviewerGroup>
): boolean => {
  if (!reviewerGroups || reviewerGroups.length === 0) {
    return false;
  }

  return reviewerGroups[0].reviewers.some(
    (reviewer) => reviewer.id === action.reviewer_id && reviewer.is_required
  );
};

/**
 *
 * @returns true if an approval action for the reviewer exists in the actions array.
 */
export const getHasReviewerApproved = (
  reviewer: Reviewer,
  actions: Array<ReviewerAction>
): boolean => {
  if (!actions.length) {
    return false;
  }

  return actions.some(
    (action) => action.type === 'approval' && action.reviewer_id === reviewer.id
  );
};

/**
 * @returns true if every reviewer in reviewerGroup has approved.
 */
export const getHasReviewerGroupApproved = (
  reviewerGroup: ReviewerGroup
): boolean => {
  return reviewerGroup.reviewers.every((reviewer) =>
    getHasReviewerApproved(reviewer, reviewerGroup.actions)
  );
};

/**
 * @returns true if every reviewerGroup has approved.
 */
export const getHaveAllReviewerGroupsApproved = (
  reviewerGroups: Array<ReviewerGroup> = []
): boolean => {
  if (!reviewerGroups) {
    return true;
  }
  return reviewerGroups.every((reviewerGroup) =>
    getHasReviewerGroupApproved(reviewerGroup)
  );
};

// TODO: move `Draft` type to shared
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getIsReviewApproved = (review) => {
  if (review.reviewer_groups) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    return getHaveAllReviewerGroupsApproved(review.reviewer_groups);
  }

  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  throw new Error(`Review [${review._id}] does not have reviewer_groups.`);
};

export const getAreAllReviewerGroupsEmpty = (
  reviewerGroups: Array<ReviewerGroup>
): boolean => {
  return reviewerGroups.every(
    (reviewerGroup) => reviewerGroup.reviewers.length === 0
  );
};

/**
 * @returns {Object} New reviewer object
 */
export const getNewReviewer = (): Reviewer => {
  const newReviewer: Omit<Reviewer, 'id'> = lodash.cloneDeep(INITIAL_REVIEWER);
  (newReviewer as Reviewer).id = generateReviewerId();

  return newReviewer as Reviewer;
};

/**
 * @returns New ReviewerGroup object.
 */
export const getNewReviewerGroup = (): ReviewerGroup => {
  const newReviewerGroup: Omit<ReviewerGroup, 'id'> = lodash.cloneDeep(
    INITIAL_REVIEWER_GROUP
  );

  (newReviewerGroup as ReviewerGroup).id = generateReviewerGroupId();

  return newReviewerGroup as ReviewerGroup;
};

export const getNewReviewerGroupWithNewReviewer = (): ReviewerGroup => {
  const rg = getNewReviewerGroup();
  rg.reviewers = [getNewReviewer()];
  return rg;
};

/**
 * @returns  New ReviewerGroups Array, that includes an initial Reviewer Group object.
 */
export const getInitialReviewerGroups = (): Array<ReviewerGroup> => {
  const newReviewerGroup = getNewReviewerGroup();
  newReviewerGroup.reviewers = [];

  return [newReviewerGroup];
};

/**
 * @returns New review type object.
 */
export const getNewReviewType = (): ReviewType => {
  const newReviewType: Omit<ReviewType, 'id'> =
    lodash.cloneDeep(INITIAL_REVIEW_TYPE);

  (newReviewType as ReviewType).id = generateReviewTypeId();
  newReviewType.reviewer_groups = [getNewReviewerGroupWithNewReviewer()];
  newReviewType.reviewer_groups[0].name = '';

  return newReviewType as ReviewType;
};

/**
 * Gets the list of values within a Reviewer (e.g. for an OR reviewer of <tester@epsilon3.io | FD>,
 * the returned list would be ['tester@epsilon3.io', 'FD']
 */
const _getReviewerValues = (reviewer: Reviewer): Array<ReviewerId['value']> => {
  return reviewer.reviewer_ids.map((reviewerId) => reviewerId.value);
};

/**
 * Removes from changeable reviewers the reviewer id values that are in both required and changeable reviewers,
 * and returns a new deduped list of changeable reviewers.
 */
const _dedupChangeableReviewers = (
  requiredReviewers: Array<Reviewer>,
  changeableReviewers: Array<Reviewer>
): Array<Reviewer> => {
  const requiredReviewerValues = requiredReviewers.flatMap(_getReviewerValues);
  const requiredReviewerValueSet = new Set(requiredReviewerValues);

  const changeableReviewersCopy = lodash.cloneDeep(changeableReviewers);
  const changeableReviewersDeduped: Array<Reviewer> = [];
  changeableReviewersCopy.forEach((reviewer) => {
    reviewer.reviewer_ids = reviewer.reviewer_ids.filter(
      (reviewerId) => !requiredReviewerValueSet.has(reviewerId.value)
    );
    if (reviewer.reviewer_ids.length > 0) {
      changeableReviewersDeduped.push(reviewer);
    }
  });

  return changeableReviewersDeduped;
};

/**
 * Get changeable reviewers from the existing reviewers list by omitting required reviewers
 * and removing duplicates that exist in the required reviewers list.
 */
export const getChangeableReviewers = (
  requiredReviewers: Array<Reviewer>,
  existingReviewers: Array<Reviewer>
): Array<Reviewer> => {
  const changeableReviewers = existingReviewers.filter(
    (reviewer) => !reviewer.is_required
  );

  const changeableReviewersDeduped = _dedupChangeableReviewers(
    requiredReviewers,
    changeableReviewers
  );

  return changeableReviewersDeduped;
};

/**
 * Merge required reviewers to the passed-in procedure.
 * // TODO: move `Draft` type to shared
 * @deprecated
 */
export const mergeRequiredReviewersDeprecated = (
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  procedure,
  requiredReviewerGroups: Array<ReviewerGroup> | undefined
): void => {
  let updatedRequiredReviewerGroups = lodash.cloneDeep(requiredReviewerGroups);
  if (
    !updatedRequiredReviewerGroups ||
    updatedRequiredReviewerGroups.length === 0
  ) {
    updatedRequiredReviewerGroups = getInitialReviewerGroups();
    /*
     If there are no reviewer groups in the workspace, but there used to be,
     then any old name associated with the now-removed reviewer group should also be removed.
    */
    delete procedure.reviewer_groups?.[0]?.name;
  }
  if (!procedure.reviewer_groups || procedure.reviewer_groups.length === 0) {
    procedure.reviewer_groups = getInitialReviewerGroups();
  }

  const requiredReviewers = lodash.cloneDeep(
    updatedRequiredReviewerGroups[0].reviewers
  );
  const existingReviewers = lodash.cloneDeep(
    procedure.reviewer_groups[0].reviewers
  ) as Array<Reviewer>;

  requiredReviewers.forEach((reviewer) => (reviewer.is_required = true));
  const changeableReviewers = getChangeableReviewers(
    requiredReviewers,
    existingReviewers
  );

  procedure.reviewer_groups[0].reviewers = [
    ...requiredReviewers,
    ...changeableReviewers,
  ];

  _mergeOperatorRoleUserIds(
    existingReviewers,
    procedure.reviewer_groups[0].reviewers as Array<Reviewer>
  );

  if (updatedRequiredReviewerGroups[0].name) {
    procedure.reviewer_groups[0].name = updatedRequiredReviewerGroups[0].name;
  }
  if (requiredReviewerGroups && requiredReviewers.length > 0) {
    procedure.reviewer_groups[0].id = updatedRequiredReviewerGroups[0].id;
  }
  procedure.reviewer_groups[0].persist_approvals =
    updatedRequiredReviewerGroups[0].persist_approvals;
};

const _mergeOperatorRoleUserIds = (
  previousReviewers: Array<Reviewer>,
  updatedReviewers: Array<Reviewer>
) => {
  // Remove user_ids for later comparison.
  const previousReviewersCleaned = lodash.cloneDeep(previousReviewers);
  previousReviewersCleaned.forEach((_prevReviewer) => {
    _prevReviewer.reviewer_ids.forEach((reviewerId) => {
      if (reviewerId.type === 'operator_role') {
        delete reviewerId.user_ids;
      }
    });
  });

  /*
    Since adding user_ids changes the reviewer id, even for required reviewers,
    use the previous reviewer object if everything but user_ids matches in the
    reviewer_id
   */
  updatedReviewers.forEach((updatedReviewer, index) => {
    const prevIndex = previousReviewersCleaned.findIndex((_prevReviewer) => {
      return lodash.isEqual(
        _prevReviewer.reviewer_ids,
        updatedReviewer.reviewer_ids
      );
    });
    if (prevIndex !== -1) {
      const updatedReviewerIsRequired = updatedReviewers[index].is_required;
      updatedReviewers[index] = previousReviewers[prevIndex];
      updatedReviewers[index].is_required = updatedReviewerIsRequired;
    }
  });
};

export const mergeRequiredReviewers = (
  existingReviewerGroups,
  requiredReviewerGroups: Array<ReviewerGroup> | undefined,
  reviewTypeChanged: boolean
): Array<ReviewerGroup> => {
  let updatedRequiredReviewerGroups =
    lodash.cloneDeep(requiredReviewerGroups) ?? [];
  for (const rg of updatedRequiredReviewerGroups) {
    rg.reviewers.forEach((reviewer) => (reviewer.is_required = true));
  }

  /*
    No need to do any merging of reviewers or actions if the review type changed.
    Just use the new reviewer groups.
   */
  if (reviewTypeChanged) {
    return updatedRequiredReviewerGroups;
  }

  /*
    If the review type did not change,
    the reviewer groups in the review type may still have been added/removed/updated.
   */
  if (updatedRequiredReviewerGroups.length === 0) {
    updatedRequiredReviewerGroups = getInitialReviewerGroups();
  }

  let existingReviewerGroupsCopy = lodash.cloneDeep(
    existingReviewerGroups
  ) as Array<ReviewerGroup>;
  if (!existingReviewerGroups || existingReviewerGroups.length === 0) {
    existingReviewerGroupsCopy = getInitialReviewerGroups();
  }

  /*
    Update the first reviewer groups that overlap with
    the first reviewer groups in the previous procedure.
    If the order and id do not match exactly, remove the old reviewer group
    (along with its actions) and use the new reviewer group.
   */
  for (let i = 0; i < updatedRequiredReviewerGroups.length; i++) {
    /*
      If the new reviewer group has a different id from the previous reviewer group,
      replace the previous reviewer group with the new reviewer group.
     */
    if (
      !existingReviewerGroupsCopy[i] ||
      (requiredReviewerGroups &&
        existingReviewerGroupsCopy[i].id !==
          updatedRequiredReviewerGroups[i].id)
    ) {
      // Do nothing. The new reviewer group will be used.
      continue;
    }
    /*
      At this point previous and new reviewer groups are in the same location in the array
      and have the same id, so merge them to include changeable reviewers.
     */
    const newRequiredReviewers = updatedRequiredReviewerGroups[i].reviewers;
    const previousReviewers = existingReviewerGroupsCopy[i].reviewers;

    // Additional reviewers not included in the review type may have been added during review.
    const changeableReviewers = getChangeableReviewers(
      newRequiredReviewers,
      previousReviewers
    );

    updatedRequiredReviewerGroups[i].reviewers = [
      ...newRequiredReviewers,
      ...changeableReviewers,
    ];

    _mergeOperatorRoleUserIds(
      previousReviewers,
      updatedRequiredReviewerGroups[i].reviewers
    );

    // Keep the same actions in this case.
    updatedRequiredReviewerGroups[i].actions =
      existingReviewerGroupsCopy[i].actions;
  }

  return updatedRequiredReviewerGroups;
};

const _getConcatenatedReviewerIds = (reviewerId: ReviewerId): Array<string> => {
  const concatenatedReviewerIds = [`${reviewerId.type}:${reviewerId.value}`];
  if (
    reviewerId.type === 'operator_role' &&
    reviewerId.user_ids &&
    reviewerId.user_ids.length > 0
  ) {
    concatenatedReviewerIds.push(
      ...reviewerId.user_ids.map((userId) => `user_id:${userId}`)
    );
  }
  return concatenatedReviewerIds;
};

const _getReviewerIdsSet = (reviewers: Array<Reviewer>) => {
  const reviewersSet = new Set();

  reviewers.forEach((reviewer) => {
    reviewer.reviewer_ids.forEach((reviewerId) => {
      _getConcatenatedReviewerIds(reviewerId).forEach((concatId) => {
        reviewersSet.add(concatId);
      });
    });
  });

  return reviewersSet;
};

export const getRemainingReviewerIds = (
  allReviewerIds: Array<ReviewerId>,
  reviewers: Array<Reviewer>
) => {
  const usedReviewerIdsSet = _getReviewerIdsSet(reviewers);
  const remainingReviewerIds: Array<ReviewerId> = [];

  allReviewerIds.forEach((reviewerId) => {
    const concatenatedIds = _getConcatenatedReviewerIds(reviewerId);

    concatenatedIds.forEach((concatId) => {
      if (!usedReviewerIdsSet.has(concatId)) {
        remainingReviewerIds.push(reviewerId);
      }
    });
  });

  return remainingReviewerIds;
};

/**
 * Find the current reviewer group. If all reviewer groups are approved,
 * return the last group.
 */
const _getCurrentReviewerGroup = (
  reviewerGroups: Array<ReviewerGroup> | undefined
) => {
  if (!reviewerGroups || reviewerGroups.length === 0) {
    return null;
  }
  return (
    reviewerGroups.find((rg) => !getHasReviewerGroupApproved(rg)) ??
    reviewerGroups.at(-1)
  );
};

export const getReviewerGroupIdsToClear = (
  requiredReviewerGroups: Array<ReviewerGroup> | undefined
): Array<string> => {
  const currentReviewerGroup = _getCurrentReviewerGroup(requiredReviewerGroups);
  if (!currentReviewerGroup) {
    return [];
  }
  return currentReviewerGroup.persist_approvals
    ? []
    : [currentReviewerGroup.id];
};

const _clearReviewerGroupActionsWith = (
  procedure,
  clearActionMatcher: (action: ReviewerAction) => boolean = () => true, // default to all
  rgSelector: (rg: ReviewerGroup) => boolean = () => true // default to all
) => {
  if (!procedure.reviewer_groups) {
    return;
  }
  const newReviewerActionsHistory: Array<ReviewerAction> =
    procedure.reviewer_actions_history ?? [];

  // Keep actions that do not match the clear condition.
  (procedure.reviewer_groups as Array<ReviewerGroup>)
    .filter(rgSelector)
    .forEach((reviewerGroup) => {
      const [clearedActions, persistedActions] =
        lodash.partition<ReviewerAction>(
          reviewerGroup.actions,
          clearActionMatcher
        );

      newReviewerActionsHistory.push(...clearedActions);
      reviewerGroup.actions = persistedActions;
    });

  procedure.reviewer_actions_history = newReviewerActionsHistory;
};

/**
 * Clears all approval actions.
 * // TODO: Move Procedure type to shared
 */
export const clearApprovals = (
  procedure,
  rgIdsToClearApprovals?: Array<string>
): void => {
  if (rgIdsToClearApprovals && rgIdsToClearApprovals.length === 0) {
    return;
  }
  _clearReviewerGroupActionsWith(
    procedure,
    (action) => action.type === 'approval',
    rgIdsToClearApprovals
      ? (rg) => rgIdsToClearApprovals.includes(rg.id)
      : undefined // clear all reviewer groups
  );
};

export const clearAllActions = (procedure): void => {
  _clearReviewerGroupActionsWith(procedure);
};

/**
 * Clears approval actions from required reviewers only.
 */
export const clearRequiredApprovals = (procedure) => {
  _clearReviewerGroupActionsWith(
    procedure,
    (action) =>
      action.type === 'approval' &&
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      getIsRequiredApproval(action, procedure.reviewer_groups)
  );
};

/**
 * Resets actions for next draft.
 */
export const resetProcedureReviewerGroups = (procedure): void => {
  clearAllActions(procedure);
};

// TODO: refactor Draft type to `shared`
export const isProcedureContentEqual = (
  firstProcedureDraft,
  secondProcedureDraft
): boolean => {
  const fieldsToOmit = [
    '_id',
    '_rev',
    'locked_at',
    'locked_by',
    'state',
    'editedAt',
    'editedUserId',
    'reviewer_groups',
    'reviewer_actions_history',
    'type',
    'comments',
    'actions',
    'procedure_rev_num',
    'initial_rev_num',
    'review_type',
    'release_note',
  ];
  return lodash.isEqual(
    lodash.omit(firstProcedureDraft, fieldsToOmit),
    lodash.omit(secondProcedureDraft, fieldsToOmit)
  );
};

export const getReviewSettings = (
  configReviewTypes?: Array<ReviewType>,
  configReviewerGroups?: Array<ReviewerGroup>,
  reviewType?: ReviewType,
  reviewerGroups?: Array<ReviewerGroup>
): ReviewSettings => {
  // Handle the case of review types in settings.
  if (configReviewTypes && configReviewTypes.length > 0) {
    const reviewerGroupMap = configReviewTypes.reduce((rgMap, rt) => {
      /*
        If the current review type is in settings, merge their groups.
        Merge other reviewer groups with an empty array.
       */
      rgMap[rt.id] = mergeRequiredReviewers(
        rt.id === reviewType?.id ? reviewerGroups : [],
        rt.reviewer_groups,
        rt.id !== reviewType?.id
      );
      return rgMap;
    }, {});

    const currentReviewTypeInSettings = configReviewTypes.find(
      (rt) => rt.id === reviewType?.id
    );
    const firstReviewType = currentReviewTypeInSettings
      ? currentReviewTypeInSettings
      : configReviewTypes[0];
    return {
      reviewTypeOrGroupId: firstReviewType.id,
      reviewTypes: configReviewTypes.map((rt) =>
        lodash.pick(rt, ['id', 'name'])
      ),
      reviewerGroupMap,
    };
  }

  // Handle the case of reviewer groups in settings.
  if (
    configReviewerGroups &&
    configReviewerGroups.length > 0 &&
    (!reviewerGroups || reviewerGroups.length <= 1) // Old-style reviewer groups
  ) {
    const reviewerGroupMap = configReviewerGroups.reduce((rgMap, rg) => {
      /*
        If the current reviewer group is in settings, merge their groups.
        Merge other reviewer groups with an empty array.
       */
      const pseudoProcedure = {
        reviewer_groups:
          rg.id === reviewerGroups?.[0]?.id ? reviewerGroups : [],
      };
      mergeRequiredReviewersDeprecated(pseudoProcedure, [rg]);
      rgMap[rg.id] = pseudoProcedure.reviewer_groups;

      return rgMap;
    }, {});
    const reviewerGroupInSettings = configReviewerGroups.find(
      (rg) => rg.id === reviewerGroups?.[0]?.id
    );

    const firstReviewerGroup = reviewerGroupInSettings
      ? reviewerGroupInSettings
      : configReviewerGroups[0];
    return {
      reviewTypeOrGroupId: firstReviewerGroup.id,
      reviewerGroupMap,
    };
  }

  /*
     At this point, there are no review types or reviewer groups in settings.
     Carry over all reviewers for procedures without a review type or required reviewers.
    */
  if (
    !reviewType &&
    reviewerGroups &&
    reviewerGroups[0] &&
    !reviewerGroups[0].name
  ) {
    return {
      reviewTypeOrGroupId: reviewerGroups[0].id,
      reviewerGroupMap: {
        [reviewerGroups[0].id]: reviewerGroups,
      },
    };
  }

  // As a fallback, make a new reviewer group.
  const newReviewerGroup = getNewReviewerGroup();
  return {
    reviewTypeOrGroupId: newReviewerGroup.id,
    reviewerGroupMap: { [newReviewerGroup.id]: [newReviewerGroup] },
  };
};

export const getHasReviewerPermission = (
  reviewerId: ReviewerId | null,
  userId: string,
  userOperatorRolesSet: Set<string>
) => {
  if (!reviewerId || !userId) {
    return false;
  }
  const { type, value } = reviewerId;
  if (type === 'user_id') {
    return userId === value;
  } else if (type === 'operator_role') {
    return (
      userOperatorRolesSet.has(value) &&
      (!reviewerId.user_ids ||
        reviewerId.user_ids.length === 0 ||
        reviewerId.user_ids.includes(userId))
    );
  }

  return false;
};

export const getIsReviewerGroupActive = (
  reviewerGroups: Array<ReviewerGroup>,
  reviewerGroupId: string
) => {
  if (!reviewerGroups) {
    return false;
  }
  const reviewerGroup = reviewerGroups.find((rg) => rg.id === reviewerGroupId);
  if (!reviewerGroup) {
    return false;
  }
  const index = reviewerGroups.findIndex((rg) => rg.id === reviewerGroup.id);
  if (index === -1) {
    return false;
  }
  return (
    (index === reviewerGroups.length - 1 ||
      !getHasReviewerGroupApproved(reviewerGroup)) &&
    reviewerGroups
      .slice(0, index)
      .every((previousReviewerGroup) =>
        getHasReviewerGroupApproved(previousReviewerGroup)
      )
  );
};

export const getHasReleasePermission = (
  operators: Array<Operator> | undefined,
  userOperatorRolesSet: Set<string>
) => {
  if (!operators) {
    return false;
  }
  // Allow approval if there are no operator roles
  if (operators.length === 0) {
    return true;
  }

  const releaseRoles = new Set(
    operators
      .filter((operator) => operator.can_release !== false)
      .map((operator) => operator.code)
  );

  for (const role of userOperatorRolesSet) {
    if (releaseRoles.has(role)) {
      return true;
    }
  }
  return false;
};

const getApprovalAction = (
  userId: string,
  reviewerId: string,
  operatorRole?: string
): ApproveAction => ({
  type: 'approval',
  reviewer_id: reviewerId,
  user_id: userId,
  operator_role: operatorRole,
  approved_at: new Date().toISOString(),
});

export const updateProcedureWithApproval = ({
  doc,
  userId,
  reviewerGroupId,
  reviewerId,
  operatorRole,
}: {
  doc: Draft;
  userId: string;
  reviewerGroupId: string;
  reviewerId: string;
  operatorRole?: string;
}) => {
  const approveAction = getApprovalAction(userId, reviewerId, operatorRole);
  const reviewerGroupsCopy = lodash.cloneDeep(doc.reviewer_groups) ?? [];
  const reviewerGroup = reviewerGroupsCopy.find(
    (rg) => rg.id === reviewerGroupId
  );
  if (!reviewerGroup || !reviewerGroup.actions) {
    return;
  }

  reviewerGroup.actions.push(approveAction);
  doc.reviewer_groups = reviewerGroupsCopy;

  if (!doc.actions) {
    doc.actions = [];
  }
  doc.actions.push(approveAction);
};

export const addApprovalToReviewerGroups = (
  reviewerGroups: Array<ReviewerGroup>,
  userId: string,
  reviewerGroupId: string,
  reviewerId: string,
  operatorRole?: string
) => {
  const approveAction = getApprovalAction(userId, reviewerId, operatorRole);

  const reviewerGroup = reviewerGroups.find((rg) => rg.id === reviewerGroupId);

  if (!reviewerGroup) {
    return;
  }

  if (!reviewerGroup.actions) {
    reviewerGroup.actions = [];
  }

  reviewerGroup.actions.push(approveAction);
};

export const updateProcedureWithApprovalRevoked = ({
  doc,
  userId,
  reviewerGroupId,
  reviewerId,
}: {
  doc: Draft;
  userId: string;
  reviewerGroupId: string;
  reviewerId: string;
}) => {
  const revokeAction: RevokeApprovalAction = {
    type: 'revoke_approval',
    reviewer_id: reviewerId,
    user_id: userId,
    changed_at: new Date().toISOString(),
  };

  const reviewerGroupsCopy = lodash.cloneDeep(doc.reviewer_groups) ?? [];
  const reviewerGroup = reviewerGroupsCopy.find(
    (rg) => rg.id === reviewerGroupId
  );
  if (!reviewerGroup || !reviewerGroup.actions) {
    return;
  }

  const updatedReviewerGroupActions = reviewerGroup.actions.filter((action) => {
    return action.reviewer_id !== reviewerId;
  });
  if (updatedReviewerGroupActions.length === reviewerGroup.actions.length) {
    return; // Do nothing if no action was removed.
  }
  reviewerGroup.actions = updatedReviewerGroupActions;
  doc.reviewer_groups = reviewerGroupsCopy;

  if (!doc.actions) {
    doc.actions = [];
  }
  doc.actions.push(revokeAction);
};

export const removeApprovalFromReviewerGroups = (
  reviewerGroups: Array<ReviewerGroup>,
  reviewerGroupId: string,
  reviewerId: string
) => {
  const reviewerGroup = reviewerGroups.find((rg) => rg.id === reviewerGroupId);
  if (!reviewerGroup || !reviewerGroup.actions) {
    return;
  }

  reviewerGroup.actions = reviewerGroup.actions.filter((action) => {
    return action.reviewer_id !== reviewerId;
  });
};

export const compareReviewActions = (
  actionA: ReviewerAction,
  actionB: ReviewerAction
) => {
  const timestampA =
    (actionA as ApproveAction).approved_at ??
    (actionA as DraftAction | RevokeApprovalAction).changed_at;
  const timestampB =
    (actionB as ApproveAction).approved_at ??
    (actionB as DraftAction | RevokeApprovalAction).changed_at;

  return timestampA.localeCompare(timestampB);
};

/**
 * Whether reviewer groups can be updated for a review type.
 * Does not account for changing review types.
 */
export const checkCanUpdateReviewerGroupsWithinReviewType = ({
  reviewTypeId,
  previousReviewerGroups,
  requestedReviewerGroups,
}: {
  reviewTypeId: string | undefined;
  previousReviewerGroups: Array<ReviewerGroup>;
  requestedReviewerGroups: Array<ReviewerGroup>;
}): boolean => {
  if (!reviewTypeId) {
    return true;
  }
  if (!previousReviewerGroups || previousReviewerGroups.length === 0) {
    return false;
  }
  if (!requestedReviewerGroups || requestedReviewerGroups.length === 0) {
    return false;
  }

  return requestedReviewerGroups.every(
    (requestedGroup, requestedGroupIndex) => {
      const previousGroup = previousReviewerGroups.find(
        (_previous) => _previous.id === requestedGroup.id
      );
      if (!previousGroup) {
        return true;
      }
      const previousGroupIsActive = getIsReviewerGroupActive(
        previousReviewerGroups,
        previousGroup.id
      );

      const previousChangeableReviewers = previousGroup.reviewers.filter(
        (reviewer) => !reviewer.is_required
      );
      const [requestedRequiredReviewers, requestedChangeableReviewers] =
        lodash.partition(
          requestedGroup.reviewers,
          (reviewer) => reviewer.is_required
        );

      const requestedGroupsCopy = [...requestedReviewerGroups];
      requestedGroupsCopy[requestedGroupIndex] = {
        ...requestedGroup,
        reviewers: requestedRequiredReviewers,
      };

      /* Cannot update the review type if all the following are true:
        1. Previous group is not active.
        2. The requested required reviewers are not active.
        3. The previous changeable reviewers do not match the requested changeable reviewers
      */
      if (
        !previousGroupIsActive &&
        !getIsReviewerGroupActive(requestedGroupsCopy, requestedGroup.id) &&
        !lodash.isEqual(
          previousChangeableReviewers,
          requestedChangeableReviewers
        ) &&
        requestedGroupIndex !== requestedReviewerGroups.length - 1
      ) {
        return false;
      }

      return true;
    }
  );
};
