import { ViewTab } from 'shared/lib/types/postgres/users';
import Tooltip from '../../elements/Tooltip';
import Button, { BUTTON_TYPES } from '../Button';

export interface ViewTabToggleProps {
  viewTab: ViewTab;
  setViewTab: (viewTab: ViewTab) => void;
  viewTabLabelOverride?: Record<ViewTab, string>;
}

const ViewTabToggle = ({ viewTab, setViewTab, viewTabLabelOverride }: ViewTabToggleProps) => {
  const labels = viewTabLabelOverride || { [ViewTab.List]: 'List View', [ViewTab.Tree]: 'Tree View' };

  return (
    <div className="flex flex-row gap-x-1 items-center">
      <Tooltip content={labels[ViewTab.List]} visible={viewTab !== ViewTab.List}>
        <div className={`${viewTab === ViewTab.List ? 'bg-slate-300' : ''} text-slate-700 hover:bg-slate-300 rounded `}>
          <Button
            type={BUTTON_TYPES.TERTIARY}
            leadingIcon="list"
            size="base"
            onClick={() => setViewTab(ViewTab.List)}
            isDisabled={viewTab === ViewTab.List}
            iconTextColor="text-slate-700"
            ariaLabel={labels[ViewTab.List]}
          />
        </div>
      </Tooltip>
      <Tooltip content={labels[ViewTab.Tree]} visible={viewTab !== ViewTab.Tree}>
        <div className={`${viewTab === ViewTab.Tree ? 'bg-slate-300' : ''} text-slate-700 hover:bg-slate-300 rounded `}>
          <Button
            type={BUTTON_TYPES.TERTIARY}
            leadingIcon="sitemap"
            size="base"
            onClick={() => setViewTab(ViewTab.Tree)}
            isDisabled={viewTab === ViewTab.Tree}
            iconTextColor="text-slate-700"
            ariaLabel={labels[ViewTab.Tree]}
          />
        </div>
      </Tooltip>
      <div className="pr-1"></div>
    </div>
  );
};

export default ViewTabToggle;
