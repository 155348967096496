import { faGear } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';

export type SubNavItem = {
  label: string;
  /** key is used to disambiguate items with the same label */
  key?: string;
  to: string;
};

interface NavigationSubMenuProps {
  title: string;
  items: Array<SubNavItem>;
  currentScreen: string;
  settingsPath?: string;
  closeMenu: () => void;
}

const NavigationSubMenu = ({ title, items, currentScreen, settingsPath, closeMenu }: NavigationSubMenuProps) => {
  useEffect(() => {
    document.addEventListener('click', closeMenu);
    return () => {
      document.removeEventListener('click', closeMenu);
    };
  }, [closeMenu]);

  return (
    <div className="relative left-[4rem] top-[-2rem] flex flex-col bg-slate-800  text-gray-300 border rounded-md shadow-md pb-1">
      <div className="flex flex-row px-2 py-1 items-center">
        <div className="flex flex-grow pb-1 text-sm text-gray-400">{title}</div>
        {settingsPath && (
          <div className={`${currentScreen === title && 'bg-slate-700'} justify-end hover:bg-slate-700 rounded-md`}>
            <Link to={settingsPath} className="inline-block p-2">
              <FontAwesomeIcon fixedWidth={true} icon={faGear} />
            </Link>
          </div>
        )}
      </div>
      {items.map((item) => (
        <div key={item.label}>
          <Link
            to={item.to}
            className={`flex flex-row ${
              currentScreen === (item.key ?? item.label) && 'bg-slate-700'
            } w-48 my-0.5 mx-1 py-1 px-2 items-center rounded-md focus:bg-slate-700 hover:bg-slate-700`}
          >
            {item.label}
          </Link>
        </div>
      ))}
    </div>
  );
};

export default NavigationSubMenu;
