import _ from 'lodash';
import { useCallback, useMemo } from 'react';
import {
  ComponentPart,
  Part,
} from 'shared/lib/types/postgres/manufacturing/types';
import {
  PartBuildBlock,
  PartKitBlock,
  PartListItem,
} from 'shared/lib/types/views/procedures';
import idUtil from '../../lib/idUtil';
import { asComponentPart } from '../lib/parts';
import useSpecificParts from './useSpecificParts';
import useLatestParts from './useLatestParts';

// TODO: KitComponentPart should be a much smaller subset of ComponentPart
type KitComponentPart = Omit<ComponentPart, 'id' | 'units'> & {
  id: string;
};

export type PartStub = {
  id: string;
  rev: string | undefined;
};

interface PartBlockHelpers {
  getKitItems: (part: Part) => KitComponentPart[];
  configurePartKitBlock: (part: PartKitBlock) => void;
  configurePartBuildBlock: (part: PartBuildBlock) => void;
}

interface Props {
  part?: PartStub | null;
}

const usePartBlockHelpers = ({ part }: Props): PartBlockHelpers => {
  const partIds = part ? [part.id] : [];

  // Need to pull all revisions for specific part to ensure the revision for the check in part is correct
  const { getPartByRevision } = useSpecificParts({ partIds });

  // Need to pull all parts to look up tracking type for any components
  const { getLatestPartRevision } = useLatestParts();

  const getKitItems = useCallback(
    (part: Part): PartListItem[] => {
      const inventoryItems: KitComponentPart[] = [];
      for (const component of part.components) {
        const inventoryPart = getLatestPartRevision(component.part_id);
        if (inventoryPart?.tracking === 'serial') {
          for (let i = 0; i < component.amount; i++) {
            inventoryItems.push({
              ...component,
              id: idUtil.generateUuidEquivalentId(),
              amount: 1,
            });
          }
        } else {
          inventoryItems.push({
            ...component,
            id: idUtil.generateUuidEquivalentId(),
          });
        }
      }
      return inventoryItems.map((item) =>
        _.pick(item, ['id', 'part_id', 'revision_id', 'amount'])
      );
    },
    [getLatestPartRevision]
  );

  const getCloseoutItems = useCallback(
    (part: PartStub) => {
      const fullPart = getPartByRevision(part.id, part.rev);
      if (fullPart) {
        const component = asComponentPart(fullPart, 1);
        return [{ ...component, id: idUtil.generateUuidEquivalentId() }];
      }
    },
    [getPartByRevision]
  );

  const configurePartKitBlock = useCallback(
    (block: PartKitBlock) => {
      if (part) {
        const fullPart = getPartByRevision(part.id, part.rev);
        block.part = fullPart ?? null;
        block.items = fullPart ? getKitItems(fullPart) : [];
      } else {
        block.part = null;
        block.items = [];
      }
    },
    [part, getPartByRevision, getKitItems]
  );

  const configurePartBuildBlock = useCallback(
    (block: PartBuildBlock) => {
      if (part) {
        const fullPart = getPartByRevision(part.id, part.rev);
        block.part = fullPart;
        block.items = fullPart ? getCloseoutItems(fullPart) ?? [] : [];
      } else {
        block.part = undefined;
        block.items = [];
      }
    },
    [part, getPartByRevision, getCloseoutItems]
  );

  const values = useMemo(() => {
    return {
      getKitItems,
      configurePartKitBlock,
      configurePartBuildBlock,
    };
  }, [getKitItems, configurePartKitBlock, configurePartBuildBlock]);

  return values;
};

export default usePartBlockHelpers;
