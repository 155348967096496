import { useMemo } from 'react';
import { useSettings } from '../../contexts/SettingsContext';
import projectUtil from '../../lib/projectUtil';
import SearchFilter, { FilterOption } from '../lib/SearchFilter';
import { getOperationsAsOptions } from './runGridUtils';
import { usePersistedViewReturns } from './usePersistedView';
import LocationTreeSelect from '../../manufacturing/components/LocationTreeSelect';
import useLocations from '../../manufacturing/hooks/useLocations';
import Select from 'react-select';
import { reactSelectStyles } from '../../lib/styles';
import { PurchaseOrderState } from 'shared/lib/types/postgres/manufacturing/orders';
import PurchaseOrderStateTag from '../../manufacturing/components/PurchaseOrderStateTag';
import PurchaseOrderTag from '../../manufacturing/components/PurchaseOrderTag';
import useOrders from '../../manufacturing/hooks/useOrders';

export enum Filter {
  Projects,
  Tags,
  RunTags,
  Operations,
  Priority,
  Type,
  Location,
  POTag,
  POStatus,
}

const allStatuses: PurchaseOrderState[] = Object.values(PurchaseOrderState);
const mapStatusToSelectOption = (status: PurchaseOrderState) => ({
  value: status,
  label: <PurchaseOrderStateTag id={status} orderState={status} />,
});

const mapTagToSelectOption = (tag: string) => ({
  value: tag,
  label: <PurchaseOrderTag id={tag} name={tag} />,
});

interface FilterItemsProps {
  persistedView: usePersistedViewReturns;
  filters: ReadonlySet<Filter>;
  includeEndedOperations?: boolean;
  tagOptions: Array<FilterOption<string>>;
  priorityOptions?: Array<FilterOption<string>>;
  typeOptions?: Array<FilterOption<string>>;
}

const FilterItems = ({
  persistedView,
  filters,
  includeEndedOperations = false,
  tagOptions,
  priorityOptions = [],
  typeOptions = [],
}: FilterItemsProps) => {
  const { projects, operations } = useSettings();
  const { locations } = useLocations();
  const { tags: POTags } = useOrders();

  const projectOptions = useMemo(() => projectUtil.getProjectsAsOptions(projects), [projects]);
  const operationOptions = useMemo(
    () => getOperationsAsOptions(Object.values(operations?.operations ?? []), includeEndedOperations),
    [includeEndedOperations, operations?.operations]
  );

  return (
    <>
      {filters.has(Filter.Location) && (
        <>
          <label htmlFor="tag_select" className="ml-4 mr-2">
            Location:
          </label>
          <LocationTreeSelect
            locations={locations || {}}
            selected={persistedView.selectedLocationIds?.[0]}
            onSelect={persistedView.setSelectedLocationIds}
          />
        </>
      )}
      {filters.has(Filter.Projects) && (
        <SearchFilter
          filterTitle="Projects"
          filterOptions={projectOptions}
          selectedFilterIds={persistedView.selectedProjectIds}
          onFilterIdsChange={(ids) => persistedView.setSelectedProjectIds(ids)}
          ariaLabel="Projects Filter"
        />
      )}
      {filters.has(Filter.Tags) && (
        <>
          {filters.has(Filter.Projects) && <div className="border-l border-gray-300 h-6"></div>}
          <SearchFilter
            filterTitle="Tags"
            filterOptions={tagOptions}
            selectedFilterIds={persistedView.selectedTagKeys}
            onFilterIdsChange={(ids) => persistedView.setSelectedTagKeys(ids)}
            ariaLabel="Tags Filter"
          />
        </>
      )}
      {filters.has(Filter.RunTags) && (
        <>
          {filters.has(Filter.Projects) && <div className="border-l border-gray-300 h-6"></div>}
          <SearchFilter
            filterTitle="Tags"
            filterOptions={tagOptions}
            selectedFilterIds={persistedView.selectedRunTagKeys}
            onFilterIdsChange={(ids) => persistedView.setSelectedRunTagKeys(ids)}
            ariaLabel="Run Tags Filter"
          />
        </>
      )}
      {filters.has(Filter.Operations) && (
        <>
          <div className="border-l border-gray-300 h-6"></div>
          <SearchFilter
            filterTitle="Operations"
            filterOptions={operationOptions}
            selectedFilterIds={persistedView.selectedOperationKeys}
            onFilterIdsChange={(ids) => persistedView.setSelectedOperationKeys(ids)}
            disableSelectAll={true}
            ariaLabel="Operations Filter"
          />
        </>
      )}
      {filters.has(Filter.Priority) && (
        <>
          <SearchFilter
            filterTitle="Priority"
            filterOptions={priorityOptions}
            selectedFilterIds={persistedView.selectedPriorityIds}
            onFilterIdsChange={(ids) => persistedView.setSelectedPriorityIds(ids)}
            ariaLabel="Priority Filter"
          />
        </>
      )}
      {filters.has(Filter.Type) && (
        <>
          <SearchFilter
            filterTitle="Type"
            filterOptions={typeOptions}
            selectedFilterIds={persistedView.selectedTypeIds}
            onFilterIdsChange={(ids) => persistedView.setSelectedTypeIds(ids)}
            ariaLabel="Type Filter"
          />
        </>
      )}
      {filters.has(Filter.POTag) && (
        <>
          <label htmlFor="tag_select">Tags:</label>
          <Select
            id="tag_select"
            className="w-80 ml-2"
            classNamePrefix="react-select"
            styles={reactSelectStyles}
            placeholder="Select tag filters..."
            value={persistedView.selectedPOTags.map(mapTagToSelectOption)}
            options={POTags.map(mapTagToSelectOption)}
            onChange={(selected) => persistedView.setSelectedPOTags(selected.map((option) => option.value))}
            isSearchable
            isMulti
            isClearable
          />
        </>
      )}
      {filters.has(Filter.POStatus) && (
        <>
          <label htmlFor="status_select">Status:</label>
          <Select
            id="status_select"
            className="w-80 ml-2"
            classNamePrefix="react-select"
            styles={reactSelectStyles}
            placeholder="Select status filters..."
            value={persistedView.selectedPOStatuses.map(mapStatusToSelectOption)}
            options={allStatuses.map(mapStatusToSelectOption)}
            onChange={(selected) => persistedView.setSelectedPOStatuses(selected.map((option) => option.value))}
            isSearchable
            isMulti
            isClearable
          />
        </>
      )}
    </>
  );
};

export default FilterItems;
