import React, { useMemo } from 'react';
import { Formik, Form } from 'formik';
import { generateHiddenClassString } from '../../lib/styles';
import SubstepNumber from '../SubstepNumber';
import Spacer from '../Spacer';
import DiffContainer from '../Diff/DiffContainer';
import diffUtil from '../../lib/diffUtil';
import ReviewTable from './ReviewTable';
import { ARRAY_CHANGE_SYMBOLS } from 'shared/lib/diffUtil';

const ReviewTableInput = function ({ blockLabel, content, isHidden, isSpacerHidden, onSelectCell, selectedCell }) {
  /**
   * If there are legacy recorded values (no version property),
   * we will need to transpose the values because they were being stored as columns by rows in the old version,
   * and the current version stores the cell values as rows by columns.
   */
  const initialValues = useMemo(() => {
    const initialCells = content.cells;

    return { values: initialCells };
  }, [content]);

  const diffChangeState = useMemo(() => {
    return diffUtil.getDiffChangeStateForAddedRemovedOnly(content);
  }, [content]);

  const isAddedOrRemoved = useMemo(() => {
    return diffChangeState === ARRAY_CHANGE_SYMBOLS.ADDED || diffChangeState === ARRAY_CHANGE_SYMBOLS.REMOVED;
  }, [diffChangeState]);

  return (
    <div className={generateHiddenClassString('flex mt-4 mr-4 page-break', isHidden)}>
      <Spacer isHidden={isSpacerHidden} />
      <div className={`${isAddedOrRemoved ? 'mr-4' : ''}`}>
        <SubstepNumber blockLabel={blockLabel} isHidden={isSpacerHidden} hasExtraVerticalSpacing={false} />
      </div>
      {/* Table */}
      <div className={`w-full h-full mr-4 ${isAddedOrRemoved ? '-ml-6' : ''}`}>
        <DiffContainer label="Table" diffChangeState={diffChangeState} isTextSticky={false}>
          <div className={isAddedOrRemoved ? 'pl-6' : ''}>
            <Formik
              initialValues={initialValues}
              onSubmit={() => {
                /* no-op */
              }}
              enableReinitialize
            >
              {({ values, errors, setFieldValue }) => (
                <Form>
                  <ReviewTable
                    path="values"
                    uniqueId={content.id}
                    columns={content.columns}
                    rowMetadata={content.row_metadata}
                    rowsCount={content.rows}
                    cells={content.cells}
                    values={values}
                    errors={errors}
                    disableColumnHeaders={true}
                    setFieldValue={setFieldValue}
                    removedRows={content.removed_rows}
                    addedRows={content.added_rows}
                    removedColumns={content.removed_columns}
                    addedColumns={content.added_columns}
                    onSelectCell={onSelectCell}
                    selectedCell={selectedCell}
                  />
                  {errors && errors.values && <div className="text-red-700 my-1">Enter valid numbers</div>}
                </Form>
              )}
            </Formik>
          </div>
        </DiffContainer>
      </div>
    </div>
  );
};

export default ReviewTableInput;
