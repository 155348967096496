import ReviewInventoryItem from './ReviewInventoryItem';
import SubstepNumber from '../../../components/SubstepNumber';
import useSpecificParts from '../../hooks/useSpecificParts';
import { generateHiddenClassString } from '../../../lib/styles';
import DiffContainer from '../../../components/Diff/DiffContainer';
import diffUtil from '../../../lib/diffUtil';
import sharedDiffUtil from 'shared/lib/diffUtil';
import PartAndRevisionPusher from '../PartAndRevisionPusher';
import { KitPart } from 'shared/lib/types/views/procedures';

const ReviewPartKit = ({
  content,
  recorded,
  blockLabel,
  teamId,
  onRecordValuesChanged,
  isEnabled,
  isHidden,
  isStepComplete,
}) => {
  const partIds = content.items.map((item) => item.part_id);
  const {
    isLoaded: arePartsLoaded,
    getLatestPartRevision,
    getPartByRevisionId,
  } = useSpecificParts({
    partIds,
  });

  const items: Array<KitPart> = (sharedDiffUtil.getDiffValue(content, 'items', 'new') ?? []) as Array<KitPart>;
  const recordItemValuesChanged = (item, values) => {
    const updated = {
      ...recorded,
      items: {
        ...recorded?.items,
        [item.id]: values,
      },
    };
    onRecordValuesChanged?.(content.id, updated);

    const itemIds: string[] = [];
    for (const key in updated.items) {
      const _item = updated.items[key];
      if (_item.item_id) {
        itemIds.push(_item.item_id);
      }
    }
  };

  if (!arePartsLoaded) {
    return null;
  }

  return (
    <div className={generateHiddenClassString('mt-3 ml-4 flex flex-wrap page-break', isHidden)}>
      <SubstepNumber blockLabel={blockLabel} hasExtraVerticalSpacing={false} />
      <DiffContainer
        label="Part kit"
        diffChangeState={diffUtil.getDiffChangeStateForAddedRemovedOnly(content)}
        isTextSticky={false}
        width="fit"
      >
        <div className="flex items-start w-full py-1 mr-8">
          {/* Kit items */}
          <table className="w-full table-fixed">
            <thead>
              <tr>
                <td>
                  <div className="p-1">
                    <label htmlFor="components" className=" font-medium uppercase">
                      Parts for Check-Out
                    </label>
                  </div>
                </td>
                <td>
                  <div className="p-1">
                    <span className=" font-medium uppercase">Quantity</span>
                  </div>
                </td>
                <td>
                  <div className="p-1">
                    <span className=" font-medium uppercase">Inventory</span>
                  </div>
                </td>
              </tr>
            </thead>
            <tbody>
              {items.map((item, index) => {
                const part = getPartByRevisionId(item.revision_id ?? '') ?? getLatestPartRevision(item.part_id);
                return (
                  <ReviewInventoryItem
                    key={index}
                    item={item}
                    part={part}
                    isEnabled={isEnabled}
                    teamId={teamId}
                    recorded={recorded?.items[item.id]}
                    onRecordValuesChanged={(values) => recordItemValuesChanged(item, values)}
                    isStepComplete={isStepComplete}
                  />
                );
              })}
              <tr>
                <td colSpan={3}>
                  <PartAndRevisionPusher
                    onPush={() => {
                      /* no-op */
                    }}
                    isDisabled={true}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </DiffContainer>
    </div>
  );
};

export default ReviewPartKit;
