import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const LicenseExpiringBanner = () => (
  <div className="flex justify-center w-full fixed top-0 z-30">
    <div className="px-2 py-0.5 rounded-b bg-amber-300">
      <FontAwesomeIcon icon={faTriangleExclamation} />
      <span className="ml-2">This product's license expires soon. See your admin to renew.</span>
    </div>
  </div>
);

export default LicenseExpiringBanner;
