import superlogin from './superlogin';
import { Socket } from 'socket.io-client';
import Counter from '../lib/counter';
import onNetworkChanged from './lib/network';
import {
  EntityType,
  EventMessage,
} from 'shared/lib/types/realtimeUpdatesTypes';
import {
  SocketIoActions,
  getEmitEventName,
  getRoomEventName,
} from 'shared/lib/realtimeUpdates';
import { API_URL } from '../config';
import apm from '../lib/apm';

const BACK_ONLINE_ACTION = 'BACK_ONLINE';
const DELETE_ACTION = 'DELETE';

export type Observer = {
  cancel: () => void;
};

export type Callback = (data: EventMessage) => void;
export type ResourceCallback = <T>(data: T) => void;

class RealtimeService {
  private teamId: string;
  private socket: Socket;
  private listenersForRoom: Counter;

  constructor(teamId: string, socket: Socket) {
    this.teamId = teamId;
    this.socket = socket;
    this.listenersForRoom = new Counter();
  }

  observeEvent(
    callback: Callback,
    entityType: EntityType,
    entityId?: number | string
  ): Observer {
    const eventListener = this.addEventListener(callback, entityType, entityId);
    const networkListener = addNetworkListener(callback);

    const cancel = () => {
      eventListener.cancel();
      networkListener.cancel();
    };

    return { cancel };
  }

  observeResource(
    resourceUrl: string,
    callback: ResourceCallback,
    entityType: EntityType,
    entityId?: number | string
  ): Observer {
    const refresh = async (): Promise<void> => {
      return fetchResource(resourceUrl)
        .then(callback)
        .catch((error) => apm.captureError(error));
    };

    const update = async (ids: Array<string>): Promise<void> => {
      return fetchResource(resourceUrl, ids)
        .then(callback)
        .catch((error) => apm.captureError(error));
    };

    const eventCallback = ((message: EventMessage): void => {
      const action = message.action;
      if (action === BACK_ONLINE_ACTION || action === DELETE_ACTION) {
        void refresh();
      } else {
        const ids = message.data as Array<string>;
        void update(ids);
      }
    }) as Callback;

    const observer = this.observeEvent(eventCallback, entityType, entityId);
    const cancel = () => {
      observer.cancel();
    };
    return { cancel };
  }

  /*
   * Only use this pattern as necessary, e.g., for storing data in redux.
   *
   * Prefer the useRealtimeUpdates hook instead.
   */
  onUsersEvent(callback: Callback): Observer {
    return this.observeEvent(callback, 'users');
  }

  onOperationsEvent(callback: Callback): Observer {
    return this.observeEvent(callback, 'operations');
  }

  onOperatorRoleEvent(callback: Callback): Observer {
    return this.observeEvent(callback, 'operator_roles');
  }

  onTagsEvent(callback: Callback): Observer {
    return this.observeEvent(callback, 'tags');
  }

  onUnitEvent(callback: Callback): Observer {
    return this.observeEvent(callback, 'units');
  }

  onProjectsEvent(callback: Callback): Observer {
    return this.observeEvent(callback, 'projects');
  }

  onProcedureEvent(entityId: number | string, callback: Callback): Observer {
    return this.observeEvent(callback, 'procedure', entityId);
  }

  onProceduresEvent(callback: Callback): Observer {
    return this.observeEvent(callback, 'procedures');
  }

  onRunsEvent(callback: Callback): Observer {
    return this.observeEvent(callback, 'runs');
  }

  onRunIndividualEvent(entityId: string, callback: Callback): Observer {
    return this.observeEvent(callback, 'run', entityId);
  }

  onRunStepsEvent(runId: string, callback: ResourceCallback): Observer {
    const url = `${API_URL}/teams/${this.teamId}/runs/${runId}/steps`;
    return this.observeResource(url, callback, 'run_steps', runId);
  }

  private addEventListener(
    callback: Callback,
    entityType: EntityType,
    entityId?: string | number
  ): Observer {
    const roomName = this.roomName(entityType, entityId);

    // join room if not already joined
    if (!this.listenersForRoom.has(roomName)) {
      this.joinRoom(entityType, entityId);
    }

    // add listener
    const eventName = getEmitEventName({ entityType, entityId });
    this.socket.on(eventName, callback);

    // increment number of listeners for room
    this.listenersForRoom.add(roomName);

    const cancel = () =>
      this.removeEventListener(callback, entityType, entityId);
    return { cancel };
  }

  private removeEventListener(
    callback: Callback,
    entityType: EntityType,
    entityId?: string | number
  ): void {
    const roomName = this.roomName(entityType, entityId);

    // remove listener
    const eventName = getEmitEventName({ entityType, entityId });
    this.socket.off(eventName, callback);

    // decrement number of listeners for room
    this.listenersForRoom.remove(roomName);

    // leave room if room has no more listeners
    if (!this.listenersForRoom.has(roomName)) {
      this.leaveRoom(entityType, entityId);
    }
  }

  private joinRoom(entityType: string, entityId?: string | number) {
    const payload = {
      teamId: this.teamId,
      entityType,
      entityId,
    };
    this.socket.emit(SocketIoActions.joinRoom, payload);
  }

  private leaveRoom(entityType: string, entityId?: string | number) {
    const payload = {
      teamId: this.teamId,
      entityType,
      entityId,
    };

    this.socket.emit(SocketIoActions.leaveRoom, payload);
  }

  private roomName(entityType: string, entityId?: string | number): string {
    return getRoomEventName({
      teamId: this.teamId,
      entityType,
      entityId,
    });
  }
}

const fetchResource = async <T>(
  resourceUrl: string,
  ids?: Array<string>
): Promise<T> => {
  const params = { ids };
  const response = await superlogin.getHttp().get(resourceUrl, { params });
  return response.data;
};

const addNetworkListener = (callback: Callback): Observer => {
  const notifyBackOnline = ({ online }: { online: boolean }): void => {
    if (online) {
      callback({ action: BACK_ONLINE_ACTION, data: {} });
    }
  };
  return onNetworkChanged(notifyBackOnline);
};

export default RealtimeService;
