import React, { useContext, useEffect, useState } from 'react';
import { useDatabaseServices } from './DatabaseContext';
import { FEATURE_WORKSPACES_ENABLED } from '../config';
import superlogin from '../api/superlogin';
import { UserOrgData } from 'shared/lib/types/api/util';
import apm from '../lib/apm';

interface Teams {
  userOrgData?: Array<UserOrgData>;
}

const TeamsContext = React.createContext<Teams | undefined>(undefined);

/**
 * This context provides teams that the user has access to.
 * A team object is as follows: { id: teamId, name: teamName }
 */
const TeamsProvider = (props) => {
  const { teamsServices } = useDatabaseServices();
  const [userOrgData, setUserOrgData] = useState<Array<UserOrgData>>();

  useEffect(() => {
    const session = superlogin.getSession();
    if (!session?.userOrgData || !teamsServices) {
      return;
    }
    const allTeamIds = session?.userOrgData.map((org) => org.teamIds).flat();
    const configPromises: Array<Promise<{ id: string; name: string }>> = [];

    allTeamIds.forEach((teamId) => {
      const settingsService = teamsServices[teamId]?.settings;
      if (!settingsService) {
        return;
      }
      configPromises.push(settingsService.getConfig().then((config) => ({ id: teamId, name: config.name })));
    });

    Promise.all(configPromises)
      .then((teams) => {
        for (const org of session.userOrgData) {
          org.teams = teams.filter((team) => org.teamIds.includes(team.id));
        }

        setUserOrgData(session.userOrgData);
      })
      .catch((err) => apm.captureError(err));
  }, [teamsServices]);

  return <TeamsContext.Provider value={{ userOrgData }}>{props.children}</TeamsContext.Provider>;
};

const useTeamsInfo = () => {
  const context = useContext(TeamsContext);
  // If feature flag is disabled, return an empty object because components expect an object returned.
  if (!FEATURE_WORKSPACES_ENABLED) {
    return {};
  }

  if (context === undefined) {
    throw new Error('useTeamsInfo must be used within a TeamsProvider');
  }
  return context;
};

export { TeamsProvider, useTeamsInfo };
