import ModalContent from './ModalContent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ModalBase from './ModalBase';
import React from 'react';

const ModalLogout = () => {
  return (
    <ModalBase>
      <ModalContent title="Logging Out">
        <div className="flex flex-row space-x-2 items-end">
          <FontAwesomeIcon icon="meteor" className="text-4xl text-blue-500" />
          <span>Clearing orbital debris...</span>
        </div>
      </ModalContent>
    </ModalBase>
  );
};

export default ModalLogout;
