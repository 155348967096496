import React from 'react';
import { generateHiddenClassString } from '../../lib/styles';
import TextLinkify from '../TextLinkify';
import { DiffArrayChangeSymbol, ExpressionTokenDiffElement, WithDiffChange } from 'shared/lib/types/views/procedures';
import SubstepNumber from '../SubstepNumber';
import Spacer from '../Spacer';
import { BlockProvider } from '../../contexts/BlockContext';
import DiffContainer from '../Diff/DiffContainer';
import TooltipOverlay from '../TooltipOverlay';
import ReviewReferenceDetail from './ReviewReferenceDetail';

interface ReviewExpressionBlockProps {
  name: string;
  id: WithDiffChange<string>;
  tokens: Array<ExpressionTokenDiffElement>;
  isHidden: boolean;
  blockLabel?: string;
  isValid?: boolean;
  diffChangeState?: DiffArrayChangeSymbol;
}

const ReviewExpressionBlock = ({
  name,
  id,
  tokens,
  isHidden,
  blockLabel = '',
  isValid = true,
  diffChangeState,
}: ReviewExpressionBlockProps) => {
  return (
    <BlockProvider value={{ isValid }}>
      <div className={generateHiddenClassString('', isHidden)}>
        <div className={generateHiddenClassString('mt-2 mr-8 flex items-start page-break', isHidden)}>
          <Spacer />
          <SubstepNumber blockLabel={blockLabel} hasExtraVerticalSpacing={true} />
          <DiffContainer label="Expression" diffChangeState={diffChangeState} isTextSticky={false}>
            {tokens && (
              <div className="flex flex-col">
                <div className="flex flex-wrap flex-col items-start w-full my-1 gap-x-2">
                  <div className="flex flex-row items-start w-full py-1 gap-x-2">
                    <TextLinkify>
                      <div
                        style={{
                          flex: '2',
                          minHeight: '2.25rem',
                        }}
                        className="flex self-top max-w-max"
                      >
                        <div className="flex self-center">
                          <div>{name}</div>
                        </div>
                      </div>
                    </TextLinkify>
                    <div className="flex items-center h-9">
                      <span>=</span>
                    </div>
                    <TooltipOverlay content={<ReviewReferenceDetail tokens={tokens} />} delayClose={true}>
                      <div className="group  px-2 py-1.5 border-2 border-gray-400 rounded bg-gray-200 bg-opacity-50 whitespace-wrap">
                        <div className="text-gray-400">Pending</div>
                      </div>
                    </TooltipOverlay>
                  </div>
                </div>
              </div>
            )}
          </DiffContainer>
        </div>
      </div>
    </BlockProvider>
  );
};

export default React.memo(ReviewExpressionBlock);
