import React, { useCallback, useMemo, useState } from 'react';
import Select from 'react-select';
import { useProcedureContext } from '../../contexts/ProcedureContext';
import procedureUtil from '../../lib/procedureUtil';
import referenceUtil from '../../lib/referenceUtil';
import { useSettings } from '../../contexts/SettingsContext';
import { reactSelectStyles } from '../../lib/styles';
import { FormikProps } from 'formik';
import { FieldInputProps } from 'formik/dist/types';
import {
  DraftFieldInputBlock,
  DraftSection,
  DraftAddedStep,
  RunSection,
  RepeatedSection,
  V2DraftVariable,
  RunStep,
  RepeatedStep,
  DraftStep,
  StepBlock,
  AddedStep,
} from 'shared/lib/types/views/procedures';
import { SUPPORTED_INPUT_DISPLAYS } from './ReferenceBlock';
import { isNumber } from 'lodash';
import { getLatestApprovedBlock } from '../../hooks/useBlockRedlines';
import revisions from '../../lib/revisions';

type ReferenceOptionsType = {
  label: string;
  value: string; // the reference id
  referenceSubtype?: string;
  fieldIndex?: number;
};

const supportedReferenceTypes = Object.keys(SUPPORTED_INPUT_DISPLAYS);

// Returns string with "'stepKey'. 'blockName'"
const getBlockLabel = (
  contentBlockName: string,
  stepIndex: number,
  sectionIndex: number,
  style: 'letters' | 'numbers'
): string => {
  const stepLabel = procedureUtil.displaySectionStepKey(sectionIndex, stepIndex, style);

  if (contentBlockName) {
    return `${stepLabel}. ${contentBlockName}`;
  }

  return `${stepLabel}.`;
};

const getReferenceOptionsFromContent = (
  contentBlock: DraftFieldInputBlock,
  prefix = ''
): Array<ReferenceOptionsType> => {
  const options: Array<ReferenceOptionsType> = [];

  // External search results may contain sub items to reference
  if (contentBlock.inputType === 'external_search') {
    options.push({
      label: `${prefix}${contentBlock.name}`,
      value: contentBlock.id,
    });
    contentBlock.external_search_type.filter_options.forEach((filterOption) => {
      options.push({
        label: `${prefix}${contentBlock.name}. ${filterOption}`,
        value: contentBlock.id,
        referenceSubtype: filterOption,
      });
    });
  } else {
    options.push({
      label: `${prefix}${contentBlock.name}`,
      value: contentBlock.id,
    });
  }
  return options;
};

// Returns an array of react-select type options objects. Only gets options for non-repeated sections and steps
const getOptions = (
  variables: Array<V2DraftVariable>,
  sections: Array<DraftSection | RunSection | RepeatedSection>,
  getSetting: (displaySectionsAs: string, lettersOrNumbers: string) => string,
  pendingStep: DraftAddedStep,
  precedingStepId: string
): Array<ReferenceOptionsType> => {
  let options: Array<ReferenceOptionsType> = [];

  variables.forEach((contentBlock) => {
    if (referenceUtil.isReferenceEnabled(contentBlock, supportedReferenceTypes)) {
      options = options.concat(getReferenceOptionsFromContent(contentBlock, ''));
    }
  });

  sections.forEach((section, sectionIndex) => {
    if (!(section as RepeatedSection).repeat_of) {
      section.steps.forEach((step: DraftStep | AddedStep | RunStep | RepeatedStep, stepIndex: number) => {
        if (!(step as RepeatedStep).repeat_of) {
          step.content.forEach((contentBlock: StepBlock, contentBlockIndex) => {
            if (referenceUtil.isReferenceEnabled(contentBlock, supportedReferenceTypes)) {
              const prefix = `${procedureUtil.displaySectionStepKey(
                sectionIndex,
                stepIndex,
                getSetting('display_sections_as', 'letters') as 'letters' | 'numbers'
              )}. `;
              const blockRedlines = revisions.getBlockChanges(
                contentBlock,
                contentBlockIndex,
                (step as RunStep).redlines ?? []
              );
              const latestBlock = getLatestApprovedBlock({
                block: contentBlock,
                redlines: blockRedlines,
              });
              options = options.concat(getReferenceOptionsFromContent(latestBlock as DraftFieldInputBlock, prefix));
            } else if (contentBlock.type === 'field_input_table') {
              const prefix = `${procedureUtil.displaySectionStepKey(
                sectionIndex,
                stepIndex,
                getSetting('display_sections_as', 'letters') as 'letters' | 'numbers'
              )}. `;
              contentBlock.fields.forEach((field, index) => {
                options.push({ label: `${prefix}${field.name}`, value: contentBlock.id, fieldIndex: index });
              });
            }
          });

          // Add any content in the pending step here to make the order in which the referred-to names appear in the dropdown list the same order they appear in the procedure
          if (pendingStep && step.id === precedingStepId) {
            pendingStep.content.forEach((contentBlock) => {
              if (referenceUtil.isReferenceEnabled(contentBlock, supportedReferenceTypes)) {
                options = options.concat(getReferenceOptionsFromContent(contentBlock as DraftFieldInputBlock, '-- '));
              }
            });
          }
        }
      });
    }
  });

  return options;
};

export type ReferenceValue = {
  referenceContentId: string;
  referenceSubtype?: string;
  referenceFieldIndex?: string;
};

interface ReferenceEditSelectProps {
  value: ReferenceValue;
  field: FieldInputProps<unknown>;
  form: FormikProps<string>;
  pendingStep: DraftAddedStep;
  precedingStepId: string;
}

const ReferenceEditSelect = React.memo(
  ({ value, field, form, pendingStep, precedingStepId }: ReferenceEditSelectProps) => {
    const {
      getAllSections,
      getAllVariables,
      getSectionSummary,
      getStepSummary,
      getContentBlockSummary,
      getContentItemPath,
    } = useProcedureContext();
    const [options, setOptions] = useState<Array<ReferenceOptionsType>>([]);
    const { getSetting } = useSettings();

    const updateOptions = useCallback(() => {
      const sections = getAllSections();
      const variables = getAllVariables() as Array<V2DraftVariable>;
      const options = getOptions(variables, sections, getSetting, pendingStep, precedingStepId);

      setOptions(options);
    }, [getAllSections, getSetting, precedingStepId, pendingStep, getAllVariables]);

    const getVariableSelectValue = useCallback(
      (value: ReferenceValue): ReferenceOptionsType | null => {
        const variables = getAllVariables();

        const matchingVariable = variables.find((variable) => variable.id === value.referenceContentId);
        if (matchingVariable) {
          let label = matchingVariable.name;
          if (value.referenceSubtype) {
            label = `${label}. ${value.referenceSubtype}`;
          }
          return {
            label,
            value: matchingVariable.id,
            referenceSubtype: value.referenceSubtype,
          };
        }
        return null;
      },
      [getAllVariables]
    );

    const getFieldInputSelectValue = useCallback(
      (value: ReferenceValue): ReferenceOptionsType | null => {
        const path = getContentItemPath(value.referenceContentId);

        if (!path) {
          if (!pendingStep) {
            return null;
          }

          const { matchingContentBlock } = procedureUtil.getContentBlock(value.referenceContentId, pendingStep) as {
            matchingContentBlock: DraftFieldInputBlock;
          };

          if (matchingContentBlock) {
            return {
              label: `-- ${matchingContentBlock.name}`,
              value: matchingContentBlock.id,
            };
          }

          return null;
        }

        const { sectionId, stepId } = procedureUtil.parseContentPath(path);
        const section = getSectionSummary(sectionId);
        if (!section) {
          return null;
        }

        const step = getStepSummary(stepId, sectionId);
        if (!step) {
          return null;
        }

        const contentBlock = getContentBlockSummary(
          value.referenceContentId,
          stepId,
          sectionId,
          value.referenceFieldIndex
        );

        if (!contentBlock) {
          return null;
        }

        let label = getBlockLabel(
          contentBlock.name,
          step.index,
          section.index,
          getSetting('display_sections_as', 'letters')
        );
        if (value.referenceSubtype) {
          label = `${label}. ${value.referenceSubtype}`;
        }

        return {
          label,
          value: contentBlock.id,
          referenceSubtype: value.referenceSubtype,
        };
      },
      [getContentItemPath, getSectionSummary, getStepSummary, getContentBlockSummary, getSetting, pendingStep]
    );

    const selectValue = useMemo((): ReferenceOptionsType | null => {
      if (value === null) {
        return null;
      }

      const variableSelectValue = getVariableSelectValue(value);
      if (variableSelectValue) {
        return variableSelectValue;
      }

      return getFieldInputSelectValue(value);
    }, [value, getVariableSelectValue, getFieldInputSelectValue]);

    const onChangeHandler = (option: ReferenceOptionsType) => {
      const selectedOptionValue = option.value;
      const selectedFieldIndex = option.fieldIndex;

      // Skip onChange when re-selecting the same value.
      if (selectedOptionValue === value.referenceContentId && option.referenceSubtype === value.referenceSubtype) {
        return;
      }

      form.setFieldValue(`${field.name}.reference`, selectedOptionValue);
      if (option.referenceSubtype) {
        form.setFieldValue(`${field.name}.sub_reference`, option.referenceSubtype);
      } else {
        form.setFieldValue(`${field.name}.sub_reference`, '');
      }
      if (isNumber(selectedFieldIndex)) {
        form.setFieldValue(`${field.name}.field_index`, selectedFieldIndex);
      } else {
        form.setFieldValue(`${field.name}.field_index`, undefined);
      }
    };

    const isOptionSelected = (option: ReferenceOptionsType): boolean => {
      if (value.referenceContentId === option.value && value.referenceSubtype === option.referenceSubtype) {
        return true;
      }
      return false;
    };

    return (
      <Select
        aria-label="Select Reference" // React-select requires aria-label instead of ariaLabel.
        classNamePrefix="react-select"
        formatOptionLabel={(option) => `${option.label}`}
        name={field.name}
        onBlur={field.onBlur}
        onChange={onChangeHandler}
        onMenuOpen={updateOptions}
        options={options}
        styles={reactSelectStyles}
        value={selectValue}
        isOptionSelected={isOptionSelected}
      />
    );
  }
);

export default ReferenceEditSelect;
