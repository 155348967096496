import { Tag as GlobalTag } from 'shared/lib/types/api/settings/tags/models';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tag from '../../Tag';

type RemovableTagProps = {
  tag: GlobalTag;
  onRemove: () => void;
  isDisabled?: boolean;
};

const RemovableTag = ({ tag, onRemove, isDisabled = false }: RemovableTagProps) => {
  return (
    <div className="relative group max-w-[7rem]">
      <Tag key={tag.id} id={tag.id} name={tag.name} color={tag.color} description={tag.description} />
      {!isDisabled && <RemoveTagButton onClick={onRemove} />}
    </div>
  );
};

const RemoveTagButton = ({ onClick }) => {
  return (
    <FontAwesomeIcon
      onClick={onClick}
      aria-label="Remove Tag"
      icon="times-circle"
      className="absolute -top-3 right-0 text-gray-400 hover:text-gray-500 opacity-0 group-hover:opacity-100 hover:cursor-pointer bg-white rounded-full border-2 border-white"
    />
  );
};

type TagsDisplayProps = {
  tags: Array<GlobalTag>;
  onTagAction?: (tag: GlobalTag) => Promise<void> | void;
  isDisabled?: boolean;
  isClickable?: boolean;
};

const TagsDisplay = ({ tags, onTagAction, isDisabled = false, isClickable = false }: TagsDisplayProps) => {
  const getOnRemove = (tag: GlobalTag) => () => onTagAction && onTagAction(tag);

  return (
    <div className="flex flex-row flex-wrap items-center">
      {tags.map((tag) => {
        if (isDisabled) {
          return (
            <Tag
              key={tag.id}
              id={tag.id}
              name={tag.name}
              color={tag.color}
              description={tag.description}
              {...(onTagAction && { onClick: () => onTagAction(tag) })}
              {...(!onTagAction &&
                isClickable && {
                  onClick: () => {
                    /* */
                  },
                })} // the procedure and run list have the onClick handler at a higher level, but should still be clickable
            />
          );
        }
        return <RemovableTag key={tag.id} tag={tag} onRemove={getOnRemove(tag)} isDisabled={isDisabled} />;
      })}
    </div>
  );
};

export default TagsDisplay;
