import { useMemo } from 'react';
import revisionsUtil from '../lib/revisions';
import { REDLINE_TYPE } from 'shared/lib/redlineUtil';

export const getLatestApprovedField = ({ fieldName, fieldValue, redlines }) => {
  let idx = redlines ? redlines.length : 0;
  while (idx--) {
    if (!redlines[idx].pending) {
      return redlines[idx].type === REDLINE_TYPE.FULL_STEP_REDLINE ? fieldValue : redlines[idx][fieldName];
    }
  }
  return fieldValue;
};

/**
 * Hook for retrieving latest redlines and redline edits for a field.
 *
 * @param {Object} step - Step object containing the field whose form is rendered.
 * @param {String} fieldName - Name of the field.
 * @param {String} fieldValue - Value of the field.
 */
const useFieldRedlines = ({ fieldName, fieldValue, redlines }) => {
  const latestRedline = useMemo(() => redlines && redlines.length > 0 && redlines[redlines.length - 1], [redlines]);

  // Last value of the field-- taken from the last redline if one exists, otherwise the value saved in the procedure.
  const latestField = useMemo(() => {
    if (latestRedline) {
      return latestRedline[fieldName];
    }
    return fieldValue;
  }, [fieldName, fieldValue, latestRedline]);

  // Last value of the field to be approved
  const latestApprovedField = useMemo(() => {
    return getLatestApprovedField({ fieldName, fieldValue, redlines });
  }, [fieldName, fieldValue, redlines]);

  const hasPendingRedlines = useMemo(() => latestRedline && latestRedline.pending, [latestRedline]);

  const hasRedlines = useMemo(() => {
    return Boolean(latestRedline);
  }, [latestRedline]);

  // Ids of all users who redlined the field.
  const allRedlineUserIds = useMemo(() => {
    if (!hasRedlines) {
      return [];
    }
    const userIds = redlines.map((redline) => {
      return revisionsUtil.createdBy(redline);
    });

    // Do not return duplicate user ids.
    const uniqueUserIds = [...new Set(userIds)];
    return uniqueUserIds;
  }, [hasRedlines, redlines]);

  return {
    latestRedline,
    latestField,
    latestApprovedField,
    hasRedlines,
    allRedlineUserIds,
    hasPendingRedlines,
  };
};

export default useFieldRedlines;
