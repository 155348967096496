import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cloneDeep from 'lodash.clonedeep';
import { useMemo } from 'react';
import { Part } from 'shared/lib/types/postgres/manufacturing/types';
import FieldSetCheckbox from '../../components/FieldSetCheckbox';
import Tooltip from '../../elements/Tooltip';
import idUtil from '../../lib/idUtil';
import useSpecificParts from '../hooks/useSpecificParts';
import inventoryUtil from '../lib/inventoryUtil';
import { asComponentPart, isPartRestricted } from '../lib/parts';
import ItemQuantityInput from './ItemQuantityInput';
import ItemSelect from './ItemSelect';
import PartAndRevisionPusher from './PartAndRevisionPusher';
import RestrictedInfo from './RestrictedInfo';
import { useRunContext } from '../../contexts/RunContext';
import { FormikHelpers, FormikValues } from 'formik';
import { DraftPartKitBlock } from 'shared/lib/types/views/procedures';
import { BaseContentBlockErrors } from '../../lib/types';
import UnitDisplay from '../../components/Settings/Units/UnitDisplay';
import PartBadge from '../components/PartBadge';

const TOOLTIP_MESSAGE = 'Additional parts for check-out may be added during a run';

const QuantityField = ({ component, componentPart, onQuantityChange }) => {
  return (
    <div className="w-full flex items-center gap-x-2">
      <ItemQuantityInput
        value={component.amount}
        allowDecimalValue={componentPart?.allow_decimal_quantities === true}
        allowNull={false}
        onChange={(value) => onQuantityChange(value)}
        disabled={componentPart?.tracking === 'serial'}
        placeholder="Quantity"
        textSize="sm"
        w={36}
      />
      {component.units && <UnitDisplay unit={component.units} />}
    </div>
  );
};

interface PartKitFieldSetProps {
  content: DraftPartKitBlock;
  contentErrors?: BaseContentBlockErrors;
  path?: string;
  teamId: string;
  setFieldValue: FormikHelpers<FormikValues>['setFieldValue'];
  projectId?: string;
}

const PartKitFieldSet = ({ content, contentErrors, path, teamId, setFieldValue, projectId }: PartKitFieldSetProps) => {
  const partIdsInBlock = useMemo(() => inventoryUtil.collectPartIdsInBlock(content), [content]);
  const { isRun } = useRunContext();

  const { getLatestPartRevision, getPartByRevisionId } = useSpecificParts({
    partIds: partIdsInBlock,
  });

  const onAddItem = (part: Part) => {
    const component = asComponentPart(part, 1);
    const value = {
      ...content,
      items: [
        ...content.items,
        {
          ...component,
          id: idUtil.generateUuidEquivalentId(),
        },
      ],
    };
    setFieldValue(path ? path : '', value);
  };

  const onQuantityChange = (index, newQuantity) => {
    const updated = cloneDeep(content);
    updated.items[index].amount = newQuantity;
    setFieldValue(path ? path : '', updated);
  };

  const onRemoveItem = (item) => {
    const updated = cloneDeep(content);
    updated.items = updated.items.filter((component) => component.id !== item.id);
    setFieldValue(path ? path : '', updated);
  };

  return (
    <div className="flex flex-col grow">
      {/* Part components */}
      <table className="w-full table-fixed">
        <thead>
          <tr className="border-b items-center">
            <td>
              <div className="p-1">
                <span className="field-title">Parts for Check-Out</span>
                <Tooltip content={TOOLTIP_MESSAGE}>
                  <FontAwesomeIcon icon="circle-info" className="text-sm text-gray-400 ml-1" />
                </Tooltip>
              </div>
            </td>
            <td>
              <div className="p-1">
                <span className="field-title">Quantity</span>
              </div>
            </td>
            <td>
              <div className="p-1">
                <span className="field-title">Inventory</span>
              </div>
            </td>
            <td className="w-6"></td>
          </tr>
        </thead>
        <tbody>
          {content?.items.map((component, index) => {
            const componentPart =
              getPartByRevisionId(component.revision_id ?? '') ?? getLatestPartRevision(component.part_id);
            const partRestricted = isPartRestricted(componentPart);
            return (
              <tr key={index} className="h-10  border-b items-center">
                <td>
                  <PartBadge part={componentPart} teamId={teamId} showImage={false} />
                </td>
                <td>
                  {!partRestricted && (
                    <QuantityField
                      component={component}
                      componentPart={componentPart}
                      onQuantityChange={(quantity) => onQuantityChange(index, quantity)}
                    />
                  )}
                  {partRestricted && (
                    <div className="ml-1.5">
                      <RestrictedInfo />
                    </div>
                  )}
                </td>
                <td>
                  {!partRestricted && <ItemSelect itemId="" isDisabled={true} />}
                  {partRestricted && (
                    <div className="ml-1.5">
                      <RestrictedInfo />
                    </div>
                  )}
                </td>
                <td>
                  <div className="w-6 p-1">
                    <button type="button" className="secondary group" onClick={() => onRemoveItem?.(component)}>
                      <FontAwesomeIcon className="text-gray-400 group-hover:text-gray-500 p-1" icon="trash" />
                    </button>
                  </div>
                </td>
              </tr>
            );
          })}
          <tr>
            <td colSpan={4}>
              {contentErrors?.error && <div className="text-red-700 ">{contentErrors.error}</div>}
              <div className="flex flex-row flex-wrap">
                <PartAndRevisionPusher onPush={onAddItem} projectId={projectId} />
                {/* Include in summary checkbox */}
                <div className="ml-2 mt-2 flex flex-row items-center">
                  <FieldSetCheckbox
                    text="Include in Summary"
                    fieldName={`${path}.include_in_summary`}
                    setFieldValue={setFieldValue}
                    isDisabled={isRun}
                  />
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default PartKitFieldSet;
