import {
  ExpressionBlock,
  ExpressionFunctionDefinition,
  ExpressionToken,
  FieldInputBlock,
  FieldInputTimestampBlock,
  RunExpressionBlock,
  RunFieldInputBlock,
  RunFieldInputNumberBlock,
  RunFieldInputRecorded,
  RunFieldInputTableBlock,
  RunFieldInputTextBlock,
  RunTableInputBlock,
  StepBlock,
  TableColumn,
  TimestampValue,
} from './types/views/procedures';
import { Unit } from './types/api/settings/units/models';
import {
  ExpressionReferenceRecordableTargetBlock,
  ExpressionReferenceTargetBlock,
  GetRecorded,
  ParseTokenParams,
  ReferenceContext,
  TokenResolvedResult,
} from './types/expressions';
import { getTimestampFromRecorded } from './datetime';
import { evaluate as evaluateMathJs } from 'mathjs';
import { isEmpty, isNil, isNumber } from 'lodash';
import { isNumber as sharedIsNumber } from './math';
import { DateTime, Duration } from 'luxon';

const UNRESOLVED_RESULT: TokenResolvedResult = {
  resolvedExpression: undefined,
  richDisplayText: '?',
  displayText: '?',
  isResolved: false,
  context: {},
};

export const functionRegistry = new Map<string, ExpressionFunctionDefinition>();

functionRegistry.set('calculateDuration', {
  name: 'calculateDuration',
  params: [
    { name: 'start', type: 'timestamp' },
    { name: 'end', type: 'timestamp' },
  ],
  evaluate: (args: Array<number>) => {
    const [startSeconds, endSeconds] = args;
    const DAYS_IN_SECONDS = 86400;
    try {
      // For time fields (stored as seconds in a day)
      if (startSeconds < DAYS_IN_SECONDS && endSeconds < DAYS_IN_SECONDS) {
        return endSeconds - startSeconds;
      }

      // For date/datetime fields (stored as epoch seconds)
      // Convert seconds to ISO strings
      const startDate = DateTime.fromSeconds(Number(startSeconds));
      const endDate = DateTime.fromSeconds(Number(endSeconds));

      if (!startDate.isValid) {
        throw new Error(`Invalid start time: ${startSeconds}`);
      }
      if (!endDate.isValid) {
        throw new Error(`Invalid end time: ${endSeconds}`);
      }

      // Calculate duration in seconds
      const durationInSeconds = endDate.diff(startDate).as('seconds');

      // Return rounded seconds
      return Math.abs(Math.round(durationInSeconds));
    } catch (error) {
      throw new Error('Error calculating duration');
    }
  },

  getDisplayMetadata: (seconds) => {
    const secondsNum = Math.abs(Number(seconds));

    const duration = Duration.fromObject({ seconds: secondsNum });

    const days = Math.floor(duration.as('days'));
    const daysStr = days > 0 ? `${days}d ` : '';

    // Format remaining time as HH:MM:SS
    const timeStr = duration
      .minus({ days })
      .toFormat('hh:mm:ss')
      .replace(/^(\d{1}):/, '0$1:'); // Ensure hours is always 2 digits

    return {
      formattedValue: `${daysStr}${timeStr}`,
    };
  },
});

/**
 * _getRichText creates a string with delimiters and context to give a markdown
 * code block a way to identify and then enhance reference tokens.  It adds
 * backticks to denote the block as code and uses the emoji delimiters to
 * avoid overlap with anything a user might enter into a text field.  Lastly,
 * it uses the zero width space &#x200B; to allow tokens to be right next to
 * each other while still parsing out to separate code blocks
 */
const _getRichText = ({
  referenceId,
  value = '',
  isResolved,
  fieldIndex,
}: {
  referenceId: string;
  value?: string;
  isResolved?: boolean;
  fieldIndex?: number;
}) => {
  return `\`🌜${referenceId}🌓${isResolved ? value : '??'}🌗${
    fieldIndex ?? ''
  }🌛\`&#x200B;`;
};

const _handleFunctionToken = ({
  token,
  referenceContext,
  findDefinedUnit,
  getRecorded,
}: ParseTokenParams): TokenResolvedResult => {
  if (!token?.function_name || !token.function_params) {
    return UNRESOLVED_RESULT;
  }

  const resolvedParams = token.function_params.map((paramTokens) =>
    resolveTokens({
      tokens: paramTokens,
      referenceContext,
      findDefinedUnit,
      getRecorded,
      consolidate: true,
    })
  );

  const allParamsResolved = resolvedParams.every((param) => param.isResolved);
  if (!allParamsResolved) {
    return UNRESOLVED_RESULT;
  }

  const hasTimestamp = token.function_name === 'calculateDuration';

  const functionCall = `${token.function_name}(${resolvedParams
    .map((param) => param.resolvedExpression)
    .join(', ')})`;

  const displayText = `${token.function_name}(${resolvedParams
    .map((param) => param.displayText)
    .join(', ')})`;

  const context = resolvedParams.reduce(
    (ctx, param) => ({
      ...ctx,
      ...param.context,
    }),
    {}
  );

  return {
    resolvedExpression: functionCall,
    displayText,
    richDisplayText: token.value,
    isResolved: true,
    context,
    hasTimestamp,
    functionName: token.function_name,
  };
};

const _handleExpressionToken = ({
  token,
  referenceContext,
  findDefinedUnit,
  getRecorded,
}: ParseTokenParams): TokenResolvedResult => {
  if (!token?.reference_id) {
    return UNRESOLVED_RESULT;
  }
  if (getRecorded(referenceContext[token.reference_id] as RunExpressionBlock)) {
    const recorded = getRecorded(
      referenceContext[token.reference_id] as RunExpressionBlock
    );
    const value = recorded?.value ?? '';
    const isResolved = !(value === undefined || value === '');
    const resolvedExpression = isResolved
      ? `(${token.reference_id})`
      : undefined;

    const functionName = recorded?.functionName;

    return {
      resolvedExpression,
      richDisplayText: _getRichText({
        referenceId: token.reference_id,
        value,
        isResolved,
      }),
      displayText: isResolved ? `${value as string | number}` : '?',
      isResolved,
      context: { [token.reference_id]: `${(value as string | number) ?? ''}` },
      functionName,
    };
  }

  const childBlock = referenceContext[token.reference_id] as ExpressionBlock;
  if (!childBlock?.tokens) {
    return UNRESOLVED_RESULT;
  }

  const containsFunction = childBlock.tokens.some((t) => t.type === 'function');

  const childTokens = childBlock.tokens;
  const hasTimestamp = childTokens.some(
    (token) => token.reference_subtype === 'timestamp'
  );

  const result = resolveTokens({
    tokens: childTokens,
    referenceContext,
    findDefinedUnit,
    consolidate: true,
    parentToken: token,
    getRecorded,
  });

  let resolvedExpression = result.resolvedExpression;
  if (
    resolvedExpression &&
    containsFunction &&
    sharedIsNumber(Number(result.displayText))
  ) {
    resolvedExpression = `(${Number(result.displayText)})`;
  } else if (resolvedExpression !== undefined) {
    resolvedExpression = `(${resolvedExpression})`;
  }

  return {
    ...result,
    hasTimestamp,
    resolvedExpression,
    functionName: result.functionName,
  };
};

const _handleInputToken = ({
  token,
  referenceContext,
  findDefinedUnit,
  getRecorded,
}: ParseTokenParams): TokenResolvedResult => {
  if (!token?.reference_id) {
    return UNRESOLVED_RESULT;
  }

  const referencedInputBlock = referenceContext[token.reference_id] as
    | RunFieldInputBlock
    | RunFieldInputTableBlock;
  const block: RunFieldInputBlock =
    referencedInputBlock?.type === 'field_input_table' &&
    isNumber(token.field_index) &&
    referencedInputBlock?.fields
      ? (referencedInputBlock.fields[token.field_index] as RunFieldInputBlock)
      : (referencedInputBlock as RunFieldInputBlock);

  const recorded = getRecorded(block);
  if (!recorded || recorded.value === '') {
    return UNRESOLVED_RESULT;
  }

  const { value } = recorded;
  let valueWithUnits = value;

  if (findDefinedUnit) {
    const units = (block as RunFieldInputNumberBlock | RunFieldInputTextBlock)
      ?.units;

    if (units) {
      const settingsUnit = findDefinedUnit(units);
      const abbreviation = settingsUnit?.abbreviation ?? units;
      valueWithUnits = `${value as string | number} ${abbreviation}`;
    }
  }

  const expressionName = isNumber(token.field_index)
    ? `${token.reference_id}_${token.field_index}`
    : token.reference_id;
  const resolvedExpression = `(${expressionName})`;

  if ((block as FieldInputBlock).inputType === 'timestamp') {
    const { timestamp } = getTimestampFromRecorded(
      recorded as RunFieldInputRecorded<TimestampValue>
    );

    return timestamp
      ? {
          resolvedExpression,
          richDisplayText: _getRichText({
            referenceId: token.reference_id,
            value: timestamp,
            isResolved: true,
            fieldIndex: token.field_index,
          }),
          displayText:
            (block as FieldInputTimestampBlock).dateTimeType === 'time'
              ? DateTime.fromFormat(timestamp, 'HH:mm:ss').toFormat('HH:mm:ss')
              : DateTime.fromISO(timestamp).toFormat('yyyy-MM-dd HH:mm:ss'),
          isResolved: true,
          hasTimestamp: true,
          context: {
            [expressionName]: ((block as FieldInputTimestampBlock)
              .dateTimeType === 'time'
              ? DateTime.fromFormat(timestamp, 'HH:mm:ss')
                  .diff(DateTime.fromFormat('00:00:00', 'HH:mm:ss'))
                  .as('seconds')
                  .toString()
              : DateTime.fromISO(timestamp, { zone: 'UTC' })
                  .toSeconds()
                  .toString()
            ).toString(),
          },
        }
      : {
          resolvedExpression,
          richDisplayText: _getRichText({
            referenceId: token.reference_id,
            fieldIndex: token.field_index,
          }),
          displayText: '?',
          isResolved: false,
          context: {},
        };
  }

  return {
    resolvedExpression,
    displayText: `${(valueWithUnits as string | number) ?? ''}`,
    richDisplayText: _getRichText({
      referenceId: token.reference_id,
      value: valueWithUnits as string,
      isResolved: true,
      fieldIndex: token.field_index,
    }),
    isResolved: true,
    context: { [expressionName]: `${(value as string | number) ?? ''}` },
  };
};

export const canReferenceColumn = (column: TableColumn) => {
  if (!column) {
    return false;
  }
  return (
    column.column_type === 'input' &&
    ['text', 'number', 'list'].some(
      (inputType) => inputType === column.input_type
    )
  );
};

const _handleTableToken = ({
  token,
  referenceContext,
  findDefinedUnit,
  getRecorded,
}: ParseTokenParams): TokenResolvedResult => {
  if (!token?.reference_id || !token.table_reference) {
    return UNRESOLVED_RESULT;
  }

  const referencedTableBlock = referenceContext[
    token.reference_id
  ] as RunTableInputBlock;
  const recorded = getRecorded(referencedTableBlock);
  const rowIndex = referencedTableBlock.row_metadata?.findIndex(
    ({ id }) => id === token.table_reference?.row_id
  );
  const columnIndex = referencedTableBlock.columns?.findIndex(
    ({ id }) => id === token.table_reference?.column_id
  );
  if (
    !recorded ||
    !recorded.values ||
    isNil(rowIndex) ||
    isNil(columnIndex) ||
    rowIndex === -1 ||
    columnIndex === -1
  ) {
    return UNRESOLVED_RESULT;
  }
  const value = recorded.values[rowIndex][columnIndex];
  const column = referencedTableBlock.columns[columnIndex];

  if (isEmpty(value) || !canReferenceColumn(column)) {
    return UNRESOLVED_RESULT;
  }

  let valueWithUnits = value as string;
  if (findDefinedUnit && column.units) {
    const settingsUnit = findDefinedUnit(column.units);
    const abbreviation = settingsUnit?.abbreviation ?? column.units;
    valueWithUnits = `${value as string | number} ${abbreviation}`;
  }

  const expressionName = `${token.reference_id}_${token.table_reference.row_id}_${token.table_reference.column_id}`;
  const resolvedExpression = `(${expressionName})`;

  return {
    resolvedExpression,
    displayText: `${valueWithUnits ?? ''}`,
    richDisplayText: _getRichText({
      referenceId: token.reference_id,
      value: valueWithUnits,
      isResolved: true,
    }),
    isResolved: true,
    context: { [expressionName]: `${(value as string) ?? ''}` },
  };
};

/**
 * To allow an expression to reference a block type,
 * add a handler for that block type to this dispatch table.
 */
const TOKEN_REFERENCE_HANDLER: {
  [tokenType in
    | NonNullable<ExpressionReferenceTargetBlock>['type']
    | 'function']: (params: ParseTokenParams) => TokenResolvedResult;
} = {
  expression: _handleExpressionToken,
  input: _handleInputToken,
  field_input_table: _handleInputToken,
  table_input: _handleTableToken,
  function: _handleFunctionToken,
};

const _resolveToken = ({
  token,
  referenceContext,
  findDefinedUnit,
  getRecorded,
}: ParseTokenParams): TokenResolvedResult => {
  if (token.type === 'text') {
    return {
      resolvedExpression: token.value,
      displayText: token.value,
      richDisplayText: token.value,
      isResolved: true,
      context: {},
    };
  }

  if (token.type === 'function') {
    return _handleFunctionToken({
      token,
      referenceContext,
      findDefinedUnit,
      getRecorded,
    });
  }

  // At this point, the token must be a reference token.
  const referencedContext = getContentBlock(
    referenceContext[token.reference_id ?? ''],
    token.field_index
  );
  const handlerType = referencedContext?.type ?? '';
  const tokenReferenceHandler: (
    params: ParseTokenParams
  ) => TokenResolvedResult = TOKEN_REFERENCE_HANDLER[handlerType];
  if (!token.reference_id || !tokenReferenceHandler) {
    return UNRESOLVED_RESULT;
  }

  return tokenReferenceHandler({
    token,
    referenceContext,
    findDefinedUnit,
    getRecorded,
  });
};

export const resolveTokens = ({
  tokens,
  referenceContext,
  findDefinedUnit,
  consolidate = false,
  parentToken,
  getRecorded = (block: ExpressionReferenceRecordableTargetBlock) =>
    block?.recorded,
}: {
  tokens: Array<ExpressionToken>;
  referenceContext: Record<string, ReferenceContext>;
  findDefinedUnit?: (string) => Unit | undefined;
  consolidate?: boolean;
  parentToken?: ExpressionToken;
  getRecorded?: GetRecorded;
}): Required<TokenResolvedResult> => {
  const tokenResolutionResults = tokens.map((token) => {
    return _resolveToken({
      token,
      referenceContext,
      findDefinedUnit,
      getRecorded,
    });
  });

  const hasTimestamp = tokenResolutionResults.some(
    (token) => token.hasTimestamp
  );

  const functionName =
    tokenResolutionResults.find((result) => result.functionName)
      ?.functionName || '';

  const isResolved = tokenResolutionResults.every(
    (result) => result.isResolved
  );
  const resolvedExpression = tokenResolutionResults
    .map((result) => result.resolvedExpression ?? '')
    .join('');
  const displayTextRaw = tokenResolutionResults
    .map((result) => result.displayText)
    .join('');
  const richDisplayTextRaw = tokenResolutionResults
    .map((result) => result.richDisplayText)
    .join('');
  const context = tokenResolutionResults
    .map((result) => result.context)
    .reduce((fullContext, tokenContext) => {
      return {
        ...fullContext,
        ...tokenContext,
      };
    }, {});

  const evaluated = isResolved
    ? evaluate(resolvedExpression, context)
    : undefined;

  return consolidate && parentToken?.reference_id
    ? {
        resolvedExpression,
        displayText: evaluated ?? '?',
        richDisplayText: _getRichText({
          referenceId: parentToken.reference_id,
          value: evaluated as string,
          isResolved,
        }),
        isResolved,
        context,
        hasTimestamp,
        functionName,
      }
    : {
        resolvedExpression,
        displayText: displayTextRaw,
        richDisplayText: richDisplayTextRaw,
        isResolved,
        context,
        hasTimestamp,
        functionName,
      };
};

export const evaluate = (
  formula: string,
  context: Record<string, string>
): string => {
  try {
    const functionMatch = formula.match(/^(\w+)\((.*)\)$/);
    if (functionMatch) {
      const [, functionName, paramsExpr] = functionMatch;
      const functionDef = functionRegistry.get(functionName);

      if (functionDef) {
        const params: number[] = paramsExpr.split(',').map((param) => {
          const trimmedParam = param.trim();
          return Number(evaluateMathJs(trimmedParam, context));
        });

        const result = functionDef.evaluate(params);
        return String(result);
      }
    }

    const result = evaluateMathJs(formula, context);
    return result?.toString() as string;
  } catch (e) {
    return (e as Error).message;
  }
};

/**
 * Depth-first search to check if there is a cycle anywhere in the directed graph
 */
export const hasCyclicReference = ({
  tokens,
  procedureMap,
  seen = new Set(),
}: {
  tokens: Array<ExpressionToken>;
  procedureMap: { [id: string]: ExpressionBlock | FieldInputBlock | undefined };
  seen?: Set<string>;
}) => {
  return tokens
    .filter((token) => token.type === 'reference')
    .some((token) => {
      const referenceId = token.reference_id ?? '';
      if (seen.has(referenceId)) {
        return true;
      }

      const target = procedureMap[referenceId];
      if (target && target.type === 'expression') {
        return hasCyclicReference({
          tokens: target.tokens,
          procedureMap,
          seen: new Set([...seen, referenceId]),
        });
      }

      return false;
    });
};

export const getContentBlock = (
  block?: StepBlock,
  fieldIndex?: number
): StepBlock | undefined => {
  if (block?.type === 'field_input_table' && isNumber(fieldIndex)) {
    return block.fields[fieldIndex] as FieldInputBlock;
  }
  return block;
};

export const validateFunctionParameters = (
  functionName: string,
  params: ExpressionToken[][]
): string | null => {
  const functionDef = functionRegistry.get(functionName);
  if (!functionDef) return 'Unknown function';
  if (params.length !== functionDef.params.length) {
    return `${functionName} requires exactly ${functionDef.params.length} parameters`;
  }

  // First do basic type validation
  for (let i = 0; i < params.length; i++) {
    const expectedType = functionDef.params[i].type;
    const tokens = params[i];

    for (const token of tokens) {
      if (token.type !== 'reference') continue;

      if (
        expectedType === 'timestamp' &&
        token.reference_subtype !== 'timestamp'
      ) {
        return `Parameter ${i + 1} of ${functionName} must be a timestamp`;
      }

      if (expectedType === 'number' && token.reference_subtype !== 'number') {
        return `Parameter ${i + 1} of ${functionName} must be a number`;
      }
    }
  }

  const timestampParams = params.filter(
    (_, i) => functionDef.params[i].type === 'timestamp'
  );
  if (timestampParams.length > 1) {
    const subtypes = new Set<string>();

    for (const param of timestampParams) {
      for (const token of param) {
        if (
          token.type === 'reference' &&
          token.reference_subtype === 'timestamp' &&
          token.timestamp_subtype
        ) {
          subtypes.add(token.timestamp_subtype);
        }
      }
    }

    if (
      subtypes.has('time') &&
      (subtypes.has('date') || subtypes.has('datetime'))
    ) {
      return `${functionName} cannot mix time values with date or datetime values`;
    }

    if (subtypes.has('time') && subtypes.size > 1) {
      return `${functionName} can only combine time values with other time values`;
    }
  }

  return null;
};
