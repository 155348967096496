import { parser, parse, isOperatorNode } from 'mathjs';
import {
  ExpressionBlock,
  ExpressionToken,
  FieldInputBlock,
  TableInputBlock,
} from 'shared/lib/types/views/procedures';
import { Step } from 'react-step-progress-bar';
import tableInputUtil from './tableInputUtil';
import { identity, isNil } from 'lodash';
import isNumber from './number';
import {
  canReferenceColumn,
  functionRegistry,
  validateFunctionParameters,
} from 'shared/lib/expressionUtil';
import sharedTableUtil from 'shared/lib/tableUtil';

const NO_PARSE_ERRORS = '';
export const NAMESPACE_DELIMITER = '::';
export const ALLOWED_EXPRESSION_REFERENCE_SUBTYPES = [
  'number',
  'timestamp',
] as const;

type ReferenceOptionBase = {
  referenceLabel: string;
  textToSearch: string;
  origin?: {
    isVariable?: boolean;
    sectionId?: string;
    stepId?: string;
    step?: Step;
    fieldIndex?: number;
  };
};

type EmptyReferenceOption = ReferenceOptionBase & {
  name: string;
  type: 'empty';
  id: string;
};
export type ExpressionReferenceOption = ReferenceOptionBase & ExpressionBlock;
export type FieldInputReferenceOption = ReferenceOptionBase & FieldInputBlock;
type TableInputReferenceOption = ReferenceOptionBase &
  TableInputBlock & { name: string };

export type ReferenceOption =
  | EmptyReferenceOption
  | ExpressionReferenceOption
  | FieldInputReferenceOption
  | TableInputReferenceOption;

const expression = {
  _isValidTableReference: ({
    item,
    token,
  }: {
    item: ReferenceOption;
    token: ExpressionToken;
  }): boolean => {
    if (item.type !== 'table_input') {
      return false;
    }
    const { columnIndex } = sharedTableUtil.cellIdsToIndices({
      rowId: token.table_reference?.row_id,
      columnId: token.table_reference?.column_id,
      rowMetadata: item.row_metadata,
      columnMetadata: item.columns,
    });

    return canReferenceColumn(item.columns[columnIndex]);
  },

  findMatchFromToken: ({
    token,
    options,
  }: {
    token: ExpressionToken;
    options: Array<ReferenceOption>;
  }): ReferenceOption | undefined => {
    if (sharedTableUtil.isValidNumber(token.field_index)) {
      return options.find(
        (item) =>
          item.id === token.reference_id &&
          item.origin?.fieldIndex === token.field_index
      );
    }
    if (!isNil(token.table_reference)) {
      return options.find(
        (item) =>
          item.id === token.reference_id &&
          expression._isValidTableReference({ item, token })
      );
    }
    return options.find((item) => item.id === token.reference_id);
  },

  findMatch: ({
    referenceId,
    fieldIndex,
    options,
  }: {
    referenceId: string;
    fieldIndex?: number;
    options: Array<ReferenceOption>;
  }): ReferenceOption | undefined => {
    const pseudoToken: ExpressionToken = {
      type: 'reference',
      value: '',
      reference_id: referenceId,
      field_index: fieldIndex,
    };

    return expression.findMatchFromToken({ token: pseudoToken, options });
  },

  findMatchFromLabel: ({
    label,
    options,
  }: {
    label: string;
    options: Array<ReferenceOption>;
  }): ReferenceOption | undefined => {
    const [substepKey, location] = label.split(NAMESPACE_DELIMITER);
    if (sharedTableUtil.isValidNumber(location)) {
      // is a field input table label
      return options.find(
        (item) =>
          item.referenceLabel.toLowerCase() === substepKey.toLowerCase() &&
          Number(item.origin?.fieldIndex) === Number(location) - 1
      );
    }
    if (substepKey.toLowerCase() === 'variable' && location) {
      return options.find(
        (item) =>
          item.referenceLabel.toLowerCase() === substepKey.toLowerCase() &&
          item.name.toLowerCase() === location.toLowerCase()
      );
    }
    return options.find(
      (item) => item.referenceLabel.toLowerCase() === substepKey.toLowerCase()
    );
  },

  getNamespacedReference: ({
    id,
    options,
    fieldIndex,
    rowId,
    columnId,
  }: {
    id: string;
    options: ReferenceOption[];
    fieldIndex?: number;
    rowId?: string;
    columnId?: string;
  }): string => {
    const idMatch = expression.findMatch({
      referenceId: id,
      fieldIndex,
      options,
    });

    if (idMatch && idMatch.type === 'table_input') {
      const cellCoordinates = tableInputUtil.getCellCoordinates({
        rowId,
        columnId,
        rowMetadata: idMatch.row_metadata,
        columnMetadata: idMatch.columns,
      });

      return `{${[idMatch.referenceLabel, cellCoordinates ?? '??'].join(
        NAMESPACE_DELIMITER
      )}}`;
    }

    if (
      idMatch &&
      idMatch.type === 'input' &&
      !isNil(idMatch.origin?.fieldIndex)
    ) {
      return `{${[
        idMatch.referenceLabel,
        (idMatch.origin?.fieldIndex ?? -2) + 1,
      ].join(NAMESPACE_DELIMITER)}}`;
    }

    if (idMatch?.origin?.isVariable) {
      return `{${idMatch.referenceLabel}${NAMESPACE_DELIMITER}${idMatch.name}}`;
    }

    if (idMatch) {
      return `{${idMatch.referenceLabel}}`;
    }

    return '{??}';
  },

  getNamespacedTokenText: ({
    token,
    options,
  }: {
    token: ExpressionToken;
    options: ReferenceOption[];
  }): string => {
    if (token.type === 'function') {
      return token.function_name || 'Unknown Function';
    }

    const match = expression.findMatchFromToken({ token, options });

    if (match?.type === 'table_input') {
      const subLabel = tableInputUtil.getCellCoordinates({
        rowId: token.table_reference?.row_id,
        columnId: token.table_reference?.column_id,
        rowMetadata: match.row_metadata,
        columnMetadata: match.columns,
      });

      return [
        match?.referenceLabel,
        match?.name,
        ...(subLabel ? [subLabel] : []),
      ].join(NAMESPACE_DELIMITER);
    }
    if (match?.type === 'input' && !isNil(match?.origin?.fieldIndex)) {
      const subLabel = (match?.origin?.fieldIndex ?? -2) + 1;
      return [
        match?.referenceLabel,
        ...(subLabel ? [subLabel] : []),
        match?.name,
      ].join(NAMESPACE_DELIMITER);
    }

    return match
      ? [match?.referenceLabel, match?.name].join(NAMESPACE_DELIMITER)
      : 'Unknown Reference';
  },

  tokensToRawText: (
    tokens: ExpressionToken[],
    referenceOptions: ReferenceOption[]
  ): string => {
    if (!tokens || tokens.length === 0) {
      return '';
    }
    return tokens
      .map((token) => {
        if (token.type === 'text') {
          return token.value;
        }
        if (
          token.type === 'function' &&
          token.function_name &&
          token.function_params
        ) {
          const params = token.function_params.map((paramTokens) =>
            expression.tokensToRawText(paramTokens, referenceOptions)
          );
          return `${token.function_name}(${params.join(', ')})`;
        }
        if (token.reference_id) {
          return expression.getNamespacedReference({
            id: token.reference_id,
            options: referenceOptions,
            fieldIndex: token.field_index,
            rowId: token.table_reference?.row_id,
            columnId: token.table_reference?.column_id,
          });
        }
        return null;
      })
      .filter(identity)
      .join('');
  },

  _idToToken: ({
    label,
    options,
  }: {
    label: string;
    options: Array<ReferenceOption>;
  }): ExpressionToken => {
    const match = expression.findMatchFromLabel({ label, options });
    const [, location] = label.split(NAMESPACE_DELIMITER);
    let name = '';
    let rowId;
    let columnId;

    if (match?.type === 'table_input') {
      ({ rowId, columnId } = tableInputUtil.cellCoordinatesToIds({
        coordinates: location,
        rowMetadata: match.row_metadata,
        columnMetadata: match.columns,
      }));

      name = location;
    } else {
      name = (match as ExpressionReferenceOption | FieldInputReferenceOption)
        ?.name;
    }
    if (match) {
      return {
        type: 'reference',
        value: name,
        reference_id: match.id,
        ...(match.type === 'input' &&
          ['number', 'timestamp'].includes(match.inputType) && {
            reference_subtype: match.inputType as 'number' | 'timestamp',
          }),
        ...(match.type === 'input' &&
          match.inputType === 'timestamp' &&
          match.dateTimeType && {
            timestamp_subtype: match.dateTimeType,
          }),
        ...(isNumber(match?.origin?.fieldIndex) && {
          field_index: match?.origin?.fieldIndex,
        }),
        ...(rowId &&
          columnId && {
            table_reference: {
              row_id: rowId,
              column_id: columnId,
            },
          }),
      };
    } else {
      return {
        type: 'text',
        value: `{${label}}`,
      };
    }
  },

  textToTokens: (
    text: string,
    referenceOptions: ReferenceOption[]
  ): ExpressionToken[] => {
    // First try to parse as a function
    const functionToken = expression.parseFunction(text, referenceOptions);
    if (functionToken) {
      return [functionToken];
    }

    const updatedTokens: ExpressionToken[] = [];
    let leftBracketIndexes: number[] = [];
    let index = 0;
    let textStart = 0;
    for (const char of text) {
      if (char === '{') {
        leftBracketIndexes.unshift(index);
      } else if (char === '}') {
        if (leftBracketIndexes.length > 0) {
          let foundMatch = false;
          let leftIndex = 0;
          while (!foundMatch && leftIndex < leftBracketIndexes.length) {
            const possibleMatch = text.substring(
              leftBracketIndexes[leftIndex] + 1,
              index
            );
            const token = expression._idToToken({
              label: possibleMatch,
              options: referenceOptions,
            });
            if (token?.type === 'reference') {
              foundMatch = true;
              if (textStart < leftBracketIndexes[leftIndex]) {
                updatedTokens.push({
                  type: 'text',
                  value: text.substring(
                    textStart,
                    leftBracketIndexes[leftIndex]
                  ),
                });
              }
              textStart = index + 1;
              leftBracketIndexes = [];
              updatedTokens.push(token);
            }
            leftIndex++;
          }
        }
      }
      index++;
    }
    if (textStart < text.length) {
      updatedTokens.push({
        type: 'text',
        value: text.substring(textStart, text.length),
      });
    }
    return updatedTokens;
  },

  parseFunction(
    text: string,
    referenceOptions: ReferenceOption[]
  ): ExpressionToken | null {
    const functionMatch = text.match(/^(\w+)\((.*)\)$/);
    if (!functionMatch) return null;

    const [, functionName, paramsText] = functionMatch;
    if (!functionRegistry.has(functionName)) return null;

    const params: Array<string> = [];
    let currentParam = '';
    let parenCount = 0;

    for (const char of paramsText) {
      if (char === '(') parenCount++;
      else if (char === ')') parenCount--;
      else if (char === ',' && parenCount === 0) {
        params.push(currentParam.trim());
        currentParam = '';
        continue;
      }
      currentParam += char;
    }

    if (currentParam) params.push(currentParam.trim());

    // Simple count check
    const functionDef = functionRegistry.get(functionName);
    if (!functionDef || params.length !== functionDef.params.length) {
      return null;
    }

    try {
      const parsedParams = params.map((param) =>
        expression.textToTokens(param, referenceOptions)
      );

      return {
        type: 'function',
        value: text,
        function_name: functionName,
        function_params: parsedParams,
      };
    } catch (e) {
      return null;
    }
  },

  validate: (tokens: ExpressionToken[]): string => {
    for (const token of tokens) {
      if (
        token.type === 'function' &&
        token.function_name &&
        token.function_params
      ) {
        const validationError = validateFunctionParameters(
          token.function_name,
          token.function_params
        );
        if (validationError) return validationError;

        if (token.function_name === 'calculateDuration') {
          for (let i = 0; i < token.function_params.length; i++) {
            const hasTimestamp = token.function_params[i].some(
              (p) =>
                p.type === 'reference' && p.reference_subtype === 'timestamp'
            );
            if (!hasTimestamp) {
              return `Parameter ${
                i + 1
              } of calculateDuration must include a timestamp`;
            }
          }
        }
      }
    }

    const mathParser = parser();
    const resolved: string[] = [];
    tokens.forEach((token) => {
      if (token.type === 'text') {
        resolved.push(token.value);
      } else {
        if (token.reference_id) {
          resolved.push(`(${token.reference_id})`);
          mathParser.set(token.reference_id, 1);
        }
      }
    });
    const expression = resolved.join('');

    try {
      const node = parse(expression);
      let implicitError = false;
      node.traverse((node) => {
        if (isOperatorNode(node)) {
          if (node.op === '*' && node['implicit']) {
            implicitError = true;
          }
        }
      });
      if (implicitError) {
        return 'Implicit multiplication not allowed';
      }

      mathParser.evaluate(expression);
      return NO_PARSE_ERRORS;
    } catch (e) {
      return e.message;
    }
  },
};

export default expression;
