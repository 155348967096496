import { useQuery } from '@tanstack/react-query';
import { useCallback } from 'react';
import { Part } from 'shared/lib/types/postgres/manufacturing/types';
import { useDatabaseServices } from '../../contexts/DatabaseContext';
import { DatabaseServices } from '../../contexts/proceduresSlice';
import { useSettings } from '../../contexts/SettingsContext';
import { getLatestReleasedRevisionForPartId } from '../lib/parts';

interface LatestPartsState {
  parts?: Part[];
  assemblyParts?: Part[];
  getLatestPartRevision: (partId: string) => Part | undefined;
}

const useLatestParts = (): LatestPartsState => {
  const { services }: { services: DatabaseServices } = useDatabaseServices();
  const { isManufacturingEnabled } = useSettings();

  const fetchParts = async () => {
    if (!isManufacturingEnabled?.()) {
      return null;
    }
    const promises = [
      services.manufacturing.getParts(),
      services.manufacturing.getAssemblyParts(),
    ];
    const [parts, assemblyParts] = await Promise.all(promises);
    return { parts, assemblyParts };
  };

  const { data } = useQuery({ queryKey: ['parts'], queryFn: fetchParts });

  const getLatestPartRevision = useCallback(
    (partId: string) =>
      getLatestReleasedRevisionForPartId(partId, data?.parts ?? []),
    [data?.parts]
  );

  return {
    parts: data?.parts,
    assemblyParts: data?.assemblyParts,
    getLatestPartRevision,
  };
};

export default useLatestParts;
