import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState, useRef } from 'react';
import DataGrid, {
  CellClickArgs,
  CellMouseEvent,
  Column,
  SortColumn,
  SortDirection,
  RenderSortStatusProps,
  TreeDataGrid,
  RowHeightArgs,
} from 'react-data-grid';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import LoadingBadge from '../components/LoadingBadge';
import DraggableRowRenderer from './renderers/DraggableRowRenderer';
import ThreeDotMenu from './ThreeDotMenu';
import { exportToCsv } from './lib/gridDownloadUtil';
import { MenuContextAction } from '../components/MenuContext';
import Modal from '../components/Modal';

// extends .rdg-cell in index.css
export enum TextAlign {
  Left = 'text-left justify-start',
  Right = 'text-right justify-end',
  Center = 'justify-center',
}

export interface GridColumn<R> extends Column<R> {
  comparator?: (a, b, direction) => number;
  align?: TextAlign;
  hidden?: boolean;
}

declare type Maybe<T> = T | undefined | null;

export interface Grouping<R> {
  groupBy: ReadonlyArray<string>;
  rowGrouper?: Maybe<(rows: ReadonlyArray<R>, columnKey: string) => Record<string, ReadonlyArray<R>>>;
  expandedGroupIds?: ReadonlySet<string>;
  onExpandedGroupIdsChange?: Dispatch<SetStateAction<ReadonlySet<string>>>;
}

export interface GridProps<R> {
  columns: ReadonlyArray<GridColumn<R>>;
  rows: ReadonlyArray<R>;
  rowHeight?: Maybe<number | ((row: R) => number) | ((args: RowHeightArgs<R>) => number)>;
  /** Vertical space taken up by headers or footers in px.  Allows grid to resize vertically */
  usedVerticalSpace?: number | (() => number);
  rowGrouping?: Maybe<Grouping<R>>;
  defaultSort?: Array<SortColumn>;
  isLoading?: boolean;
  emptyRowMessage?: string;
  manualReordering?: boolean;
  gridKey?: string;
  selectedRows?: ReadonlySet<string>;
  enableExport?: boolean;
  /**
   * Event props
   */
  onCellClick?: Maybe<(args: CellClickArgs<R>, event: CellMouseEvent) => void>;
  onRowReorder?: (fromIndex: number, toIndex: number) => void;
  /** If defined, this will override the internal sorting to allow the parent to manage it */
  onSortColumnsChange?: Maybe<(sortColumns: Array<SortColumn>) => void>;
  /** If defined, this runs after sorting on the sorted rows */
  sortPostProcessor?: (rowsToBeDisplayed: Array<R>) => Array<R>;
  onSelectedRowsChange?: Maybe<(selectedRows: Set<string>) => void>;
  rowKeyGetter?: Maybe<(row: R) => string>;
}

export const NO_SCROLL_PAD = 2; // Adding this to grid height removes the inner vertical scrollbar
const EMPTY_ROW_MESSAGE = 'No items found';
export const DEFAULT_HEADER_ROW_HEIGHT = 40;
export const DEFAULT_ROW_HEIGHT = 50;
export const DEFAULT_GROUP_ROW_HEIGHT_PX = 30;
export const TOOLBAR_HEIGHT_PX = 37;

export const compareNumbers = (a: number, b: number) => {
  return a - b;
};

const Grid = <R,>(props: GridProps<R>) => {
  const {
    columns,
    rows,
    rowHeight = DEFAULT_ROW_HEIGHT,
    defaultSort,
    usedVerticalSpace = () => 0,
    rowGrouping,
    isLoading = false,
    emptyRowMessage = EMPTY_ROW_MESSAGE,
    manualReordering = false,
    gridKey,
    selectedRows,
    enableExport = true,
    onCellClick,
    onRowReorder,
    onSortColumnsChange,
    sortPostProcessor,
    onSelectedRowsChange,
    rowKeyGetter,
  } = props;

  const getUsedVerticalSpace = useCallback(() => {
    let usedContentVerticalSpace = 0;

    if (typeof usedVerticalSpace === 'number') {
      usedContentVerticalSpace = usedVerticalSpace;
    } else if (typeof usedVerticalSpace === 'function') {
      usedContentVerticalSpace = usedVerticalSpace();
    }

    return usedContentVerticalSpace + TOOLBAR_HEIGHT_PX;
  }, [usedVerticalSpace]);

  const [enableVirtualization, setEnableVirtualization] = useState(true);
  const [showDownloadingModal, setShowDownloadingModal] = useState(false);
  const [mainHeight, setMainHeight] = useState<number>(window.innerHeight - getUsedVerticalSpace());
  const [sortColumns, setSortColumns] = useState<ReadonlyArray<SortColumn>>(defaultSort ? defaultSort : []);
  const [expandedGroupIds, setExpandedGroupIds] = useState<ReadonlySet<string>>(() => new Set());
  const gridContainerRef = useRef(null);

  useEffect(() => {
    if (!defaultSort || !onSortColumnsChange) {
      return;
    }
    if (!_.isEqual(sortColumns, defaultSort)) {
      setSortColumns(defaultSort);
    }
  }, [defaultSort, onSortColumnsChange, sortColumns]);

  useEffect(() => {
    if (rowGrouping?.expandedGroupIds) {
      setExpandedGroupIds(rowGrouping.expandedGroupIds);
    }
  }, [rowGrouping?.expandedGroupIds]);

  useEffect(() => {
    let resizeTimeoutId: ReturnType<typeof setTimeout>;
    const handleViewportResize = () => {
      clearTimeout(resizeTimeoutId);
      resizeTimeoutId = setTimeout(() => {
        setMainHeight(window.innerHeight - getUsedVerticalSpace());
      }, 100);
    };

    window.addEventListener('resize', handleViewportResize);
    handleViewportResize();

    return () => {
      window.removeEventListener('resize', handleViewportResize);
    };
  }, [getUsedVerticalSpace]);

  const handleExpandGroupIdsChange = useCallback(
    (groupIds: ReadonlySet<unknown>) => {
      if (!rowGrouping) {
        return;
      }
      if (rowGrouping.onExpandedGroupIdsChange) {
        rowGrouping.onExpandedGroupIdsChange(groupIds as ReadonlySet<string>);
        return;
      }
      setExpandedGroupIds(groupIds as ReadonlySet<string>);
    },
    [rowGrouping]
  );

  const getComparator = useCallback(
    (sortColumn: string) => {
      return (a: R, b: R, direction?: SortDirection) => {
        const col = columns.find((c) => c.key === sortColumn);
        if (col?.comparator) {
          return col.comparator(a, b, direction);
        }

        // handle keys with . in the name to access sub-properties (e.g. 'metadata.editedAt')
        const valA = sortColumn.split('.').reduce((a, b) => a?.[b], a) as string;
        const valB = sortColumn.split('.').reduce((a, b) => a?.[b], b) as string;
        if (!valA) {
          return -1;
        }
        if (!valB) {
          return 1;
        }
        return valA.localeCompare(valB);
      };
    },
    [columns]
  );

  const sortedRows = useMemo((): ReadonlyArray<R> => {
    if (sortColumns.length === 0) return rows;

    const rowsToBeDisplayed = [...rows].sort((a, b) => {
      for (const sort of sortColumns) {
        const comparator = getComparator(sort.columnKey);
        const compResult = comparator(a, b, sort.direction);
        if (compResult !== 0) {
          return sort.direction === 'ASC' ? compResult : -compResult;
        }
      }
      return 0;
    });
    return sortPostProcessor ? sortPostProcessor(rowsToBeDisplayed) : rowsToBeDisplayed;
  }, [sortColumns, rows, sortPostProcessor, getComparator]);

  const sortStatus = ({ sortDirection }: RenderSortStatusProps) => {
    return (
      <div className="flex flex-col pt-3 w-6">
        {sortDirection !== undefined ? (
          sortDirection === 'ASC' ? (
            <FontAwesomeIcon className="text-xs" icon="chevron-up" />
          ) : (
            <FontAwesomeIcon className="text-xs pt-2" icon="chevron-down" />
          )
        ) : (
          <>
            <FontAwesomeIcon className="text-xs" icon="chevron-up" />
            <FontAwesomeIcon className="text-xs" icon="chevron-down" />
          </>
        )}
      </div>
    );
  };

  const dndRowRenderer = useCallback(
    (key, props) => {
      return <DraggableRowRenderer key={key} {...props} onRowReorder={onRowReorder} />;
    },
    [onRowReorder]
  );

  const ConditionalDndWrapper = useCallback(
    ({ children }) => {
      return manualReordering ? <DndProvider backend={HTML5Backend}>{children}</DndProvider> : <>{children}</>;
    },
    [manualReordering]
  );

  const renderedColumns = useMemo(() => {
    return columns
      .map((col) => ({
        ...col,
        headerCellClass: col.align || TextAlign.Left,
        cellClass: col.align || TextAlign.Left,
      }))
      .filter((col) => !col.hidden);
  }, [columns]);

  const menuActions = useMemo((): Array<MenuContextAction> => {
    return [
      {
        type: 'label',
        label: 'Export as CSV',
        data: {
          title: 'Export as CSV',
          icon: 'download',
          onClick: () => {
            setShowDownloadingModal(true);
            setEnableVirtualization(false);
            setTimeout(() => {
              if (gridContainerRef.current) {
                exportToCsv(gridContainerRef.current, 'Export');
              }

              setTimeout(() => {
                setEnableVirtualization(true);
                setShowDownloadingModal(false);
              }, 500);
            }, 0);
          },
        },
      },
    ];
  }, []);

  return (
    <>
      <div ref={gridContainerRef} className="relative group/gridheader" style={{ contain: 'inline-size' }}>
        <div className="flex flex-row bg-white border-r border-l border-t border-gray-200 justify-between">
          {/**Left side actions */}
          <div></div>

          {/**Right side actions */}
          <div>
            {enableExport && (
              <div className="w-8">
                <ThreeDotMenu menuActions={menuActions} menuLabel="Table Options Menu" boxSize="narrow" />
              </div>
            )}
          </div>
        </div>
        {isLoading && (
          <div className="absolute z-200 inset-y-1/2 inset-x-1/2 flex items-center justify-center h-16">
            <LoadingBadge />
          </div>
        )}
        <ConditionalDndWrapper>
          {rowGrouping && (
            <TreeDataGrid
              {...(gridKey && { key: gridKey })}
              className="fill-grid rdg-light w-full"
              style={{ height: rows.length > 0 ? mainHeight : 40 + NO_SCROLL_PAD }}
              columns={renderedColumns}
              rows={sortedRows}
              rowHeight={rowHeight as number | ((args: RowHeightArgs<R>) => number)}
              headerRowHeight={DEFAULT_HEADER_ROW_HEIGHT}
              groupBy={rowGrouping.groupBy}
              rowGrouper={rowGrouping.rowGrouper ?? _.groupBy}
              expandedGroupIds={expandedGroupIds}
              onExpandedGroupIdsChange={handleExpandGroupIdsChange}
              renderers={{
                ...(manualReordering ? { renderRow: dndRowRenderer } : { renderSortStatus: sortStatus }),
              }}
              sortColumns={sortColumns}
              onSortColumnsChange={onSortColumnsChange ? onSortColumnsChange : setSortColumns}
              onCellClick={onCellClick}
              selectedRows={selectedRows}
              onSelectedRowsChange={onSelectedRowsChange}
              rowKeyGetter={rowKeyGetter}
              enableVirtualization={enableVirtualization}
            />
          )}
          {!rowGrouping && (
            <DataGrid
              {...(gridKey && { key: gridKey })}
              className="fill-grid rdg-light w-full"
              style={{ height: rows.length > 0 ? mainHeight : 40 + NO_SCROLL_PAD }}
              columns={renderedColumns}
              rows={sortedRows}
              rowHeight={rowHeight as number | ((row: R) => number)}
              headerRowHeight={DEFAULT_HEADER_ROW_HEIGHT}
              renderers={{
                ...(manualReordering ? { renderRow: dndRowRenderer } : { renderSortStatus: sortStatus }),
              }}
              sortColumns={sortColumns}
              onSortColumnsChange={onSortColumnsChange ? onSortColumnsChange : setSortColumns}
              onCellClick={onCellClick}
              selectedRows={selectedRows}
              onSelectedRowsChange={onSelectedRowsChange}
              rowKeyGetter={rowKeyGetter}
              enableVirtualization={enableVirtualization}
            />
          )}
        </ConditionalDndWrapper>
        {(!sortedRows || sortedRows.length === 0) && (
          <div className="flex w-full h-10 bg-white border-b items-center justify-center  font-medium text-center text-gray-400">
            {emptyRowMessage}
          </div>
        )}
      </div>
      {showDownloadingModal && (
        <Modal isPrimaryActionVisible={false}>
          <div className="flex grow items-center justify-center mt-4">Downloading CSV ...</div>
        </Modal>
      )}
    </>
  );
};

export default Grid;
