import {
  newPreviewRunDoc,
  updateDocWithParticipantAdded,
} from 'shared/lib/runUtil';
import { useEffect, useCallback, useRef, useState, useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useStore } from 'react-redux';
import useRunObserver from './useRunObserver';
import { useDatabaseServices } from '../contexts/DatabaseContext';
import {
  DatabaseServices,
  selectProcedureById,
} from '../contexts/proceduresSlice';
import { useUserInfo } from '../contexts/UserContext';
import { Run, Draft, Release } from 'shared/lib/types/views/procedures';
import { getPendingProcedureIndex } from 'shared/lib/procedureUtil';
import _ from 'lodash';
import { prepareBatchStepsForRun } from '../lib/batchSteps';
import { useSettings } from '../contexts/SettingsContext';
import { useRealtimeContext } from '../contexts/RealtimeContext';
import { Observer } from '../api/realtime';
import apm from '../lib/apm';

const stripRedlineStepsFromDraft = (draft: Draft) => {
  if (draft?.sections?.length > 0) {
    for (
      let sectionIndex = draft.sections.length - 1;
      sectionIndex >= 0;
      sectionIndex--
    ) {
      if (draft.sections[sectionIndex]?.steps?.length > 0) {
        for (
          let stepIndex = draft.sections[sectionIndex].steps.length - 1;
          stepIndex >= 0;
          stepIndex--
        ) {
          if (draft.sections[sectionIndex].steps[stepIndex].redline_id) {
            draft.sections[sectionIndex].steps.splice(stepIndex, 1);
          }
        }
      }
    }
  }
};

const stripRedlineCommentsFromDraft = (draft: Draft) => {
  if (draft.comments) {
    draft.comments = draft.comments.filter(
      (comment) => !('redline_id' in comment)
    );
  }
};

type LocalRunState = {
  id: string;
  run: Run | null;
  runNotFound: boolean;
};

type UseRunPreviewReturns = LocalRunState & {
  updatePreviewRun: (run: Run) => void;
};

const useRunPreview = (isPreviewMode: boolean): UseRunPreviewReturns => {
  const {
    services,
    currentTeamId,
  }: { services: DatabaseServices; currentTeamId: string } =
    useDatabaseServices();
  const { userInfo } = useUserInfo();
  const store = useStore();
  const userId = userInfo.session.user_id;
  const isMounted = useRef(false);
  const [localRun, setLocalRun] = useState<LocalRunState>({
    id: '',
    run: null,
    runNotFound: false,
  });
  const { isPostgresOnlyEnabled } = useSettings();
  const { realtimeService } = useRealtimeContext();
  const { search: queryString } = useLocation();
  const batchSize = useMemo(() => {
    const defaultSize = 1;
    if (isPreviewMode) {
      const queryParams = new URLSearchParams(queryString);
      const batchSizeValue = queryParams.get('batchSize');
      return parseInt(batchSizeValue ?? '') || defaultSize;
    }
    return defaultSize;
  }, [isPreviewMode, queryString]);

  // real runs have an id param for the run id, preview runs do not
  const { id: paramId } = useParams<{ id: string }>();
  const { run: remoteRun, runNotFound: remoteNotFound } = useRunObserver({
    id: paramId,
    isPreviewMode,
  });

  const updatePreviewRun = (run) => {
    setLocalRun({
      id: run._id,
      run: _.cloneDeep(run),
      runNotFound: false,
    });
  };

  const updatePreviewRunReviewComments = useCallback((procedure) => {
    setLocalRun((oldValues) => {
      if (!oldValues.run) {
        return oldValues;
      }

      const previewRun = _.cloneDeep(oldValues.run);
      previewRun.comments = procedure.comments.filter(
        (comment) => comment.type === 'review_comment'
      );

      return {
        ...oldValues,
        run: previewRun,
      };
    });
  }, []);

  const createCleanLocalRun = useCallback(
    (procedure: Draft) => {
      // since we are previewing a pending procedure, there could be pending redline pieces we need to strip
      stripRedlineStepsFromDraft(procedure);
      stripRedlineCommentsFromDraft(procedure);
      if (isPreviewMode) {
        prepareBatchStepsForRun(procedure, batchSize);
      }
      const run: Run = newPreviewRunDoc({
        procedure: procedure as Release,
        userId,
      });
      updateDocWithParticipantAdded(run, userId, new Date().toISOString());
      setLocalRun({
        id: run._id,
        run,
        runNotFound: false,
      });
    },
    [userId, isPreviewMode, batchSize]
  );

  useEffect(() => {
    if (isMounted.current || !isPreviewMode || !realtimeService) {
      return;
    }
    const pendingProcedureId = getPendingProcedureIndex(paramId);

    const fetchProcedure = async () => {
      services.procedures
        .getProcedure(pendingProcedureId)
        .then((procedure) => {
          createCleanLocalRun(procedure as Draft);
        })
        .catch(() => {
          // fallback to local copy of draft
          const procedure = selectProcedureById(
            store.getState(),
            currentTeamId,
            pendingProcedureId
          );
          if (procedure) {
            createCleanLocalRun(procedure as Draft);
          }
        });
    };

    const updateReviewCommentsOnChange = async () => {
      return services.procedures
        .getProcedure(pendingProcedureId)
        .then((procedure) => {
          updatePreviewRunReviewComments(procedure as Draft);
        })
        .catch(() => {
          // No-op
        });
    };

    // Observe the pending procedure for review comment changes.
    let procedureObserver: Observer;
    if (isPostgresOnlyEnabled()) {
      procedureObserver = realtimeService.onProcedureEvent(
        pendingProcedureId,
        updateReviewCommentsOnChange
      );
    } else {
      procedureObserver = services.procedures.onProcedureChanged(
        pendingProcedureId,
        updateReviewCommentsOnChange
      );
    }

    fetchProcedure().catch((err) => apm.captureError(err));

    return () => {
      procedureObserver.cancel();
    };
  }, [
    isPreviewMode,
    paramId,
    services.procedures,
    realtimeService,
    userId,
    currentTeamId,
    store,
    createCleanLocalRun,
    updatePreviewRunReviewComments,
    isPostgresOnlyEnabled,
  ]);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  if (isPreviewMode) {
    return {
      ...localRun,
      updatePreviewRun,
    };
  } else {
    return {
      id: paramId,
      run: remoteRun,
      runNotFound: remoteNotFound,
      updatePreviewRun,
    };
  }
};

export default useRunPreview;
