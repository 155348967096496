import { useCallback, useMemo } from 'react';
import diffUtil from '../lib/diffUtil';
import { newStepRedline, REDLINE_TYPE } from 'shared/lib/redlineUtil';
import {
  Recorded,
  RedlinedStep,
  RunStep,
  RunStepFullRedline,
  RunStepRedline,
  StepBlockDiffElement,
  StepDiffElement,
  TableCellRecorded,
} from 'shared/lib/types/views/procedures';

import sharedDiffUtil, { ARRAY_CHANGE_SYMBOLS } from 'shared/lib/diffUtil';
import { useStore } from 'react-redux';
import { selectRunStep } from '../contexts/runsSlice';
import { useDatabaseServices } from '../contexts/DatabaseContext';
import { useRunContext } from '../contexts/RunContext';
import procedureUtil from '../lib/procedureUtil';

interface UseFullStepRedlineProps {
  currentStep: RunStep;
  redlines: Array<RunStepRedline>;
  sectionId: string;
  stepId?: string;
  formattedStepKey?: string;
}

type UseFullStepRedlineReturn = {
  isCurrentLatest: boolean;
  currentStepCleaned: Partial<RedlinedStep>;
  latestRedline?: RunStepRedline;
  latestStepDiff?: Array<StepDiffElement>;
  reversedRedlines: Array<RunStepRedline>;
  confirmRedlineDataLoss: () => boolean;
};

const useFullStepRedline = ({
  currentStep,
  redlines = [],
  sectionId,
  stepId = currentStep.id,
  formattedStepKey = '--',
}: UseFullStepRedlineProps): UseFullStepRedlineReturn => {
  const store = useStore();
  const { currentTeamId } = useDatabaseServices();
  const { run } = useRunContext();

  const redlinesWithOriginal = useMemo(() => {
    if (redlines[0] && !(redlines[0] as RunStepFullRedline).is_original) {
      const originalPseudoBlueline = newStepRedline({
        step: currentStep,
        userId: 'system',
        pending: false,
        fieldOrBlockMetadata: {},
        isRedline: false,
        createdAt: new Date().toISOString(),
        type: REDLINE_TYPE.FULL_STEP_REDLINE,
      }) as RunStepFullRedline;

      originalPseudoBlueline.is_original = true;
      return [originalPseudoBlueline, ...redlines];
    }
    return redlines;
  }, [currentStep, redlines]);

  const latestRedline = useMemo(() => {
    return redlinesWithOriginal.length > 0
      ? redlinesWithOriginal[redlinesWithOriginal.length - 1]
      : undefined;
  }, [redlinesWithOriginal]);

  const reversedRedlines = useMemo(
    () => [...redlinesWithOriginal].reverse(),
    [redlinesWithOriginal]
  );

  const currentStepCleaned = useMemo(
    () => diffUtil.prepareRedlinedStepForDiff({ step: currentStep }),
    [currentStep]
  );

  const latestStepDiff = useMemo(() => {
    if (!latestRedline) {
      return;
    }

    try {
      return diffUtil.getFullStepRedlineDiff({
        previousStep: currentStepCleaned as RedlinedStep,
        updatedStep: latestRedline.step,
      });
    } catch {
      return [];
    }
  }, [latestRedline, currentStepCleaned]);

  const latestStepDiffIgnoringIds = useMemo(() => {
    if (!latestRedline) {
      return;
    }

    try {
      return diffUtil.getFullStepRedlineDiff({
        previousStep: currentStepCleaned as RedlinedStep,
        updatedStep: latestRedline.step,
        ignoreIds: true,
      });
    } catch {
      return [];
    }
  }, [latestRedline, currentStepCleaned]);

  const isCurrentLatest = useMemo(() => {
    const isLatestFullStepRedlineIncluded =
      latestRedline &&
      (latestRedline as RunStepFullRedline).type ===
        REDLINE_TYPE.FULL_STEP_REDLINE &&
      !latestRedline.pending;

    const areAllOldStyleRedlinesIncluded =
      latestRedline &&
      !('type' in latestRedline) &&
      !redlines.some((redline) => redline.pending);

    return (
      !latestRedline ||
      // For full step redlines, only the latest needs to be included.
      isLatestFullStepRedlineIncluded ||
      // For old-style redlines, all redlines must be included.
      areAllOldStyleRedlinesIncluded ||
      // Consider the step to be latest if no changes in the step (i.e. only a comment was made)
      Boolean(
        latestStepDiffIgnoringIds &&
          latestStepDiffIgnoringIds.length > 0 &&
          (!latestStepDiffIgnoringIds[0].diff_change_state ||
            latestStepDiffIgnoringIds[0].diff_change_state ===
              ARRAY_CHANGE_SYMBOLS.UNCHANGED)
      )
    );
  }, [latestRedline, latestStepDiffIgnoringIds, redlines]);

  const confirmRedlineDataLoss = useCallback(() => {
    if (!latestStepDiff || latestStepDiff.length === 0) {
      return true;
    }

    const state = store.getState();
    const runStep = selectRunStep(
      state as { runs: { [runId: string]: unknown } },
      currentTeamId,
      run._id,
      sectionId,
      stepId
    );

    if (!runStep) {
      return true;
    }
    // If the diff returns a completely split diff (should be rare, if at all) err on the side of caution.
    const dataMayBeLost = latestStepDiff.length > 1;
    if (dataMayBeLost) {
      return window.confirm(
        'Warning: data entered in a changed block may be lost if this change is included in the run. Ok to proceed?'
      );
    }
    const blocksWithDataLoss = (
      latestStepDiff[0].content as Array<StepBlockDiffElement>
    ).filter((block) => {
      if (block.type === 'table_input' && block.row_metadata && block.columns) {
        return false; // Table input data is retained using row/column ids.
      }
      const blockId = sharedDiffUtil.getDiffValue<string>(block, 'id', 'new');
      const runStepBlock = runStep.content.find(
        (runStepBlock) => runStepBlock.id === blockId
      );
      const hasRecordedDataAndChanged =
        runStepBlock &&
        (runStepBlock.type === 'field_input_table'
          ? runStepBlock.fields.some((field) => field.recorded)
          : (runStepBlock as { recorded: Recorded | TableCellRecorded })
              .recorded) &&
        block.diff_change_state &&
        block.diff_change_state !== ARRAY_CHANGE_SYMBOLS.UNCHANGED;

      if (
        hasRecordedDataAndChanged &&
        block.type === 'input' &&
        (block.diff_change_state === ARRAY_CHANGE_SYMBOLS.MODIFIED ||
          block.diff_change_state === ARRAY_CHANGE_SYMBOLS.ADDED) &&
        !sharedDiffUtil.isChanged(block, 'inputType')
      ) {
        return false; // Field input blocks whose inputType does not change will retain data.
      }
      return hasRecordedDataAndChanged;
    });

    if (blocksWithDataLoss.length > 0) {
      const substepKeyMap = procedureUtil.getSubstepKeyMap({
        step: currentStep,
        formattedStepKey,
      });
      const keysWithDataLoss = blocksWithDataLoss.map((block) => {
        const blockId = sharedDiffUtil.getDiffValue<string>(block, 'id', 'new');
        return substepKeyMap[blockId];
      });
      return window.confirm(
        `Warning: data entered in ${keysWithDataLoss.join(
          ', '
        )} will be lost if this change is included in the run. Ok to proceed?`
      );
    }

    return true;
  }, [
    currentStep,
    currentTeamId,
    formattedStepKey,
    latestStepDiff,
    run._id,
    sectionId,
    stepId,
    store,
  ]);

  return {
    isCurrentLatest,
    currentStepCleaned,
    latestRedline,
    latestStepDiff,
    reversedRedlines,
    confirmRedlineDataLoss,
  };
};

export default useFullStepRedline;
