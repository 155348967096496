import { TreeNode } from 'primereact/treenode';
import { useCallback, useEffect, useMemo, useState } from 'react';
import projectUtil, { DEFAULT_PROJECT_NAME } from '../lib/projectUtil';
import { useSettings } from '../contexts/SettingsContext';
import { TreeSelect, TreeSelectEventNodeEvent, TreeSelectExpandedKeysType } from 'primereact/treeselect';
import { faAngleDown, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import Icon from './Icon';

export type EntityWithProject = Pick<CustomizedTreeNode, 'getExpandedIcon' | 'getCollapsedIcon'> & {
  id: string | number;
  projectId?: string;
  children?: Array<object>;
};

export type CustomizedTreeNode = TreeNode & {
  getExpandedIcon?: () => JSX.Element;
  getCollapsedIcon?: () => JSX.Element;
};

interface ExpandCollapseIconProps {
  node: CustomizedTreeNode;
  expanded: boolean;
}

const ExpandCollapseIcon = ({ node, expanded }: ExpandCollapseIconProps) => {
  if (expanded) {
    return node.getExpandedIcon?.() ?? <Icon element={faAngleDown} />;
  }
  return node.getCollapsedIcon?.() ?? <Icon element={faAngleRight} />;
};

interface ProjectTreeSelectorProps<T extends EntityWithProject> {
  entities: Array<T>;
  currentProjectId?: string;
  currentEntityId?: string | number;
  isDisabled?: boolean;
  labelFormatter: (entity: T) => string;
  onSelect: (key?: string | number, data?: T) => void;
  nodeTemplate?: (entity: T) => JSX.Element | string;
  valueTemplate?: (entity: T) => JSX.Element | string;
  placeholder?: string;
  sortChildren?: boolean;
}

const ProjectTreeSelector = <T extends EntityWithProject>({
  entities,
  currentProjectId,
  currentEntityId,
  isDisabled = false,
  labelFormatter,
  onSelect,
  nodeTemplate,
  valueTemplate,
  placeholder = 'Select...',
  sortChildren = true,
}: ProjectTreeSelectorProps<T>) => {
  const { projects } = useSettings();
  const [selectedNodeKey, setSelectedNodeKey] = useState<string>();
  const [expandedKeys, setExpandedKeys] = useState<TreeSelectExpandedKeysType>();

  const entitiesByProject = useMemo(() => {
    // Group by projectId
    const grouped = entities.reduce<Record<string, CustomizedTreeNode[]>>((acc, entity) => {
      const projectId = entity.projectId || DEFAULT_PROJECT_NAME;
      if (!acc[projectId]) {
        acc[projectId] = [];
      }
      acc[projectId].push({
        data: entity,
        key: entity.id,
        id: `${entity.id}`,
        label: labelFormatter(entity),
        ...(entity.children && { children: entity.children }),
        ...(entity.getExpandedIcon && { getExpandedIcon: entity.getExpandedIcon }),
        ...(entity.getCollapsedIcon && { getCollapsedIcon: entity.getCollapsedIcon }),
      });
      return acc;
    }, {});

    // Sort each group by name
    const tree: CustomizedTreeNode[] = Object.entries(grouped).map(([projectId, items]) => ({
      selectable: false,
      key: projectId,
      label: projectUtil.getProjectName(projects, projectId) || DEFAULT_PROJECT_NAME,
      children: sortChildren ? items.sort((a, b) => (a.label ?? '').localeCompare(b.label ?? '')) : items,
    }));

    tree.sort((a, b) => {
      if (a.key === currentProjectId) {
        return -1;
      }
      if (b.key === currentProjectId) {
        return 1;
      }
      return (a.label ?? '').localeCompare(b.label ?? '');
    });
    return tree;
  }, [entities, currentProjectId, labelFormatter, projects, sortChildren]);

  useEffect(() => {
    const initiallyExpanded = currentProjectId ?? DEFAULT_PROJECT_NAME;
    setExpandedKeys({ [initiallyExpanded]: true });
  }, [currentProjectId]);

  useEffect(() => {
    setSelectedNodeKey(`${currentEntityId}` || '');
  }, [currentEntityId]);

  const customNodeTemplate = (node: CustomizedTreeNode) => {
    const nodeStyle = node.key === DEFAULT_PROJECT_NAME ? 'italic' : '';
    return (
      <div data-pc-section="selector-node" className="max-w-96 break-words flex flex-col leading-4">
        {nodeTemplate && node.data ? (
          nodeTemplate(node.data)
        ) : (
          <div className={`flex flex-row ${nodeStyle}`}>{node.label}</div>
        )}
      </div>
    );
  };

  const customValueTemplate = (nodes: CustomizedTreeNode | CustomizedTreeNode[]) => {
    const node = nodes[0];
    return node ? (
      <div className="w-56 flex flex-col">
        {valueTemplate && node.data ? valueTemplate(node.data) : <div className="flex flex-row">{node.label}</div>}
      </div>
    ) : (
      <div className="">{placeholder}</div>
    );
  };

  const togglerTemplate = (node: CustomizedTreeNode, options) => {
    if (node.children?.length) {
      return (
        <div
          role="button"
          aria-expanded={options.expanded}
          data-pc-section="triggericon"
          aria-label={`toggle-${node.label}`}
          className="py-1 px-2 mr-1 hover:bg-gray-200 rounded"
          onClick={(event) => {
            options.onClick(event);
          }}
        >
          <ExpandCollapseIcon node={node} expanded={options.expanded} />
        </div>
      );
    }
    return <div className="w-6 h-8" />;
  };

  const onNodeSelect = (e: TreeSelectEventNodeEvent) => {
    const selectedKey = e.node.key;
    setSelectedNodeKey(selectedKey as string);
    onSelect(selectedKey, e.node.data);
  };

  const onToggle = useCallback((event) => {
    setExpandedKeys(event.value);
  }, []);

  return (
    <TreeSelect
      pt={{ labelContainer: { className: 'w-60 truncate' } }}
      className={`${isDisabled ? 'dropdown-disabled' : ''}`}
      value={selectedNodeKey}
      expandedKeys={expandedKeys}
      options={entitiesByProject}
      onNodeSelect={onNodeSelect}
      onToggle={onToggle}
      togglerTemplate={togglerTemplate}
      aria-label={placeholder}
      placeholder={placeholder}
      nodeTemplate={customNodeTemplate}
      valueTemplate={customValueTemplate}
      disabled={isDisabled}
      filter
      resetFilterOnHide
    />
  );
};

export default ProjectTreeSelector;
