import React, { useMemo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import superlogin from '../api/superlogin';
import { useMixpanel } from '../contexts/MixpanelContext';
import { LocationState } from './types';

import LoginFlowForm from '../elements/loginFlows/LoginFlowForm';

const ForgotPasswordSchema = Yup.object().shape({ email: Yup.string().email().required('Required') });

const ForgotPassword = () => {
  const location = useLocation<LocationState>();
  const [flash, setFlash] = useState((location.state && location.state.flash) || '');
  const [globalErrors, setGlobalErrors] = useState(null);
  const { mixpanel } = useMixpanel();

  const forgotPassword = (email) => {
    setGlobalErrors(null);
    return superlogin
      .forgotPassword(email)
      .then(() => {
        if (mixpanel) {
          mixpanel.track('Forgot Password');
        }
        setFlash('If you provided a valid email address, please check your email for a reset link.');
      })
      .catch((error) => {
        if (error && error.message) {
          setGlobalErrors(error.message);
        } else if (error && error.error) {
          setGlobalErrors(error.error);
        } else {
          setGlobalErrors(error);
        }
      });
  };

  const inputFields = useMemo(() => {
    return [
      {
        key: 'email',
        type: 'email',
        label: 'Email',
      },
    ];
  }, []);

  return (
    <LoginFlowForm
      heading="Forgot password"
      subHeading="Enter the email address associated with your account"
      initialValues={{
        email: '',
      }}
      validationSchema={ForgotPasswordSchema}
      onSubmit={async (values) => forgotPassword(values.email)}
      successMessage={flash}
      errorMessage={globalErrors}
      inputFields={inputFields}
    >
      {(isSubmitting) => (
        <>
          <button className="btn" type="submit" disabled={isSubmitting}>
            Reset
          </button>
          <Link
            to={(location) => ({
              // Preserve search url params when navigating through login flows.
              ...location,
              pathname: '/login',
            })}
            className="text-slate-600 underline text-center text-sm hover:brightness-75"
          >
            Back to login
          </Link>
        </>
      )}
    </LoginFlowForm>
  );
};

export default ForgotPassword;
