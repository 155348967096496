import {
  EntityTagMapping,
  EntityType,
  Tag,
} from 'shared/lib/types/api/settings/tags/models';
import { useDatabaseServices } from '../contexts/DatabaseContext';
import { DatabaseServices } from '../contexts/proceduresSlice';
import { useTags } from './useTags';
import { useCallback, useEffect, useState } from 'react';

type UseEntityTagsProps = {
  entityType: EntityType;
};

const useEntityTags = ({
  entityType,
}: UseEntityTagsProps): {
  getTagsForEntity: (entityId: string) => Tag[];
  getAllUniqueTags: () => Tag[];
} => {
  const { services }: { services: DatabaseServices } = useDatabaseServices();
  const { tags } = useTags({ includeInactive: false });
  const [entityTagMappings, setEntityTagMappings] = useState<EntityTagMapping>(
    {}
  );

  useEffect(() => {
    void services.entityTags
      .getTagMappingsForEntityType(entityType)
      .then(setEntityTagMappings);
  }, [entityType, services.entityTags]);

  const getTagsForEntity = useCallback(
    (entityId: string) => {
      if (!tags || !entityTagMappings) {
        return [];
      }
      const tagIdsForEntity = entityTagMappings[entityId] || [];
      const tagsForEntity = tagIdsForEntity
        .map(({ tagId }) => tags.find((tag) => tag.id === tagId))
        .filter(Boolean) as Tag[];
      return tagsForEntity;
    },
    [entityTagMappings, tags]
  );

  const getAllUniqueTags = useCallback(() => {
    if (!tags || !entityTagMappings) {
      return [];
    }
    const uniqueTagIds = new Set(
      Object.values(entityTagMappings)
        .flat()
        .map((mapping) => mapping.tagId)
    );
    return tags.filter((tag) => uniqueTagIds.has(tag.id));
  }, [entityTagMappings, tags]);

  return {
    getTagsForEntity,
    getAllUniqueTags,
  };
};

export default useEntityTags;
