import React, { useEffect, useMemo, useCallback, useState, useRef, MutableRefObject } from 'react';
import { FormikValues, useFormik } from 'formik';
import { useAuth } from '../../contexts/AuthContext';
import { generateHiddenClassString } from '../../lib/styles';
import { isEmptyValue } from 'shared/lib/text';
import { isInt } from '../../lib/number';
import commandingUtil from '../../lib/commanding';
import { Command } from '../../lib/models/postgres/commanding';
import { useDatabaseServices } from '../../contexts/DatabaseContext';
import { useRunContext } from '../../contexts/RunContext';
import { useSettings, REQUIRE_SEND_COMMAND_CONFIRMATION_KEY } from '../../contexts/SettingsContext';
import DateTimeDisplay from '.././DateTimeDisplay';
import { escapeArgName } from '../FieldSetCommanding';
import runUtil from '../../lib/runUtil';
import {
  CommandingBlock,
  CommandingBlockArguments,
  CommandingBlockRecorded,
  CommandingBlockRecordedResults,
} from 'shared/lib/types/views/procedures';
import ResultsLabel from '../ResultsLabel';
import SubstepNumber from '../SubstepNumber';
import Spacer from '../Spacer';
import { Mutex } from 'async-mutex';
import { useSelector } from 'react-redux';
import { selectOfflineInfo } from '../../app/offline';
import CommandingConfirmationModal from './CommandingConfirmationModal';
import { DatabaseServices } from '../../contexts/proceduresSlice';
import { commandMetadata } from '../../api/commanding';
import { apm } from '@elastic/apm-rum';

type InputChangeEvent = React.ChangeEvent<HTMLSelectElement> | React.FocusEvent<HTMLInputElement, Element>;

interface BlockCommandingProps {
  commanding: CommandingBlock;
  contentIndex: number;
  docState: string;
  isCompleteEnabled: boolean;
  isHidden: boolean;
  isSpacerHidden: boolean;
  stepName: string;
  blockLabel: string;
  onFieldValueChanged?: (id: string, updated: Array<CommandingBlockRecorded>) => void;
  recorded?: Array<CommandingBlockRecorded>;
}

// Component for rendering a commanding rule.
const BlockCommanding = ({
  commanding,
  contentIndex,
  docState,
  isCompleteEnabled,
  isHidden,
  isSpacerHidden,
  stepName,
  blockLabel,
  onFieldValueChanged,
  recorded,
}: BlockCommandingProps) => {
  const { auth } = useAuth();
  const { services }: { services: DatabaseServices } = useDatabaseServices();
  const fileInputRef = useRef<Map<string, MutableRefObject<HTMLInputElement>>>();
  const [fileUploadFailed, setFileUploadFailed] = useState<Map<string, boolean>>(new Map());
  const [errMsg, setErrMsg] = useState('');
  const [isFileUploading, setIsFileUploading] = useState<Map<string, boolean>>(new Map());
  const [uploadFiles, setUploadFiles] = useState<Map<string, File>>(new Map());
  const runContext = useRunContext(); // Will be empty object when not in Run.js.
  const [standardCommand, setStandardCommand] = useState<Command>();
  const online = useSelector((state) => selectOfflineInfo(state).online);
  const sent = useRef(false);
  const [args, setArgs] = useState<FormikValues | null>(null);
  const mutex = useRef(new Mutex());
  const isMounted = useRef(true);
  const [showConfirmationMessage, setShowConfirmationMessage] = useState<boolean>(false);
  const [isConfirmSend, setIsConfirmSend] = useState<boolean>(false); // Keep track of whether user clicks confirm on send command confirmation modal, if setting enabled
  const { getSetting } = useSettings();

  useEffect(
    () => () => {
      isMounted.current = false;
    },
    []
  );

  // Load standard command definition
  useEffect(() => {
    if (!commandingUtil.isStandardCommand(commanding) || !commanding.name) {
      return;
    }
    services.commanding
      .getCommandByName(commanding.name, commanding.dictionary_id)
      .then((command) => isMounted.current && setStandardCommand(command))
      .catch(() => {
        /* no-op */
      });
  }, [commanding, services.commanding]);

  const getArgument = useCallback(
    (argName) => {
      if (!standardCommand || !standardCommand.arguments) {
        return null;
      }
      return standardCommand.arguments.find((arg) => arg.name === argName);
    },
    [standardCommand]
  );

  const getArgumentType = useCallback(
    (argName) => {
      const argument = getArgument(argName);
      return argument && argument.type;
    },
    [getArgument]
  );

  const onFileInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    name: string,
    handler: typeof formik.handleChange
  ) => {
    setFileUploadFailed((values) => values.set(name, false));
    if (!services.attachments) {
      return Promise.reject('Services unavailable');
    }
    if (!e.currentTarget?.files) {
      return Promise.resolve('No file found');
    }
    const file = e.currentTarget.files[0];
    // setUploadFiles seems to be async in Safari, this workaround allows the handler to see the updated values
    setUploadFiles((values) => {
      values.set(name, file);
      handler(e);
      return values;
    });
  };

  // Needed for substep syncing
  const onInputChange = (
    name: string,
    event: InputChangeEvent,
    handler: (event: InputChangeEvent) => void,
    values: object
  ) => {
    const updated = { pendingArguments: { ...values, [name]: event.target.value } };
    onFieldValueChanged?.(commanding?.id, [updated]); // recorded value is an array for legacy reasons
    handler(event);
  };

  const uploadAttachments = useCallback(
    /**
     * @returns true if uploading succeeded, false otherwise
     */
    async (args: FormikValues): Promise<boolean> => {
      for (const [name, file] of uploadFiles) {
        try {
          setIsFileUploading((values) => values.set(name, true));
          if (file.size > 2 * 1024 * 1024) {
            window.alert('Max file size is 2MB');
            return false;
          }
          const attachment = await services.attachments.uploadFile(file, 'procedures:commanding', {
            remote: true,
          });
          args[name] = attachment;
        } catch (err) {
          setFileUploadFailed((values) => values.set(name, true));
          return false;
        } finally {
          setIsFileUploading((values) => values.set(name, false));
        }
      }
      return true;
    },
    [services.attachments, uploadFiles]
  );

  // Retrieve send command confirmation setting set by user
  const isCommandConfirmationEnabled = useMemo(() => {
    return getSetting(REQUIRE_SEND_COMMAND_CONFIRMATION_KEY, false);
  }, [getSetting]);

  useEffect(() => {
    (async () => {
      // runExclusive mutex + sent guard against double submission of the command
      await mutex.current.runExclusive(async () => {
        if (sent.current || args === null) {
          return;
        }

        try {
          // Send command command confirmation setting disabled or user confirms send confirmation modal with setting enabled
          if (!isCommandConfirmationEnabled || isConfirmSend) {
            if (!(await uploadAttachments(args))) {
              throw new Error('attachment');
            }

            if (commandingUtil.isStandardCommand(commanding)) {
              const name = commandingUtil.getCommandName(commanding);
              const operation = runContext.run && runContext.run.operation;
              const variables = runContext.run && runContext.run.variables;
              const runId = runContext.run && runContext.run._id;

              const metadata: commandMetadata = {
                operation,
                variables,
                runId,
                stepName,
              };
              const results = await services.commanding.sendCommand(
                name as string,
                commanding.dictionary_id as number,
                args,
                metadata
              );
              if (results.message === 'Network error') {
                throw new Error('network');
              }
              const updated: CommandingBlockRecorded = {
                results: {
                  ...(results as CommandingBlockRecordedResults),
                  arguments: args,
                },
                timestamp: new Date().toISOString(),
              };
              onFieldValueChanged?.(commanding?.id, [updated]); // recorded value is an array for legacy reasons
            }

            sent.current = true;
            if (isCommandConfirmationEnabled) {
              setIsConfirmSend(false);
            }
          }
        } catch (err) {
          // allow retry on network or attachment upload errors
          setArgs(null);
          setIsConfirmSend(false);
          if (err.message === 'network') {
            setErrMsg('Network error. Please try again.');
          }
        }
      });
    })().catch((err) => apm.captureError(err));
  }, [
    args,
    auth.teamId,
    commanding,
    contentIndex,
    sent,
    runContext.run,
    services.commanding,
    stepName,
    uploadAttachments,
    isConfirmSend,
    isCommandConfirmationEnabled,
    onFieldValueChanged,
  ]);

  const sendCommandHandler = useCallback(
    async (args: FormikValues) => {
      if (!commanding || !commanding.key) {
        return;
      }
      if (!services.commanding) {
        return;
      }

      setErrMsg('');
      setArgs(args);
      if (isCommandConfirmationEnabled) {
        setShowConfirmationMessage(true);
      }
    },
    [commanding, services.commanding, isCommandConfirmationEnabled]
  );

  const submitCommand = useCallback(
    (values: FormikValues) => {
      sendCommandHandler(values).catch((err) => apm.captureError(err));
    },
    [sendCommandHandler]
  );

  const commandName = useMemo(() => commandingUtil.getCommandName(commanding), [commanding]);

  /**
   * Computes a timestamp suitable for rendering as "Received: {timestamp}".
   *
   * Prefers the explicit `received_at` timestamp from the command results, and
   * falls back to the `timestamp` field of when results were recorded (legacy).
   *
   * recorded: A results object from sending a command.
   * returns: String, an ISO8601 timestamp with milliseconds, or null if no
   *          timestamp is available.
   */
  const receivedAtTimestamp = (recorded) => {
    if (!recorded) {
      return null;
    }
    if (recorded.results && recorded.results.received_at) {
      return recorded.results.received_at;
    }
    if (recorded.timestamp) {
      return recorded.timestamp;
    }
    return null;
  };

  const initialValues = useMemo(() => {
    if (recorded?.[0]) {
      return recorded[0].results?.arguments || recorded[0].pendingArguments || {};
    }
    return commanding.arguments || {};
  }, [commanding, recorded]);

  // Returns true if the given argument name has a predefined value.
  const hasPredefinedValue = useCallback(
    (name) => {
      if (!commanding.arguments || !(name in commanding.arguments)) {
        return false;
      }
      /*
       * Argument is represented in the arguments list. If null or an empty string,
       * allow user to specify a real value. Note that '0' or other falsey values
       * can still be valid argument values.
       */
      const value = commanding.arguments?.[name];
      return !(value === null || value === '');
    },
    [commanding]
  );

  // Returns true if any command arguments need to be specified at runtime.
  const hasRuntimeArgs = useMemo(() => {
    if (!commanding.arguments) {
      return false;
    }
    return Object.keys(commanding.arguments).some((name) => !hasPredefinedValue(name));
  }, [commanding, hasPredefinedValue]);

  const validateArguments = useCallback(
    (values) => {
      const errors = {};
      for (const [name, value] of Object.entries(values)) {
        const type = getArgumentType(name);
        if (!type) {
          continue;
        }
        const argument = getArgument(name);
        if (isEmptyValue(value) && !argument?.optional) {
          errors[name] = 'Required';
        }
        switch (type) {
          case 'int':
            if (!isInt(value) && !isEmptyValue(value)) {
              errors[name] = 'Please enter an integer.';
            }
            break;
          case 'float':
            if (isNaN(Number(value))) {
              errors[name] = 'Please enter a number.';
            }
            break;
          case 'file':
            if (!uploadFiles.get(name) && !argument?.optional) {
              errors[name] = `File ${name} required`;
            }
            break;
          default:
            break;
        }
      }
      return errors;
    },
    [getArgument, getArgumentType, uploadFiles]
  );

  const onCancelConfirmationModal = () => {
    setShowConfirmationMessage(false);
    setArgs(null);
  };

  const onConfirmConfirmationModal = () => {
    setShowConfirmationMessage(false);
    setIsConfirmSend(true);
  };

  const isCommandEnabled = useMemo(() => {
    return !recorded?.[0]?.results && isCompleteEnabled && args === null && runUtil.isRunStateActive(docState);
  }, [recorded, docState, args, isCompleteEnabled]);

  const getFileName = useCallback((path) => {
    // Isolate file name from path
    return path.replace(/^.*\\/, '');
  }, []);

  // Format the command arguments for confirmation modal
  const displayArgs = useCallback(
    (commanding, args) => {
      const displayArgs: CommandingBlockArguments = {};
      if (!commanding.arguments || !args) {
        return displayArgs;
      }

      Object.keys(commanding.arguments).forEach((name) => {
        if (getArgumentType(name) === 'file') {
          displayArgs[name] = getFileName(args[name]);
        } else if (getArgumentType(name) === 'enum') {
          getArgument(name)?.values?.forEach((enumValue) => {
            if (enumValue.value === parseInt(args[name])) {
              displayArgs[name] = `${args[name]} (${enumValue.string})`;
            }
          });
        } else if (getArgumentType(name)) {
          displayArgs[name] = args[name];
        }
      });

      return displayArgs;
    },
    [getArgument, getArgumentType, getFileName]
  );

  // Requires `validateOnMount` to handle partially predefined command args.
  const formik = useFormik({
    initialValues,
    onSubmit: submitCommand,
    validate: validateArguments,
    validateOnMount: true,
    initialErrors: validateArguments(initialValues),
    enableReinitialize: true,
  });

  if (!commanding) {
    return null;
  }

  return (
    <>
      <div className={generateHiddenClassString('', isHidden)}></div>
      <div className={generateHiddenClassString('flex flex-row mt-2 page-break', isHidden)}>
        <Spacer isHidden={isSpacerHidden} />
        <SubstepNumber blockLabel={blockLabel} hasExtraVerticalSpacing={true} />
        {/* Command definition */}
        <div>
          <div className="flex flex-row flex-wrap py-0 mt-4 bg-yellow-100d">
            {commandName && <div className="flex mr-2">{commandName}</div>}
            <div>
              {showConfirmationMessage && (
                <CommandingConfirmationModal
                  commandName={commandName}
                  displayArgs={displayArgs(commanding, args)}
                  onConfirm={() => onConfirmConfirmationModal()}
                  onCancel={() => onCancelConfirmationModal()}
                  isSubmitEnabled={!recorded?.[0]?.results}
                ></CommandingConfirmationModal>
              )}
              <button
                className="btn-small mr-2"
                disabled={!isCommandEnabled || (hasRuntimeArgs && !formik.isValid) || !online}
                onClick={formik.submitForm}
                type="button"
              >
                Send
              </button>
            </div>
          </div>
          <form onSubmit={formik.handleSubmit} className="mt-2">
            {commanding.arguments &&
              Object.keys(commanding.arguments).map((name) => (
                <div key={name} className="flex space-x-2 items-start ">
                  <div className="self-center mb-0.9">
                    <label htmlFor={`${commanding.id}-${name}`}>{name}</label>
                  </div>

                  <div className="mt-0.5 mb-1">
                    {getArgumentType(name) && getArgumentType(name) !== 'enum' && getArgumentType(name) !== 'file' && (
                      <input
                        id={`${commanding.id}-${name}`}
                        name={`[${escapeArgName(name)}]`}
                        disabled={!isCommandEnabled || hasPredefinedValue(name)}
                        type="text"
                        accept="*" // for Safari, otherwise onChange handler is not triggered
                        placeholder={name}
                        className=" border-1 border-gray-400 rounded disabled:bg-gray-300 disabled:bg-opacity-50"
                        onChange={formik.handleChange}
                        onBlur={(e) => onInputChange(name, e, formik.handleBlur, formik.values)}
                        value={{ name: formik.values[name] }.name}
                      />
                    )}
                    {getArgumentType(name) === 'file' && (
                      <>
                        <div>
                          <input
                            id={`${commanding.id}-${name}`}
                            name={`[${escapeArgName(name)}]`}
                            ref={fileInputRef.current?.get(name)}
                            type="file"
                            accept="*" // for Safari, otherwise onChange handler is not triggered
                            disabled={!isCommandEnabled}
                            onChange={(e) => onFileInputChange(e, name, formik.handleChange)}
                            onBlur={formik.handleBlur}
                          />
                        </div>
                        {fileUploadFailed.get(name) && (
                          <div className="text-red-700">File upload failed. Please try again.</div>
                        )}
                        {isFileUploading.get(name) && <div>Uploading...</div>}
                      </>
                    )}
                    {getArgumentType(name) === 'enum' && (
                      <select
                        name={`[${escapeArgName(name)}]`}
                        disabled={!isCommandEnabled || hasPredefinedValue(name)}
                        className=" border border-gray-400 rounded disabled:bg-gray-200"
                        onChange={(e) => onInputChange(name, e, formik.handleChange, formik.values)}
                        value={formik.values[name]}
                      >
                        <option value="">Select</option>
                        {getArgument(name)?.values?.map((enumValue) => (
                          <option key={enumValue.value} value={enumValue.value}>
                            {enumValue.value} ({enumValue.string})
                          </option>
                        ))}
                      </select>
                    )}
                    {/* For file upload, no need to show error message since most browsers show "no file chosen" */}
                    {getArgumentType(name) !== 'file' && formik.errors?.[name] && formik.touched?.[name] && (
                      <div className="text-red-700">{formik.errors?.[name]}</div>
                    )}
                    {errMsg && <div className="text-red-700">{errMsg}</div>}
                  </div>
                </div>
              ))}
          </form>
          <div className="mb-2">
            {/* Recorded command results rows */}
            {recorded?.map((recorded) => (
              <div key={recorded.timestamp}>
                {/* Legacy COSMOS command results */}
                {Array.isArray(recorded.results) && recorded.results.map((item) => JSON.stringify(item)).join(', ')}
                {/* Unrecognized command results - TODO deprecate/remove this code path */}
                {!Array.isArray(recorded.results) && typeof recorded.results !== 'object' && recorded.results}
                {/* Standard command results */}
                {!Array.isArray(recorded.results) && typeof recorded.results === 'object' && (
                  <>
                    <div className="flex flex-row gap-x-2 items-center">
                      <ResultsLabel success={recorded.results.success} successText="Success" failText="Error" />
                      {recorded.results.message && <span className="text-sm">{`${recorded.results.message}`}</span>}
                      {receivedAtTimestamp(recorded) && (
                        <span className="ml-2 text-sm text-gray-500">
                          Received <DateTimeDisplay timestamp={receivedAtTimestamp(recorded)} />
                        </span>
                      )}
                    </div>
                    {recorded.results.results && (
                      <div className="ml-2">
                        <pre>results: {JSON.stringify(recorded.results.results, null, 4)}</pre>
                      </div>
                    )}
                  </>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default BlockCommanding;
