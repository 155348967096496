import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Redirect, Link, useHistory, useLocation, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { loginWithSSO, agreeToDisclaimer, SamlIdPChoice, getLastSSOProvider, getSSOProviders } from '../api/auth';
import { API_URL } from '../config';
import useAuth from '../hooks/useAuth';
import useLocationParams from '../hooks/useLocationParams';
import { LocationState } from './types';
import DisclaimerModal from '../components/DisclaimerModal';
import apm from '../lib/apm';
import LoginFlowForm from '../elements/loginFlows/LoginFlowForm';
import { SamlIdpChoiceButton } from '../components/SamlIdpChoiceButton';

const EmailSchema = Yup.object().shape({ email: Yup.string().email().required('Required') });
const DEFAULT_LOGIN_ERROR_STR = 'There was an error signing in. Please try again.';

const LoginSSO = () => {
  const location = useLocation<LocationState>();
  const { searchParams } = useLocationParams(location);
  const { action } = useParams<{ action: string }>();
  const history = useHistory();
  const { flash } = location.state || { flash: '' };
  const [redirectTrigger, setRedirectTrigger] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [login, setLogin] = useState({
    // Use error passed in through routing state, if present.
    error: location.state && location.state.error,
    isAuthenticating: false,
  });
  const { onAuthenticationSuccess, logout } = useAuth();
  const isMounted = useRef(true);
  const [showDisclaimerModal, setShowDisclaimerModal] = useState(false);
  const [disclaimerText, setDisclamerText] = useState('');
  const [lastUsedIDP, setLastUsedIDP] = useState<SamlIdPChoice | null>(null);
  const [matchingIdpChoices, setMatchingIdpChoices] = useState<SamlIdPChoice[] | null>(null);

  useEffect(
    () => () => {
      isMounted.current = false;
    },
    []
  );
  useEffect(() => {
    getLastSSOProvider()
      .then((lastUsedIDFromServer) => {
        if (lastUsedIDFromServer) {
          setLastUsedIDP(lastUsedIDFromServer);
        }
      })
      .catch((error) => {
        apm.captureError(error);
      });
  }, [setLastUsedIDP]);

  const token = useMemo(() => searchParams.get('token'), [searchParams]);
  const redirect = useMemo(() => searchParams.get('redirect') || '', [searchParams]);

  // On error, send to the beginning of SSO flow with error message.
  const onAuthenticationFailure = useCallback(
    (error) => {
      history.replace({ pathname: '/sso/provider' });
      setLogin({
        error: error.message,
        isAuthenticating: false,
      });
    },
    [history]
  );

  const onSSOLogin = useCallback(
    async (token) => {
      // Protect from calling multiple times.
      if (login.isAuthenticating || login.error) {
        return;
      }
      setLogin({
        error: undefined,
        isAuthenticating: true,
      });
      try {
        const session = await loginWithSSO(token);
        if (session.disclaimer_text) {
          setDisclamerText(session.disclaimer_text);
          setShowDisclaimerModal(true);
        } else {
          await onAuthenticationSuccess(session);
          setRedirectTrigger(true);
        }
      } catch {
        onAuthenticationFailure(new Error(DEFAULT_LOGIN_ERROR_STR));
      }
    },
    [login, onAuthenticationSuccess, onAuthenticationFailure]
  );

  const onAgree = useCallback(async () => {
    try {
      setShowDisclaimerModal(false);
      const session = await agreeToDisclaimer();
      await onAuthenticationSuccess(session);
      setRedirectTrigger(true);
    } catch (error) {
      onAuthenticationFailure(error);
    }
  }, [onAuthenticationSuccess, onAuthenticationFailure]);

  const onDecline = useCallback(() => {
    setLogin({
      error: undefined,
      isAuthenticating: false,
    });
    setShowDisclaimerModal(false);
    logout().catch((err) => apm.captureError(err));
  }, [logout]);

  // Query for SSO identity provider and begin authentication flow.
  const onSubmitEmail = useCallback(
    async (values, { setSubmitting }) => {
      setLogin(() => ({
        error: undefined,
        isAuthenticating: false,
      }));

      return getSSOProviders(values.email)
        .then((results) => {
          if (!isMounted.current) {
            return;
          }
          if (results.providers.length === 0) {
            onAuthenticationFailure(new Error('No identity provider found.'));
            setSubmitting(false);
            return;
          }
          if (results.providers.length === 1) {
            const idpChoice = results.providers[0];
            const url = `${API_URL}/sso/initiate-idp-login-uid?uid=${idpChoice.uid}&redirect=${redirect}`;
            window.location.assign(url);
            return;
          }
          setMatchingIdpChoices(results.providers);
        })
        .catch((error) => {
          if (!isMounted.current) {
            return;
          }
          onAuthenticationFailure(error);
          setSubmitting(false);
        });
    },
    [onAuthenticationFailure, setMatchingIdpChoices, redirect]
  );

  // Finish authentication flow by requesting a superlogin session.
  useEffect(() => {
    if (action === 'login') {
      onSSOLogin(token).catch((err) => apm.captureError(err));
    }
    setIsLoading(false);
  }, [action, login, onSSOLogin, token]);

  const inputFields = useMemo(() => {
    return [
      {
        key: 'email',
        type: 'email',
        label: 'Email',
      },
    ];
  }, []);

  // Redirect to home page or original app path.
  if (redirectTrigger === true) {
    return <Redirect to={redirect || '/'} />;
  }

  if (isLoading) {
    return null;
  }

  return (
    <>
      {showDisclaimerModal && <DisclaimerModal disclaimer={disclaimerText} onAgree={onAgree} onDecline={onDecline} />}
      <LoginFlowForm
        heading="Login via Single Sign On (SSO)"
        initialValues={{
          email: '',
        }}
        validationSchema={EmailSchema}
        onSubmit={onSubmitEmail}
        successMessage={login.isAuthenticating ? 'Authenticating...' : flash}
        errorMessage={login.error}
        inputFields={inputFields}
      >
        {(isSubmitting) => (
          <>
            {(!matchingIdpChoices || matchingIdpChoices.length === 0) && (
              <>
                {lastUsedIDP && (
                  <div className="flex flex-col mt-2">
                    <SamlIdpChoiceButton idpChoice={lastUsedIDP} />
                  </div>
                )}

                <button
                  className="btn self-center mt-2 disabled:bg-opacity-80"
                  type="submit"
                  disabled={isSubmitting ? true : undefined}
                >
                  Continue
                </button>
              </>
            )}
            {matchingIdpChoices &&
              matchingIdpChoices.length > 0 &&
              matchingIdpChoices?.map((choice) => (
                <div className="flex flex-col mt-2">
                  <SamlIdpChoiceButton idpChoice={choice} />
                </div>
              ))}
            <div className="flex flex-col">
              <Link
                to={(location) => ({
                  // Preserve search url params when navigating through login flows.
                  ...location,
                  pathname: '/login',
                })}
                className="text-slate-600 underline text-center text-sm hover:brightness-75"
              >
                Back to Login
              </Link>
            </div>
          </>
        )}
      </LoginFlowForm>
    </>
  );
};

export default LoginSSO;
