import React from 'react';
import ReferenceToken from './ReferenceToken';
import { ExpressionToken } from 'shared/lib/types/views/procedures';
import { isNumber } from 'lodash';

interface ReferenceTokenTooltipProps {
  referenceId: string;
  referenceFieldIndex?: number;
}
const ReferenceTokenTooltip = ({ referenceId, referenceFieldIndex }: ReferenceTokenTooltipProps) => {
  const token: ExpressionToken = {
    value: referenceId,
    type: 'reference',
    field_index: referenceFieldIndex,
  };

  return (
    <div className="hidden absolute shadow-lg -mt-4 ml-2 rounded-xl group-hover:block bg-white bg-opacity-50 z-[500]">
      <ReferenceToken
        originalReferencedContentId={referenceId}
        originalToken={isNumber(referenceFieldIndex) ? token : undefined}
      />
    </div>
  );
};

export default React.memo(ReferenceTokenTooltip);
