import { useCallback, useState } from 'react';
import procedureUtil from '../lib/procedureUtil';
import { Mention } from 'shared/lib/types/postgres/util';
import { CommentInterface } from 'shared/lib/comment';
import { AttachmentValue } from 'shared/lib/types/views/procedures';

interface UseCommentHandlerProps {
  onAddComment?: (
    comment: string,
    mentions: Array<Mention>,
    attachments: Array<AttachmentValue> | undefined
  ) => Promise<void>;
  onEditComment?: (
    comment: CommentInterface,
    commentId: string
  ) => Promise<void>;
  autoFocus?: boolean;
  includeAttachments?: boolean;
}

interface EditCommentContext {
  id: string | null;
  text: string;
  mentions: Array<Mention>;
  attachments?: Array<AttachmentValue>;
}

interface UseCommentHandlerReturn {
  isSubmittingComment: boolean;
  hasCommentError: boolean;
  editCommentContext: EditCommentContext;
  autoFocus: boolean;
  submitComment: (
    values: CommentInterface,
    helpers: { resetForm: () => void }
  ) => Promise<void>;
  submitCommentWithAttachments: (values: CommentInterface) => Promise<void>;
  handleEditClick: (comment) => void;
  handleCancel: () => void;
}

const getEmptyCommentState = (includeAttachments): EditCommentContext => {
  const _state = {
    id: null,
    text: '',
    mentions: [],
    attachments: [],
  } as EditCommentContext;

  if (!includeAttachments) {
    delete _state.attachments;
  }

  return _state;
};

const useCommentHandler = ({
  onAddComment,
  onEditComment,
  autoFocus = false,
  includeAttachments = false,
}: UseCommentHandlerProps): UseCommentHandlerReturn => {
  const [isSubmittingComment, setIsSubmittingComment] = useState(false);
  const [hasCommentError, setHasCommentError] = useState(false);
  const [editCommentContext, setEditCommentContext] =
    useState<EditCommentContext>(getEmptyCommentState(includeAttachments));

  const submitComment = useCallback(
    async (values: CommentInterface, { resetForm }) => {
      setIsSubmittingComment(true);
      const trimmed = procedureUtil.trimLeadingAndTrailing(values.comment);
      const mentions = procedureUtil.getValidMentions(values.mentions, trimmed);
      resetForm();
      setHasCommentError(false);
      try {
        if (editCommentContext.id && onEditComment) {
          values.comment = trimmed;
          values.mentions = mentions;
          values.updated_at = new Date().toISOString();
          await onEditComment(values, editCommentContext.id);
          setEditCommentContext({ id: null, text: '', mentions: [] });
        } else if (onAddComment) {
          await onAddComment(trimmed, mentions, undefined);
        }
      } catch (error) {
        setHasCommentError(true);
      }
      setIsSubmittingComment(false);
    },
    [editCommentContext, onAddComment, onEditComment]
  );

  const submitCommentWithAttachments = useCallback(
    async (values: CommentInterface) => {
      setIsSubmittingComment(true);
      setHasCommentError(false);

      const _comment = { ...values };

      try {
        if (editCommentContext.id && onEditComment) {
          _comment.updated_at = new Date().toISOString();
          await onEditComment(_comment, editCommentContext.id);
          setEditCommentContext(getEmptyCommentState(includeAttachments));
        } else if (onAddComment) {
          await onAddComment(
            _comment.comment,
            _comment.mentions,
            _comment.attachments
          );
        }
      } catch (error) {
        setHasCommentError(true);
      }

      setIsSubmittingComment(false);
    },
    [editCommentContext, includeAttachments, onEditComment, onAddComment]
  );

  const handleEditClick = useCallback((comment) => {
    setEditCommentContext({
      id: comment.id ?? '',
      text: comment.comment ?? comment.text ?? '',
      mentions: comment.mentions || [],
      attachments: comment.attachments || [],
    });
  }, []);

  const handleCancel = useCallback(() => {
    setEditCommentContext({ id: null, text: '', mentions: [] });
  }, []);

  return {
    isSubmittingComment,
    hasCommentError,
    editCommentContext,
    autoFocus,
    submitComment,
    submitCommentWithAttachments,
    handleEditClick,
    handleCancel,
  };
};

export default useCommentHandler;
