import React, { useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { capitalize } from 'lodash';

const TAILWIND_COLOR_ROWS = [
  ['red', 'orange', 'yellow', 'lime', 'green'],
  ['teal', 'sky', 'blue', 'cyan', 'indigo'],
  ['purple', 'fuchsia', 'pink', 'stone', 'slate'],
] as const;

const SHADE = 200;

const ColorPicker = () => {
  const { watch, setValue } = useFormContext();
  const selectedColor = watch('color');
  const isOpen = watch('showColorPicker');
  const buttonRef = useRef<HTMLButtonElement>(null);

  const [position, setPosition] = useState({
    top: 0,
    left: 0,
  });

  useEffect(() => {
    if (isOpen && buttonRef.current) {
      const rect = buttonRef.current.getBoundingClientRect();
      setPosition({
        top: rect.bottom + window.scrollY + 5, // 5px offset to show below button
        left: rect.left + window.scrollX,
      });
    }
  }, [isOpen]);

  return (
    <div className="flex flex-col items-center gap-1">
      <label htmlFor="color">Color</label>
      <div className="relative">
        <button
          ref={buttonRef}
          type="button"
          className={`w-8 h-8 rounded-full border ${selectedColor} hover:ring-2 hover:ring-offset-1`}
          onClick={() => setValue('showColorPicker', !isOpen)}
        />
        {isOpen && (
          <div
            className="fixed z-50 p-3 bg-white border rounded shadow-lg"
            style={{ top: `${position.top}px`, left: `${position.left}px` }}
          >
            <div className="flex flex-col gap-2">
              {TAILWIND_COLOR_ROWS.map((row, rowIndex) => (
                <div key={rowIndex} className="flex gap-2">
                  {row.map((color) => (
                    <button
                      key={`${color}-${SHADE}`}
                      type="button"
                      className={`w-5 h-5 rounded-full bg-${color}-${SHADE} hover:ring-2 hover:ring-offset-1 
                      border border-gray-300 shadow-sm 
                      ${
                        selectedColor === `bg-${color}-${SHADE}`
                          ? 'ring-2 ring-offset-1 ring-blue-400'
                          : 'ring-gray-200'
                      }`}
                      onClick={() => {
                        setValue('color', `bg-${color}-${SHADE}`);
                        setValue('showColorPicker', false);
                      }}
                      title={capitalize(color)}
                    />
                  ))}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ColorPicker;
