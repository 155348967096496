export const exportToCsv = (gridEl: HTMLDivElement, fileName: string): void => {
  const { head, body, foot } = getGridContent(gridEl);
  const content = [...head, ...body, ...foot]
    .map((cells) => cells.map(serialiseCellValue).join(','))
    .join('\n');

  downloadFile(
    fileName,
    new Blob([content], { type: 'text/csv;charset=utf-8;' })
  );
};

const getGridContent = (
  gridEl: HTMLDivElement
): {
  head: string[][];
  body: string[][];
  foot: string[][];
} => {
  const getRows = (selector: string) => {
    return Array.from(gridEl.querySelectorAll<HTMLDivElement>(selector)).map(
      (gridRow) => {
        return Array.from(
          gridRow.querySelectorAll<HTMLDivElement>('.rdg-cell')
        ).map((gridCell) => gridCell.innerText);
      }
    );
  };

  return {
    head: getRows('.rdg-header-row'),
    body: getRows('.rdg-row:not(.rdg-summary-row)'),
    foot: getRows('.rdg-summary-row'),
  };
};

const serialiseCellValue = (value: unknown) => {
  if (typeof value === 'string') {
    const valueWithoutNewLines = value.replace(/\n/g, ' ');
    const formattedValue = valueWithoutNewLines.replace(/"/g, '""');

    return valueWithoutNewLines.includes(',')
      ? `"${formattedValue}"`
      : formattedValue;
  }
  return value;
};

const downloadFile = (fileName: string, data: Blob) => {
  const downloadLink = document.createElement('a');
  downloadLink.download = fileName;
  const url = URL.createObjectURL(data);
  downloadLink.href = url;
  downloadLink.click();
  URL.revokeObjectURL(url);
};
