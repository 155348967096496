import { Field } from 'formik';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import { Procedure, Section } from 'shared/lib/types/views/procedures';
import { useDatabaseServices } from '../contexts/DatabaseContext';
import { useProcedureContext } from '../contexts/ProcedureContext';
import { useSettings } from '../contexts/SettingsContext';
import { selectProceduresLoading, selectProceduresNoDraftsForReleased } from '../contexts/proceduresSlice';
import ProjectTreeSelector, { EntityWithProject } from '../elements/ProjectTreeSelector';
import { StringSelectOption } from '../lib/formik';
import { reactSelectStyles } from '../lib/styles';
import { TypedFieldSetProps } from './Blocks/BlockTypes';
import { isDraft, stripProcedureId, upgradeProcedureMetadata } from 'shared/lib/procedureUtil';
import procedureUtil from '../lib/procedureUtil';
import { ProcedureMetadata } from 'shared/lib/types/couch/procedures';
import { useQuery } from '@tanstack/react-query';

export const CONTENT_TYPE_PROCEDURE_LINK = 'procedure_link';
type ProcedureEntity = ProcedureMetadata & EntityWithProject;
interface FieldSetProcedureLinkProps extends TypedFieldSetProps<'procedure_link'> {
  contentIndex: number;
  excludedProcedureIds?: Array<string>;
  showLabels?: boolean;
  isDisabled?: boolean;
  isClearable?: boolean;
  filterUnreleased?: boolean;
}

const FieldSetProcedureLink = ({
  content,
  contentIndex,
  contentErrors,
  path,
  setFieldValue,
  excludedProcedureIds = [],
  showLabels = true,
  isDisabled = false,
  isClearable = false,
  filterUnreleased = false,
}: FieldSetProcedureLinkProps) => {
  const fieldName = `${path ? path : 'content'}[${contentIndex}]`;
  const { getSetting } = useSettings();
  const { currentTeamId, services } = useDatabaseServices();
  const { procedure: sourceProcedure, stepOptions } = useProcedureContext();
  const isProceduresLoading = useSelector((state) => selectProceduresLoading(state, currentTeamId));
  const procedureMetadata = useSelector((state) => selectProceduresNoDraftsForReleased(state, currentTeamId));

  const procedures = useMemo(() => {
    return Object.values(procedureMetadata)
      .filter((proc) => (!filterUnreleased || proc.state === 'released') && !proc.archived)
      .map((procedure) => upgradeProcedureMetadata(procedure));
  }, [filterUnreleased, procedureMetadata]);

  const selectedProcedureId = useMemo(() => {
    if (!content.procedure) {
      return;
    }
    if (procedureMetadata[content.procedure]) {
      return content.procedure;
    }
    const releasedId = stripProcedureId(content.procedure);
    if (procedureMetadata[releasedId]) {
      setFieldValue && setFieldValue(fieldName, { ...content, procedure: releasedId });
      return releasedId;
    }
  }, [content, fieldName, procedureMetadata, setFieldValue]);

  const getProcedure = async (procedureId) => {
    const procedure: Procedure = await services.procedures.getProcedure(procedureId);
    return procedure;
  };

  const { data: procedure, isLoading } = useQuery({
    queryKey: ['procedures', selectedProcedureId],
    queryFn: () => getProcedure(selectedProcedureId),
  });

  const sections = useMemo(() => {
    return procedure && !isLoading && procedure?.sections;
  }, [isLoading, procedure]);

  const onChangeSection = (option: StringSelectOption) => {
    setFieldValue && setFieldValue(`${fieldName}.section`, option.value);
  };

  const filteredProcedures: Array<ProcedureMetadata> = useMemo(() => {
    if (!procedures) {
      return [];
    }
    return procedures.filter((procedure) => {
      // Exclude procedures whose _id is in excludedProcedureIds
      if (excludedProcedureIds.includes(procedure._id)) {
        return false;
      }

      // If filterProceduresByProject and procedure has a project, only include procedures with matching project_id
      if (stepOptions?.filterProceduresByProject && sourceProcedure && sourceProcedure.project_id) {
        return procedure.project_id === sourceProcedure.project_id;
      }
      return true;
    });
  }, [procedures, stepOptions?.filterProceduresByProject, excludedProcedureIds, sourceProcedure]);

  const entities = useMemo<Array<ProcedureEntity>>(() => {
    return filteredProcedures.map((procedure) => ({
      ...procedure,
      projectId: procedure.project_id,
      id: procedure._id,
    }));
  }, [filteredProcedures]);

  const linkedSectionOptions: Array<StringSelectOption> = useMemo(() => {
    const options = [
      {
        value: '',
        label: 'All Sections',
      },
    ];

    if (!sections) {
      return options;
    }

    sections.forEach((section: Section, index: number) => {
      options.push({
        value: section.id,
        label: `Section ${procedureUtil.displaySectionKey(index, getSetting('display_sections_as', 'letters'))}: ${
          section.name
        }`,
      });
    });
    return options;
  }, [sections, getSetting]);

  const linkedSectionSelected: StringSelectOption | undefined = useMemo(() => {
    return linkedSectionOptions.find((option) => option.value === content.section);
  }, [linkedSectionOptions, content]);

  const onSelect = (key?: string | number) => {
    setFieldValue &&
      setFieldValue(fieldName, {
        ...content,
        procedure: key,
        section: '',
      });
  };

  const labelFormatter = (procedure: ProcedureEntity) => `${procedure.code} ${procedure.name}`;

  const listItemTemplate = (procedure: ProcedureEntity) => (
    <>
      <div className="flex flex-row">
        <div className="text-sm text-blue-500">{procedure.code}</div>
        {isDraft(procedure) && <div className="ml-1 text-xs italic text-gray-500">(draft)</div>}
      </div>
      <div className="text-black">{procedure.name}</div>
    </>
  );

  const selectValueTemplate = (procedure: ProcedureEntity) => (
    <div className="-mt-1 leading-3">
      <div className="text-sm text-blue-500 truncate">{procedure.code}</div>
      <div className="truncate">{procedure.name}</div>
    </div>
  );

  return (
    <fieldset disabled={isProceduresLoading ? true : undefined}>
      <div className="flex flex-wrap items-start">
        <div className="flex flex-col mr-2">
          {showLabels && <span className="field-title">Procedure</span>}
          <Field name={`${fieldName}.procedure`}>
            {() => (
              <ProjectTreeSelector
                entities={entities}
                currentProjectId={sourceProcedure?.project_id}
                placeholder="Select Procedure"
                currentEntityId={selectedProcedureId}
                labelFormatter={labelFormatter}
                onSelect={onSelect}
                isDisabled={isDisabled}
                nodeTemplate={listItemTemplate}
                valueTemplate={selectValueTemplate}
              />
            )}
          </Field>
          {contentErrors && contentErrors.procedure && <div className="text-red-700">{contentErrors.procedure}</div>}
        </div>
        <div className="flex flex-col mr-2">
          {showLabels && <span className="field-title">Section</span>}
          <Field name={`${fieldName}.section`}>
            {() => (
              <Select
                key={linkedSectionSelected}
                value={linkedSectionSelected}
                placeholder="Select Section"
                disabled={!sections}
                classNamePrefix="react-select"
                className="w-48 max-w-prose  border-1 border-gray-400 rounded disabled:bg-gray-100"
                onChange={onChangeSection}
                options={linkedSectionOptions}
                styles={reactSelectStyles}
                isDisabled={isDisabled}
              />
            )}
          </Field>
        </div>
      </div>
    </fieldset>
  );
};

export default React.memo(FieldSetProcedureLink);
