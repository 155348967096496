import { useMemo } from 'react';
import Select from 'react-select';
import { Part, PartRevisionSummary } from 'shared/lib/types/postgres/manufacturing/types';
import { StringSelectOption as SelectOption } from '../../lib/formik';
import { reactSelectStyles } from '../../lib/styles';
import useLatestParts from '../hooks/useLatestParts';
import { getReleasedRevisions } from '../lib/part_revisions';
import { EMPTY_REVISION_DISPLAY_VALUE } from '../types';
import './styles/TreeSelect.css';
import { PartSelection } from './PartAndRevisionIdSelect';

type RevisionSelectProps = {
  selected: PartSelection | null;
  onSelect: (selection: PartSelection | null) => void;
  isDisabled?: boolean;
};

const RevisionSelect = ({ selected, onSelect, isDisabled = false }: RevisionSelectProps) => {
  const { getLatestPartRevision } = useLatestParts();

  /*
   * Guards against the user passing in a part from a content block in a procedure,
   * which may not have some expected fields like revisions.
   */
  let selectedPart: Part | undefined = undefined;
  let revisions: PartRevisionSummary[] = [];
  if (selected?.part) {
    selectedPart = getLatestPartRevision(selected.part.id);
    if (selectedPart?.revisions) {
      revisions = selectedPart.revisions;
    }
  }
  const released = getReleasedRevisions(revisions);

  const revisionOptions: SelectOption[] = released.map((revision) => ({
    value: revision.id,
    label: revision.revision || EMPTY_REVISION_DISPLAY_VALUE,
  }));

  const selectedRevisionOption: SelectOption | null = useMemo(() => {
    if (!selectedPart) {
      return null;
    }

    // Revision id specified in selected.partRevisionId object
    if (selected?.partRevisionId) {
      const selectedRevision = released.find((revision) => revision.id === selected.partRevisionId);
      if (selectedRevision) {
        return { value: selectedRevision.id, label: selectedRevision.revision || EMPTY_REVISION_DISPLAY_VALUE };
      }
    }

    // Fall back first to revision matching selected.part
    if (selected?.part) {
      const selectedRevision = released.find((revision) => revision.revision === selected?.part.rev);
      if (selectedRevision) {
        return { value: selectedRevision.id, label: selectedRevision.revision || EMPTY_REVISION_DISPLAY_VALUE };
      }
    }

    // Fall back last to showing latest released revision if one exists
    if (released.length > 0) {
      const selectedRevision = released[0];
      return { value: selectedRevision.id, label: selectedRevision.revision || EMPTY_REVISION_DISPLAY_VALUE };
    }

    return null;
  }, [selected, selectedPart, released]);

  const onRevisionChange = (option: SelectOption) => {
    if (!selected || !selected.part) {
      return;
    }
    onSelect({ part: selected.part, partRevisionId: option.value, nodeKeyId: selected.nodeKeyId });
  };

  return (
    <Select
      styles={reactSelectStyles}
      classNamePrefix="react-select"
      isDisabled={isDisabled || !selectedPart}
      options={revisionOptions}
      onChange={onRevisionChange}
      value={selectedRevisionOption}
      placeholder="Select revision"
    />
  );
};

export default RevisionSelect;
