import { keyBy, mergeWith, values } from 'lodash';
import { RealtimeData } from 'shared/lib/types/realtimeUpdatesTypes';

const EPOCH_ZERO_TIMESTAMP = new Date(0).toISOString();

const _getUpdatedAt = (item: RealtimeData | undefined): string => {
  if (!item) {
    return EPOCH_ZERO_TIMESTAMP;
  }
  return item.updated_at ?? item.created_at;
};

const realtime = {
  /**
   * @returns a positive number if item B is later
   *          a negative number if item A is later
   *          0 if both items were updated at the same time
   */
  latestUpdateComparator: (
    itemA: RealtimeData,
    itemB: RealtimeData
  ): number => {
    const updatedA = _getUpdatedAt(itemA);
    const updatedB = _getUpdatedAt(itemB);
    return updatedB.localeCompare(updatedA);
  },

  getLaterItem: (itemA: RealtimeData, itemB: RealtimeData): RealtimeData => {
    // Favor the "update" element (itemB) if updated_at fields are equal
    return realtime.latestUpdateComparator(itemA, itemB) >= 0 ? itemB : itemA;
  },

  mergeByIdAndTime: <T extends RealtimeData>(
    listA: Array<T>,
    listB: Array<T>
  ): Array<T> => {
    const objectA = keyBy(listA, (item) => item.id);
    const objectB = keyBy(listB, (item) => item.id);

    return values(mergeWith(objectA, objectB, realtime.getLaterItem));
  },

  filterOriginalListAndMerge: <T extends RealtimeData>(
    listA: Array<T>,
    listB: Array<T>
  ): Array<T> => {
    // only process items that exist in listB
    const presentIds = new Set(listB.map((item) => item.id));
    const relevantItemsFromA = listA.filter((item) => presentIds.has(item.id));

    // merge only the relevant items to get the latest versions
    const objectA = keyBy(relevantItemsFromA, (item) => item.id);
    const objectB = keyBy(listB, (item) => item.id);

    return values(mergeWith(objectA, objectB, realtime.getLaterItem));
  },
};

export default realtime;
