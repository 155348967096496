import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { AttachmentValue } from 'shared/lib/types/views/procedures';
import AttachmentPreview from '../Attachments/AttachmentPreview';
import AttachmentCarouselModal from '../../elements/AttachmentCarouselModal';

type AttachmentsContainerProps = {
  attachments: Array<AttachmentValue>;
  onRemove?: (attachment: AttachmentValue) => void;
};

const AttachmentsContainer = ({ attachments, onRemove }: AttachmentsContainerProps) => {
  const [overlayPreviewStartIndex, setOverlayPreviewStartIndex] = useState<number | null>(null);

  return (
    <div aria-label="Attachments container">
      <div className="flex flex-row gap-x-2 gap-y-1 flex-wrap">
        {attachments.map((attachment, index) => (
          <div key={index} className="relative group p-1">
            <AttachmentPreview
              attachment={attachment}
              file={undefined}
              onShouldRenderImageChange={() => undefined}
              canDownload={false}
            />
            <div
              className="absolute top-0 left-0 h-full w-full bg-gray-400 opacity-50 rounded-md hidden group-hover:block"
              onClick={() => setOverlayPreviewStartIndex(index)}
            ></div>
            {onRemove && (
              <button
                className="flex items-center absolute -top-2 -right-2 text-gray-400 p-[3px] rounded-full bg-white hover:text-gray-500 hidden group-hover:block"
                onClick={() => onRemove(attachment)}
              >
                <FontAwesomeIcon size="lg" icon="circle-xmark" />
              </button>
            )}
          </div>
        ))}
      </div>

      {overlayPreviewStartIndex !== null && (
        <AttachmentCarouselModal
          startIndex={overlayPreviewStartIndex}
          attachments={attachments}
          onClose={() => {
            setOverlayPreviewStartIndex(null);
          }}
        />
      )}
    </div>
  );
};

export default React.memo(AttachmentsContainer);
