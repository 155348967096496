import {
  equal as mathJsEqual,
  unequal as mathJsUnequal,
  smaller as mathJsSmaller,
  smallerEq as mathJsSmallerEq,
  larger as mathJsLarger,
  largerEq as mathJsLargerEq,
  MathType,
  MathCollection,
} from 'mathjs';

const BOOL_TRUE = [true, 1, 't', 'true', 'y', 'yes', 'on', '1'];
const BOOL_FALSE = [false, 0, 'f', 'false', 'n', 'no', 'off', '0'];
export const SUPPORTED_OPERATIONS = ['=', '≠', '<', '>', '≤', '≥'] as const;
export type SupportedOperation = (typeof SUPPORTED_OPERATIONS)[number];

const isSupportedOperation = (op: SupportedOperation | string): boolean => {
  return SUPPORTED_OPERATIONS.includes(op as SupportedOperation);
};

const JS_RELATIONAL_FUNCTION: {
  [op in SupportedOperation]: (
    a: string | boolean,
    b: string | boolean
  ) => boolean;
} = {
  '=': (a, b) => a === b,
  '≠': (a, b) => a !== b,
  '<': (a, b) => a < b,
  '>': (a, b) => a > b,
  '≤': (a, b) => a <= b,
  '≥': (a, b) => a >= b,
};

const MATH_JS_RELATIONAL_FUNCTION: {
  [op in SupportedOperation]: (
    x: MathType | string,
    y: MathType | string
  ) => boolean | MathCollection;
} = {
  '=': mathJsEqual,
  '≠': mathJsUnequal,
  '<': mathJsSmaller,
  '>': mathJsLarger,
  '≤': mathJsSmallerEq,
  '≥': mathJsLargerEq,
};

const _evaluateBasic = (
  lhs: string | boolean,
  rhs: string | boolean,
  op: SupportedOperation
): boolean | null => {
  const relationalFunction = JS_RELATIONAL_FUNCTION[op];
  return relationalFunction ? relationalFunction(lhs, rhs) : null;
};

/**
 * Evaluates math expression of the form "lhs op rhs."
 * Uses mathjs's built-in relative and absolute tolerance for comparing
 * floating point values.
 *
 * E.g., lhs = 1, rhs = 2, and op = '<', evaluates to true.
 *
 * @param lhs - left hand side of the expression
 * @param rhs - right hand side of the expression
 * @param op - math operation to perform
 *
 * @returns true if the expression is true
 */
const evaluate = (
  lhs: number | string | boolean,
  rhs: number | string | boolean,
  op: SupportedOperation
): boolean | null => {
  // Mathjs number functions cannot accept a boolean, and do not properly compare strings.
  if (
    typeof lhs === 'boolean' ||
    typeof rhs === 'boolean' ||
    (typeof lhs === 'string' && typeof rhs === 'string')
  ) {
    return _evaluateBasic(lhs as boolean | string, rhs as boolean | string, op);
  }

  // Evaluate as a number.
  const mathJsRelationalFunction = MATH_JS_RELATIONAL_FUNCTION[op];
  return mathJsRelationalFunction
    ? (mathJsRelationalFunction(lhs, rhs) as boolean)
    : null;
};

enum RangeLocation {
  BelowRange = -1,
  WithinRange = 0,
  AboveRange = 1,
}

type Range = {
  min: number;
  max: number;
  include_min?: boolean;
  include_max?: boolean;
};

/**
 * Evaluates where value lies in range (below, within, or above),
 * taking into account exclusive/inclusive min/max
 * @param value
 * @param range
 */
const evaluateRangeLocation = (
  value: number | string | boolean,
  range: Required<Range>
): RangeLocation => {
  if (typeof (range.min as unknown as string) === 'string') {
    range.min = parseFloat(range.min as unknown as string);
  }
  if (typeof (range.max as unknown as string) === 'string') {
    range.max = parseFloat(range.max as unknown as string);
  }

  if (evaluate(value, range.min, range.include_min ? '<' : '≤')) {
    return RangeLocation.BelowRange;
  }

  if (evaluate(value, range.max, range.include_max ? '>' : '≥')) {
    return RangeLocation.AboveRange;
  }

  return RangeLocation.WithinRange;
};

/**
 * @returns true if value lies in range
 */
const evaluateIsWithinRange = (
  value: number | string | boolean,
  range: Required<Range>
): boolean => {
  return evaluateRangeLocation(value, range) === RangeLocation.WithinRange;
};

const passFailEvaluateUtil = {
  /**
   * Parses a value into its corresponding Javascript value.
   *
   * type: String, one of the allowed API types in PARAMETER_TYPES.
   * value: Any, will attempt corresponding Javascript conversion.
   * returns: The corresponding Javascript value, or null if parsing failed.
   */
  parseValue: (type, value) => {
    let parsed;
    switch (type) {
      case 'string':
        return `${value as string}`;
      case 'float':
      case 'number':
        parsed = parseFloat(value as string);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument,@typescript-eslint/no-unsafe-return
        return isNaN(parsed) ? null : parsed;
      case 'int':
        parsed = parseInt(value as string);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument,@typescript-eslint/no-unsafe-return
        return isNaN(parsed) ? null : parsed;
      case 'enum':
        parsed = parseInt(value as string);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument,@typescript-eslint/no-unsafe-return
        return isNaN(parsed) ? value : parsed;
      case 'bool':
        parsed = typeof value === 'string' ? value.toLowerCase() : value;
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        if (BOOL_TRUE.includes(parsed)) {
          return true;
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        } else if (BOOL_FALSE.includes(parsed)) {
          return false;
        } else {
          return null;
        }
      default:
        return null;
    }
  },

  /**
   * Returns true if the given value passes the given rule.
   *
   * Handles parsing of all values involved into their corresponding Javascript
   * value types. Hence, `value`, `telemetry.value` or `fieldInput.value` and etc may be represented
   * as strings or other types when passing in. The correct type is obtained
   * from the parameter `type` property.
   *
   * passFailObject: An object containing a rule definition.
   * parameter: An object containing a `type` property.
   * value: String, number, or boolean representation of current sample
   *        (reading) value.
   * returns: True if the given value passes the rule, otherwise false.
   *          Throws an error if passFailObject or parameter objects are missing.
   *          Returns null if the given value failed parsing or validation.
   */
  isValuePassing: (
    passFailObject,
    parameter: { type: string; values?: { [val: number]: string } },
    value
  ) => {
    if (!passFailObject) {
      return null;
    }
    if (!parameter || !parameter.type) {
      return null;
    }
    if (value === null || value === undefined) {
      return null;
    }

    if (!passFailObject.rule) {
      return true;
    }
    const rule: SupportedOperation =
      typeof passFailObject.rule === 'string'
        ? passFailObject.rule.toLowerCase()
        : passFailObject.rule.op.toLowerCase();
    const range: Range = passFailObject.rule.range
      ? passFailObject.rule.range
      : passFailObject.range;

    // Parse values
    let _value = passFailEvaluateUtil.parseValue(parameter.type, value);
    if (_value === null) {
      return null;
    }
    if (isSupportedOperation(rule)) {
      const expressionRHS = passFailEvaluateUtil.parseValue(
        parameter.type,
        passFailObject.value || passFailObject.rule?.value
      );
      if (expressionRHS === null) {
        return null;
      }

      // if comparing to enum string value, convert value to string value
      if (parameter.type === 'enum' && typeof expressionRHS === 'string') {
        _value = parameter?.values?.[_value];
      }

      const evaluationResult = evaluate(_value, expressionRHS, rule);
      return evaluationResult;
      // Field input rules are objects with a nested op property while in telemetry the rule is a string already
    } else if (rule === ('range' as SupportedOperation)) {
      const expressionMax = passFailEvaluateUtil.parseValue(
        parameter.type,
        range.max
      );
      const expressionMin = passFailEvaluateUtil.parseValue(
        parameter.type,
        range.min
      );
      if (
        typeof expressionMax !== 'number' ||
        isNaN(expressionMax) ||
        typeof expressionMin !== 'number' ||
        isNaN(expressionMin)
      ) {
        return null;
      }
      const rangeObject = {
        min: expressionMin,
        max: expressionMax,
        include_min: Boolean(range.include_min),
        include_max: Boolean(range.include_min),
      };
      const rangeResult = evaluateIsWithinRange(_value, rangeObject);
      return rangeResult;
    }
    return null;
  },
};

const isNumber = (value): boolean => {
  return Number.isFinite(value);
};

export {
  isSupportedOperation,
  evaluate,
  evaluateIsWithinRange,
  evaluateRangeLocation,
  RangeLocation,
  passFailEvaluateUtil,
  isNumber,
};
