import { Link } from 'react-router-dom';
import useSpecificParts from '../../hooks/useSpecificParts';
import { getPartLabel, getPartRevisionId } from '../../lib/parts';
import { partPath } from '../../../lib/pathUtil';
import sharedDiffUtil from 'shared/lib/diffUtil';

const ReviewPartTopLabel = ({ teamId, part }) => {
  const partIds = part?.id ? [part.id] : [];
  const { getLatestPartRevision } = useSpecificParts({ partIds });
  const numberRevLabel = getPartLabel(part);
  const partId: string | undefined = part ? sharedDiffUtil.getDiffValue(part, 'id', 'new') : undefined;
  const partDeleted = !partId || !getLatestPartRevision(partId);

  if (partDeleted) {
    return <div className="">{numberRevLabel}</div>;
  }

  const partRevisionId = getPartRevisionId(part);
  const path = partPath(teamId, part.id, partRevisionId);

  return (
    <Link to={path}>
      <div className=" text-blue-600">{numberRevLabel}</div>
    </Link>
  );
};

export default ReviewPartTopLabel;
