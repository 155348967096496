import React, { useCallback, useMemo, useState } from 'react';
import Select, { OptionsType } from 'react-select';
import { useProcedureContext } from '../../contexts/ProcedureContext';
import procedureUtil from '../../lib/procedureUtil';
import JumpToEditSelectOption from './JumpToEditSelectOption';
import { useSettings } from '../../contexts/SettingsContext';
import { reactSelectStyles } from '../../lib/styles';
import { getLatestApprovedField } from '../../hooks/useFieldRedlines';
import revisions from '../../lib/revisions';
import { getStepById } from 'shared/lib/procedureUtil';
import { RunStep } from 'shared/lib/types/views/procedures';

// Returns string with "'sectionKey'. 'sectionName'"
const getSectionLabel = (sectionName, index, style) => {
  const sectionKey = procedureUtil.displaySectionKey(index, style);

  if (sectionName) {
    return `${sectionKey}. ${sectionName}`;
  }

  return `${sectionKey}.`;
};

// Returns string with "'stepKey'. 'stepName'"
const getStepLabel = (stepName, stepIndex, sectionIndex, style) => {
  const stepLabel = procedureUtil.displaySectionStepKey(sectionIndex, stepIndex, style);

  if (stepName) {
    return `${stepLabel}. ${stepName}`;
  }

  return `${stepLabel}.`;
};

// Returns an array of react-select type options objects.
const getOptions = ({ sections, getSetting, includeSections, containingStepId }) => {
  const options: OptionsType[] = [];

  sections.forEach((section, sectionIndex) => {
    if (includeSections) {
      const sectionLabel = getSectionLabel(section.name, sectionIndex, getSetting('display_sections_as', 'letters'));

      options.push({
        type: 'section',
        label: sectionLabel,
        value: section.id,
      });
    }

    section.steps.forEach((step, stepIndex) => {
      // Don't add containing step or dynamic step that has not been accepted into the options array.
      if (containingStepId === step.id || step.created_during_run) {
        return;
      }

      const fieldRedlines = revisions.getStepFieldChanges(step, 'name', step.redlines);
      const latestStepName = getLatestApprovedField({
        fieldName: 'name',
        fieldValue: step.name,
        redlines: fieldRedlines,
      });
      const stepLabel = getStepLabel(
        latestStepName,
        stepIndex,
        sectionIndex,
        getSetting('display_sections_as', 'letters')
      );
      options.push({
        type: 'step',
        label: stepLabel,
        value: step.id,
      });
    });
  });

  return options;
};

interface JumpToEditSelectProps {
  includeSections: boolean;
  path: string;
  containingStepId: string;
  value: string;
  onChange: (path: string, jumpToId: string) => void;
  isDisabled: boolean;
}

const JumpToEditSelect = React.memo(
  ({ includeSections, path, containingStepId, value, onChange, isDisabled }: JumpToEditSelectProps) => {
    const { getAllSections, getSectionSummary, getStepSummary, getItemPath, procedure } = useProcedureContext();
    const [options, setOptions] = useState<OptionsType>();
    const { getSetting } = useSettings();

    const updateOptions = useCallback(() => {
      const sections = getAllSections();
      const options = getOptions({
        sections,
        getSetting,
        includeSections,
        containingStepId,
      });

      setOptions(options);
    }, [getAllSections, getSetting, includeSections, containingStepId]);

    const selectValue = useMemo(() => {
      if (value === null) {
        return null;
      }

      const path = getItemPath(value);

      if (!path) {
        return null;
      }

      const { sectionId, stepId } = procedureUtil.parsePath(path);
      const sectionSummary = getSectionSummary(sectionId);
      if (!sectionSummary) {
        return null;
      }

      if (!stepId) {
        return {
          type: 'section',
          label: getSectionLabel(
            sectionSummary.name,
            sectionSummary.index,
            getSetting('display_sections_as', 'letters')
          ),
          value: sectionId,
        };
      }

      const step = getStepById(procedure, sectionId, stepId);
      const stepSummary = getStepSummary(stepId, sectionId);

      if (!step || !stepSummary) {
        return null;
      }

      const fieldRedlines = revisions.getStepFieldChanges(step, 'name', (step as RunStep).redlines ?? []);
      const latestStepName = getLatestApprovedField({
        fieldName: 'name',
        fieldValue: step.name,
        redlines: fieldRedlines,
      });
      return {
        type: 'step',
        label: getStepLabel(
          latestStepName,
          stepSummary.index,
          sectionSummary.index,
          getSetting('display_sections_as', 'letters')
        ),
        value: stepId,
      };
    }, [value, getItemPath, getSectionSummary, procedure, getStepSummary, getSetting]);

    const onChangeHandler = (option) => {
      const jumpToId = option.value;

      // Skip onChange when re-selecting the same value.
      if (jumpToId === value) {
        return;
      }

      onChange(path, jumpToId);
    };

    return (
      <Select
        aria-label="Select Jump To Location" // React-select requires aria-label instead of ariaLabel.
        classNamePrefix="react-select"
        components={{ Option: JumpToEditSelectOption }}
        name={path}
        onChange={onChangeHandler}
        onMenuOpen={updateOptions}
        options={options}
        styles={reactSelectStyles}
        value={selectValue}
        isDisabled={isDisabled}
      />
    );
  }
);

export default JumpToEditSelect;
