// @ts-nocheck
import axios, { AxiosInstance } from 'axios';
import { API_URL } from '../config';
import { EventEmitter2 } from 'eventemitter2';

const isStorageAvailable = () => {
  const mod = '__STORAGE__';
  try {
    localStorage.setItem(mod, mod);
    localStorage.removeItem(mod);
    return true;
  } catch (e) {
    return false;
  }
};

const parseHostFromUrl = (url) => {
  const parsedURL = new URL(url);
  return parsedURL.host;
};

const memoryStorage = {
  setItem: (key, value) => {
    memoryStorage.storage.set(key, value);
  },
  getItem: (key) => {
    const value = memoryStorage.storage.get(key);
    if (typeof value !== 'undefined') {
      return value;
    }
    return null;
  },
  removeItem: (key) => {
    memoryStorage.storage.delete(key);
  },
  storage: new Map(),
};

class e3Login extends EventEmitter2 {
  /* eslint-disable @typescript-eslint/no-explicit-any */
  private _http: AxiosInstance;
  private _config: any;
  private _session: any;
  storage: any;

  private serverUrl = API_URL;
  private baseUrl = `${API_URL}/auth`;
  private configStorage = 'local';

  private endpoints = [parseHostFromUrl(API_URL) || window.location.host];
  private providers: string[] = [];
  private timeout = 0;
  /* eslint-disable @typescript-eslint/no-explicit-any */

  constructor() {
    super();
    this._http = axios.create({
      baseURL: this.serverUrl.replace(/\/$/, ''),
      timeout: this.timeout,
      withCredentials: true,
    });

    // Redirect to login page if we get 401s from not auth
    this._http.interceptors.response.use(
      (response) => response,
      (error) => {
        if (
          error.response?.status === 401 &&
          !error.config.url.includes('/auth/')
        ) {
          this.deleteSession();
          if (window.location.pathname !== '/login') {
            window.location.href = '/login';
          }
        }
        return Promise.reject(error);
      }
    );

    if (!isStorageAvailable()) {
      this.storage = memoryStorage;
    } else if (this.configStorage === 'session') {
      this.storage = window.sessionStorage;
    } else {
      this.storage = window.localStorage;
    }

    // Setup the new session
    this._session = JSON.parse(this.storage.getItem('session'));
    this.setAuthHeaders(this._session?.token);
  }

  /* eslint-disable class-methods-use-this */
  configure(): void {
    // Just needed for the superlogin-client interface
  }

  /* eslint-disable @typescript-eslint/explicit-module-boundary-types */
  getSession() {
    if (!this._session) {
      this._session = JSON.parse(this.storage.getItem('session'));
      this.setAuthHeaders(this._session?.token);
    }
    return this._session ? { ...this._session } : null;
  }

  /* eslint-disable @typescript-eslint/explicit-module-boundary-types */
  getHttp() {
    return this._http;
  }

  authenticated(): boolean {
    return !!(this._session && this._session.id);
  }

  private setAuthHeaders(token?: string) {
    if (token) {
      this._http.defaults.headers.common['x-csrf-token'] = token;
    } else {
      delete this._http.defaults.headers.common['x-csrf-token'];
    }
  }

  private deleteSession(): void {
    this.storage.removeItem('session');
    this.setAuthHeaders();
    this._session = null;
  }

  /* eslint-disable class-methods-use-this */
  onLogin(msg: any): void {
    this.emit('login', msg);
  }

  /* eslint-disable class-methods-use-this */
  private onRegister(msg: object): void {
    this.emit('register', msg);
  }

  private onLogout(msg: string): void {
    this.deleteSession();
    this.emit('logout', msg);
  }

  /* eslint-disable @typescript-eslint/no-explicit-any */
  /* eslint-disable @typescript-eslint/explicit-module-boundary-types */
  setSession(session: any): void {
    this._session = session;
    this.storage.setItem('session', JSON.stringify(this._session));
    this.setAuthHeaders(session?.token);
  }

  /* eslint-disable @typescript-eslint/no-explicit-any */
  async login(credentials: {
    username: string;
    password: string;
  }): Promise<void> {
    const res = await this._http.post(`${this.baseUrl}/login`, credentials);
    this.setSession(res.data);
    this.onLogin(res.data);
    return res.data;
  }

  /* eslint-disable @typescript-eslint/no-explicit-any */
  async logout(msg?: string): Promise<void> {
    try {
      await this._http.post(`${this.baseUrl}/logout`);
    } catch (err) {
      if (!err.response || err.response.status !== 401) {
        throw err;
      }
    } finally {
      this.deleteSession();
      this.onLogout(msg || 'Logged out');
    }
  }

  async resetPassword(form: {
    token: string;
    password: string;
    confirmPassword: string;
  }): Promise<void> {
    const res = await this._http.post(`${this.baseUrl}/password-reset`, form);
    return res.data;
  }

  async forgotPassword(email: string): Promise<void> {
    const res = await this._http.post(`${this.baseUrl}/forgot-password`, {
      email,
    });
    return res.data;
  }

  async changePassword(values: {
    currentPassword: string;
    newPassword: string;
    confirmPassword: string;
  }): Promise<void> {
    const res = await this._http.post(
      `${this.baseUrl}/password-change`,
      values
    );
    return res.data;
  }
}

export default e3Login;
