import {
  faBarsStaggered,
  faChartLine,
  faChevronCircleLeft,
  faChevronCircleRight,
  faClipboard,
  faCog,
  faCubes,
  faFileCircleExclamation,
  faLayerGroup,
  faListCheck,
  faSearch,
  faTriangleExclamation,
  faUserCircle,
} from '@fortawesome/free-solid-svg-icons';
import React, { useCallback, useEffect, useMemo, useRef, useContext, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import MenuWorkspace from '../components/MenuWorkspace';
import ModalLogout from '../components/ModalLogout';
import { useAuth } from '../contexts/AuthContext';
import { default as useAuthHook } from '../hooks/useAuth';
import { useDatabaseServices } from '../contexts/DatabaseContext';
import { useMixpanel } from '../contexts/MixpanelContext';
import { useNavState } from '../contexts/NavContext';
import { NotificationProvider } from '../contexts/NotificationContext';
import { useSettings } from '../contexts/SettingsContext';
import { PERM } from '../lib/auth';
import {
  manufacturingInsightsPath,
  homePath,
  insightsPath,
  inventoryIndexPath,
  issuesPath,
  issuesSettingsPath,
  manufacturingSettingsPath,
  notificationsPath,
  operationsDashboardPath,
  ordersIndexPath,
  partIndexPath,
  proceduresPath,
  runsPath,
  schedulePath,
  snippetsPath,
  storagePath,
  teamSettingsPath,
  testingPlansPath,
  testingSettingsPath,
  testingTestCasesPath,
  toolsIndexPath,
  testingPath,
  testingRequirementsPath,
  testingHazardsPath,
  risksPath,
  risksSettingsPath,
  searchPath,
  workOrdersIndexPath,
} from '../lib/pathUtil';
import logoNarrow from '../images/logo-e3-light.svg';
import logoWide from '../images/logo-epsilon3.svg';
import BaseNavItem, { NavItemProps } from './Navigation/BaseNavItem';
import NavItem from './Navigation/NavItem';
import NavItemProfile from './Navigation/NavItemProfile';
import NavItemWithMenu from './Navigation/NavItemWithMenu';
import { useUserInfo } from '../contexts/UserContext';
import { SubNavItem } from './Navigation/NavigationSubMenu';
import apm from '../lib/apm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NavItemProjectSelector from './Navigation/NavItemProjectSelector';
import { faFolder } from '@fortawesome/free-regular-svg-icons';
import Snow from '../components/Snow';
import StatusContext, { LicenseStatusInfo } from '../contexts/StatusContext';
import LicenseExpiringBanner from '../components/LicenseExpiringBanner';

export const MainScrollPanelId = 'main-scroll-panel';

interface NavItemDefinition
  extends Omit<NavItemProps<typeof NavItem | typeof NavItemWithMenu>, 'currentScreen' | 'hideLabels'> {
  enabled: boolean;
  items?: Array<SubNavItem>;
}

const shouldBeCollapsed = (): boolean => {
  return window.innerWidth < 1280;
};

const Navigation = ({ onClickSearch, children }) => {
  const location = useLocation();
  const { mixpanel } = useMixpanel();
  const [hideLabels, setHideLabels] = useState(shouldBeCollapsed());
  const navState = useNavState();
  const [isManuallyCollapsed, setIsManuallyCollapsed] = useState(false);
  const logoLinkRef = useRef<HTMLAnchorElement>(null);
  const { currentTeamId } = useDatabaseServices();
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const { auth } = useAuth();
  const { logout } = useAuthHook(setIsLoggingOut);
  const { userInfo } = useUserInfo();
  const { isIssuesEnabled, isRisksEnabled, isTestConditionsMatrixEnabled } = useSettings();

  const logo = navState.isCollapsed ? logoNarrow : logoWide;

  const expandLeftNav = useCallback(() => {
    navState.setIsCollapsed(false);
    setTimeout(() => {
      setHideLabels(false);
    }, 100);
  }, [navState]);

  const collapseLeftNav = useCallback(() => {
    setHideLabels(true);
    navState.setIsCollapsed(true);
  }, [navState]);

  const manuallyCollapse = useCallback(() => {
    if (mixpanel) {
      mixpanel.track('Toggle Nav', { Direction: navState.isCollapsed ? 'Expand' : 'Collapse' });
    }
    setIsManuallyCollapsed(true);
    navState.isCollapsed ? expandLeftNav() : collapseLeftNav();
  }, [mixpanel, navState.isCollapsed, expandLeftNav, collapseLeftNav]);

  const onClickLogout = useCallback(() => {
    logout()
      .then(() => navState.setProjectId(undefined))
      .catch((err) => apm.captureError(err));
  }, [logout, navState]);

  const topNavItems = useMemo<Array<NavItemDefinition>>(
    () => [
      {
        label: 'Search',
        enabled: navState.isCollapsed,
        component: NavItem,
        icon: faSearch,
        onClick: onClickSearch,
      },
      {
        label: userInfo.session.user_id,
        enabled: true,
        component: NavItemProfile,
        icon: faUserCircle,
        to: notificationsPath(currentTeamId),
        onClick: onClickLogout,
      },

      {
        label: 'Project',
        enabled: true,
        component: NavItemProjectSelector,
        icon: faFolder,
      },
      {
        label: 'Runs',
        enabled: true,
        component: NavItem,
        icon: faListCheck,
        to: runsPath(currentTeamId),
      },
      {
        label: 'Procedures',
        enabled: true,
        component: NavItemWithMenu,
        icon: faLayerGroup,
        items: [
          { label: 'Library', to: proceduresPath(currentTeamId) },
          { label: 'Snippets', to: snippetsPath(currentTeamId) },
          { label: 'Insights', to: insightsPath(currentTeamId), key: 'procedure-insights' },
        ],
      },
      {
        label: 'Builds',
        enabled: true,
        component: NavItemWithMenu,
        settingsPath: manufacturingSettingsPath(currentTeamId),
        icon: faCubes,
        items: [
          { label: 'Work Orders', to: workOrdersIndexPath(currentTeamId) },
          { label: 'Inventory', to: inventoryIndexPath(currentTeamId) },
          { label: 'Parts', to: partIndexPath(currentTeamId) },
          { label: 'Tools', to: toolsIndexPath(currentTeamId) },
          { label: 'Purchase Orders', to: ordersIndexPath(currentTeamId) },
          { label: 'Insights', to: manufacturingInsightsPath(currentTeamId), key: 'builds-insights' },
        ],
      },
      {
        label: 'Testing',
        enabled: isTestConditionsMatrixEnabled && isTestConditionsMatrixEnabled(),
        component: NavItemWithMenu,
        settingsPath: testingSettingsPath(currentTeamId),
        icon: faClipboard,
        items: [
          { label: 'Requirements', to: testingRequirementsPath(currentTeamId) },
          { label: 'Hazards', to: testingHazardsPath(currentTeamId) },
          { label: 'Test Plans', to: testingPlansPath(currentTeamId) },
          { label: 'Test Points', to: testingTestCasesPath(currentTeamId) },
        ],
      },
      {
        label: 'Issues',
        enabled: isIssuesEnabled && isIssuesEnabled(),
        component: NavItem,
        icon: faFileCircleExclamation,
        to: issuesPath(currentTeamId),
        settingsPath: issuesSettingsPath(currentTeamId),
      },
      {
        label: 'Risks',
        enabled: isRisksEnabled && isRisksEnabled(),
        component: NavItem,
        icon: faTriangleExclamation,
        to: risksPath(currentTeamId),
        settingsPath: risksSettingsPath(currentTeamId),
      },
    ],
    [
      currentTeamId,
      isIssuesEnabled,
      isRisksEnabled,
      isTestConditionsMatrixEnabled,
      navState.isCollapsed,
      onClickLogout,
      onClickSearch,
      userInfo.session.user_id,
    ]
  );

  const middleNavItems = useMemo<Array<NavItemDefinition>>(
    () => [
      {
        label: 'Planning',
        enabled: auth.hasPermission(PERM.VIEW_OPERATIONS),
        component: NavItemWithMenu,
        icon: faBarsStaggered,
        items: [
          { label: 'Operations', to: operationsDashboardPath(currentTeamId) },
          { label: 'Schedules', to: schedulePath(currentTeamId) },
        ],
      },
      {
        label: 'Data',
        enabled: true,
        component: NavItem,
        icon: faChartLine,
        to: storagePath(currentTeamId),
      },
    ],
    [auth, currentTeamId]
  );

  const bottomNavItems = useMemo<Array<NavItemDefinition>>(
    () => [
      {
        label: 'Settings',
        enabled: true,
        component: NavItem,
        icon: faCog,
        to: teamSettingsPath(currentTeamId),
      },
      {
        label: navState.isCollapsed ? 'Expand' : 'Collapse',
        enabled: true,
        component: NavItem,
        icon: navState.isCollapsed ? faChevronCircleRight : faChevronCircleLeft,
        onClick: manuallyCollapse,
      },
    ],
    [currentTeamId, manuallyCollapse, navState.isCollapsed]
  );

  const allItems = useMemo(
    () => [...topNavItems, ...middleNavItems, ...bottomNavItems],
    [bottomNavItems, middleNavItems, topNavItems]
  );

  const isTestingSettingsPage = (pathname: string, currentTeamId: string) => {
    return pathname.startsWith(testingPath(currentTeamId)) && pathname.includes('conditions');
  };

  const currentScreen = useMemo(() => {
    let key = '';
    allItems.forEach((item) => {
      if (
        (item.to && location.pathname.startsWith(item.to)) ||
        (item.settingsPath && location.pathname.startsWith(item.settingsPath))
      ) {
        key = item.label;
      }
      if (item.items) {
        item.items.forEach((subItem) => {
          if (location.pathname.startsWith(subItem.to)) {
            key = subItem.key ?? subItem.label;
          }
        });
      }
    });

    // temporary exception for the 3 testing settings pages whose urls don't match the convention
    if (isTestingSettingsPage(location.pathname, currentTeamId)) {
      key = 'Testing';
    }

    // temporary exception for events that live under scheduling
    if (location.pathname.includes('planning/event')) {
      key = 'Schedules';
    }

    return key;
  }, [allItems, currentTeamId, location.pathname]);

  /*
   * This is to accommodate mobile devices where the browser viewport height can be dynamic
   * depending on whether the address bar is shown or not.  Without this, the nav bar is too tall
   * and extends beyond the bottom of the screen when the address bar is visible
   * see https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
   * It would be good to detect this and use only on affected devices because h-screen is smoother
   */
  useEffect(() => {
    let resizeTimeoutId: ReturnType<typeof setTimeout>;
    const handleViewportResize = () => {
      clearTimeout(resizeTimeoutId);
      resizeTimeoutId = setTimeout(() => {
        const vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);

        if (!isManuallyCollapsed) {
          if (shouldBeCollapsed() && !navState.isCollapsed) {
            collapseLeftNav();
          }
          if (!shouldBeCollapsed() && navState.isCollapsed) {
            expandLeftNav();
          }
        }
      }, 200);
    };

    window.addEventListener('resize', handleViewportResize);
    handleViewportResize();

    return () => {
      window.removeEventListener('resize', handleViewportResize);
    };
  }, [location, currentTeamId, isManuallyCollapsed, navState.isCollapsed, collapseLeftNav, expandLeftNav]);

  const licenseStatus: LicenseStatusInfo = useContext<LicenseStatusInfo>(StatusContext);

  return (
    <NotificationProvider>
      {isLoggingOut && <ModalLogout />}
      {licenseStatus?.warn && <LicenseExpiringBanner />}

      <Snow />
      <div className="main-viewport flex flex-row h-screen transition-all overflow-hidden print:overflow-visible">
        <nav
          className={`print:hidden transition-all flex flex-col bg-slate-800 text-white z-[100] overflow-y-auto ${
            navState.isCollapsed ? 'w-16 min-w-16' : 'w-64 min-w-64'
          }`}
        >
          <div className="flex flex-row items-center justify-between">
            <Link
              ref={logoLinkRef}
              to={homePath(currentTeamId)}
              className="focus:opacity-40 hover:opacity-40 outline-none"
            >
              <div className={`${navState.isCollapsed ? 'pl-4 mt-5 mb-1' : 'px-5 pt-5 pb-3'}`}>
                <img
                  src={logo}
                  className="app-logo"
                  style={{
                    height: navState.isCollapsed ? '26px' : '18px',
                    width: 'auto',
                  }}
                  alt="logo"
                />
              </div>
            </Link>
            {!navState.isCollapsed && (
              <Link className="mr-1 pt-5 pb-3 px-3" to={searchPath(currentTeamId)} aria-label="Search">
                <FontAwesomeIcon icon="search" className="text-white" />
              </Link>
            )}
          </div>
          <div>
            <MenuWorkspace hideLabels={hideLabels} />
          </div>

          {/* Top section that can grow in height */}
          <div className="flex flex-col flex-grow">
            {topNavItems
              .filter((navItem) => navItem.enabled !== false)
              .map((navItem) => (
                <BaseNavItem
                  key={navItem.label}
                  {...navItem}
                  currentScreen={currentScreen}
                  hideLabels={hideLabels}
                  isNavigationExpanded={!navState.isCollapsed}
                />
              ))}
            {/* transitional until all are on project-navigation */}
            {middleNavItems.length > 0 && <div className="border-t mx-1 border-gray-500" />}
            {middleNavItems
              .filter((navItem) => navItem.enabled !== false)
              .map((navItem) => (
                <BaseNavItem
                  key={navItem.label}
                  {...navItem}
                  currentScreen={currentScreen}
                  hideLabels={hideLabels}
                  isNavigationExpanded={!navState.isCollapsed}
                />
              ))}
          </div>

          {/* Section pinned to the bottom */}
          <div>
            <div className="border-t mx-1 border-gray-500" />
            {bottomNavItems
              .filter((navItem) => navItem.enabled !== false)
              .map((navItem) => (
                <BaseNavItem
                  key={navItem.label}
                  {...navItem}
                  currentScreen={currentScreen}
                  hideLabels={hideLabels}
                  isNavigationExpanded={!navState.isCollapsed}
                />
              ))}
          </div>
        </nav>
        {/* Main Component */}
        <div
          role="main"
          className="flex flex-grow overflow-auto print:overflow-visible relative"
          id={MainScrollPanelId}
        >
          {children}
        </div>
      </div>
    </NotificationProvider>
  );
};

export default React.memo(Navigation);
