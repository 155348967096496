import React, { Fragment, useMemo } from 'react';
import useBlockComponents from '../../hooks/useBlockComponents';
import sharedDiffUtil, { ARRAY_CHANGE_SYMBOLS } from 'shared/lib/diffUtil';
import DiffContainer from '../Diff/DiffContainer';

const ProcedureBlockReview = ({
  blockLabel,
  block,
  recorded,
  isEnabled,
  isHidden,
  isSpacerHidden,
  isDark,
  scrollMarginTopValueRem,
}) => {
  const { TypedProcedureBlock, TypedBlock } = useBlockComponents({ blockType: block.type, isReview: true });

  const diffContainerLabel = useMemo(() => {
    const type = sharedDiffUtil.getDiffValue(block, 'type', 'new');
    if (type === 'alert') {
      const subtype = sharedDiffUtil.getDiffValue(block, 'subtype', 'new');
      return typeof subtype === 'string' && subtype.length > 0
        ? [...subtype][0].toUpperCase() + subtype.slice(1)
        : 'Alert';
    }
    if (type === 'input') {
      return 'Field input';
    }
    if (type === 'requirement') {
      return 'Requirement';
    }
    if (type === 'external_item') {
      return 'External item';
    }
    return 'Block';
  }, [block]);

  const diffChangeState = useMemo(() => {
    // We provide a more detailed diff for these fields, so we don't need to show the diff container box when they are modified.
    return block.diff_change_state === ARRAY_CHANGE_SYMBOLS.MODIFIED &&
      ['alert', 'requirement', 'input'].some((type) => type === block.type)
      ? ARRAY_CHANGE_SYMBOLS.UNCHANGED
      : block.diff_change_state;
  }, [block]);

  return (
    <Fragment>
      {/* Render specific block content */}
      <TypedProcedureBlock
        blockLabel={blockLabel}
        isHidden={isHidden}
        actions={[]}
        isSpacerHidden={isSpacerHidden}
        hasExtraVerticalSpacing={isEnabled && block.inputType === 'checkbox'}
      >
        <DiffContainer diffChangeState={diffChangeState} label={diffContainerLabel} isTextSticky={false}>
          {/* Pass the block id to use in case the latest approved block is not
         the same as the original block.*/}
          <TypedBlock
            block={block}
            blockId={block.id}
            recorded={recorded}
            isEnabled={isEnabled}
            isDark={isDark}
            scrollMarginTopValueRem={scrollMarginTopValueRem}
          />
        </DiffContainer>
      </TypedProcedureBlock>
    </Fragment>
  );
};

export default ProcedureBlockReview;
