import superlogin from './superlogin';
import { API_URL } from '../config';
import {
  EntityTagMapping,
  EntityType,
  ServerTag,
  TagUsage,
} from 'shared/lib/types/api/settings/tags/models';

class EntityTagService {
  private teamId: string;

  constructor(teamId: string) {
    this.teamId = teamId;
  }

  async getTagsForEntity(
    entityType: EntityType,
    entityId: string
  ): Promise<ServerTag[]> {
    const url = `${API_URL}/teams/${
      this.teamId
    }/entity-tags/${encodeURIComponent(entityType)}/${encodeURIComponent(
      entityId
    )}`;
    return superlogin
      .getHttp()
      .get(url)
      .then((resp) => resp.data.tags);
  }

  async getTagMappingsForEntityType(
    entityType: EntityType
  ): Promise<EntityTagMapping> {
    const url = `${API_URL}/teams/${
      this.teamId
    }/entity-tags/${encodeURIComponent(entityType)}`;
    return superlogin
      .getHttp()
      .get(url)
      .then((resp) => resp.data.tag_mappings);
  }

  async addTagToEntity(
    entityType: EntityType,
    entityId: string,
    tagId: string
  ): Promise<void> {
    const url = `${API_URL}/teams/${
      this.teamId
    }/entity-tags/${encodeURIComponent(entityType)}/${encodeURIComponent(
      entityId
    )}/${encodeURIComponent(tagId)}`;
    return superlogin.getHttp().put(url);
  }

  async removeTagFromEntity(
    entityType: EntityType,
    entityId: string,
    tagId: string
  ): Promise<void> {
    const url = `${API_URL}/teams/${
      this.teamId
    }/entity-tags/${encodeURIComponent(entityType)}/${encodeURIComponent(
      entityId
    )}/${encodeURIComponent(tagId)}`;
    return superlogin.getHttp().delete(url);
  }

  async getTagsUsage(): Promise<TagUsage> {
    const url = `${API_URL}/teams/${this.teamId}/entity-tags/usage`;
    return superlogin
      .getHttp()
      .get(url)
      .then((resp) => resp.data.usage);
  }
}

export default EntityTagService;
