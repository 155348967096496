import {
  AutomationStatus,
  DraftAction,
  IRedlineId,
  ProcedureType,
  Reviewer,
  ReviewerAction,
  ReviewerGroup,
  DraftState as SharedDraftState,
  Participant as SharedParticipant,
  ProcedureMetadata as SharedProcedureMetadata,
  ProcedureState as SharedProcedureState,
  ReleaseState as SharedReleaseState,
  RunMetadata as SharedRunMetadata,
  RunState as SharedRunState,
  RunStatus as SharedRunStatus,
  StepState as SharedStepState,
  StepDuration,
  StepTiming,
} from '../couch/procedures';
import { RunTag, Tag } from '../couch/settings';
import { OperationId } from '../operations';
import { Severity, Status } from '../postgres/issues';
import {
  ComponentPart,
  Part,
  TrackingType,
  UsageType,
} from '../postgres/manufacturing/types';
import { Mention } from '../postgres/util';
import { TelemetryType } from '../postgres/telemetry';
import { ReviewType } from '../settings';
import { Case } from '../testing';
import { Overwrite } from '../utilTypes';
import {
  RequirementIntegrationDetails,
  RequirementIntegrationDetailsDiff,
} from '../api/integrations/integrations';
import { SupportedOperation } from '../../math';
import { BaseBlockContent } from '../blockTypes';

/*
 * =============================================================================
 * Metadata types
 * =============================================================================
 */
export type ProcedureMetadata = SharedProcedureMetadata;

export type RunMetadata = SharedRunMetadata;
export type LinkedRunsGraphNode = RunMetadata & {
  linked_runs: LinkedRunsGraphNode[];
};

/*
 * =============================================================================
 * Misc Types
 * =============================================================================
 */
export type Approval = {
  user_id: string;
  approved_at: string;
};

export type PrintContent = {
  text?: string;
  variable_id?: string;
};

export type PrintSettings = {
  header?: PrintContent;
  footer?: PrintContent;
};

export type Settings = {
  print_settings?: PrintSettings;
};

export type SettingsDiff = WithDiffChange<{
  print_settings?: PrintSettingsDiff;
}>;

export type PrintSettingsDiff = {
  header?: PrintContentDiff;
  header__added?: PrintContent;
  header__deleted?: PrintContent;

  footer?: PrintContentDiff;
  footer__added?: PrintContent;
  footer__deleted?: PrintContent;
};

export type PrintContentDiff = {
  text?: DiffField<string>;
  text__added?: string;
  text__deleted?: string;

  variable_id?: DiffField<string>;
  variable_id__added?: string;
  variable_id__deleted?: string;
};

/*
 * =============================================================================
 * Procedure types
 * =============================================================================
 */
export type ProcedureSettings = {
  automation_enabled?: boolean;
  is_strict_signoff_enabled?: boolean;
} & Omit<StepSettings, 'runAsBatch'>;

export type Draft = {
  _id: string;
  _rev?: string;
  procedure_id?: string;
  project_id?: string;
  code: string;
  name: string;
  variables?: Array<DraftVariable>;
  risks?: Array<ProcedureRisk>;
  part_list?: PartList;
  headers?: Array<DraftHeader>;
  version?: string;
  description: string;
  archived?: boolean;
  state?: DraftState;
  sections: Array<DraftSection>;
  review_type?: Pick<ReviewType, 'id' | 'name'>;
  reviewer_groups?: Array<ReviewerGroup>;
  reviewer_actions_history?: Array<ReviewerAction>;
  locked_by?: string;
  locked_at?: string;
  redline_actions?: Array<RedlineAction>;
  editedAt?: string;
  editedUserId?: string;
  created_at?: string;
  is_ai_generated?: boolean;
  owner?: string;
  procedure_rev_num?: number;
  release_note?: ReleaseNote;
  comments?: Array<DraftRedlineComment | ReviewComment>;
  approvals?: Array<Approval>;
  tags?: Array<Tag>;
  settings?: Settings;
  reviewers?: Array<Reviewer>;
  end_run_signoffs_groups?: Array<EndRunSignoff>;
  start_run_signoffs_groups?: Array<StartRunSignoff>;
  default_view_mode?: string;
  dictionary_id?: number;
  procedure_type?: ProcedureType;
  actions?: Array<DraftAction | ReviewerAction>;
  initial_rev_num?: number;
  test_case_list?: TestCaseList;
  auto_procedure_id_enabled?: boolean;
} & ProcedureSettings;

export type DraftRevision = Draft & {
  deleted?: boolean;
  deletedAt?: string;
  deletedBy?: string;
};

export type DraftState = SharedDraftState;

export type RedlineAction = {
  redline_id: string;
  state: 'accepted' | 'rejected' | 'resolved' | 'unresolved';
  resolved_at: string;
  resolved_by: string;
  resolution_procedure_rev_num?: number;
  release_procedure_rev_num?: number;
};

export type Release = {
  _id: string;
  _rev?: string;
  procedure_id?: string;
  project_id?: string;
  procedure_rev_num?: number;
  code: string;
  name: string;
  variables?: Array<ReleaseVariable>;
  risks?: Array<ProcedureRisk>;
  part_list?: PartList;
  headers?: Array<ReleaseHeader>;
  version?: string;
  description: string;
  archived?: boolean;
  state?: ReleaseState;
  sections: Array<ReleaseSection>;
  review_type?: Pick<ReviewType, 'id' | 'name'>;
  reviewer_groups?: Array<ReviewerGroup>;
  reviewer_actions_history?: Array<ReviewerAction>;
  editedAt?: string;
  editedUserId?: string;
  created_at?: string;
  is_ai_generated?: boolean;
  owner?: string;
  release_note?: string;
  comments?: Array<ReviewComment>;
  approvals?: Array<Approval>;
  tags?: Array<Tag>;
  settings?: Settings;
  reviewers?: Array<Reviewer>;
  default_view_mode?: string;
  end_run_signoffs_groups?: Array<EndRunSignoff>;
  start_run_signoffs_groups?: Array<StartRunSignoff>;
  dictionary_id?: string;
  procedure_type?: ProcedureType;
  test_case_list?: TestCaseList;
  auto_procedure_id_enabled?: boolean;
} & ProcedureSettings;

export type ReleaseRevision = Release & {
  deleted?: boolean;
  deletedAt?: string;
  deletedBy?: string;
};

export type ReleaseState = SharedReleaseState;

export type ProcedurePrimitive = string | number | boolean | undefined | null;

export type DiffField<T extends ProcedurePrimitive> = T | DiffFieldChange<T>;

export type DiffFieldChange<T extends ProcedurePrimitive> = {
  __old: T;
  __new: T;
};

export type DiffArrayChangeSymbol = '+' | '-' | '~' | ' ';
// TODO the correct type of this is `[DiffArrayChangeSymbol, T]`
type DiffArrayElement<T> = Array<DiffArrayChangeSymbol | T>;
export type ProcedureDiff = {
  _id: DiffField<string>;
  code: DiffField<string>;
  name: DiffField<string>;
  version?: DiffField<string>;
  description: DiffField<string>;
  state?: DiffField<ReleaseState | DraftState>;
  variables?: Array<VariableDiff> | Array<VariableDiffElement>;
  variables__previous?: Array<ReleaseVariable>;
  headers?: Array<HeaderDiff> | Array<HeaderDiffElement>;
  headers__added?: Array<Header>;
  headers__deleted?: Array<Header>;
  part_list?: PartList;
  sections: Array<SectionDiff> | Array<SectionDiffElement>;
  editedAt?: string;
  editedUserId?: string;
  reviewer_groups?: Array<ReviewerGroup>;
  reviewer_actions_history?: Array<ReviewerAction>;
  release_note?: ReleaseNote;
  release_note__added?: ReleaseNote;
  release_note__deleted?: ReleaseNote;
  comments?: Array<ReviewComment>;
  end_run_signoffs_groups?:
    | Array<EndRunSignoffsGroupsDiff>
    | Array<EndRunSignoffsGroupsDiffElement>;
  end_run_signoffs_groups__added?: Array<EndRunSignoff>;
  end_run_signoffs_groups__deleted?: Array<EndRunSignoff>;
  tags?: Array<TagDiff> | Array<TagDiffElement>;
  tags__added?: Array<Tag>;
  tags__deleted?: Array<Tag>;
  settings?: SettingsDiff;
  settings__added?: Settings;
  settings__deleted?: Settings;
  owner?: DiffField<string>;
  owner__added?: string;
  owner__deleted?: string;
  project_id?: DiffField<string>;
  project_id__added?: string;
  project_id__deleted?: string;
  default_view_mode?: DiffField<string>;
  default_view_mode__added?: string;
  default_view_mode__deleted?: string;

  // TODO: Add properties as needed.
  risks?: Array<ProcedureRisk>;
  test_case_list?: WithDiffChange<TestCaseList>;
};

export type Diffable =
  | EndRunSignoff
  | Tag
  | Variable
  | Header
  | Section
  | SectionHeader
  | Step
  | StepBlock
  | Dependency
  | Conditional
  | Signoff
  | JumpToBlock
  | FieldInputBlock
  | Rule
  | Range
  | ExpressionToken;
export type DiffableDiff =
  | EndRunSignoffsGroupsDiff
  | TagDiff
  | VariableDiff
  | HeaderDiff
  | SectionDiff
  | SectionHeaderDiff
  | StepDiff
  | StepBlockDiff
  | HeaderBlockDiff
  | StepHeaderDiff
  | DependencyDiff
  | ConditionalDiff
  | SignoffDiff
  | FieldsDiff
  | ExpressionTokenDiff;
export type DiffElement =
  | EndRunSignoffsGroupsDiffElement
  | TagDiffElement
  | VariableDiffElement
  | HeaderDiffElement
  | SectionDiffElement
  | StepDiffElement
  | StepBlockDiffElement
  | HeaderBlockDiffElement
  | StepHeaderDiffElement
  | DependencyDiffElement
  | ConditionalDiffElement
  | SignoffDiffElement
  | JumpToBlockDiffElement
  | FieldInputBlockDiffElement
  | RuleDiffElement
  | RangeDiffElement
  | FieldInputTableBlockDiffElement;

export interface WithDiffChangeI {
  diff_change_state?: DiffArrayChangeSymbol;
}
export type WithDiffChange<T> = T & {
  diff_change_state?: DiffArrayChangeSymbol;
};
export type EndRunSignoffsGroupsDiff =
  DiffArrayElement<EndRunSignoffsGroupsDiffElement>;
export type EndRunSignoffsGroupsDiffElement = WithDiffChange<{
  id: string;
  operators: Array<DiffArrayElement<string>>;
}>;

export type TagDiff = DiffArrayElement<TagDiffElement>;
export type TagDiffElement = WithDiffChange<Tag>;

export type VariableDiff = DiffArrayElement<Variable>;
export type VariableDiffElement = WithDiffChange<Variable>;

export type HeaderDiff = DiffArrayElement<HeaderDiffElement>;
export type HeaderDiffElement = WithDiffChange<
  | Header
  | {
      id: DiffField<string>;
      name: DiffField<string>;
      content: Array<HeaderBlockDiff> | Array<HeaderBlockDiffElement>;
    }
>;

export type SectionDiff = DiffArrayElement<SectionDiffElement>;

export type SectionDiffElement = WithDiffChange<
  | Section
  | {
      id: DiffField<string>;
      name: DiffField<string>;
      headers?: Array<SectionHeaderDiff> | Array<SectionHeaderDiffElement>;
      steps: Array<StepDiff> | Array<StepDiffElement>;
      dependencies?: Array<DependencyDiff> | Array<DependencyDiffElement>;
      // TODO: Add properties as needed.
    }
>;

export type HeaderBlockDiff = DiffArrayElement<HeaderBlockDiffElement>;
export type HeaderBlockDiffElement =
  | AlertBlockDiffElement
  | AttachmentBlockDiffElement
  | TextBlockDiffElement
  | TableInputBlockDiffElement;

export type SectionHeaderDiff = DiffArrayElement<SectionHeaderDiffElement>;
export type SectionHeaderDiffElement = WithDiffChange<
  | SectionHeader
  | {
      id: DiffField<string>;
      name: DiffField<string>;
      content: Array<HeaderBlockDiff> | Array<HeaderBlockDiffElement>;
      redlines?: Array<DraftHeaderRedlinedBlock | RunHeaderRedline>;
    }
>;

export type StepHeaderBlockDiff = DiffArrayElement<StepHeaderBlockDiffElement>;
export type StepHeaderBlockDiffElement =
  | WithDiffChange<StepHeaderBlock>
  | AlertBlockDiffElement
  | TextBlockDiffElement;

export type StepHeaderDiff = DiffArrayElement<StepHeaderDiffElement>;
export type StepHeaderDiffElement = WithDiffChange<
  | StepHeader
  | {
      id: DiffField<string>;
      name: DiffField<string>;
      content: Array<StepHeaderBlockDiff> | Array<StepHeaderBlockDiffElement>;
    }
>;

export type StepDiff = DiffArrayElement<StepDiffElement>;

export type StepDiffElement = WithDiffChange<
  | Step
  | {
      id: DiffField<string>;
      name: DiffField<string>;
      content: Array<StepBlockDiff> | Array<StepBlockDiffElement>;
      dependencies: Array<DependencyDiff> | Array<DependencyDiffElement>;
      conditionals?: Array<ConditionalDiff> | Array<ConditionalDiffElement>;
      signoffs: Array<SignoffDiff> | Array<SignoffDiffElement>;
      headers?: Array<StepHeaderDiff> | Array<StepHeaderDiffElement>;
      // Will add properties here as needed.
    }
>;

export type SignoffDiff = DiffArrayElement<SignoffDiffElement>;
export type SignoffDiffElement = WithDiffChange<{
  id: string;
  operators: Array<DiffField<string>>;
}>;

export type StepBlockDiff = DiffArrayElement<StepBlockDiffElement>;
export type StepBlockPlaceholder = WithDiffChange<{
  id: string;
  type: 'placeholder';
}>;
export type StepBlockDiffElement =
  | WithDiffChange<StepBlock>
  | AlertBlockDiffElement
  | AttachmentBlockDiffElement
  | TextBlockDiffElement
  | RequirementBlockDiffElement
  | FieldInputBlockDiffElement
  | InputReferenceBlockDiff
  | JumpToBlockDiffElement
  | ExpressionBlockDiffElement
  | TableInputBlockDiffElement
  | ExternalItemBlockDiffElement
  | TelemetryBlockDiffElement
  | FieldInputTableBlockDiffElement
  | StepBlockPlaceholder
  | ReviewToolUsageBlock;

export type DependencyDiff = DiffArrayElement<DependencyDiffElement>;
export type DependencyDiffElement = WithDiffChange<
  Dependency | { id: string; dependent_ids: Array<DiffField<string>> }
>;

export type ConditionalDiff = DiffArrayElement<ConditionalDiffElement>;
export type ConditionalDiffElement =
  | StepConditionalDiffElement
  | FieldInputConditionalDiffElement
  | ContentBinaryConditionalDiffElement
  | ContentTernaryConditionalDiffElement
  | DurationConditionalDiffElement;

export type FieldInputConditionalDiffElement = WithDiffChange<
  | FieldInputConditional
  | {
      id: string;
      source_type: 'content';
      state: DiffField<string>;
      content_id: DiffField<string>;
      source_id: DiffField<string>;
      target_id: DiffField<string>;
      target_type: 'step';
    }
>;

export type ContentBinaryConditionalDiffElement = WithDiffChange<
  | ContentBinaryConditional
  | {
      id: string;
      source_type: 'content_binary';
      state: DiffField<ContentBinaryConditionalState>;
      content_id: DiffField<string>;
      source_id: DiffField<string>;
      target_id: DiffField<string>;
      target_type: 'step';
    }
>;

export type ContentTernaryConditionalDiffElement = WithDiffChange<
  | ContentTernaryConditional
  | {
      id: string;
      source_type: 'content_ternary';
      state: DiffField<ContentTernaryConditionalState>;
      content_id: DiffField<string>;
      source_id: DiffField<string>;
      target_id: DiffField<string>;
      target_type: 'step';
    }
>;

export type StepConditionalDiffElement = WithDiffChange<
  | StepConditional
  | {
      id: string;
      source_type: 'step';
      state: DiffField<StepConditionalState>;
      content_id: null;
      source_id: DiffField<string>;
      target_id: DiffField<string>;
      target_type: 'step';
    }
>;

export type DurationConditionalDiffElement = WithDiffChange<
  | DurationConditional
  | {
      id: string;
      source_type: 'duration';
      state: DiffField<string>;
      content_id: null;
      source_id: DiffField<string>;
      target_id: DiffField<string>;
      target_type: 'step';
    }
>;

export type AlertBlockDiffElement = WithDiffChange<
  | AlertBlock
  | (BaseBlockContent & {
      type: 'alert';
      subtype: DiffField<AlertSubtype>;
      text: DiffField<string>;
      tokens?: ExpressionToken[];
    })
>;

export type AttachmentBlockDiffElement = WithDiffChange<
  | AttachmentBlock
  | (BaseBlockContent & {
      type: 'attachment';
      attachment_id: DiffField<string>;
      name?: DiffField<string>;
      file?: File;
      caption?: DiffField<string>;
      size?: DiffField<'small' | 'best_fit' | 'original'>;
    })
>;

export type TextBlockDiffElement = WithDiffChange<
  | TextBlock
  | (BaseBlockContent & {
      type: 'text';
      text: DiffField<string>;
      tokens?: ExpressionTokenDiffElement[];
    })
>;

export type FieldsDiff = DiffArrayElement<
  | FieldInputTextBlockDiffElement
  | FieldInputNumberBlockDiffElement
  | FieldInputCheckboxBlockDiffElement
  | FieldInputTimestampBlockDiffElement
  | FieldInputCustomListBlockDiffElement
  | FieldInputSettingsListBlockDiffElement
>;

export type FieldInputTableBlockDiffElement = WithDiffChange<
  | FieldInputTableBlock
  | {
      id: DiffField<string>;
      type: 'field_input_table';
      fieldsPerRow: DiffField<number>;
      include_in_summary?: boolean;
      fields:
        | Array<FieldsDiff>
        | Array<
            | FieldInputTextBlockDiffElement
            | FieldInputNumberBlockDiffElement
            | FieldInputCheckboxBlockDiffElement
            | FieldInputTimestampBlockDiffElement
            | FieldInputCustomListBlockDiffElement
            | FieldInputSettingsListBlockDiffElement
          >;
    }
>;

export type RequirementBlockDiffElement = WithDiffChange<
  | RequirementBlock
  | (BaseBlockContent & {
      type: 'requirement';
      requirement_id: DiffField<string>;
      label: DiffField<string>;
      metadata: object;
      subType: DiffField<RequirementSubType>;
      integrationDetails?: RequirementIntegrationDetailsDiff;
    })
>;

export type JumpToBlockDiffElement = WithDiffChange<
  | JumpToBlock
  | (BaseBlockContent & { type: 'jump_to'; jumpToId: DiffField<string> })
>;

export enum ParentReferenceType {
  Run = 'run',
  Issue = 'issue',
  Risk = 'risk',
  Event = 'event',
  Operation = 'operation',
  WorkOrder = 'work_order',
}

export type RunParentReference = {
  id: string | number;
  type: ParentReferenceType;
};

export type Run = {
  _id: string;
  _rev?: string;
  name: string;
  procedure_id?: string;
  run_number?: number;
  project_id?: string;
  headers?: Array<RunHeader>;
  code: string;
  variables?: Array<RunVariable>;
  risks?: Array<ProcedureRisk>;
  part_list?: PartList;
  version?: string;
  description: string;
  owner?: string;
  editedAt?: string;
  editedUserId?: string;
  starttime: string;
  state?: RunState;
  status?: RunStatus;
  procedureRev?: string;
  sections: Array<RunSection | RepeatedSection>;
  participants?: Array<Participant>;
  archived?: boolean;
  actions?: Array<RunAction>;
  reviewer_actions_history?: Array<ReviewerAction>;
  reviewer_groups?: Array<ReviewerGroup>;
  review_type?: ReviewType;
  operation?: OperationId;
  tags?: Array<Tag>;
  run_tags?: Array<RunTag>;
  global_procedure_tag_ids?: Array<string>;
  global_run_tag_ids?: Array<string>;
  run_section?: string;
  completedAt?: string;
  completedUserId?: string;
  procedure_rev_num?: number;
  approvals?: Array<Approval>;
  settings?: Settings;
  reviewers?: Array<Reviewer>;
  default_view_mode?: string;
  source_run?: string;
  parent_reference?: RunParentReference;
  started_by?: StartedBy;
  issues?: Record<string, RunIssue>; // Used only for JIRA issues
  /** @todo - should be `procedure_id` */
  procedure?: string;
  end_run_signoffs_groups?: Array<EndRunSignoff>;
  start_run_signoffs_groups?: Array<StartRunSignoff>;
  dictionary_id?: string;
  comments?: Array<ReviewComment>;
  operators?: Array<string>;
  procedure_type?: ProcedureType;
  automation_status?: AutomationStatus;
  test_case_list?: TestCaseList;
  auto_procedure_id_enabled?: boolean;
} & ProcedureSettings;

export type RunState = SharedRunState;

export type StepState = SharedStepState;

export type RunStatus = SharedRunStatus;

export type PauseAction = {
  timestamp: string;
  user_id: string;
  type: 'pause';
  comment: string;
};

export type ResumeAction = {
  timestamp: string;
  user_id: string;
  type: 'resume';
};

export type ChangeOperationAction = {
  timestamp: string;
  user_id: string;
  type: 'change operation';
  comment: string;
};

export type ReopenAction = {
  timestamp: string;
  user_id: string;
  type: 'reopen';
  comment: string;
};

export type RunAction =
  | PauseAction
  | ResumeAction
  | ChangeOperationAction
  | ReopenAction;

export type Participant = SharedParticipant;

type StartedBy = {
  method: 'web' | 'api';
  user_id: string;
};

export type ProcedureState = SharedProcedureState;

export type Procedure = Draft | Release | Run;

/*
 * =============================================================================
 * Variable types
 * =============================================================================
 */

export type LegacyDraftVariable = {
  name: string;
  type: 'input';
  input_type?: 'text' | 'number' | 'checkbox';
};

export type LegacyReleaseVariable = {
  name: string;
  type: 'input';
  input_type?: 'text' | 'number' | 'checkbox';
};

export type LegacyRunVariable = {
  name: string;
  type: 'input';
  input_type?: 'text' | 'number' | 'checkbox';
  value?: string | number | boolean;
};

export type LegacyVariable =
  | LegacyDraftVariable
  | LegacyReleaseVariable
  | LegacyRunVariable;

export type V2DraftVariable = DraftFieldInputBlock & {
  version: 2;
  dateTimeType: 'date' | 'time' | 'datetime'; // for legacy reasons
};

export type V2ReleaseVariable = ReleaseFieldInputBlock & {
  version: 2;
};

export type V2RunVariableTextBlock = Omit<
  RunFieldInputTextBlock,
  'recorded'
> & {
  version: 2;
  value?: string;
};

export type V2RunVariableNumberBlock = Omit<
  RunFieldInputNumberBlock,
  'recorded'
> & {
  version: 2;
  value?: number;
};

export type V2RunVariableCheckboxBlock = Omit<
  RunFieldInputCheckboxBlock,
  'recorded'
> & {
  version: 2;
  value?: boolean;
};

export type V2RunVariableTimestampBlock = Omit<
  RunFieldInputTimestampBlock,
  'recorded'
> & {
  version: 2;
  value?: string;
};

export type V2RunVariableAttachmentBlock = Omit<
  RunFieldInputAttachmentBlock,
  'recorded'
> & {
  version: 2;
  value?: AttachmentValue;
};

export type V2RunVariableSketchBlock = Omit<
  RunFieldInputSketchBlock,
  'recorded'
> & {
  version: 2;
  value?: SketchValue;
};

export type V2RunVariableSettingsListBlock = Omit<
  RunFieldInputSettingsListBlock,
  'recorded'
> & {
  version: 2;
  value?: string;
};

export type V2RunVariableCustomListBlock = Omit<
  RunFieldInputCustomListBlock,
  'recorded'
> & {
  version: 2;
  value?: string;
};

export type V2RunVariableExternalDataBlock = Omit<
  RunFieldInputExternalDataBlock,
  'recorded'
> & {
  version: 2;
  value?: ExternalDataValue;
};

export type V2RunVariableExternalSearchBlock = Omit<
  RunFieldInputExternalSearchBlock,
  'recorded'
> & {
  version: 2;
  value?: ExternalDataValue;
};

export type V2RunVariableMultipleChoiceBlock = Omit<
  RunFieldInputMultipleChoiceBlock,
  'recorded'
> & {
  version: 2;
  value?: string;
};

export type V2RunVariable =
  | V2RunVariableTextBlock
  | V2RunVariableNumberBlock
  | V2RunVariableCheckboxBlock
  | V2RunVariableTimestampBlock
  | V2RunVariableAttachmentBlock
  | V2RunVariableSketchBlock
  | V2RunVariableSettingsListBlock
  | V2RunVariableCustomListBlock
  | V2RunVariableExternalDataBlock
  | V2RunVariableExternalSearchBlock
  | V2RunVariableMultipleChoiceBlock;

// For backwards compatibility to connected clients
export type RunVariableDataTransferObject = {
  name: string;
  input_type?: FieldInputType;
  value?: RunFieldInputRecordedValue;
};

export type V2Variable = V2DraftVariable | V2ReleaseVariable | V2RunVariable;

export type DraftVariable = LegacyDraftVariable | V2DraftVariable;
export type ReleaseVariable = LegacyReleaseVariable | V2ReleaseVariable;
export type RunVariable = LegacyRunVariable | V2RunVariable;

export type Variable = DraftVariable | ReleaseVariable | RunVariable;

/*
 * =============================================================================
 * Risk types
 * =============================================================================
 */

export type ProcedureRisk = {
  id: number;
  title: string;
};

/*
 * =============================================================================
 * Part types
 * =============================================================================
 */

export type PartList = {
  id: string;
  type: 'part_list';
  part_id: string | null;
  part: PartRevisionObject | null;
  items: PartListItem[];
  original_id?: string;
  added?: boolean;
};

export type PartRevisionObject = Pick<Part, 'rev'>;
export type PartListItem = Pick<
  ComponentPart,
  'part_id' | 'revision_id' | 'amount'
> & {
  id: string;
};

/*
 * =============================================================================
 * TCM types
 * =============================================================================
 */

export type TestCaseList = {
  id: string;
  items: string[];
};

/*
 * =============================================================================
 * Header types
 * =============================================================================
 */
export type DraftHeader = {
  id: string;
  name: string;
  content: Array<DraftHeaderBlock>;
  header_field_redlines?: {
    [field: string]: Array<DraftHeaderFieldRedline>;
  };
};

export type ReleaseHeader = {
  id: string;
  name: string;
  content: Array<ReleaseHeaderBlock>;
};

export type RunHeader = {
  id: string;
  name: string;
  content: Array<RunHeaderBlock>;
  redlines?: Array<RunHeaderRedline>;
};

export type Header = DraftHeader | ReleaseHeader | RunHeader;

export type RedlinedHeader = ReleaseHeader;

export type HeaderRedlineMetadata = { contentId: string } | { field: 'name' };

/*
 * =============================================================================
 * Section types
 * =============================================================================
 */
export type DraftSection = {
  id: string;
  name: string;
  headers?: Array<DraftSectionHeader>;
  steps: Array<DraftStep | DraftAddedStep>;
  snippet_id?: string;
  snippet_name?: string;
  snippet_rev?: number;
  snippet_ids_map?: Record<string, string>;
  shows_snippet_detached_message?: boolean;
  dependencies?: Array<Dependency>;
};

export type ReleaseSection = {
  id: string;
  name: string;
  headers?: Array<ReleaseSectionHeader>;
  steps: Array<ReleaseStep>;
  snippet_id?: string;
  snippet_name?: string;
  snippet_rev?: number;
  snippet_ids_map?: Record<string, string>;
  dependencies?: Array<Dependency>;
};

export type RunSection = {
  id: string;
  name: string;
  headers?: Array<RunSectionHeader>;
  steps: Array<RunStep | RunAddedStep | RepeatedStep>;
  snippet_id?: string;
  snippet_name?: string;
  snippet_rev?: number;
  snippet_ids_map?: Record<string, string>;
  dependencies?: Array<Dependency>;
};

export type RepeatedSection = RunSection & {
  repeat_of: string;
  repeated_at: string;
  repeated_user_id: string;
};

export type Section = DraftSection | ReleaseSection | RunSection;

/*
 * =============================================================================
 * Section header types
 * =============================================================================
 */
export type DraftSectionHeader = {
  id: string;
  name: string;
  content: Array<DraftSectionHeaderBlock>;
  redlines?: Array<DraftHeaderRedlinedBlock | RunHeaderRedline>;
};

export type ReleaseSectionHeader = {
  id: string;
  name: string;
  content: Array<ReleaseSectionHeaderBlock>;
  redlines?: Array<DraftHeaderRedlinedBlock | RunHeaderRedline>;
};

export type RunSectionHeader = {
  id: string;
  name: string;
  content: Array<RunSectionHeaderBlock>;
  redlines?: Array<RunHeaderRedline>;
};

export type SectionHeader =
  | DraftSectionHeader
  | ReleaseSectionHeader
  | RunSectionHeader;

/*
 * =============================================================================
 * Step types
 * =============================================================================
 */
export type Signoff = {
  id: string;
  operators: Array<string>;
};

export type EndRunSignoff = {
  id: string;
  operators: Array<string>;
};

export type StartRunSignoff = {
  id: string;
  operators: Array<string>;
};

export type Dependency = {
  id: string;
  dependent_ids: Array<string>;
};

export type SourceConditionalsMap = {
  [stepId: string]: { [conditionalId: string]: StepConditionalDiffElement };
};

export type SourceType =
  | 'step'
  | 'content'
  | 'content_binary'
  | 'content_ternary'
  | 'duration';

export type StepConditional = {
  id: string;
  source_type: 'step';
  state: StepConditionalState;
  content_id?: null;
  source_id: string;
  target_id: string;
  target_type: 'step';
};

export type StepConditionalState = 'completed' | 'failed';

export type FieldInputConditional = {
  id: string;
  source_type: 'content';
  state: string;
  content_id: string;
  source_id: string;
  target_id: string;
  target_type: 'step';
};

export type ContentBinaryConditionalState = 'pass' | 'fail';

export type ContentBinaryConditional = {
  id: string;
  source_type: 'content_binary';
  state: ContentBinaryConditionalState;
  content_id: string;
  source_id: string;
  target_id: string;
  target_type: 'step';
};

export type ContentTernaryConditionalState =
  | 'pass'
  | 'fail_high'
  | 'fail_low'
  | 'fail_no_data';

export type ContentTernaryConditional = {
  id: string;
  source_type: 'content_ternary';
  state: ContentTernaryConditionalState;
  content_id: string;
  source_id: string;
  target_id: string;
  target_type: 'step';
};

export type DurationConditional = {
  id: string;
  source_type: 'duration';
  state: string;
  content_id: null;
  source_id: string;
  target_id: string;
  target_type: 'step';
};

export type Conditional =
  | StepConditional
  | FieldInputConditional
  | ContentBinaryConditional
  | ContentTernaryConditional
  | DurationConditional;

export type StepEndAction = {
  type: 'complete' | 'fail' | 'skip';
  user_id: string;
  timestamp: string;
  conditional_value?: ConditionalValue;
};

export type StepSignoffAction = {
  type: 'signoff';
  user_id: string;
  timestamp: string;
  conditional_value?: ConditionalValue;
  signoff_id: string;
  operator: string;
  device_user_id?: string;
};

export type StepRevokeSignoffAction = {
  type: 'revoke_signoff';
  user_id: string;
  timestamp: string;
  signoff_id: string;
  revoked_user_id: string;
  revoked_operator: string;
  revoked_conditional_value?: ConditionalValue;
};

export type StepIssueAction = {
  type: 'issue';
  user_id: string;
  timestamp: string;
  issue_id: string;
};

export type StepAutomationPauseAction = {
  type: 'automation_pause';
  user_id: string;
  timestamp: string;
};

export type StepAddedAction = {
  type: 'step added';
  user_id: string;
  timestamp: string;
};

export type SuggestedEditAddedAction = {
  type: 'redline_added' | 'blueline_added';
  redline_id?: string;
  user_id: string;
  timestamp: string;
};

export type SuggestedEditIncludedAction = {
  type: 'redline_included' | 'blueline_included';
  redline_id?: string;
  user_id: string;
  timestamp: string;
};

export type ConditionalValue = string;

export type StepAction =
  | StepSignoffAction
  | StepRevokeSignoffAction
  | StepEndAction
  | StepIssueAction
  | StepAutomationPauseAction
  | StepAddedAction
  | SuggestedEditAddedAction
  | SuggestedEditIncludedAction;

export type BatchStepProps = {
  batchSize: number;
  index: number;
  batchId: string;
  instanceId: string;
};

export type DraftStep = {
  id: string;
  name: string;
  type?: string;
  headers?: Array<DraftStepHeader>;
  timing?: string;
  timer?: StepTiming;
  duration?: StepDuration;
  expected_duration?: string;
  location?: string;
  channel?: string;
  content: Array<DraftStepBlock>;
  signoffs: Array<Signoff>;
  dependencies?: Array<Dependency>;
  conditionals?: Array<Conditional>;
  actions?: Array<StepAction>;
  step_field_redlines?: {
    [field: string]: Array<DraftStepFieldRedline>;
  };
  shows_snippet_detached_message?: boolean;
  snippet_id?: string;
  snippet_name?: string;
  snippet_rev?: number;
  snippet_ids_map?: Record<string, string>;
  redline_id?: string;
  precedingStepId?: string;
  automation_status?: AutomationStatus;
  /** @deprecated */
  requires_previous?: boolean;
  created_during_run?: boolean;
} & StepSettings;

export type ReleaseStep = {
  id: string;
  name: string;
  type?: string;
  headers?: Array<ReleaseStepHeader>;
  timing?: string;
  timer?: StepTiming;
  duration?: StepDuration;
  expected_duration?: string;
  location?: string;
  channel?: string;
  content: Array<ReleaseStepBlock>;
  signoffs: Array<Signoff>;
  dependencies?: Array<Dependency>;
  conditionals?: Array<Conditional>;
  actions?: Array<StepAction>;
  snippet_id?: string;
  snippet_name?: string;
  snippet_rev?: number;
  snippet_ids_map?: Record<string, string>;
  precedingStepId?: string;
  automation_status?: AutomationStatus;
  /** @deprecated */
  requires_previous?: boolean;
  batchProps?: BatchStepProps;
} & StepSettings;

export type StepSettings = {
  [setting in
    | 'skip_step_enabled'
    | 'repeat_step_enabled'
    | 'step_suggest_edits_enabled'
    | 'runAsBatch']?: boolean | undefined;
};

export type RunStep = {
  id: string;
  run_id?: string;
  name: string;
  type?: string;
  /*
   * The state property, if it exists, on any type of run step can only be failed. Completed steps have a completed property, and skipped steps have a skipped property.
   * The state property is meant to be a move towards consolidating these various properties into one property.
   */
  state?: 'failed' | 'paused';
  headers?: Array<RunStepHeader>;
  content: Array<RunStepBlock>;
  dependencies?: Array<Dependency>;
  signoffs: Array<Signoff>;
  conditionals?: Array<Conditional>;
  actions?: Array<StepAction>;
  completed?: true;
  completedAt?: string;
  completedUserId?: string;
  skipped?: true;
  skippedAt?: string;
  skippedUserId?: string;
  snippet_id?: string;
  snippet_name?: string;
  snippet_rev?: number;
  snippet_ids_map?: Record<string, string>;
  timing?: string;
  timer?: StepTiming;
  duration?: StepDuration;
  expected_duration?: string;
  location?: string;
  channel?: string;
  redline_comments?: Array<RunRedlineComment>;
  redlines?: Array<RunStepRedline>;
  comments?: Array<RunStepComment>;
  shows_snippet_detached_message?: boolean;
  precedingStepId?: string;
  automation_status?: AutomationStatus;
  /** @deprecated */
  requires_previous?: boolean;
  batchProps?: BatchStepProps;
  redline_id?: string;
} & StepSettings;

export type RepeatedStep = RunStep & {
  repeat_of: string;
  repeated_at: string;
  repeated_user_id: string;
};

export type RunStepComment = {
  text: string;
  timestamp: string;
  updated_at?: string;
  user: string;
  id: string;
  parent_id: string;
  attachment?: AttachmentValue;
  attachments?: Array<AttachmentValue>;
  mention_list?: Array<Mention>;
  reference_id?: string;
};

export enum IssueType {
  Jira,
  Internal,
}

export enum SignoffType {
  Start = 'start',
  End = 'end',
}

export type RunIssue = {
  id: string | number; // JIRA uses strings, NCR uses number
  text: string;
  url?: string;
  type: IssueType;
  severity?: Severity;
  status?: Status;
};

export type Step = DraftStep | ReleaseStep | RunStep;

export type RedlinedStep = Omit<ReleaseStep, 'id'> & { id?: string };

export type DraftAddedStep = {
  id: string;
  name: string;
  headers?: Array<DraftStepHeader>;
  snippet_id?: string;
  snippet_rev?: number;
  snippet_ids_map?: Record<string, string>;
  timing?: string;
  timer?: StepTiming;
  duration?: StepDuration;
  expected_duration?: string;
  location?: string;
  channel?: string;
  content: Array<ReleaseStepBlock>;
  signoffs: Array<Signoff>;
  dependencies?: Array<Dependency>;
  conditionals?: Array<Conditional>;
  actions?: Array<StepAction>;
  redline_id?: string;
  created_during_run: true;
  created_at: string;
  created_by: string;
  orphaned?: boolean;
  moved?: boolean;
  source_run_id?: string;
} & Omit<StepSettings, 'runAsBatch'>;

export type RunAddedStep = {
  id: string;
  name: string;
  content: Array<RunStepBlock>;
  signoffs: Array<Signoff>;
  dependencies?: Array<Dependency>;
  conditionals?: Array<Conditional>;
  actions?: Array<StepAction>;
  completed?: true;
  completedAt?: string;
  completedUserId?: string;
  skipped?: true;
  skippedAt?: string;
  skippedUserId?: string;
  snippet_id?: string;
  snippet_name?: string;
  snippet_rev?: number;
  snippet_ids_map?: Record<string, string>;
  timing?: string;
  timer?: StepTiming;
  duration?: StepDuration;
  expected_duration?: string;
  location?: string;
  channel?: string;
  created_during_run: true;
  created_at: string;
  created_by: string;
  comments?: Array<RunStepComment>;
  /*
   * The state property, if it exists, on any type of run step can only be failed. Completed steps have a completed property, and skipped steps have a skipped property.
   * The state property is meant to be a move towards consolidating these various properties into one property.
   */
  state?: 'failed';
  run_only?: boolean;
  redlines?: Array<RunStepRedline>;
  previous?: Array<RunAddedStep>;
  /** @deprecated */
  requires_previous?: boolean;
  /** @deprecated */
  redline_comments?: Array<RunRedlineComment>;
} & IRedlineId &
  Omit<StepSettings, 'runAsBatch'>;

export type RunModifiedStep = RunStep & IRedlineId;

export type AddedStep = DraftAddedStep | RunAddedStep;

/*
 * =============================================================================
 * Step header types
 * =============================================================================
 */
export type DraftStepHeader = {
  id: string;
  name: string;
  content: Array<RunStepHeaderBlock>;
};

export type ReleaseStepHeader = {
  id: string;
  name: string;
  content: Array<ReleaseStepHeaderBlock>;
};

export type RunStepHeader = {
  id: string;
  name: string;
  content: Array<RunStepHeaderBlock>;
};

export type StepHeader = DraftStepHeader | ReleaseStepHeader | RunStepHeader;

/*
 * =============================================================================
 * Util types
 * =============================================================================
 */
export type RepeatedSectionOrStep = RepeatedSection | RepeatedStep;

export type SnippetRef = {
  snippet_id?: string;
  snippet_name?: string;
  snippet_rev?: number;
  snippet_ids_map?: Record<string, string>;
  deleted?: boolean;
  created_by?: string;
};

/*
 * =============================================================================
 * Comment types
 * =============================================================================
 */
export type ReleaseNote = {
  user_id: string;
  timestamp: string;
  text: string;
};

export type DraftRedlineComment = {
  id: string;
  reference_id: string;
  redline_id?: string;
  text: string;
  user_id: string;
  created_at: string;
  updated_at?: string;
  type?: 'redline_comment';
  source_run_id: string;
  resolved?: boolean;
  resolved_by?: string;
  resolved_at?: string;
  resolution_procedure_rev_num?: number;
};

export type ReviewComment = {
  id: string;
  parent_id?: string;
  reference_id: string;
  text: string;
  user_id: string;
  created_at: string;
  updated_at?: string;
  type?: 'review_comment';
  resolved?: boolean;
  resolved_by?: string;
  resolved_at?: string;
  resolution_procedure_rev_num?: number;
  mention_list?: Array<Mention>;
};

export type RunRedlineComment = {
  id: string;
  text: string;
  user_id: string;
  created_at: string;
  updated_at?: string;
  redline_id?: string;
  /** @deprecated */
  redlineId?: string;
};

/*
 * =============================================================================
 * Redline types
 * =============================================================================
 */
export type DraftHeaderFieldRedline = {
  redline_id?: string;
  created_at?: string;
  user_id?: string;
  redlineIndex: number;
  name: string;
  /** @deprecated */
  redlineId?: string;
  /** @deprecated */
  createdAt?: string;
  /** @deprecated */
  userId?: string;
};

export type BlockRedline<
  BlockT extends DraftStepRedlinedBlock | DraftHeaderRedlinedBlock
> = {
  block: BlockT;
};

export type DraftStepFieldRedline = {
  redline_id?: string;
  created_at?: string;
  user_id?: string;
  redlineIndex: number;
  name: string;
  /** @deprecated */
  redlineId?: string;
  /** @deprecated */
  createdAt?: string;
  /** @deprecated */
  userId?: string;
};

export type DraftBlockRedline = DraftHeaderBlockRedline | DraftStepBlockRedline;
export type DraftStepRedlinedBlock =
  | ReleaseAlertBlock
  | ReleaseFieldInputBlock
  | ReleaseTextBlock;
export type DraftStepBlockRedline = BlockRedline<DraftStepRedlinedBlock>;

export type DraftHeaderRedlinedBlock = ReleaseAlertBlock | ReleaseTextBlock;
export type DraftHeaderBlockRedline = BlockRedline<DraftHeaderRedlinedBlock>;

export type RunHeaderFieldRedline = {
  redline_id?: string;
  created_at?: string;
  user_id?: string;
  pending?: boolean;
  header: RedlinedHeader;
  field: 'name';
  run_only?: boolean;
  accepted_by?: string;
  accepted_at?: string;
  /** @deprecated */
  redlineId?: string;
  /** @deprecated */
  createdAt?: string;
  /** @deprecated */
  userId?: string;
};

export type RunHeaderBlockRedline = {
  redline_id?: string;
  created_at?: string;
  user_id?: string;
  content_id: string;
  pending?: boolean;
  header: RedlinedHeader;
  /** @deprecated */
  redlineId?: string;
  run_only?: boolean;
  accepted_by?: string;
  accepted_at?: string;
};

export type RunHeaderRedline = RunHeaderFieldRedline | RunHeaderBlockRedline;

export type RunStepFieldRedline = {
  redline_id?: string;
  created_at?: string;
  user_id?: string;
  acceptedBy?: string;
  acceptedAt?: string;
  pending?: boolean;
  step: RedlinedStep;
  field: 'name';
  /** @deprecated */
  redlineId?: string;
  /** @deprecated */
  createdAt?: string;
  /** @deprecated */
  userId?: string;
  run_only?: boolean;
};

export type RunStepBlockRedline = {
  redline_id?: string;
  created_at?: string;
  user_id?: string;
  acceptedBy?: string;
  acceptedAt?: string;
  pending?: boolean;
  step: RedlinedStep;
  source_content_id?: string;
  /** @deprecated */
  redlineId?: string;
  /** @deprecated */
  createdAt?: string;
  /** @deprecated */
  userId?: string;
  run_only?: boolean;
};

export type RunStepFullRedline = {
  redline_id?: string;
  created_at?: string;
  user_id?: string;
  acceptedBy?: string;
  acceptedAt?: string;
  pending?: boolean;
  step: RedlinedStep;
  run_only?: boolean;
  comments?: Array<RunRedlineComment>;
  is_original?: boolean;
  type: 'full_step_redline';

  /** @deprecated */
  redlineId?: string;
  /** @deprecated */
  createdAt?: string;
  /** @deprecated */
  userId?: string;
};

export type RunStepRedline =
  | RunStepFieldRedline
  | RunStepBlockRedline
  | RunStepFullRedline;

/*
 * =============================================================================
 * Block types
 * =============================================================================
 */
export type DraftHeaderBlock =
  | DraftAlertBlock
  | DraftAttachmentBlock
  | DraftTextBlock
  | DraftTableInputBlock;

export type ReleaseHeaderBlock =
  | ReleaseAlertBlock
  | ReleaseAttachmentBlock
  | ReleaseTextBlock
  | ReleaseTableInputBlock;

export type RunHeaderBlock = RunAlertBlock | RunAttachmentBlock | RunTextBlock;

export type HeaderBlock =
  | DraftHeaderBlock
  | ReleaseHeaderBlock
  | RunHeaderBlock
  | RunTableInputBlock;

export type DraftSectionHeaderBlock = ReleaseHeaderBlock;
export type ReleaseSectionHeaderBlock = ReleaseHeaderBlock;
export type RunSectionHeaderBlock = RunHeaderBlock;

// RunSectionHeaderBlock

// DraftStepHeaderBlock

export type ReleaseStepHeaderBlock = ReleaseAlertBlock | ReleaseTextBlock;

export type RunStepHeaderBlock = RunAlertBlock | RunTextBlock;

export type StepHeaderBlock = ReleaseStepHeaderBlock | RunStepHeaderBlock;

// TODO: Include Part Usage Draft/Release/Run Blocks
export type DraftStepBlock = (
  | DraftAlertBlock
  | DraftAttachmentBlock
  | DraftCommandingBlock
  | DraftExpressionBlock
  | DraftExternalItemBlock
  | DraftFieldInputBlock
  | DraftInputReferenceBlock
  | DraftJumpToBlock
  | DraftProcedureLinkBlock
  | DraftRequirementBlock
  | DraftTableInputBlock
  | DraftTelemetryBlock
  | DraftTextBlock
  | DraftTestCasesBlock
  | DraftPartBuildBlock
  | DraftPartUsageBlock
  | DraftPartKitBlock
  | DraftToolCheckOutBlock
  | DraftToolCheckInBlock
  | DraftInventoryDetailInputBlock
  | DraftFieldInputTableBlock
  | DraftToolUsageBlock
) & {
  original_id?: string;
  added?: boolean;
  orphaned?: boolean;
};

export type ReleaseStepBlock = (
  | ReleaseAlertBlock
  | ReleaseAttachmentBlock
  | ReleaseCommandingBlock
  | ReleaseExpressionBlock
  | ReleaseExternalItemBlock
  | ReleaseFieldInputBlock
  | ReleaseInputReferenceBlock
  | ReleaseJumpToBlock
  | ReleaseProcedureLinkBlock
  | ReleaseRequirementBlock
  | ReleaseTableInputBlock
  | ReleaseTelemetryBlock
  | ReleaseTextBlock
  | ReleaseTestCasesBlock
  | ReleasePartBuildBlock
  | ReleasePartUsageBlock
  | ReleasePartKitBlock
  | ReleaseToolCheckOutBlock
  | ReleaseToolCheckInBlock
  | ReleaseInventoryDetailInputBlock
  | ReleaseFieldInputTableBlock
  | ReleaseToolUsageBlock
) & {
  original_id?: string;
  added?: boolean;
  orphaned?: boolean;
};

export type RunStepBlock = (
  | RunAlertBlock
  | RunAttachmentBlock
  | RunCommandingBlock
  | RunExpressionBlock
  | RunExternalItemBlock
  | RunFieldInputBlock
  | RunInputReferenceBlock
  | RunJumpToBlock
  | RunProcedureLinkBlock
  | RunRequirementBlock
  | RunTableInputBlock
  | RunTelemetryBlock
  | RunTextBlock
  | RunTestCasesBlock
  | RunPartBuildBlock
  | RunPartUsageBlock
  | RunPartKitBlock
  | RunToolCheckOutBlock
  | RunToolCheckInBlock
  | RunInventoryDetailInputBlock
  | RunFieldInputTableBlock
  | RunToolUsageBlock
) & {
  original_id?: string;
  added?: boolean;
  orphaned?: boolean;
};

export type StepBlock = DraftStepBlock | ReleaseStepBlock | RunStepBlock;

export type ContentBlock = HeaderBlock | StepBlock;

export type RunStepBlockWithRecorded =
  | RunCommandingBlock
  | RunFieldInputBlock
  | RunTableInputBlock
  | RunPartKitBlock
  | RunInventoryDetailInputBlock;

export type AttachmentValue = {
  attachment_id: string;
  name: string;
  content_type: string;
};

export type SketchValue = {
  attachment_id?: string;
  name?: string;
  content_type?: string;
  text?: string;
};

export type ExternalDataValue = {
  id: string;
  name: string;
  label?: string;
  url?: string;
  valid?: boolean;
  metadata?: object;
  details?: Array<ExternalDataValueDetails>;
};

export type ExternalDataValueDetails = {
  name: string;
  value: string;
};

export type ExternalSearchDataValue = Overwrite<
  ExternalDataValue,
  { name?: string }
>;

export type TimestampValue = string | TimestampValueV2;

export type TimestampValueV2 = {
  date?: string | null;
  time?: string | null;
  zone?: string;
};

export type DraftBlockRedlines = {
  redlines?: Array<DraftBlockRedline>;
};

export type AlertSubtype = 'note' | 'caution' | 'warning';

export type DraftAlertBlock = BaseBlockContent & {
  type: 'alert';
  subtype: AlertSubtype;
  text: string;
  redlines?: Array<BlockRedline<ReleaseAlertBlock>>;
  tokens?: ExpressionToken[];
};

export type ReleaseAlertBlock = BaseBlockContent & {
  type: 'alert';
  subtype: AlertSubtype;
  text: string;
  tokens?: ExpressionToken[];
};

export type RunAlertBlock = BaseBlockContent & {
  type: 'alert';
  subtype: AlertSubtype;
  text: string;
  tokens?: ExpressionToken[];
  recorded?: {
    value?: string;
  };
};

export type AlertBlock = DraftAlertBlock | ReleaseAlertBlock | RunAlertBlock;

export type DraftAttachmentBlock = BaseBlockContent & {
  type: 'attachment';
  attachment_id?: string;
  name?: string;
  file?: File;
  caption?: string;
  size?: 'small' | 'best_fit' | 'original';
  content_type?: string;
  display_style?: 'inline' | 'to_the_side';
};

export type ReleaseAttachmentBlock = BaseBlockContent & {
  type: 'attachment';
  name?: string;
  file?: File;
  caption?: string;
  attachment_id?: string;
  content_type?: string;
  size?: 'small' | 'best_fit' | 'original';
  display_style?: 'inline' | 'to_the_side';
};

export type RunAttachmentBlock = BaseBlockContent & {
  type: 'attachment';
  name?: string;
  file?: File;
  caption?: string;
  attachment_id?: string;
  content_type?: string;
  size?: 'small' | 'best_fit' | 'original';
  display_style?: 'inline' | 'to_the_side';
};

export type AttachmentBlock =
  | DraftAttachmentBlock
  | ReleaseAttachmentBlock
  | RunAttachmentBlock;

export type DraftCommandingBlock = BaseBlockContent & {
  type: 'commanding';
  key: 'command' | 'COSMOS';
  name?: string;
  // favoring the command id over legacy command name for unique identifier
  command_id?: number;
  dictionary_id?: number;
  arguments?: CommandingBlockArguments;
  include_in_summary?: boolean;
  /** @deprecated */
  cosmos: CosmosCommand;
};

export type ReleaseCommandingBlock = BaseBlockContent & {
  type: 'commanding';
  name?: string;
  // favoring the command id over legacy command name for unique identifier
  command_id?: number;
  dictionary_id?: number;
  key: 'command' | 'COSMOS';
  arguments?: CommandingBlockArguments;
  include_in_summary?: boolean;
  /** @deprecated */
  cosmos: CosmosCommand;
};

type RunCommandingRecorded = Array<CommandingBlockRecorded>;

export type RunCommandingBlock = BaseBlockContent & {
  type: 'commanding';
  name?: string;
  // favoring the command id over legacy command name for unique identifier
  command_id?: number;
  dictionary_id?: number;
  key: 'command' | 'COSMOS';
  recorded?: RunCommandingRecorded;
  arguments?: CommandingBlockArguments;
  include_in_summary?: boolean;
  /** @deprecated */
  cosmos: CosmosCommand;
};

export type CommandingBlockRecorded = {
  results?: CommandingBlockRecordedResults;
  timestamp?: string;
  pendingArguments?: CommandingBlockArguments;
};

export type CommandingBlockRecordedResults = {
  name: string;
  arguments?: Array<Argument> | object | null;
  success: boolean;
  message?: string;
  results: object | null;
  received_at: string;
};

export type Argument = {
  name: string;
  type: 'float' | 'int' | 'string' | 'enum' | 'file';
  values?: Array<object>;
};

export type CommandingBlockArguments = {
  [argument: string]: string | null;
} | null;

export type CommandingBlock =
  | DraftCommandingBlock
  | ReleaseCommandingBlock
  | RunCommandingBlock;

export type CommandingBlockDiffElement = WithDiffChange<
  | CommandingBlock
  | (BaseBlockContent & {
      type: 'commanding';
      name?: DiffField<string>;
      // favoring the command id over legacy command name for unique identifier
      command_id?: DiffField<number>;
      dictionary_id?: DiffField<number>;
      key: DiffField<'command' | 'COSMOS'>;
      arguments?: CommandingBlockArgumentsDiffElement;
      include_in_summary?: boolean;
      /** @deprecated */
      cosmos: CosmosCommand;
    })
>;

export type CommandingBlockArgumentsDiffElement = {
  [argument: string]: DiffField<string | null>;
} | null;

/** @deprecated */
export type CosmosCommand = {
  target: string;
  command: string;
};

export type ExpressionToken = {
  type: 'text' | 'reference' | 'function';
  value: string;
  reference_subtype?: 'number' | 'timestamp';
  timestamp_subtype?: 'date' | 'time' | 'datetime';
  reference_id?: string;
  field_index?: number;
  table_reference?: {
    row_id: string;
    column_id: string;
  };
  function_name?: string;
  function_params?: ExpressionToken[][];
};

export interface DisplayMetadata {
  formattedValue: string;
}

export interface ExpressionFunctionDefinition {
  name: string;
  params: Array<{
    name: string;
    type: 'number' | 'timestamp' | 'any';
  }>;
  evaluate: (args: number[]) => number;
  getDisplayMetadata?: (value: any) => DisplayMetadata;
}

export type ExpressionBlock =
  | DraftExpressionBlock
  | ReleaseExpressionBlock
  | RunExpressionBlock;

export type ExpressionBlockDiffElement = WithDiffChange<ExpressionBlock>;
export type ExpressionBlockDiff = DiffArrayElement<ExpressionBlockDiffElement>;

export type ExpressionTokenDiffElement = WithDiffChange<ExpressionToken>;
export type ExpressionTokenDiff = DiffArrayElement<ExpressionTokenDiffElement>;
export type DraftExpressionBlock = BaseBlockContent & {
  type: 'expression';
  name: string;
  tokens: ExpressionToken[];
  include_in_summary?: boolean;
};

export type ReleaseExpressionBlock = BaseBlockContent & {
  type: 'expression';
  name: string;
  tokens: ExpressionToken[];
  include_in_summary?: boolean;
};

export type ExpressionReferences = {
  [content_id: string]: {
    referenced_section_id: string;
    referenced_step_id: string;
  };
};
export type RunExpressionBlock = BaseBlockContent & {
  type: 'expression';
  name: string;
  tokens: ExpressionToken[];
  include_in_summary?: boolean;
  recorded?: {
    value?: string;
    richDisplay?: string;
    display?: string;
    references?: ExpressionReferences;
    functionName?: string;
  };
};

export type DraftExternalItemBlock = BaseBlockContent & {
  dictionary_id?: number;
  type: 'external_item';
  item_type: string;
  item_id: string;
  item_url?: string;
  item_valid?: boolean;
  item?: {
    id: string;
    name?: string;
    label?: string;
    url?: string;
    valid?: boolean;
  };
};

export type ReleaseExternalItemBlock = BaseBlockContent & {
  dictionary_id?: number;
  type: 'external_item';
  item_type: string;
  item_id: string;
  item?: {
    id: string;
    name?: string;
    label?: string;
    url?: string;
    valid?: boolean;
  };
};

export type RunExternalItemBlock = BaseBlockContent & {
  dictionary_id?: number;
  type: 'external_item';
  item_type: string;
  item_id: string;
  item?: {
    id: string;
    type?: string;
    name?: string;
    label?: string;
    url?: string;
    valid?: boolean;
    metadata?: unknown;
    details?: Array<{ name: string; value: string }>;
  };
};

// blocks that can contain references and must have recorded field
export type RunReferencingBlockType =
  | RunExpressionBlock
  | RunTextBlock
  | RunAlertBlock;

export type ExternalItemBlock =
  | DraftExternalItemBlock
  | ReleaseExternalItemBlock
  | RunExternalItemBlock;

export type ExternalItemBlockDiffElement = WithDiffChange<
  | ExternalItemBlock
  | (BaseBlockContent & {
      dictionary_id?: DiffField<number>;
      type: 'external_item';
      item_type: DiffField<string>;
      item_id: DiffField<string>;
      item_url?: DiffField<string>;
      item_valid?: DiffField<boolean>;
      item?: {
        id: string;
        name?: string;
        label?: string;
        url?: string;
        valid?: boolean;
      };
    })
>;

export type FieldInputType =
  | 'text'
  | 'number'
  | 'checkbox'
  | 'timestamp'
  | 'attachment'
  | 'sketch'
  | 'list'
  | 'select'
  | 'external_item'
  | 'external_search'
  | 'multiple_choice';

export enum FieldInputTypes {
  Text = 'text',
  Number = 'number',
  Checkbox = 'checkbox',
  Timestamp = 'timestamp',
  Attachment = 'attachment',
  Sketch = 'sketch',
  List = 'list',
  CustomList = 'select', // Called select for some reason
  ExternalItem = 'external_item',
  ExternalSearch = 'external_search',
  MultipleChoice = 'multiple_choice',
}

export type Rule = {
  op: RuleOperator;
  value: string | number; // value is sometimes represented as a number, and sometimes as a string in the docs
  range?: Range;
};

export type RuleDiffElement = WithDiffChange<
  | Rule
  | {
      op: DiffField<string>;
      value: DiffField<string | number>;
      range?: RangeDiffElement;
    }
>;

// Range min and max are sometimes represented as numbers, and sometimes as strings in the docs
export type Range = {
  min: string | number;
  max: string | number;
  include_min?: boolean;
  include_max?: boolean;
};

export type RangeDiffElement = WithDiffChange<
  | Range
  | {
      min: DiffField<string | number>;
      max: DiffField<string | number>;
      include_min?: DiffField<boolean>;
      include_max?: DiffField<boolean>;
    }
>;

export type RunFieldInputRecordedValue =
  | null
  | string
  | number
  | boolean
  | AttachmentValue
  | SketchValue
  | ExternalDataValue
  | ExternalSearchDataValue
  | TimestampValue;

export type RunFieldInputRecorded<T = RunFieldInputRecordedValue> = {
  value: T;
};

export type DraftFieldInputBlock =
  | DraftFieldInputTextBlock
  | DraftFieldInputNumberBlock
  | DraftFieldInputCheckboxBlock
  | DraftFieldInputTimestampBlock
  | DraftFieldInputAttachmentBlock
  | DraftFieldInputSketchBlock
  | DraftFieldInputSettingsListBlock
  | DraftFieldInputCustomListBlock
  | DraftFieldInputExternalDataBlock
  | DraftFieldInputExternalSearchBlock
  | DraftFieldInputMultipleChoiceBlock;

export type ReleaseFieldInputBlock =
  | ReleaseFieldInputTextBlock
  | ReleaseFieldInputNumberBlock
  | ReleaseFieldInputCheckboxBlock
  | ReleaseFieldInputTimestampBlock
  | ReleaseFieldInputAttachmentBlock
  | ReleaseFieldInputSketchBlock
  | ReleaseFieldInputSettingsListBlock
  | ReleaseFieldInputCustomListBlock
  | ReleaseFieldInputExternalDataBlock
  | ReleaseFieldInputExternalSearchBlock
  | ReleaseFieldInputMultipleChoiceBlock;

export type RunFieldInputBlock =
  | RunFieldInputTextBlock
  | RunFieldInputNumberBlock
  | RunFieldInputCheckboxBlock
  | RunFieldInputTimestampBlock
  | RunFieldInputAttachmentBlock
  | RunFieldInputSketchBlock
  | RunFieldInputSettingsListBlock
  | RunFieldInputCustomListBlock
  | RunFieldInputExternalDataBlock
  | RunFieldInputExternalSearchBlock
  | RunFieldInputMultipleChoiceBlock;

export type FieldInputBlock =
  | DraftFieldInputBlock
  | ReleaseFieldInputBlock
  | RunFieldInputBlock;

export type DraftInputReferenceBlock = BaseBlockContent & {
  type: 'reference';
  subtype: 'input';
  reference: string;
  sub_reference?: string;
  include_in_summary?: boolean;
};

export type ReleaseInputReferenceBlock = BaseBlockContent & {
  type: 'reference';
  subtype: 'input';
  reference: string;
  sub_reference?: string;
  include_in_summary?: boolean;
};

export type RunInputReferenceBlock = BaseBlockContent & {
  type: 'reference';
  subtype: 'input';
  reference: string;
  sub_reference?: string;
  field_index?: number;
  include_in_summary?: boolean;
};

export type InputReferenceBlock =
  | DraftInputReferenceBlock
  | ReleaseInputReferenceBlock
  | RunInputReferenceBlock;

export type InputReferenceBlockDiff = WithDiffChange<
  | InputReferenceBlock
  | (BaseBlockContent & {
      type: 'reference';
      subtype: 'input';
      reference: DiffField<string>;
      sub_reference?: DiffField<string>;
      include_in_summary?: boolean;
    })
>;

export type DraftJumpToBlock = BaseBlockContent & {
  type: 'jump_to';
  jumpToId: string;
};

export type ReleaseJumpToBlock = BaseBlockContent & {
  type: 'jump_to';
  jumpToId: string;
};

export type RunJumpToBlock = BaseBlockContent & {
  type: 'jump_to';
  jumpToId: string;
};

export type JumpToBlock =
  | DraftJumpToBlock
  | ReleaseJumpToBlock
  | RunJumpToBlock;

export type DraftProcedureLinkBlock = BaseBlockContent & {
  type: 'procedure_link';
  procedure: string;
  section: string;
  run?: string;
};

export type ReleaseProcedureLinkBlock = BaseBlockContent & {
  type: 'procedure_link';
  procedure: string;
  section: string;
  run?: string;
};

export type RunProcedureLinkBlock = BaseBlockContent & {
  type: 'procedure_link';
  procedure: string;
  section: string;
  run?: string;
  run_only?: boolean;
};

export type ProcedureLinkBlock =
  | DraftProcedureLinkBlock
  | ReleaseProcedureLinkBlock
  | RunProcedureLinkBlock;

export type ProcedureLinkBlockDiffElement = WithDiffChange<
  | ProcedureLinkBlock
  | (BaseBlockContent & {
      type: 'procedure_link';
      procedure: DiffField<string>;
      section: DiffField<string>;
      run?: string;
    })
>;

export type RequirementSubType = 'manual' | 'jama' | 'epsilon3';

export type DraftRequirementBlock = BaseBlockContent & {
  type: 'requirement';
  requirement_id: string;
  label: string;
  subType?: RequirementSubType;
  integrationDetails?: RequirementIntegrationDetails;
  metadata: object;
};

export type ReleaseRequirementBlock = BaseBlockContent & {
  type: 'requirement';
  requirement_id: string;
  label: string;
  subType?: RequirementSubType;
  integrationDetails?: RequirementIntegrationDetails;
  metadata: object;
};

export type RunRequirementBlock = BaseBlockContent & {
  type: 'requirement';
  requirement_id: string;
  label: string;
  subType?: RequirementSubType;
  integrationDetails?: RequirementIntegrationDetails;
  metadata: object;
};

export type RequirementBlock =
  | DraftRequirementBlock
  | ReleaseRequirementBlock
  | RunRequirementBlock;

export type TableSignoffAction = {
  timestamp: string;
  user_id: string;
  type: 'signoff';
  operator?: string;
};

export type TableRevokeSignoffAction = {
  timestamp: string;
  user_id: string;
  type: 'revoke_signoff';
  revoked_user_id: string;
  revoked_operator?: string;
};

export type TableAction = TableSignoffAction | TableRevokeSignoffAction;

export type TableSignoff = {
  id: string;
  operators: Array<string>;
  actions: Array<TableAction>;
};

export type TableCell =
  | string
  | boolean
  | Array<TableSignoff>
  | Array<RunStepComment>
  | Case // TODO: Make sure this type is correct
  | null;
export type TableCells = Array<Array<TableCell>>;

export type TableColumn = {
  id?: string;
  name: string;
  column_type?: 'text' | 'input' | 'signoff' | 'comment' | 'test_point';
  input_type?: 'text' | 'number' | 'checkbox' | 'list';
  units: string;
  width?: number;
  list?: string;
  allow_input_after_signoff?: boolean;
  disabled?: boolean;
};

export type RowMetadata = { id: string };
export type RowMetadataDiffElement = WithDiffChange<RowMetadata>;

export type DraftTableInputBlock = BaseBlockContent & {
  type: 'table_input';
  sub_type?: '' | 'test_point';
  columns: Array<TableColumn>;
  row_metadata?: Array<RowMetadata>;
  rows: number;
  cells?: TableCells;
  include_in_summary?: boolean;
};

export type ReleaseTableInputBlock = BaseBlockContent & {
  type: 'table_input';
  sub_type?: '' | 'test_point';
  columns: Array<TableColumn>;
  row_metadata?: Array<RowMetadata>;
  rows: number;
  cells?: TableCells;
  include_in_summary?: boolean;
};

export type RunTableInputRecorded = {
  values: TableCells;
  version: number;
};

export type RunTableInputBlock = BaseBlockContent & {
  type: 'table_input';
  sub_type?: '' | 'test_point';
  columns: Array<TableColumn>;
  row_metadata?: Array<RowMetadata>;
  rows: number;
  cells?: TableCells;
  include_in_summary?: boolean;
  recorded?: RunTableInputRecorded;
};

export type TableInputBlock =
  | DraftTableInputBlock
  | ReleaseTableInputBlock
  | RunTableInputBlock;

export type TableColumnDiffElement = WithDiffChange<
  | TableColumn
  | {
      id: string;
      name: DiffField<string>;
      column_type?: DiffField<'text' | 'input'>;
      input_type?: DiffField<'text' | 'number' | 'checkbox'>;
      units: DiffField<string>;
      width?: DiffField<number>;
    }
>;

export type TableInputBlockDiffElement = WithDiffChange<
  | TableInputBlock
  | {
      id: string;
      type: 'table_input';
      columns: Array<TableColumnDiffElement>;
      row_metadata: Array<RowMetadataDiffElement>;
      rows: DiffField<number>;
      cells?: Array<Array<DiffField<string | null>>>;
      // The added/deleted fields for rows and columns are used for diffs only.
      removed_rows?: Set<number>;
      added_rows?: Set<number>;
      removed_columns?: Set<number>;
      added_columns?: Set<number>;
    }
>;

export type TableSignoffDiff = DiffArrayElement<TableSignoff>;
export type TableSignoffDiffElement = WithDiffChange<TableSignoff>;

/** @deprecated */
export type CosmosTelemetry = {
  target: string;
  packet: string;
  item: string;
};

export type RuleOperator = SupportedOperation | 'range' | '';
export type DraftTelemetryBlock = BaseBlockContent & {
  type: 'telemetry';
  key: 'parameter' | 'custom';
  name?: string;
  units?: string;
  // favoring the parameter id over legacy parameter name for unique identifier
  parameter_id?: number;
  dictionary_id?: number;
  parameter_type?: TelemetryType;
  rule: RuleOperator;
  value: string;
  expression?: string;
  cosmos?: CosmosTelemetry;
  range?: Range;
};

export type TelemetryBlockDiffElement = WithDiffChange<DraftTelemetryBlock>;

export type ReleaseTelemetryBlock = BaseBlockContent & {
  type: 'telemetry';
  key: 'parameter' | 'custom';
  name?: string;
  units?: string;
  // favoring the parameter id over legacy parameter name for unique identifier
  parameter_id?: number;
  dictionary_id?: number;
  parameter_type?: TelemetryType;
  rule: RuleOperator;
  value: string;
  expression?: string;
  cosmos?: CosmosTelemetry;
  range?: Range;
};

type RunTelemetryRecorded =
  | {
      pass: boolean;
      value: number | string;
      timestamp?: string | null;
      stale?: boolean;
    }
  | Record<string, never>; // empty object

export type RunTelemetryBlock = BaseBlockContent & {
  type: 'telemetry';
  key: 'parameter' | 'custom';
  name?: string;
  units?: string;
  // favoring the parameter id over legacy parameter name for unique identifier
  parameter_id?: number;
  dictionary_id?: number;
  parameter_type?: TelemetryType;
  refresh_rate_ms?: number;
  rule: RuleOperator;
  value: string;
  expression?: string;
  cosmos?: CosmosTelemetry;
  range?: Range;
  include_in_summary?: boolean;
  recorded?: RunTelemetryRecorded;
};

export type TelemetryBlock =
  | ReleaseTelemetryBlock
  | DraftTelemetryBlock
  | RunTelemetryBlock;

export type DraftTextBlock = BaseBlockContent & {
  type: 'text';
  text: string;
  redlines?: Array<BlockRedline<ReleaseTextBlock>>;
  tokens?: ExpressionToken[];
};

export type DraftTestCasesBlock = BaseBlockContent & {
  type: 'test_cases';
  items: Array<unknown>; //unknown until TestCase is moved to shared
};

export type ReleaseTestCasesBlock = BaseBlockContent & {
  type: 'test_cases';
  items: Array<unknown>; //unknown until TestCase is moved to shared
};

export type RunTestCasesBlock = BaseBlockContent & {
  type: 'test_cases';
  items: Array<unknown>; //unknown until TestCase is moved to shared
};

// TODO these part build and usage types are incomplete
export type PartBuildItem = {
  id: string;
  part_id: string;
  part_no?: string;
  name?: string;
  part_index?: number;
  prefix?: string;
  serial?: string;
  amount: number;
  revision?: string;
  revision_id?: string;
  tracking?: TrackingType;
  image?: {
    name: string;
    content_type: string;
    attachment_id: string;
  };
  assembly?: boolean;
  project_id?: string | null;
  /**
   * TODO `Array<Omit<PartBuildItem, 'components'>>` is incompatible with
   * `Item.components` in `web/src/manufacturing/types.ts`
   */
  components?;
};

export type ReviewPartBuildItem = WithDiffChange<PartBuildItem>;

export type DraftPartBuildBlock = BaseBlockContent & {
  part_id: string;
  type: 'part_build';
  part?: Part;
  items: Array<PartBuildItem>;
};

export type ReleasePartBuildBlock = BaseBlockContent & {
  part_id: string;
  type: 'part_build';
  part?: Part;
  items: Array<PartBuildItem>;
};

export type RunPartBuildBlock = BaseBlockContent & {
  part_id: string;
  type: 'part_build';
  part?: Part;
  items: Array<PartBuildItem>;
  recorded?: RunPartBuildRecorded;
};

export type RunPartBuildRecorded = {
  items: {
    [item_id: string]: PartBuildRecordedItem;
  };
  added_items?: PartBuildRecordedItem[];
};

export type PartBuildRecordedItem = {
  id: string;
  part_id: string;
  part_no?: string;
  tracking?: TrackingType;
  revision?: string;
  revision_id?: string;
  name?: string;
  part_index?: number;
  amount: number;
  location_id?: string;
  lot?: string;
  prefix?: string;
  serial?: string;
  item_id?: string;
};

export type PartBuildBlock =
  | DraftPartBuildBlock
  | ReleasePartBuildBlock
  | RunPartBuildBlock;

export type DraftPartUsageBlock = BaseBlockContent & {
  part_id: string;
  type: 'part_usage';
  usage_types: Array<UsageType>;
  part: Part | null;
};

export type ReleasePartUsageBlock = BaseBlockContent & {
  part_id: string;
  type: 'part_usage';
  usage_types: Array<UsageType>;
  part: Part | null;
};

export type RunPartUsageBlock = BaseBlockContent & {
  part_id: string;
  type: 'part_usage';
  recorded?: Array<RunPartUsageRecorded>;
  usage_types: Array<UsageType>;
  part: Part | null;
};

export type RunPartUsageRecorded = {
  item_id?: string;
  amount: number;
  usage_type: UsageType;
  part?: Part;
  item?: {
    part: Part;
  };
};

export type DraftPartKitBlock = BaseBlockContent & {
  part: Part | null;
  type: 'part_kit';
  items: Array<KitPart>;
};

export type ReleasePartKitBlock = BaseBlockContent & {
  part: Part | null;
  type: 'part_kit';
  items: Array<KitPart>;
};

export type RunPartKitBlock = BaseBlockContent & {
  part: Part | null;
  type: 'part_kit';
  items: Array<KitPart>;
  recorded?: RunPartKitRecorded;
  include_in_summary?: boolean;
};

// TODO: Add Part Usage Blocks
export type RunPartKitRecorded = {
  items?: Record<string, KitItem>;
  added_items?: Array<KitItem>;
};

export type KitPart = {
  id: string;
  part_id: string;
  amount: number;
  part_no?: string;
  name?: string;
  revision?: string;
  revision_id?: string;
  tracking?: TrackingType;
};

export type KitItem = KitPart & { item_id?: string };

export type PartKitBlock =
  | DraftPartKitBlock
  | ReleasePartKitBlock
  | RunPartKitBlock;

// initial block before validation
export type InitialToolCheckOutBlock = BaseBlockContent & {
  type: 'tool_check_out';
  tool_id: number | null;
  include_in_summary?: boolean;
};

export type DraftToolCheckOutBlock = BaseBlockContent & {
  type: 'tool_check_out';
  tool_id: number;
  include_in_summary?: boolean;
};

export type ReleaseToolCheckOutBlock = BaseBlockContent & {
  type: 'tool_check_out';
  tool_id: number;
  include_in_summary?: boolean;
};

type RunToolCheckoutRecorded = ReleaseToolCheckOutBlock & {
  tool_instance_id: number;
};

export type RunToolCheckOutBlock = ReleaseToolCheckOutBlock & {
  recorded?: RunToolCheckoutRecorded;
};

export type ReviewToolCheckOutBlock = WithDiffChange<
  | DraftToolCheckOutBlock
  | (BaseBlockContent & {
      type: 'tool_check_out';
      tool_id: DiffField<number>;
    })
>;

// initial block before validation
export type InitialToolCheckInBlock = BaseBlockContent & {
  type: 'tool_check_in';
  tool_id: number | null;
  include_in_summary?: boolean;
};

export type DraftToolCheckInBlock = BaseBlockContent & {
  type: 'tool_check_in';
  tool_id: number;
  include_in_summary?: boolean;
};

export type ReleaseToolCheckInBlock = BaseBlockContent & {
  type: 'tool_check_in';
  tool_id: number;
  include_in_summary?: boolean;
};

type RunToolCheckInRecorded = ReleaseToolCheckInBlock & {
  tool_instance_id: number;
};

export type RunToolCheckInBlock = ReleaseToolCheckInBlock & {
  recorded?: RunToolCheckInRecorded;
};

export type ReviewToolCheckInBlock = WithDiffChange<
  | DraftToolCheckInBlock
  | (BaseBlockContent & {
      type: 'tool_check_in';
      tool_id: DiffField<number>;
      tool_instance_id: DiffField<number>;
    })
>;

// initial block before validation
export type InitialToolUsageBlock = BaseBlockContent & {
  type: 'tool_usage';
  tool_id: number | null;
  usage_type_id: number | null;
  include_in_summary?: boolean;
};

export type DraftToolUsageBlock = BaseBlockContent & {
  type: 'tool_usage';
  tool_id: number;
  usage_type_id: number;
  include_in_summary?: boolean;
};

export type ReleaseToolUsageBlock = BaseBlockContent & {
  type: 'tool_usage';
  tool_id: number;
  usage_type_id: number;
  include_in_summary?: boolean;
};

type RunToolUsageRecorded = ReleaseToolUsageBlock & {
  tool_instance_id?: number;
  value?: number;
};
export type RunToolUsageBlock = ReleaseToolUsageBlock & {
  recorded?: RunToolUsageRecorded;
};

export type ReviewToolUsageBlock = WithDiffChange<
  | DraftToolUsageBlock
  | (BaseBlockContent & {
      type: 'tool_usage';
      tool_id: DiffField<number>;
      tool_instance_id: DiffField<number>;
    })
>;

export type ReleaseTextBlock = BaseBlockContent & {
  type: 'text';
  text: string;
  tokens?: ExpressionToken[];
};

export type RunTextBlock = BaseBlockContent & {
  type: 'text';
  text: string;
  tokens?: ExpressionToken[];
  recorded?: {
    value?: string;
    richDisplay?: string;
    references?: Record<
      string,
      {
        referenced_section_id: string;
        referenced_step_id: string;
      }
    >;
  };
};

export type TextBlock = DraftTextBlock | ReleaseTextBlock | RunTextBlock;

/*
 * =============================================================================
 * Field input types
 * =============================================================================
 */
export type DraftFieldInputTextBlock = BaseBlockContent & {
  type: 'input';
  inputType: 'text';
  name: string;
  units?: string;
  redlines?: Array<BlockRedline<ReleaseFieldInputTextBlock>>;
};

export type ReleaseFieldInputTextBlock = BaseBlockContent & {
  type: 'input';
  inputType: 'text';
  name: string;
  units?: string;
};

export type RunFieldInputTextBlock = BaseBlockContent & {
  type: 'input';
  inputType: 'text';
  name: string;
  units?: string;
  recorded?: { value: string };
};

export type FieldInputTextBlock =
  | DraftFieldInputTextBlock
  | ReleaseFieldInputTextBlock
  | RunFieldInputTextBlock;

export type FieldInputTextBlockDiffElement = WithDiffChange<
  | FieldInputTextBlock
  | (BaseBlockContent & {
      type: 'input';
      inputType: 'text';
      name: DiffField<string>;
      units?: DiffField<string>;
    })
>;

export type DraftFieldInputNumberBlock = BaseBlockContent & {
  type: 'input';
  inputType: 'number';
  name: string;
  units: string;
  rule?: Rule;
  redlines?: Array<BlockRedline<ReleaseFieldInputNumberBlock>>;
  include_in_summary?: boolean;
};

export type ReleaseFieldInputNumberBlock = BaseBlockContent & {
  type: 'input';
  inputType: 'number';
  name: string;
  units: string;
  rule?: Rule;
};

export type RunFieldInputNumberBlock = BaseBlockContent & {
  type: 'input';
  inputType: 'number';
  name: string;
  units: string;
  rule?: Rule;
  recorded?: { value: number };
};

export type FieldInputNumberBlock =
  | DraftFieldInputNumberBlock
  | ReleaseFieldInputNumberBlock
  | RunFieldInputNumberBlock;

export type FieldInputNumberBlockDiffElement = WithDiffChange<
  | FieldInputNumberBlock
  | (BaseBlockContent & {
      type: 'input';
      inputType: 'number';
      name: DiffField<string>;
      units: DiffField<string>;
      rule?: RuleDiffElement;
    })
>;

export type DraftInventoryDetailInputBlock = BaseBlockContent & {
  type: 'inventory_detail_input';
  part_revision_id: string;
  detail_id: string;
  include_in_summary?: boolean;
  part_id?: string;
};

export type ReleaseInventoryDetailInputBlock = DraftInventoryDetailInputBlock;

export type RunInventoryDetailInputRecorded = {
  value: string;
  item_id: string;
};

export type RunInventoryDetailInputBlock = ReleaseInventoryDetailInputBlock & {
  recorded?: RunInventoryDetailInputRecorded;
};

export type InventoryDetailInputBlock =
  | DraftInventoryDetailInputBlock
  | ReleaseInventoryDetailInputBlock
  | RunInventoryDetailInputBlock;

export type InventoryDetailInputBlockDiffElement = WithDiffChange<
  | InventoryDetailInputBlock
  | (BaseBlockContent & {
      type: 'inventory_detail_input';
      part_revision_id: DiffField<string>;
      detail_id: DiffField<string>;
      part_id?: string;
    })
>;

export type DraftFieldInputCheckboxBlock = BaseBlockContent & {
  type: 'input';
  inputType: 'checkbox';
  name: string;
  redlines?: Array<BlockRedline<ReleaseFieldInputCheckboxBlock>>;
};

export type ReleaseFieldInputCheckboxBlock = BaseBlockContent & {
  type: 'input';
  inputType: 'checkbox';
  name: string;
};

export type RunFieldInputCheckboxBlock = BaseBlockContent & {
  type: 'input';
  inputType: 'checkbox';
  name: string;
  recorded?: { value: boolean };
};

export type FieldInputCheckboxBlock =
  | DraftFieldInputCheckboxBlock
  | ReleaseFieldInputCheckboxBlock
  | RunFieldInputCheckboxBlock;

export type FieldInputCheckboxBlockDiffElement = WithDiffChange<
  | FieldInputCheckboxBlock
  | (BaseBlockContent & {
      type: 'input';
      inputType: 'checkbox';
      name: DiffField<string>;
    })
>;

export type DraftFieldInputTimestampBlock = BaseBlockContent & {
  type: 'input';
  inputType: 'timestamp';
  name: string;
  dateTimeType: 'date' | 'time' | 'datetime';
  redlines?: Array<BlockRedline<ReleaseFieldInputTimestampBlock>>;
};

export type ReleaseFieldInputTimestampBlock = BaseBlockContent & {
  type: 'input';
  inputType: 'timestamp';
  name: string;
  dateTimeType: 'date' | 'time' | 'datetime';
};

export type RunFieldInputTimestampBlock = BaseBlockContent & {
  type: 'input';
  inputType: 'timestamp';
  name: string;
  dateTimeType: 'date' | 'time' | 'datetime';
  recorded?: RunFieldInputRecorded<TimestampValue>;
};

export type FieldInputTimestampBlock =
  | DraftFieldInputTimestampBlock
  | ReleaseFieldInputTimestampBlock
  | RunFieldInputTimestampBlock;

export type FieldInputTimestampBlockDiffElement = WithDiffChange<
  | FieldInputTimestampBlock
  | (BaseBlockContent & {
      type: 'input';
      inputType: 'timestamp';
      name: DiffField<string>;
      dateTimeType: DiffField<'date' | 'time' | 'datetime'>;
    })
>;

export type DraftFieldInputAttachmentBlock = BaseBlockContent & {
  type: 'input';
  inputType: 'attachment';
  name: string;
  redlines?: Array<BlockRedline<ReleaseFieldInputAttachmentBlock>>;
};

export type ReleaseFieldInputAttachmentBlock = BaseBlockContent & {
  type: 'input';
  inputType: 'attachment';
  name: string;
};

export type RunFieldInputAttachmentBlock = BaseBlockContent & {
  type: 'input';
  inputType: 'attachment';
  name: string;
  recorded?: { value: AttachmentValue };
};

export type FieldInputAttachmentBlock =
  | DraftFieldInputAttachmentBlock
  | ReleaseFieldInputAttachmentBlock
  | RunFieldInputAttachmentBlock;

export type FieldInputAttachmentBlockDiffElement = WithDiffChange<
  | FieldInputAttachmentBlock
  | (BaseBlockContent & {
      type: 'input';
      inputType: 'attachment';
      name: DiffField<string>;
    })
>;

export type DraftFieldInputSketchBlock = BaseBlockContent & {
  type: 'input';
  inputType: 'sketch';
  name: string;
  redlines?: Array<BlockRedline<ReleaseFieldInputSketchBlock>>;
};

export type ReleaseFieldInputSketchBlock = BaseBlockContent & {
  type: 'input';
  inputType: 'sketch';
  name: string;
};

export type RunFieldInputSketchBlock = BaseBlockContent & {
  type: 'input';
  inputType: 'sketch';
  name: string;
  recorded?: { value: SketchValue };
};

export type FieldInputSketchBlock =
  | DraftFieldInputSketchBlock
  | ReleaseFieldInputSketchBlock
  | RunFieldInputSketchBlock;

export type FieldInputSketchBlockDiffElement = WithDiffChange<
  | FieldInputSketchBlock
  | (BaseBlockContent & {
      type: 'input';
      inputType: 'sketch';
      name: DiffField<string>;
    })
>;

export type DraftFieldInputSettingsListBlock = BaseBlockContent & {
  type: 'input';
  inputType: 'list';
  name: string;
  list: string;
  redlines?: Array<BlockRedline<ReleaseFieldInputSettingsListBlock>>;
};

export type ReleaseFieldInputSettingsListBlock = BaseBlockContent & {
  type: 'input';
  inputType: 'list';
  name: string;
  list: string;
};

export type RunFieldInputSettingsListBlock = BaseBlockContent & {
  type: 'input';
  inputType: 'list';
  name: string;
  list: string;
  recorded?: { value: string };
};

export type FieldInputSettingsListBlock =
  | DraftFieldInputSettingsListBlock
  | ReleaseFieldInputSettingsListBlock
  | RunFieldInputSettingsListBlock;

export type FieldInputSettingsListBlockDiffElement = WithDiffChange<
  | FieldInputSettingsListBlock
  | (BaseBlockContent & {
      type: 'input';
      inputType: 'list';
      name: DiffField<string>;
      list: DiffField<string>;
    })
>;

export type DraftFieldInputCustomListBlock = BaseBlockContent & {
  type: 'input';
  inputType: 'select';
  name: string;
  options: Array<string>;
  redlines?: Array<BlockRedline<ReleaseFieldInputCustomListBlock>>;
};

export type ReleaseFieldInputCustomListBlock = BaseBlockContent & {
  type: 'input';
  inputType: 'select';
  name: string;
  options: Array<string>;
};

export type RunFieldInputCustomListBlock = BaseBlockContent & {
  type: 'input';
  inputType: 'select';
  name: string;
  options: Array<string>;
  recorded?: { value: string };
};

export type FieldInputCustomListBlock =
  | DraftFieldInputCustomListBlock
  | ReleaseFieldInputCustomListBlock
  | RunFieldInputCustomListBlock;

export type FieldInputCustomListBlockDiffElement = WithDiffChange<
  | FieldInputCustomListBlock
  | (BaseBlockContent & {
      type: 'input';
      inputType: 'select';
      name: DiffField<string>;
      options: Array<DiffField<string>>;
    })
>;

export type DraftFieldInputExternalDataBlock = BaseBlockContent & {
  type: 'input';
  inputType: 'external_item';
  name: string;
  external_item_type: string;
  dictionary_id?: number;
  redlines?: Array<BlockRedline<ReleaseFieldInputExternalDataBlock>>;
};

export type ReleaseFieldInputExternalDataBlock = BaseBlockContent & {
  type: 'input';
  dictionary_id?: number;
  inputType: 'external_item';
  name: string;
  external_item_type: string;
};

export type RunFieldInputExternalDataBlock = BaseBlockContent & {
  type: 'input';
  inputType: 'external_item';
  dictionary_id?: number;
  name: string;
  external_item_type: string;
  recorded?: { value: ExternalDataValue };
};

export type FieldInputExternalDataBlock =
  | DraftFieldInputExternalDataBlock
  | ReleaseFieldInputExternalDataBlock
  | RunFieldInputExternalDataBlock;

export type FieldInputExternalDataBlockDiffElement = WithDiffChange<
  | FieldInputExternalDataBlock
  | (BaseBlockContent & {
      type: 'input';
      dictionary_id?: DiffField<number>;
      inputType: 'external_item';
      name: DiffField<string>;
      external_item_type: DiffField<string>;
    })
>;

export type DraftFieldInputExternalSearchBlock = BaseBlockContent & {
  name: string;
  type: 'input';
  inputType: 'external_search';
  external_search_type: {
    data_type_dictionary_id?: number;
    data_type: string;
    filter_options: Array<string>;
  };
  redlines?: Array<BlockRedline<ReleaseFieldInputExternalSearchBlock>>;
};

export type ReleaseFieldInputExternalSearchBlock = BaseBlockContent & {
  name: string;
  type: 'input';
  inputType: 'external_search';
  external_search_type: {
    data_type_dictionary_id?: number;
    data_type: string;
    filter_options: Array<string>;
  };
};

export type RunFieldInputExternalSearchBlock = BaseBlockContent & {
  name: string;
  type: 'input';
  inputType: 'external_search';
  external_search_type: {
    data_type_dictionary_id?: number;
    data_type: string;
    filter_options: Array<string>;
  };
  recorded?: { value: ExternalSearchDataValue | null };
};

export type FieldInputExternalSearchBlock =
  | DraftFieldInputExternalSearchBlock
  | ReleaseFieldInputExternalSearchBlock
  | RunFieldInputExternalSearchBlock;

export type FieldInputExternalSearchBlockDiffElement = WithDiffChange<
  | FieldInputExternalSearchBlock
  | (BaseBlockContent & {
      name: DiffField<string>;
      type: 'input';
      inputType: 'external_search';
      external_search_type: {
        data_type_dictionary_id?: DiffField<number>;
        data_type: DiffField<string>;
        filter_options: Array<DiffField<string>>;
      };
    })
>;

export type DraftFieldInputMultipleChoiceBlock = BaseBlockContent & {
  type: 'input';
  inputType: 'multiple_choice';
  name: string;
  options: Array<string>;
  redlines?: Array<BlockRedline<ReleaseFieldInputMultipleChoiceBlock>>;
};

export type ReleaseFieldInputMultipleChoiceBlock = BaseBlockContent & {
  type: 'input';
  inputType: 'multiple_choice';
  name: string;
  options: Array<string>;
};

export type RunFieldInputMultipleChoiceBlock = BaseBlockContent & {
  type: 'input';
  inputType: 'multiple_choice';
  name: string;
  options: Array<string>;
  recorded?: { value: string };
};

export type FieldInputMultipleChoiceBlock =
  | DraftFieldInputMultipleChoiceBlock
  | ReleaseFieldInputMultipleChoiceBlock
  | RunFieldInputMultipleChoiceBlock;

export type FieldInputMultipleChoiceBlockDiffElement = WithDiffChange<
  | FieldInputMultipleChoiceBlock
  | (BaseBlockContent & {
      type: 'input';
      inputType: 'multiple_choice';
      name: DiffField<string>;
      options: Array<DiffField<string>>;
    })
>;

export type DraftFieldInputConditionalBlock =
  | DraftFieldInputSettingsListBlock
  | DraftFieldInputCustomListBlock
  | DraftFieldInputMultipleChoiceBlock;
export type ReleaseFieldInputConditionalBlock =
  | ReleaseFieldInputSettingsListBlock
  | ReleaseFieldInputCustomListBlock
  | ReleaseFieldInputMultipleChoiceBlock;
export type RunFieldInputConditionalBlock =
  | RunFieldInputSettingsListBlock
  | RunFieldInputCustomListBlock
  | RunFieldInputMultipleChoiceBlock;
export type FieldInputConditionalBlock =
  | DraftFieldInputConditionalBlock
  | ReleaseFieldInputConditionalBlock
  | RunFieldInputConditionalBlock;

export type ReleaseFieldInputTableBlock = BaseBlockContent & {
  type: 'field_input_table';
  fieldsPerRow: number;
  include_in_summary?: boolean;
  fields: Array<
    | ReleaseFieldInputTextBlock
    | ReleaseFieldInputNumberBlock
    | ReleaseFieldInputCheckboxBlock
    | ReleaseFieldInputTimestampBlock
    | ReleaseFieldInputCustomListBlock
    | ReleaseFieldInputSettingsListBlock
  >;
};

export type RunFieldInputTableBlock = BaseBlockContent & {
  type: 'field_input_table';
  fieldsPerRow: number;
  include_in_summary?: boolean;
  fields: Array<
    | RunFieldInputTextBlock
    | RunFieldInputNumberBlock
    | RunFieldInputCheckboxBlock
    | RunFieldInputTimestampBlock
    | RunFieldInputCustomListBlock
    | RunFieldInputSettingsListBlock
  >;
};

export type DraftFieldInputTableBlock = BaseBlockContent & {
  type: 'field_input_table';
  fieldsPerRow: number;
  include_in_summary?: boolean;
  fields: Array<
    | DraftFieldInputTextBlock
    | DraftFieldInputNumberBlock
    | DraftFieldInputCheckboxBlock
    | DraftFieldInputTimestampBlock
    | DraftFieldInputCustomListBlock
    | DraftFieldInputSettingsListBlock
  >;
};

export type FieldInputTableBlock =
  | ReleaseFieldInputTableBlock
  | RunFieldInputTableBlock
  | DraftFieldInputTableBlock;

export type FieldInputBlockDiffElement =
  | FieldInputTextBlockDiffElement
  | FieldInputNumberBlockDiffElement
  | FieldInputCheckboxBlockDiffElement
  | FieldInputTimestampBlockDiffElement
  | FieldInputAttachmentBlockDiffElement
  | FieldInputSketchBlockDiffElement
  | FieldInputSettingsListBlockDiffElement
  | FieldInputCustomListBlockDiffElement
  | FieldInputExternalDataBlockDiffElement
  | FieldInputExternalSearchBlockDiffElement
  | FieldInputMultipleChoiceBlockDiffElement;

/*
 * =============================================================================
 * Additional view types
 * =============================================================================
 */
interface ProcedureLinkBase {
  code: string;
  name: string;
  procedure: string;
}

export type DraftProcedureLink = ProcedureLinkBase & {
  content: DraftProcedureLinkBlock;
};

export type ReleaseProcedureLink = ProcedureLinkBase & {
  content: ReleaseProcedureLinkBlock;
};

export type RunProcedureLink = ProcedureLinkBase & {
  content: RunProcedureLinkBlock;
};

export type ProcedureLink =
  | DraftProcedureLink
  | ReleaseProcedureLink
  | RunProcedureLink;

export type RunRedline =
  | RunHeaderRedline
  | RunStepRedline
  | RunRedlineComment
  | RunAddedStep
  | RunModifiedStep;

export type Recorded =
  | RunTelemetryRecorded
  | RunCommandingRecorded
  | RunToolUsageRecorded
  | RunToolCheckInRecorded
  | RunToolCheckoutRecorded
  | RunPartKitRecorded
  | RunPartUsageRecorded
  | RunPartBuildRecorded
  | RunTableInputRecorded
  | RunFieldInputRecorded
  | RunInventoryDetailInputRecorded;

export type TableCellRecorded = {
  row: number;
  column: number;
  value: TableCell;
  signoff_id?: string;
};

export type FieldInputTableRecorded = {
  [index: number]: { value: RunFieldInputRecordedValue };
};

export type RecordedValues = {
  [index: number]:
    | {
        value: RunFieldInputRecordedValue | TableCellRecorded;
      }
    | FieldInputTableRecorded;
};

export type StepDoc = {
  _id: string;
  _rev: string;
  run_id: string;
  section_id: string;
  step_id: string;
  redline_id?: string;
  content: Array<StepDocBlock<RunStepBlock, Recorded | TableCellRecorded>>;
  previous?: Array<{
    redline_id?: string;
    content: Array<StepDocBlock<RunStepBlock, Recorded | TableCellRecorded>>;
  }>;
};

type StepDocAction<T> = {
  user_id: string;
  action_id: string;
  timestamp: string;
  recorded: T;
};
type StepDocActions<T> = { actions?: Array<StepDocAction<T>> };

export type StepDocBlock<T extends RunStepBlock, R> = T & StepDocActions<R>;

export type FieldInputTableStepDocBlock = Omit<
  RunFieldInputTableBlock,
  'fields'
> & {
  fields: Array<
    | (RunFieldInputTextBlock & StepDocActions<RunFieldInputRecorded<string>>)
    | (RunFieldInputNumberBlock & StepDocActions<RunFieldInputRecorded<number>>)
    | (RunFieldInputCheckboxBlock &
        StepDocActions<RunFieldInputRecorded<boolean>>)
    | (RunFieldInputTimestampBlock &
        StepDocActions<RunFieldInputRecorded<TimestampValue>>)
    | (RunFieldInputCustomListBlock &
        StepDocActions<RunFieldInputRecorded<string>>)
    | (RunFieldInputSettingsListBlock &
        StepDocActions<RunFieldInputRecorded<string>>)
  >;
};

export type StepDocTableBlock = StepDocBlock<
  RunTableInputBlock,
  TableCellRecorded
>;

/*
 * =============================================================================
 * Snippet types
 * =============================================================================
 */
export type SnippetProcedure = {
  procedure_id: string;
  section_id: string;
  state: string;
  code: string;
  name: string;
  step_id?: string;
};

export type SectionSnippet = {
  _id: string;
  name: string;
  description: string;
  type: 'snippet';
  snippet_type: 'section';
  snippet_id: string;
  created_at?: string;
  created_by?: string;
  edited_at?: string;
  edited_by?: string;
  revision?: number;
  section: SnippetSection;
  deleted?: true;
  project_id?: string;
  procedures?: Array<SnippetProcedure>;
};

export type SnippetSection = Omit<ReleaseSection, keyof SnippetRef>;

export type StepSnippet = {
  _id: string;
  name: string;
  description: string;
  type: 'snippet';
  snippet_type: 'step';
  snippet_id: string;
  created_at?: string;
  created_by?: string;
  edited_at?: string;
  edited_by?: string;
  revision?: number;
  step: SnippetStep;
  deleted?: true;
  project_id?: string;
  procedures?: Array<SnippetProcedure>;
};

export type SnippetStep = Omit<ReleaseStep, keyof SnippetRef>;

export type SnippetType = 'section' | 'step';

export type Snippet = SectionSnippet | StepSnippet;
