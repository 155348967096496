import {
  Draft,
  DraftAddedStep,
  DraftHeader,
  DraftHeaderBlock,
  DraftRedlineComment,
  DraftSection,
  DraftStepBlock,
  ReviewComment,
  RunHeaderFieldRedline,
  RunStepBlock,
  RunStepFieldRedline,
} from 'shared/lib/types/views/procedures';
import {
  AddedStepRedline,
  FullStepRedline,
  HeaderBlockRedline,
  HeaderFieldRedline,
  Redline,
  RedlineComment,
  StepBlockRedline,
  StepRedline,
} from 'shared/lib/types/views/redlines';
import revisionsUtil from './revisions';
import {
  HeaderFieldScrollEntry,
  ScrollContext,
  ScrollEntry,
  StepFieldScrollEntry,
} from '../hooks/useScroll';
import {
  getRedlineFromDoc,
  getRedlineId,
  isFieldRedline,
  isStepRedline,
  REDLINE_TYPE,
  RunRedlineFromRedlineT,
} from 'shared/lib/redlineUtil';
import {
  getAllContentIds,
  getAllProcedureHeaderIds,
  getAllStepIds,
} from 'shared/lib/procedureUtil';
import { partition } from 'lodash';

export const REDLINE_STATE = {
  ACCEPTED: 'accepted',
  REJECTED: 'rejected',
  RESOLVED: 'resolved',
  UNRESOLVED: 'unresolved',
} as const;

/**
 * Get redlines that have valid redline ids.
 */
export const getRedlinesWithValidRedlineIds = (
  redlines: Array<Redline>
): Array<Redline> => {
  return redlines.filter((redlineDoc) => {
    const runRedline = getRedlineFromDoc(redlineDoc);
    const redlineId = getRedlineId(runRedline);
    return Boolean(redlineId) && redlineId === redlineDoc._id;
  });
};

/**
 * Given a list of redline docs of any redline type, get a map of the form {header_id: [header_redline]}.
 *
 * @param redlines - a list of redline docs of any type
 * @return a map of the form {header_id: [header_redline]}
 */
export const getHeaderRedlineMap = (
  redlines: Array<Redline> | undefined
): Map<string, Array<HeaderFieldRedline | HeaderBlockRedline>> => {
  const headerRedlineMap = new Map<
    string,
    Array<HeaderFieldRedline | HeaderBlockRedline>
  >();
  if (!redlines) {
    return headerRedlineMap;
  }

  const headerRedlines = redlines.filter(
    (redline) => redline.type === REDLINE_TYPE.HEADER_REDLINE
  ) as Array<HeaderFieldRedline | HeaderBlockRedline>;
  headerRedlines.forEach((redline) => {
    const headerId = getRedlineFromDoc(redline).header.id;
    if (!headerRedlineMap.has(headerId)) {
      headerRedlineMap.set(headerId, []);
    }

    headerRedlineMap.get(headerId)?.push(redline);
  });
  return headerRedlineMap;
};

/**
 * Given a list of header redline docs, get a map of the form {content_id: [block_header_redline]}.
 *
 * @param redlines - a list of header redline docs
 * @return a map of the form {content_id: [block_header_redline]}
 */
export const getHeaderBlockRedlineMap = (
  redlines: Array<HeaderFieldRedline | HeaderBlockRedline> | undefined
): Map<string, Array<HeaderBlockRedline>> => {
  const headerBlockRedlineMap = new Map<string, Array<HeaderBlockRedline>>();
  if (!redlines) {
    return headerBlockRedlineMap;
  }
  const headerBlockRedlines = redlines.filter((redline) =>
    Boolean(getRedlineFromDoc(redline as HeaderBlockRedline).content_id)
  ) as Array<HeaderBlockRedline>;
  headerBlockRedlines.forEach((redline) => {
    const contentId = getRedlineFromDoc(redline).content_id;
    if (!headerBlockRedlineMap.has(contentId)) {
      headerBlockRedlineMap.set(contentId, []);
    }

    headerBlockRedlineMap.get(contentId)?.push(redline);
  });

  return headerBlockRedlineMap;
};

/**
 * Given a list of redline docs of any redline type, get a map of the form {step_id: [step_redline]}.
 *
 * @param redlines - a list of redline docs of any type
 * @return a map of the form {step_id: [step_redline]}
 */
export const getStepRedlineMap = (
  redlines: Array<Redline> | undefined
): Map<string, Array<StepRedline>> => {
  const stepRedlineMap = new Map<string, Array<StepRedline>>();
  if (!redlines) {
    return stepRedlineMap;
  }
  const stepRedlines = redlines.filter(isStepRedline) as Array<StepRedline>;
  stepRedlines.forEach((redline) => {
    const stepId = redline.step_id;
    if (!stepRedlineMap.has(stepId)) {
      stepRedlineMap.set(stepId, []);
    }

    stepRedlineMap.get(stepId)?.push(redline);
  });
  return stepRedlineMap;
};

/**
 * Given a list of step redline docs, get a map of the form {content_id: [step_block_redline]}.
 *
 * @param redlines - a list of step redline docs
 * @return a map of the form {content_id: [step_block_redline]}
 */
export const getStepBlockRedlineMap = (
  redlines: Array<StepRedline> | undefined
): Map<string, Array<StepBlockRedline>> => {
  const stepBlockRedlineMap = new Map<string, Array<StepBlockRedline>>();
  if (!redlines) {
    return stepBlockRedlineMap;
  }
  const stepBlockRedlines = redlines.filter((redline) =>
    Boolean((redline as StepBlockRedline).content_id)
  ) as Array<StepBlockRedline>;
  stepBlockRedlines.forEach((redline) => {
    const contentId = redline.content_id;
    if (!stepBlockRedlineMap.has(contentId)) {
      stepBlockRedlineMap.set(contentId, []);
    }

    stepBlockRedlineMap.get(contentId)?.push(redline);
  });

  return stepBlockRedlineMap;
};

/**
 * @return a map of the form {[content_id (or step id if first blocK)]: [step_block_redline]}
 */
export const getAddedBlockRedlineMap = (
  redlines: Array<FullStepRedline>
): Map<string, Array<FullStepRedline>> => {
  const map = new Map();
  redlines.forEach((redline) => {
    const precedingContentId =
      redline.preceding_content_id === null
        ? redline.step_id
        : redline.preceding_content_id;
    if (!map.has(precedingContentId)) {
      map.set(precedingContentId, []);
    }
    map.get(precedingContentId).push(redline);
  });
  return map;
};

/**
 * Given an array of redline docs, get an array of run redlines sorted by their creation times.
 */
export const getRunRedlinesSorted = <RedlineT extends Redline>(
  redlines: Array<RedlineT> | undefined
): Array<RunRedlineFromRedlineT<RedlineT>> => {
  if (!redlines) {
    return [] as Array<RunRedlineFromRedlineT<RedlineT>>;
  }
  const runRedlines = redlines.map(getRedlineFromDoc);
  return revisionsUtil.sortRunRedlines(runRedlines) as Array<
    RunRedlineFromRedlineT<RedlineT>
  >;
};

export const getRedlineDocsSortedLatestToEarliest = <RedlineT extends Redline>(
  redlines: Array<RedlineT> | undefined
): Array<RedlineT> => {
  if (!redlines) {
    return [];
  }

  return redlines.sort((rlA, rlB) =>
    revisionsUtil.runRedlineComparatorLatestToEarliest(
      getRedlineFromDoc(rlA),
      getRedlineFromDoc(rlB)
    )
  );
};

/**
 * Given a list of redline docs of any redline type, get a map of the form {preceding_step_id: [added_step_redline]}.
 *
 * @param redlines - a list of redline docs of any type
 * @return a map of the form {preceding_step_id: [added_step_redline]}
 */
export const getAddedStepRedlineMap = (
  redlines: Array<Redline> | undefined
): Map<string, Array<AddedStepRedline>> => {
  const addedStepRedlineMap = new Map<string, Array<AddedStepRedline>>();
  if (!redlines) {
    return addedStepRedlineMap;
  }

  const addedStepRedlines = redlines.filter(
    (redline) => redline.type === REDLINE_TYPE.ADDED_STEP
  ) as Array<AddedStepRedline>;
  addedStepRedlines.forEach((redline) => {
    const precedingStepId = redline.preceding_step_id;
    if (!addedStepRedlineMap.has(precedingStepId)) {
      addedStepRedlineMap.set(precedingStepId, []);
    }
    addedStepRedlineMap.get(precedingStepId)?.push(redline);
  });

  return addedStepRedlineMap;
};

const _createScrollEntry = (
  fieldName: string,
  context: ScrollContext
): ScrollEntry => ({
  ...context,
  fieldName,
});

const _createAddedStepEntry = (
  id: string,
  context: ScrollContext
): ScrollEntry => _createScrollEntry(`${id}.stepAddedDuringRun`, context);

const _createTopOfStepEntry = (
  stepId: string,
  context: ScrollContext
): ScrollEntry => _createScrollEntry(`${stepId}.top`, context);

const _createNameEntry = (id: string, context: ScrollContext): ScrollEntry =>
  _createScrollEntry(`${id}.name`, context);

const _getBlockEntries = (
  content: Array<DraftStepBlock | RunStepBlock | DraftHeaderBlock>,
  blockMap: Map<string, Array<HeaderBlockRedline | StepBlockRedline>>,
  referenceId: string,
  context: ScrollContext
) => {
  return content
    .filter((block) => blockMap.has(block.id))
    .flatMap((block) => {
      return _createScrollEntry(`${referenceId}.content[${block.id}]`, context);
    });
};

const _getCommentEntries = (
  commentReferenceMap: Map<string, Array<DraftRedlineComment | ReviewComment>>,
  referenceId: string,
  context: ScrollContext
): Array<ScrollEntry> => {
  const comments = commentReferenceMap.get(referenceId);
  if (!comments) {
    return [];
  }
  return comments.map((comment) =>
    _createScrollEntry(`comment.[${comment.id}]`, context)
  );
};

const _getHeaderScrollEntries = (
  headers: Array<DraftHeader> | undefined,
  headerRedlineMap: Map<string, Array<HeaderFieldRedline | HeaderBlockRedline>>,
  commentReferenceMap: Map<string, Array<DraftRedlineComment | ReviewComment>>
): Array<HeaderFieldScrollEntry> => {
  if (!headers) {
    return [];
  }

  return headers.flatMap<HeaderFieldScrollEntry>((header) => {
    const entries: Array<HeaderFieldScrollEntry> = [];
    const headerContext = { headerId: header.id };
    const headerRedlines = headerRedlineMap.get(header.id);
    const hasHeaderFieldRedlines = headerRedlines?.some(
      (redline) => (getRedlineFromDoc(redline) as RunHeaderFieldRedline).field,
      []
    );
    if (hasHeaderFieldRedlines) {
      entries.push(
        _createNameEntry(header.id, headerContext) as HeaderFieldScrollEntry
      );
    }

    const headerBlockRedlines = getHeaderBlockRedlineMap(headerRedlines);
    const blockEntries = _getBlockEntries(
      header.content,
      headerBlockRedlines,
      header.id,
      headerContext
    ) as Array<HeaderFieldScrollEntry>;
    entries.push(...blockEntries);

    const commentEntries = _getCommentEntries(
      commentReferenceMap,
      header.id,
      headerContext
    ) as Array<HeaderFieldScrollEntry>;
    entries.push(...commentEntries);

    return entries;
  });
};

const _getStepScrollEntries = (
  sections: Array<DraftSection>,
  stepRedlineMap: Map<string, Array<StepRedline>>,
  commentReferenceMap: Map<string, Array<DraftRedlineComment | ReviewComment>>,
  scrollToTopOfStep?: boolean
): Array<StepFieldScrollEntry> => {
  return sections.flatMap<StepFieldScrollEntry>((section) => {
    return section.steps.flatMap<StepFieldScrollEntry>((step) => {
      const entries: Array<StepFieldScrollEntry> = [];
      const stepContext = {
        sectionId: section.id,
        stepId: step.id,
      };
      const isAddedStep = Boolean((step as DraftAddedStep).created_during_run);
      if (isAddedStep) {
        entries.push(
          _createAddedStepEntry(step.id, stepContext) as StepFieldScrollEntry
        );
        return entries; // Redlines on added steps are invisible in a draft, so do not make the scrollable.
      }

      const stepRedlines = stepRedlineMap.get(step.id);
      if (scrollToTopOfStep) {
        if ((stepRedlines ?? []).length > 0) {
          entries.push(
            _createTopOfStepEntry(step.id, stepContext) as StepFieldScrollEntry
          );
        }
      } else {
        const hasStepFieldRedlines = stepRedlines?.some(
          (redline) =>
            (redline as FullStepRedline).field ??
            (getRedlineFromDoc(redline) as RunStepFieldRedline).field
        );
        if (hasStepFieldRedlines) {
          entries.push(
            _createNameEntry(step.id, stepContext) as StepFieldScrollEntry
          );
        }

        const stepBlockRedlines = getStepBlockRedlineMap(stepRedlines);
        const blockEntries = _getBlockEntries(
          step.content,
          stepBlockRedlines,
          step.id,
          stepContext
        ) as Array<StepFieldScrollEntry>;
        entries.push(...blockEntries);
      }

      const commentEntries = _getCommentEntries(
        commentReferenceMap,
        step.id,
        stepContext
      ) as Array<StepFieldScrollEntry>;
      entries.push(...commentEntries);

      return entries;
    });
  });
};

export const getUnresolvedRedlines = (
  procedure: Draft,
  redlines: Array<Redline>
): Array<Redline> => {
  return redlines.filter((redline) => {
    if (
      redline.type === REDLINE_TYPE.ADDED_STEP ||
      isStepRedline(redline) ||
      redline.type === REDLINE_TYPE.HEADER_REDLINE
    ) {
      const matchingActionExists = procedure?.redline_actions?.some(
        (action) => action.redline_id === redline._id
      );
      return !matchingActionExists;
    } else if (redline.type === REDLINE_TYPE.REDLINE_COMMENT) {
      const matchingComment = procedure?.comments?.find(
        (comment) => (comment as DraftRedlineComment).redline_id === redline._id
      );
      return !matchingComment || !matchingComment.resolved;
    }
    return true;
  });
};

/**
 * Get an ordered list of redlines to scroll to.
 */
export const getScrollEntries = (
  procedure: Draft,
  redlineDocs: Array<Redline>,
  scrollToTopOfStep?: boolean
): Array<ScrollEntry> => {
  if (!procedure) {
    return [];
  }
  const unactionedRedlines = revisionsUtil.getUnactionedNonCommentRedlineDocs(
    procedure.redline_actions,
    redlineDocs
  );
  const commentReferenceMap: Map<
    string,
    Array<DraftRedlineComment | ReviewComment>
  > = revisionsUtil.getSortedCommentReferenceMap(procedure);

  const headerRedlineMap = getHeaderRedlineMap(unactionedRedlines);
  const headerScrollEntries = _getHeaderScrollEntries(
    procedure.headers,
    headerRedlineMap,
    commentReferenceMap
  );

  const stepRedlineMap = getStepRedlineMap(unactionedRedlines);
  const stepScrollEntries = _getStepScrollEntries(
    procedure.sections,
    stepRedlineMap,
    commentReferenceMap,
    scrollToTopOfStep
  );

  return [...headerScrollEntries, ...stepScrollEntries];
};

export const getUnactionedNonCommentRedlines = (
  procedure: Draft,
  redlines: Array<Redline>
): {
  unactionedNonCommentRedlines: Array<Redline>;
  detachedNonCommentRedlines: Array<Redline>;
} => {
  const allStepIds = getAllStepIds(procedure);
  const stepIdSet = new Set(allStepIds);

  const allHeaderIds = getAllProcedureHeaderIds(procedure);
  const headerIdSet = new Set(allHeaderIds);
  const allContentIds = getAllContentIds(procedure);
  const contentIdSet = new Set(allContentIds);

  const redlineDocs = revisionsUtil.getUnactionedNonCommentRedlineDocs(
    procedure.redline_actions,
    redlines
  );

  const [unactionedNonCommentRedlines, detachedNonCommentRedlines] = partition(
    redlineDocs ?? [],
    (redline) => {
      const isUnactionedStepRedline =
        isStepRedline(redline) && stepIdSet.has(redline.step_id);
      const isUnactionedHeaderRedline =
        redline.type === REDLINE_TYPE.HEADER_REDLINE &&
        headerIdSet.has(
          getRedlineFromDoc(redline as HeaderBlockRedline | HeaderFieldRedline)
            .header.id
        );
      const isRelevantStepRedline =
        isStepRedline(redline) &&
        (isFieldRedline(redline) ||
          contentIdSet.has(
            (redline as StepBlockRedline | FullStepRedline).content_id as string
          ) ||
          redline.preceding_content_id !== undefined);

      return (
        (isUnactionedStepRedline && isRelevantStepRedline) ||
        isUnactionedHeaderRedline ||
        redline.type === REDLINE_TYPE.ADDED_STEP
      );
    }
  );

  return { unactionedNonCommentRedlines, detachedNonCommentRedlines };
};

export const getCommentRedlines = (
  procedure: Draft,
  redlines: Array<Redline>
): {
  commentRedlines: Array<Redline>;
  detachedCommentRedlines: Array<Redline>;
} => {
  const allStepIdsInProcedure = getAllStepIds(procedure);
  const addedStepIds = redlines
    .filter(
      (redline): redline is AddedStepRedline =>
        redline.type === REDLINE_TYPE.ADDED_STEP
    )
    .map(getRedlineFromDoc)
    .map((addedStep) => addedStep.id);
  const stepIdSet = new Set([...allStepIdsInProcedure, ...addedStepIds]);

  const redlineComments = redlines.filter(
    (redline) => redline.type === REDLINE_TYPE.REDLINE_COMMENT
  );

  const [commentRedlines, detachedCommentRedlines] = partition(
    redlineComments,
    (comment) => stepIdSet.has((comment as RedlineComment).step_id)
  );

  return { commentRedlines, detachedCommentRedlines };
};
