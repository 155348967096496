import superlogin from '../superlogin';
import { COUCHDB_URL, NEW_LOGIN_ACTIVE } from '../../config';
import fetchJson from './fetch';

// Fetches changes from the couchdb _changes endpoint.
const fetchCouchdbChanges = async (name, docIds, lastSeq, signal) => {
  // Create url for couchdb changes endpoint.
  const url = new URL(`${COUCHDB_URL}/${name}/_changes`);

  // Filter to explicit document ids if present.
  const body = {};

  // if docIds were passed in filter to changes for those documents
  if (docIds && docIds.length > 0) {
    body.doc_ids = docIds;
    url.searchParams.append('filter', '_doc_ids');
  }

  if (lastSeq) {
    // Wait for the next change sequence with the 'longpoll' feed.
    url.searchParams.append('feed', 'longpoll');
    url.searchParams.append('since', lastSeq);
  } else {
    // Get the current change sequence immediately with the 'normal' feed.
    url.searchParams.append('feed', 'normal');
    url.searchParams.append('since', 'now');
  }

  // POST is recommended when using doc_ids.
  const options = {
    method: 'POST',
    credentials: 'include',
    body: JSON.stringify(body),
    signal,
  };

  return fetchJson(url, options);
};

/**
 * Realtime listener for couchdb database changes.
 *
 * Notifies the onChange handler when the feed is first initialized with the
 * current change update sequence, and on subsequent changes.
 *
 * @param {String} name - Name of couchdb database.
 * @param {Array | null} docIds - (Optional) List of document ids to filter changes.
 * @param {Function} onChange - Called with couchdb changes response object.
 * @param {Function} onError - Called with any error.
 * @returns {Object} - Object with field 'cancel' to cancel the listener.
 */
const changes = (name, docIds, onChange, onError) => {
  if (!NEW_LOGIN_ACTIVE) {
    let aborted = false;
    let lastSeq = null;
    let controller = null;

    const fetchNextChange = () => {
      // Exit if listener has been aborted.
      if (aborted) {
        return;
      }
      controller = new AbortController();
      fetchCouchdbChanges(name, docIds, lastSeq, controller.signal)
        .then((changes) => {
          const didChange = changes.last_seq && lastSeq !== changes.last_seq;
          lastSeq = changes.last_seq;
          // Notify listeners if a change is detected.
          if (didChange) {
            onChange && onChange(changes);
          }
          // Schedule next change request to happen immediately
          setTimeout(fetchNextChange);
        })
        .catch((error) => onError && onError(error));
    };

    // Start initial fetch
    fetchNextChange();

    return {
      cancel: () => {
        if (controller) {
          controller.abort();
        }
        controller = null;
        aborted = true;
      },
    };
  }
};

// Fetches changes from an API /changes endpoint.
const fetchResourceChanges = async (url, lastSeq, signal) => {
  // Params for resource request.
  const params = {};

  if (lastSeq) {
    // Wait for the next change sequence by providing last known checkpoint.
    params.since = lastSeq;
  }

  const options = {
    params,
    signal,
  };
  const response = await superlogin.getHttp().get(url, options);
  return response.data;
};

/**
 * Realtime listener for API resource changes.
 *
 * Notifies the onChange handler when the feed is first initialized with the
 * current change update sequence, and on subsequent changes.
 *
 * @param {String} url - Full url to an API changes endpoint.
 * @param {Function} onChange - Called with API changes response object.
 * @param {Function} onError - Called with any error.
 * @returns {Object} - Object with field 'cancel' to cancel the listener.
 */
const resourceChanges = (url, onChange, onError) => {
  let aborted = false;
  let lastSeq = null;
  let controller = null;

  const fetchNextChange = () => {
    // Exit if listener has been aborted.
    if (aborted) {
      return;
    }
    controller = new AbortController();
    fetchResourceChanges(url, lastSeq, controller.signal)
      .then((changes) => {
        const didChange = changes.last_seq && lastSeq !== changes.last_seq;
        lastSeq = changes.last_seq;
        // Notify listeners if a change is detected.
        if (didChange) {
          onChange && onChange(changes);
        }
        // Schedule next change request to happen immediately
        setTimeout(fetchNextChange);
      })
      .catch((error) => {
        onError && onError(error);
      });
  };

  // Start initial fetch
  fetchNextChange();

  return {
    cancel: () => {
      if (controller) {
        controller.abort();
      }
      controller = null;
      aborted = true;
    },
  };
};

export { changes, resourceChanges };
