import { useEffect, useMemo, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEqual } from 'lodash';
import externalDataUtil from '../lib/externalDataUtil';
import { useDatabaseServices } from '../contexts/DatabaseContext';
import { fetchProcedureById, selectProcedureById } from '../contexts/proceduresSlice';
import { useSettings } from '../contexts/SettingsContext';
import { useRealtimeContext } from '../contexts/RealtimeContext';
import apm from '../lib/apm';
import { selectOfflineInfo } from '../app/offline';

const useProcedureObserver = ({ id }) => {
  const isMounted = useRef(true);
  const { services, currentTeamId } = useDatabaseServices();
  const { isPostgresOnlyEnabled } = useSettings();
  const { realtimeService } = useRealtimeContext();
  const [loading, setLoading] = useState(true);
  const reduxProcedure = useSelector((state) => selectProcedureById(state, currentTeamId, id), isEqual);
  const [externalItems, setExternalItems] = useState(null);
  const dispatch = useDispatch();
  const [localProcedure, setLocalProcedure] = useState(null);
  const isOnline = useSelector((state) => selectOfflineInfo(state).online);

  // Flag for detecting when component is unmounted.
  useEffect(
    () => () => {
      isMounted.current = false;
    },
    []
  );

  /**
   * Updated version of procedure with any "dynamic" data checks. Currently
   * used to fetch external data items when viewing the procedure.
   *
   * If dynamic data fails to load, we fall back to using the original procedure.
   */
  const dynamic = useMemo(() => {
    const procedure = localProcedure ?? reduxProcedure;
    if (!procedure || !externalItems) {
      return procedure;
    }
    return externalDataUtil.updateProcedureWithItems(procedure, externalItems);
  }, [externalItems, localProcedure, reduxProcedure]);

  // Load procedure and observe changes.
  useEffect(() => {
    if (!id || !services.procedures || !realtimeService || !isOnline) {
      setLoading(false);
      return;
    }

    // Fetch dynamic procedure data, currently external data items.
    const loadExternalItems = async (procedure) => {
      try {
        const externalItems = await services.externalData.getAllExternalItems(procedure);
        setExternalItems(externalItems);
      } catch {
        // Ignore errors and fall back to using procedure without dynamic data.
      }
    };

    // Procedure document change handler.
    const refreshProcedure = async () => {
      if (!isMounted.current) {
        return;
      }
      setLoading(true);

      try {
        const { procedure } = await dispatch(
          fetchProcedureById({
            services,
            procedureId: id,
          })
        ).unwrap();

        await loadExternalItems(procedure);
        setLocalProcedure(procedure);
      } catch {
        // Ignore errors, we may be offline.
      } finally {
        setLoading(false);
      }
    };

    let procedureObserver;
    if (isPostgresOnlyEnabled()) {
      procedureObserver = realtimeService.onProcedureEvent(id, refreshProcedure);
    } else {
      procedureObserver = services.procedures.onProcedureChanged(id, refreshProcedure);
    }

    // Call refreshProcedures to fetch the procedures the first time.
    refreshProcedure().catch((err) => apm.captureError(err));

    return () => {
      procedureObserver.cancel();
    };
  }, [id, services, realtimeService, currentTeamId, dispatch, isPostgresOnlyEnabled, isOnline]);

  return {
    procedure: dynamic,
    loading,
  };
};

export default useProcedureObserver;
