import React, { useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ButtonActionIcon from '../components/ButtonActionIcon';
import { useDatabaseServices } from '../contexts/DatabaseContext';
import { useMixpanel } from '../contexts/MixpanelContext';
import procedureUtil from '../lib/procedureUtil';
import { useUserInfo } from '../contexts/UserContext';
import { PERM } from '../lib/auth';
import { useAuth } from '../contexts/AuthContext';
import { startRun } from '../contexts/runsSlice';
import printPDF from '../lib/printPDF';
import { newRunDoc } from 'shared/lib/runUtil';
import {
  isDraft as getIsDraft,
  isInReview as getIsInReview,
  isReleased as getIsReleased,
} from 'shared/lib/procedureUtil';
import ModalDuplicateProcedure from './ModalDuplicateProcedure';
import { Release } from 'shared/lib/types/views/procedures';
import {
  procedureEditPath,
  procedureReviewPath,
  procedureRunsPath,
  proceduresPath,
  runViewPath,
  testingPlansPath,
} from '../lib/pathUtil';
import usePendingChangesPrompt from '../hooks/usePendingChangesPrompt';
import Button, { BUTTON_TYPES } from './Button';
import { useSettings } from '../contexts/SettingsContext';
import useAutoProcedureId from '../hooks/useAutoProcedureId';
import { isProcedureWithBatchSteps, prepareBatchStepsForRun } from '../lib/batchSteps';
import RunBatchProcedureModal from './BatchSteps/RunBatchProcedureModal';
import { cloneDeep } from 'lodash';
import configUtil from '../lib/configUtil';
import { selectOfflineInfo } from '../app/offline';

const ARCHIVE_PROCEDURE_MESSAGE =
  'Any links to this procedure will be broken. Do you still want to archive this procedure?';
export const INVALID_ITEMS_MESSAGE = 'Are you sure you want to run this procedure? Some items are invalid.';

interface ButtonsProcedureProps {
  procedure: Release;
  pending?: Release;
  hasInvalidExternalItems?: boolean;
  isRunDisabled: boolean;
  isEditDisabled?: boolean;
  isDuplicateDisabled: boolean;
  isArchiveDisabled: boolean;
  isRunHistoryDisabled: boolean;
  isDraftDisabled?: boolean;
  hideRunActions: boolean;
  hideEditActions: boolean;
  onShowVersionHistory: () => void;
  showDraftAction?: boolean;
  projectId: string | null;
}

/**
 * Render a small widget with procedure actions like "Duplicate" and "Edit Draft".
 *
 * TODO: This approach is a little unsustainable as the action list grows
 * and the needs change. Figure out an alternative approach that's more flexible
 * and has a cleaner interface.
 */
const ButtonsProcedure = ({
  procedure,
  pending,
  hasInvalidExternalItems,
  isRunDisabled,
  isEditDisabled,
  isDuplicateDisabled,
  isArchiveDisabled,
  isRunHistoryDisabled,
  isDraftDisabled,
  hideRunActions,
  hideEditActions,
  onShowVersionHistory,
  showDraftAction,
  projectId,
}: ButtonsProcedureProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { services, currentTeamId } = useDatabaseServices();
  const { mixpanel } = useMixpanel();
  const { userInfo } = useUserInfo();
  const userId = userInfo.session.user_id;
  const { auth } = useAuth();
  const [showDuplicateModal, setShowDuplicateModal] = useState(false);
  const [showBatchRunModal, setShowBatchRunModal] = useState(false);
  const confirmPendingChanges = usePendingChangesPrompt();
  const { config } = useSettings();
  const { tryUpdateDocWithUniqueId } = useAutoProcedureId();
  const isOnline = useSelector((state) => selectOfflineInfo(state).online);

  const mixpanelTrack = useCallback(
    (trackingString: string, properties?: object | undefined) => {
      if (mixpanel) {
        mixpanel.track(trackingString, properties);
      }
    },
    [mixpanel]
  );

  const createAndStartRun = useCallback(
    (procedureToRun: Release) => {
      let userParticipantType;
      if (config) {
        userParticipantType = configUtil.getUserParticipantType(config);
      }
      const run = newRunDoc({
        procedure: procedureToRun as Release,
        userId,
        userParticipantType,
      });
      dispatch(startRun({ teamId: currentTeamId, run }));
      mixpanelTrack('Run Procedure', { Source: 'Procedure View' });
      history.push(runViewPath(currentTeamId, run._id));
    },
    [config, userId, dispatch, currentTeamId, mixpanelTrack, history]
  );

  const onStartRun = useCallback(async () => {
    if (!(await confirmPendingChanges(procedure))) {
      return;
    }
    if (hasInvalidExternalItems && !window.confirm(INVALID_ITEMS_MESSAGE)) {
      return;
    }
    if (isProcedureWithBatchSteps(procedure)) {
      setShowBatchRunModal(true);
      return;
    }
    createAndStartRun(procedure);
  }, [procedure, hasInvalidExternalItems, confirmPendingChanges, setShowBatchRunModal, createAndStartRun]);

  const onStartBatchRun = useCallback(
    (batchSize: number) => {
      const procedureToRun = cloneDeep(procedure);
      prepareBatchStepsForRun(procedureToRun, batchSize);
      createAndStartRun(procedureToRun);
    },
    [procedure, createAndStartRun]
  );

  const onCancelBatchRun = useCallback(() => {
    setShowBatchRunModal(false);
  }, [setShowBatchRunModal]);

  const archiveProcedure = useCallback(() => {
    if (!window.confirm(ARCHIVE_PROCEDURE_MESSAGE)) {
      return;
    }

    services.procedures
      .archiveProcedure(procedure)
      .then(() => {
        if (procedure.procedure_type === 'testPlan' || procedure.procedure_type === 'testSequence') {
          history.push(testingPlansPath(currentTeamId));
        } else {
          // Return user to the Procedure List
          history.push(proceduresPath(currentTeamId));
        }
      })
      .catch(() => {
        /* Ignore */
      });
  }, [procedure, services.procedures, history, currentTeamId]);

  const deleteDraft = useCallback(() => {
    const message = 'Are you sure you want to delete this draft?';
    if (!window.confirm(message)) {
      return;
    }

    services.procedures
      .deleteDraft(procedure._id)
      .then(() => {
        if (procedure.procedure_type === 'testPlan' || procedure.procedure_type === 'testSequence') {
          history.push(testingPlansPath(currentTeamId));
        } else {
          // Return user to the Procedure List
          history.push(proceduresPath(currentTeamId));
        }
      })
      .catch(() => {
        /* Ignore */
      });
  }, [procedure, services.procedures, history, currentTeamId]);

  const showRunActions = useMemo(
    () => auth.hasPermission(PERM.RUNS_EDIT, projectId) && !hideRunActions,
    [auth, projectId, hideRunActions]
  );

  const showEditActions = useMemo(
    () => auth.hasPermission(PERM.PROCEDURES_EDIT, projectId) && !hideEditActions,
    [auth, projectId, hideEditActions]
  );

  const isReleased = useMemo(() => getIsReleased(procedure), [procedure]);

  const isInReview = useMemo(() => getIsInReview(procedure), [procedure]);

  const isInDraft = useMemo(() => getIsDraft(procedure), [procedure]);

  const hasDraft = useMemo(() => pending && getIsDraft(pending), [pending]);

  const hasInReview = useMemo(() => pending && getIsInReview(pending), [pending]);

  const procedureId = useMemo(() => procedureUtil.getProcedureId(procedure), [procedure]);

  // For existing procedures without auto id generation, ask user to opt in upon new drafts
  const onDraftAction = useCallback(async () => {
    if (!hasDraft && config?.auto_procedure_id_enabled && !procedure.auto_procedure_id_enabled) {
      if (window.confirm('Do you want to auto generate the procedure ID?')) {
        try {
          const draft = procedureUtil.newDraft(procedure, !!config?.auto_procedure_id_enabled, config?.version);
          draft.auto_procedure_id_enabled = true;
          await tryUpdateDocWithUniqueId(draft, true);
          await services.procedures.saveDraft(draft);
        } catch {
          /* no-op */
        }
      }
    }
    history.push(procedureEditPath(currentTeamId, procedureId));
  }, [
    config?.auto_procedure_id_enabled,
    config?.version,
    currentTeamId,
    hasDraft,
    history,
    procedure,
    procedureId,
    services.procedures,
    tryUpdateDocWithUniqueId,
  ]);

  const onClickRunHistory = useCallback(() => {
    mixpanelTrack('Navigated to Insights');
    history.push(procedureRunsPath(currentTeamId, procedureId));
  }, [mixpanelTrack, history, procedureId, currentTeamId]);

  return (
    <div className="flex flex-col shrink-0 items-end ml-2 print:hidden">
      {showDuplicateModal && (
        <ModalDuplicateProcedure procedure={procedure} onCancel={() => setShowDuplicateModal(false)} />
      )}
      {showBatchRunModal && <RunBatchProcedureModal onRun={onStartBatchRun} onCancel={onCancelBatchRun} />}
      {showRunActions && (
        <Button type={BUTTON_TYPES.PRIMARY} ariaLabel="Run Procedure" onClick={onStartRun} isDisabled={isRunDisabled}>
          Run Procedure
        </Button>
      )}
      {/* Action buttons and settings */}
      <div className="mt-2">
        {showEditActions && (
          <div className="flex flex-row justify-end mt-2">
            {!showDraftAction && (
              <ButtonActionIcon
                ariaLabel="Edit Procedure"
                icon={['fas', isReleased ? 'plus' : 'edit']}
                label={isReleased ? 'New Draft' : 'Edit'}
                disabled={isEditDisabled || !isOnline}
                onAction={() => history.push(procedureEditPath(currentTeamId, procedureId))}
              />
            )}
            {showDraftAction && !hasInReview && (
              <ButtonActionIcon
                ariaLabel="Edit Procedure"
                icon={['fas', hasDraft ? 'edit' : 'plus']}
                label={hasDraft ? 'Edit Draft' : 'New Draft'}
                disabled={isDraftDisabled || !isOnline}
                onAction={onDraftAction}
              />
            )}
            {showDraftAction && hasInReview && (
              <ButtonActionIcon
                ariaLabel="Review Procedure"
                icon="clipboard-check"
                label="Review Draft"
                disabled={isDraftDisabled || !isOnline}
                onAction={() => history.push(procedureReviewPath(currentTeamId, procedureId))}
              />
            )}
            <ButtonActionIcon
              ariaLabel="Duplicate Procedure"
              icon={['far', 'copy']}
              label="Duplicate"
              onAction={() => setShowDuplicateModal(true)}
              disabled={isDuplicateDisabled || !isOnline}
            />
            {(isInDraft || isInReview) && (
              <ButtonActionIcon
                ariaLabel="Delete Draft"
                icon={['fas', 'trash']}
                iconType="caution"
                label="Delete Draft"
                disabled={isEditDisabled || !isOnline}
                onAction={deleteDraft}
              />
            )}
            {isReleased && (
              <ButtonActionIcon
                ariaLabel="Archive Procedure"
                icon={['fas', 'archive']}
                label="Archive"
                iconType="caution"
                onAction={archiveProcedure}
                disabled={isArchiveDisabled || !isOnline}
              />
            )}
          </div>
        )}
        <div className="flex flex-row justify-end mt-2">
          <ButtonActionIcon
            ariaLabel="Insights"
            icon="eye"
            label="Insights"
            disabled={isRunHistoryDisabled || !isOnline}
            onAction={onClickRunHistory}
          />
          <ButtonActionIcon
            ariaLabel="Version History"
            icon={['fas', 'clock']}
            label="Versions"
            onAction={onShowVersionHistory}
            disabled={!isOnline}
          />
          <ButtonActionIcon ariaLabel="Print" icon={['fas', 'print']} label="Print" onAction={printPDF} />
        </div>
      </div>
    </div>
  );
};

export default ButtonsProcedure;
