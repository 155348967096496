import { useMemo } from 'react';
import { useSettings } from '../../contexts/SettingsContext';
import Button, { BUTTON_TYPES } from '../Button';
import { Dialog } from 'primereact/dialog';
import { Project } from 'shared/lib/types/couch/settings';
import Label from '../Label';
import { MAX_LENGTH } from '../../lib/tableTextUtil';
import pluralize from 'pluralize';
import SimpleDialogFooter from '../../elements/SimpleDialogFooter';

interface ChangeProjectModalProps {
  selectedProject: Project | null;
  onChangeProject: () => void;
  onCancel: () => void;
  changeCount: number;
  entityName?: string;
}

const ChangeProjectModal = ({
  selectedProject,
  onChangeProject,
  onCancel,
  changeCount,
  entityName = 'procedure',
}: ChangeProjectModalProps) => {
  const { config } = useSettings();

  const footer = useMemo(
    () => (
      <SimpleDialogFooter>
        <Button
          type={BUTTON_TYPES.PRIMARY}
          title="Confirm Change Project"
          ariaLabel="Confirm Change Project"
          onClick={onChangeProject}
        >
          Proceed
        </Button>
        <Button
          type={BUTTON_TYPES.SECONDARY}
          title="Cancel Change Project"
          ariaLabel="Cancel Change Project"
          onClick={onCancel}
        >
          Cancel
        </Button>
      </SimpleDialogFooter>
    ),
    [onCancel, onChangeProject]
  );

  return (
    <Dialog
      visible={Boolean(selectedProject)}
      header="Change Project"
      footer={footer}
      onHide={onCancel}
      draggable={false}
      closable={true}
      keepInViewport={false}
      className="w-1/2"
    >
      <div className="flex whitespace-nowrap">
        {`${pluralize(entityName, changeCount, true)} will change project to `}
        <Label text={selectedProject?.name ?? ''} color="bg-gray-200" maxLength={MAX_LENGTH.lg} />
      </div>
      <ul className="ml-4 list-disc py-1">
        {config?.auto_procedure_id_enabled && (
          <li>
            Changing the project may affect procedure ID.&nbsp;
            <a
              target="_blank"
              rel="noreferrer"
              href="https://support.epsilon3.io/en/articles/9416858-auto-generate-procedure-id"
              className="link font-medium"
            >
              Learn More.
            </a>
          </li>
        )}
        <li>
          Changing the project may affect references to or from procedures, including linked procedures.&nbsp;
          <a
            target="_blank"
            rel="noreferrer"
            href="https://support.epsilon3.io/en/articles/7934263-project-permissions"
            className="link font-medium"
          >
            Learn More.
          </a>
        </li>
      </ul>
    </Dialog>
  );
};

export default ChangeProjectModal;
