import { MouseEvent } from 'react';
import { getIsReviewApproved } from 'shared/lib/reviewUtil';
import {
  isDraft,
  isInReview,
  isReleased,
  isPending,
} from 'shared/lib/procedureUtil';
import {
  ProcedureMetadata,
  StartRunSignoffsGroup,
} from 'shared/lib/types/couch/procedures';
import { ProcedureTitleMetadata, RowAction } from '../components/Home/types';
import {
  procedureEditPath,
  procedurePendingPath,
  procedureReviewPath,
  procedureViewPath,
} from './pathUtil';
import procedureUtil from './procedureUtil';
import { DefaultView } from 'shared/lib/types/postgres/users';
import { RedlineMetadata } from 'shared/lib/types/views/redlines';
import { Tag as GlobalTag } from 'shared/lib/types/api/settings/tags/models';

// temporarily extract common functions for use in Home.tsx and Landing grids
const homeUtil = {
  getDraftAction: (
    procedure: ProcedureMetadata,
    procedures: ProcedureMetadata[],
    currentTeamId: string,
    isOnline: boolean
  ): RowAction | null => {
    if (!isOnline) {
      return null;
    }
    const pending = homeUtil.getPendingProcedure(procedure, procedures);
    if (!pending) {
      return null;
    }
    const procedureId = procedureUtil.getProcedureId(pending);
    if (isDraft(pending)) {
      return homeUtil.getDraftActionEdit(procedureId, currentTeamId);
    }
    if (isInReview(pending)) {
      const isReviewApproved = getIsReviewApproved(pending);
      return homeUtil.getDraftActionReview(
        procedureId,
        isReviewApproved,
        currentTeamId
      );
    }
    return null;
  },

  getDraftActionEdit: (
    procedureId: string,
    currentTeamId: string
  ): RowAction => {
    return {
      componentType: 'buttonWithIcon',
      label: 'Edit Draft',
      icon: 'edit',
      to: procedureEditPath(currentTeamId, procedureId),
    };
  },

  getDraftActionReview: (
    procedureId: string,
    isReviewApproved: boolean,
    currentTeamId: string
  ): RowAction => {
    return {
      componentType: 'buttonWithIcon',
      label: isReviewApproved ? 'Ready to Release' : 'Review',
      icon: isReviewApproved ? 'rocket' : 'clipboard',
      to: procedureReviewPath(currentTeamId, procedureId),
    };
  },

  getRunAction: ({
    procedureMetadata,
    canStartRun,
    allStartRunSignoffOperators,
    userOperatorRolesSet,
    onClick,
  }: {
    procedureMetadata: ProcedureMetadata;
    canStartRun: boolean;
    allStartRunSignoffOperators: StartRunSignoffsGroup[] | undefined;
    userOperatorRolesSet: Set<string>;
    onClick: (event: MouseEvent<HTMLButtonElement>) => void;
  }): RowAction => {
    const userHasStartRunSignoffs = procedureUtil.userHasStartRunSignoffs(
      allStartRunSignoffOperators,
      userOperatorRolesSet
    );
    return {
      tooltipEnabled: userHasStartRunSignoffs ? false : true,
      tooltipContent: userHasStartRunSignoffs
        ? ''
        : 'You do not have an operator role with permission to start this run.',
      componentType: 'button',
      label: 'Run',
      onClick,
      disabled:
        !userHasStartRunSignoffs ||
        !canStartRun ||
        isDraft(procedureMetadata) ||
        isInReview(procedureMetadata),
    };
  },

  getPendingProcedure: (
    procedure: ProcedureMetadata,
    procedures: ProcedureMetadata[]
  ): ProcedureMetadata | undefined => {
    if (isPending(procedure)) {
      return procedure;
    }
    return procedures.find((p) => p.procedure_id === procedure._id);
  },

  getProcedureLink: (
    procedure: ProcedureMetadata,
    currentTeamId: string
  ): string => {
    const procedureId = procedureUtil.getProcedureId(procedure);
    if (isDraft(procedure)) {
      return procedurePendingPath(currentTeamId, procedureId);
    }
    if (isInReview(procedure)) {
      return procedureReviewPath(currentTeamId, procedureId);
    }
    return procedureViewPath(currentTeamId, procedureId);
  },

  getProcedureTitle: (
    procedure: ProcedureMetadata,
    synced: boolean | undefined,
    currentTeamId: string,
    getTagsForEntity: (entityId: string) => Array<GlobalTag>,
    isGlobalTagsEnabled: () => boolean,
    showSyncStatus?: boolean
  ): ProcedureTitleMetadata => {
    const metadata: ProcedureTitleMetadata = {
      label: procedure.code ? procedure.code : '000',
      name: procedure.name ? procedure.name : 'Untitled Procedure',
      link: homeUtil.getProcedureLink(procedure, currentTeamId),
      tags: isGlobalTagsEnabled() ? [] : procedure.tags || [],
      globalTags: isGlobalTagsEnabled() ? getTagsForEntity(procedure._id) : [],
    };
    if (synced === undefined) {
      return metadata;
    }
    const syncParams = {
      synced: {
        icon: 'check-circle',
        iconTitle: 'Procedure data synced',
      },
      unsynced: {
        icon: 'sync',
        iconTitle: 'Procedure data syncing',
      },
    } as const;
    if (showSyncStatus) {
      metadata.iconColor = 'text-gray-400';
      const syncStatus: keyof typeof syncParams =
        synced === true ? 'synced' : 'unsynced';
      metadata.icon = syncParams[syncStatus].icon;
      metadata.iconTitle = syncParams[syncStatus].iconTitle;
    }
    return metadata;
  },

  getRedlinesMetadataMap: (
    redlines: Array<RedlineMetadata>
  ): Record<string, number> => {
    if (!redlines) {
      return {};
    }
    const redlinesMetadataMap = {};
    redlines.forEach((redline) => {
      if (!redline.procedure_id) {
        return;
      }
      if (redlinesMetadataMap[redline.procedure_id] === undefined) {
        redlinesMetadataMap[redline.procedure_id] = 1;
      } else {
        redlinesMetadataMap[redline.procedure_id]++;
      }
    });
    return redlinesMetadataMap;
  },

  getReleasedVersion: (
    procedure: ProcedureMetadata,
    procedures: ProcedureMetadata[]
  ): string | undefined => {
    if (isReleased(procedure)) {
      return procedure.version;
    }
    const procedureId = procedureUtil.getProcedureId(procedure);
    const released = procedures.find((p) => p._id === procedureId);
    if (!released) {
      return undefined;
    }
    return released.version;
  },

  hasReviewVersion: (
    procedure: ProcedureMetadata,
    procedures: ProcedureMetadata[]
  ): boolean => {
    const pending = homeUtil.getPendingProcedure(procedure, procedures);
    if (pending) {
      return isInReview(pending);
    }
    return false;
  },

  hasCustomizedDefaultView: (
    defaultView: DefaultView | null,
    screen: 'runs' | 'procedures'
  ): boolean => {
    return Boolean(
      defaultView &&
        (defaultView.project_ids?.length > 0 ||
          (screen === 'runs' && defaultView.run_tags?.length > 0) ||
          (screen === 'procedures' && defaultView.tags?.length > 0))
    );
  },
  tabCount: (listLength: number): number | undefined => {
    return listLength > 0 ? listLength : undefined;
  },
};

export default homeUtil;
