import { useDatabaseServices } from '../contexts/DatabaseContext';
import { Mention } from 'shared/lib/types/postgres/util';
import { generateCommentId } from 'shared/lib/idUtil';
import { DatabaseServices } from '../contexts/proceduresSlice';
import { AttachmentValue } from 'shared/lib/types/views/procedures';

interface useSaveCommentProps {
  sectionId: string;
  stepId: string;
  parentId?: string;
  onSaveNewComment?: (comment) => Promise<void>;
}

interface useSaveCommentReturn {
  onSaveComment: (values: {
    text: string;
    mentions: Array<Mention>;
    attachments: Array<AttachmentValue>;
  }) => Promise<void>;
}

const useSaveComment = ({
  sectionId,
  stepId,
  parentId,
  onSaveNewComment,
}: useSaveCommentProps): useSaveCommentReturn => {
  const { services }: { services: DatabaseServices } = useDatabaseServices();

  const onSaveComment = ({
    text,
    mentions,
    attachments,
  }: {
    text: string;
    mentions: Array<Mention>;
    attachments: Array<AttachmentValue>;
  }): Promise<void> => {
    if (!services.attachments) {
      return Promise.reject('Attachment services unavailable');
    }

    if (!onSaveNewComment) {
      return Promise.reject('Unable to save new comment');
    }

    const comment = {
      id: generateCommentId(),
      parent_id: parentId,
      sectionId,
      stepId,
      text,
      mention_list: mentions,
      attachments,
    };

    return onSaveNewComment(comment);
  };

  return {
    onSaveComment,
  };
};

export default useSaveComment;
