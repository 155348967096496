import React, { Fragment, useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { useTeamsInfo } from '../contexts/TeamsContext';
import { useDatabaseServices } from '../contexts/DatabaseContext';
import { useSettings } from '../contexts/SettingsContext';
import { homePath, orgSettingsPath } from '../lib/pathUtil';
import useTeamFromPath from '../hooks/useTeamFromPath';
import useMenu from '../hooks/useMenu';
import { useAuth } from '../contexts/AuthContext';
import { useNavState } from '../contexts/NavContext';

interface Props {
  hideLabels: boolean;
}

const MenuWorkspace = ({ hideLabels }: Props) => {
  const { userOrgData } = useTeamsInfo();
  const { updateCurrentTeamId, currentTeamId } = useDatabaseServices();
  const history = useHistory();
  const { config } = useSettings();
  const navState = useNavState();
  const allTeamIds = userOrgData && userOrgData.map((org) => org.teamIds).flat();
  const workspaceName = config ? config.name : '';
  const teamFromPath = useTeamFromPath();
  const { isMenuVisible, displayMenu } = useMenu();
  const { auth } = useAuth();

  const selectorEnabled = useMemo(() => {
    // Enable if user has multiple workspace to toggle between
    if (allTeamIds && allTeamIds.length > 0) {
      return true;
    }

    // Enable if org admin in any org
    for (const org of userOrgData ?? []) {
      if (auth.isOrgAdmin(org.id)) {
        return true;
      }
    }

    return false;
  }, [allTeamIds, auth, userOrgData]);

  const switchTeam = useCallback(
    (teamId) => {
      // Redirect to homepage first to allow prompts to run before changing team id.
      history.push(homePath(teamId));

      navState.setProjectId(undefined);

      // TODO: Find a better way to do this, we shouldn't be using the path to update the team id state here.
      if (teamFromPath !== teamId) {
        updateCurrentTeamId(teamId);
      }
    },
    [history, navState, teamFromPath, updateCurrentTeamId]
  );

  const navigateToOrgSettings = useCallback(
    (orgId) => {
      const orgTeams = userOrgData?.find((org) => org.id === orgId);
      if (orgTeams && !orgTeams.teamIds.includes(currentTeamId) && orgTeams.teamIds.length > 0) {
        updateCurrentTeamId(orgTeams.teamIds[0]);
      }
      history.push(orgSettingsPath(orgId));
    },
    [history, currentTeamId, updateCurrentTeamId, userOrgData]
  );

  return (
    <>
      {!hideLabels && (
        <div className="flex items-stretch pl-5 mb-2 text-sm">
          {selectorEnabled ? (
            <button
              title="Change Workspace"
              aria-label="Change Workspace"
              className="pr-1 text-white"
              onClick={displayMenu}
            >
              {workspaceName}
              {selectorEnabled && <FontAwesomeIcon icon={faCaretDown} className="text-white text-sm ml-1" />}
            </button>
          ) : (
            <div className="pr-1 border-gray-400">{workspaceName}</div>
          )}
        </div>
      )}

      {hideLabels && <div className="h-4  mb-2"></div>}

      {selectorEnabled && isMenuVisible && (
        <div className="absolute mt-2 left-4 z-50 min-w-[13rem] max-w-[13rem] ">
          <div className="bg-white flex flex-col rounded shadow-md border mr-4">
            {userOrgData &&
              userOrgData.map((org, index) => (
                <div key={org.id}>
                  <div
                    className={`flex flex-row ${
                      index > 0 && 'border-t border-gray-200'
                    } justify-between mx-2 pt-1 p-1 text-slate-500 text-sm items-center`}
                  >
                    <div className="flex flex-grow pt-1">{org.name}</div>
                    {auth.isOrgAdmin(org.id) && (
                      <div
                        className="p-1 -mr-2 justify-end hover:bg-slate-200 rounded-md cursor-pointer"
                        onClick={() => navigateToOrgSettings(org.id)}
                      >
                        <FontAwesomeIcon fixedWidth={true} icon="gear" aria-label="Navigate to organization settings" />
                      </div>
                    )}
                  </div>
                  {org.teams &&
                    org.teams.map((team) => (
                      <div
                        key={team.id}
                        className="flex flex-row items-center py-1 pl-2 mx-1 mb-1 text-slate-800 cursor-pointer hover:bg-slate-200 rounded"
                        onClick={() => switchTeam(team.id)}
                      >
                        <div className="text-[8pt] mx-1 w-2">
                          {team.id === currentTeamId && <FontAwesomeIcon icon="circle" size="2xs" />}
                        </div>
                        {team.name}
                      </div>
                    ))}
                </div>
              ))}
          </div>
        </div>
      )}
    </>
  );
};

export default MenuWorkspace;
