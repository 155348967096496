/**
 * Redux slice for storing and managing run documents.
 *
 * Note that reducers with `createSlice` have Immer enabled, see [0].
 *
 * [0] https://redux-toolkit.js.org/usage/immer-reducers
 */
import { createSlice, createAsyncThunk, Action } from '@reduxjs/toolkit';
import cloneDeep from 'lodash.clonedeep';
import {
  updateDocWithFullStepRedline,
  updateDocWithAddedStep,
  updateDocWithComment,
  updateDocWithEndRun,
  updateDocWithRunReopened,
  updateDocWithStepSignoffRevoked,
  updateDocWithLinkedRun,
  removeLinkedRunFromDoc,
  updateDocWithParticipantAdded,
  updateDocWithParticipantRemoved,
  updateRunWithStepSignOff,
  checkCanSignOffStep,
  updateRunWithStepComplete,
  updateDocWithSectionRepeated,
  updateDocWithStepRepeated,
  updateDocWithSectionSkipped,
  updateDocWithStepSkipped,
  includeFullStepRedline,
  updateRunWithStepFailure,
  updateRunWithStepDetail,
  updateRunWithOperation,
  updateRunWithOperationCleared,
  updateRunWithTags,
  updateRunWithHeaderRedline,
  updateDocWithSuggestedEditCommentEdit,
  updateRunWithRedlineStepComment,
} from 'shared/lib/runUtil';
import superlogin from '../api/superlogin';
import AttachmentService from '../attachments/service';
import RunService from '../api/runs';
import ExternalDataService from '../api/externalData';
import ids from '../lib/idUtil';
import procedureUtil from '../lib/procedureUtil';
import runUtil from '../lib/runUtil';
import {
  getStepDocId,
  getStepRecorded,
  newStepDoc,
  updateBlockRecorded,
  updateStepDocForIncludeRedline,
  updateStepDocForRevokeSignoff,
  updateStepRecorded,
} from 'shared/lib/runStepUtil';
import summaryUtil from '../lib/summaryUtil';
import _ from 'lodash';
import {
  Run,
  RunMetadata,
  RunStep,
  StepDoc,
} from 'shared/lib/types/views/procedures';
import { AxiosResponse } from 'axios';
import {
  DEFAULT_COMMIT,
  DEFAULT_ROLLBACK,
} from '@redux-offline/redux-offline/lib/constants';
import { getStepById } from 'shared/lib/procedureUtil';
import { getRedlineId } from 'shared/lib/redlineUtil';

/*
 * ----------------------- DEFAULT ROLLBACK SUPPORT -----------------------
 * If a new action/reducer is created in this file, a default rollback action
 * will be dispatched if the corresponding network call failed in runsEffect.  However,
 * to support the default rollback, you need to add the current state of
 * the run and/or runStep in state[teamId].past.actions[actionId] in your reducer, e.g.:
 *   const run = state[teamId].active.docs[runId];
 *   const runStep = state[teamId].active.steps[stepId];
 *   state[teamId].past.actions[actionId] = { run, runStep };
 *
 * The rollback reducer will "undo" the original action by replacing the
 * corresponding redux state run/step with the one(s) you specified in the
 * state[teamId].past.actions[actionId] object
 *
 * Warning: Make sure your past subtree entries are copies of the run/steps, so they
 * aren't unintentionally changed due to pointing to the original objects.
 */

// Custom rollback action types
const START_RUN_ROLLBACK = 'runs/startRunRollback';
const ADD_LINKED_RUN_ROLLBACK = 'runs/addLinkedRunRollback';
const ADD_STEP_COMMENT_ROLLBACK = 'runs/addStepCommentRollback';
const SET_OPERATION_ROLLBACK = 'runs/setOperationRollback';
const CLEAR_OPERATION_ROLLBACK = 'runs/clearOperationRollback';
const UPDATE_RUN_TAGS_ROLLBACK = 'runs/updateRunTagsRollback';

type PastEntry = {
  run?: Run;
  runStep?: StepDoc | string; // if a string, represents a step doc id
};

type TeamState = {
  active: {
    loading: boolean;
    docs: Record<string, Run>;
    steps: Record<string, StepDoc>;
    metadata: Record<string, RunMetadata>;
  };
  past: {
    actions: Record<string, PastEntry>;
  };
};

/**
 * Since the run slice state is further broken down based on team ID,
 * we need to make sure the team state is valid.  This should be
 * called prior to reading/writing the state within a reducer.
 */
const getTeamState = (state, teamId): TeamState => {
  if (!state[teamId]) {
    state[teamId] = {
      active: {
        loading: true,
        docs: {},
        steps: {},
        metadata: {},
      },
      past: {
        actions: {},
      },
    };
  }
  if (!state[teamId]['past']) {
    state[teamId]['past'] = {
      actions: {},
    };
  }
  return state[teamId];
};

// Shared reducer to handle when a run has been updated
const _reduceRunUpdated = (state, action) => {
  const { teamId, run } = action.payload;

  const teamState = getTeamState(state, teamId);

  if (runUtil.isRunStateActive(run.state)) {
    teamState.active.docs[run._id] = run;
    teamState.active.metadata[run._id] = summaryUtil.getRunSummary(run);
  } else {
    delete teamState.active.docs[run._id];

    // Remove step docs.
    for (const [docId, doc] of Object.entries(teamState.active.steps)) {
      if (doc.run_id === run._id) {
        delete teamState.active.steps[docId];
      }
    }
  }
  teamState.active.loading = false;
};

/**
 * Fetch all metadata for all active.
 *
 * services: Object, the services object from `DatabaseContext`, for getting the
 *           current ProcedureService.
 */
export const fetchAllActiveRunsMetadata = createAsyncThunk(
  'procedures/fetchAllActiveRunsMetadata',
  async ({
    services,
    params,
  }: {
    services: { runs: RunService };
    params?: { operationKeys: Array<string> };
  }) => {
    if (!services || !services.runs) {
      throw new Error('No runs service found');
    }

    const teamId = services.runs.getTeamId();

    if (!teamId) {
      throw new Error('No team id found');
    }

    const activeRunsMetadata = await services.runs.getActiveRunSummaries(
      params?.operationKeys
    );

    return {
      teamId,
      activeRunsMetadata,
    };
  }
);

/**
 * Redux slice for storing run documents.  This function creates the
 * reducers and action creators that will be executed on the
 * global store.runs field.  The reducers are primarily used for optimistic UI
 * changes, as the persisted run doc changes will be handled by the backend
 * via the runsEffect function.
 */
export const runsSlice = createSlice({
  name: 'runs',
  initialState: {},
  reducers: {
    runUpdated: {
      prepare: ({ run, teamId }) => ({
        payload: {
          teamId,
          run,
        },
      }),
      reducer: _reduceRunUpdated,
    },
    stepUpdated: {
      prepare: ({ teamId, step }) => ({
        payload: {
          teamId,
          step,
        },
        meta: {},
        error: false,
      }),
      reducer: (state, action) => {
        const { teamId, step } = action.payload;
        const teamState = getTeamState(state, teamId);

        const existing = teamState.active.steps[step._id];
        if (!existing || existing._rev !== step._rev) {
          teamState.active.steps[step._id] = step;
        }
      },
    },
    startRun: {
      prepare: ({ teamId, run }) => {
        const actionId = ids.generateLargeId();
        const payload = {
          teamId,
          run,
        };
        return {
          payload,
          meta: {
            actionId,
            offline: {
              rollback: {
                type: START_RUN_ROLLBACK,
                meta: {
                  runId: run._id,
                  teamId,
                },
              },
            },
          },
        };
      },
      reducer: _reduceRunUpdated,
    },
    startRunRollback: (state, action) => {
      // @ts-ignore redux-offline types conflicting with redux-toolkit
      const { runId, teamId } = action.meta;
      delete state[teamId].active.docs[runId];
      delete state[teamId].active.metadata[runId];
    },
    endRun: {
      prepare: ({ teamId, run, userId, recorded, comment, status }) => {
        const endedAt = new Date().toISOString();
        const actionId = ids.generateLargeId();
        const payload = {
          teamId,
          runId: run._id,
          userId,
          endedAt,
          recorded,
          comment,
          status,
        };
        return {
          payload,
          meta: { actionId, offline: {} },
          error: false,
        };
      },
      reducer: (state, action) => {
        const { teamId, runId, endedAt, userId, comment, status, recorded } =
          action.payload;
        const { actionId } = action.meta;
        const teamState = getTeamState(state, teamId);
        const run = teamState.active.docs[runId];
        teamState.past.actions[actionId] = { run: cloneDeep(run) };
        updateDocWithEndRun(run, userId, comment, status, recorded, endedAt);
        teamState.active.metadata[run._id] = summaryUtil.getRunSummary(run);
      },
    },
    reopenRun: {
      prepare: ({ teamId, run, userId, comment }) => {
        const timestamp = new Date().toISOString();
        const actionId = ids.generateLargeId();
        const payload = {
          teamId,
          run,
          userId,
          timestamp,
          comment,
        };
        return {
          payload,
          meta: { actionId, offline: {} },
          error: false,
        };
      },
      reducer: (state, action) => {
        const { teamId, run, userId, comment, timestamp } = action.payload;
        const { actionId } = action.meta;
        const teamState = getTeamState(state, teamId);
        teamState.past.actions[actionId] = { run: cloneDeep(run) };
        updateDocWithRunReopened({ runDoc: run, userId, comment, timestamp });
        teamState.active.docs[run._id] = run;
        teamState.active.metadata[run._id] = summaryUtil.getRunSummary(run);
      },
    },
    addLinkedRun: {
      prepare: ({ teamId, run, sectionId, stepId, contentId, linkedRunId }) => {
        const actionId = ids.generateLargeId();
        const payload = {
          teamId,
          run,
          sectionId,
          stepId,
          contentId,
          linkedRunId,
        };
        return {
          payload,
          meta: {
            actionId,
            offline: {
              rollback: {
                type: ADD_LINKED_RUN_ROLLBACK,
                meta: {
                  runId: run._id,
                  teamId,
                  sectionId,
                  stepId,
                  contentId,
                  linkedRunId,
                },
              },
            },
          },
          error: false,
        };
      },
      reducer: (state, action) => {
        const { teamId, run, sectionId, stepId, contentId, linkedRunId } =
          action.payload;

        const teamState = getTeamState(state, teamId);
        const runDoc = teamState.active.docs[run._id];

        updateDocWithLinkedRun(
          runDoc,
          sectionId,
          stepId,
          contentId,
          linkedRunId
        );
      },
    },
    addLinkedRunRollback: (state, action) => {
      const { runId, teamId, sectionId, stepId, contentId, linkedRunId } =
        // @ts-ignore redux-offline types conflicting with redux-toolkit
        action.meta;
      const run = state[teamId].active.docs[runId];
      removeLinkedRunFromDoc(run, sectionId, stepId, contentId, linkedRunId);
    },
    addStepComment: {
      prepare: ({
        teamId,
        runId,
        userId,
        sectionId,
        stepId,
        contentId,
        rowIndex,
        columnIndex,
        comment,
      }) => {
        const actionId = ids.generateLargeId();
        const payload = {
          teamId,
          runId,
          userId,
          sectionId,
          stepId,
          contentId,
          rowIndex,
          columnIndex,
          comment,
        };
        return {
          payload,
          meta: {
            actionId,
            offline: {
              rollback: {
                type: ADD_STEP_COMMENT_ROLLBACK,
                meta: {
                  runId,
                  teamId,
                  sectionId,
                  stepId,
                  commentId: comment.id,
                },
              },
            },
          },
          error: false,
        };
      },
      reducer: (state, action) => {
        const {
          teamId,
          runId,
          userId,
          sectionId,
          stepId,
          contentId,
          rowIndex,
          columnIndex,
          comment,
        } = action.payload;
        const teamState = getTeamState(state, teamId);
        const run = teamState.active.docs[runId];

        /*
         * We purge completed runs from redux when online, so if this run was
         * already completed or not found, we ignore the optimistic UI in redux.
         * The network call will handle the update to the run doc in this case.
         */
        if (!run) {
          return;
        }

        updateDocWithComment({
          doc: run,
          userId,
          sectionId,
          stepId,
          contentId,
          rowIndex,
          columnIndex,
          comment,
        });
      },
    },
    addStepCommentRollback: (state, action) => {
      // @ts-ignore redux-offline types conflicting with redux-toolkit
      const { runId, teamId, sectionId, stepId, commentId } = action.meta;
      const run = state[teamId].active.docs[runId];

      if (!run) {
        return;
      }

      RunService.removeStepCommentFromDoc(run, sectionId, stepId, commentId);
    },
    completeStep: {
      prepare: ({ teamId, runId, userId, sectionId, stepId, recorded }) => {
        const completedAt = new Date().toISOString();
        const actionId = ids.generateLargeId();
        const payload = {
          teamId,
          runId,
          userId,
          sectionId,
          stepId,
          completedAt,
          recorded,
        };
        return {
          payload,
          meta: { actionId, offline: {} },
          error: false,
        };
      },
      reducer: (state, action) => {
        const {
          teamId,
          userId,
          runId,
          sectionId,
          stepId,
          completedAt,
          recorded,
        } = action.payload;
        const { actionId } = action.meta;
        const teamState = getTeamState(state, teamId);
        const run = teamState.active.docs[runId];
        const stepDocId = getStepDocId(runId, sectionId, stepId);
        const runStep = teamState.active.steps[stepDocId];
        teamState.past.actions[actionId] = {
          run: cloneDeep(run),
          runStep: cloneDeep(runStep),
        };

        let merged = cloneDeep(recorded);
        if (runStep) {
          updateStepRecorded({
            step: runStep as unknown as RunStep,
            recorded,
          });
          merged = getStepRecorded(runStep);
        }

        updateRunWithStepComplete({
          run,
          userId,
          sectionId,
          stepId,
          timestamp: completedAt,
          recorded: merged,
        });
        teamState.active.metadata[run._id] = summaryUtil.getRunSummary(run);
      },
    },
    signOffStep: {
      prepare: ({
        teamId,
        runId,
        userId,
        sectionId,
        stepId,
        signoffId,
        operator,
        recorded,
        operatorRoles,
      }) => {
        const completedAt = new Date().toISOString();
        const actionId = ids.generateLargeId();
        const payload = {
          teamId,
          runId,
          userId,
          sectionId,
          stepId,
          signoffId,
          completedAt,
          operator,
          recorded,
          operatorRoles,
        };
        return {
          payload,
          meta: { actionId, offline: {} },
          error: false,
        };
      },
      reducer: (state, action) => {
        const {
          teamId,
          userId,
          runId,
          sectionId,
          stepId,
          completedAt,
          signoffId,
          operator,
          recorded,
          operatorRoles,
        } = action.payload;
        const { actionId } = action.meta;
        const teamState = getTeamState(state, teamId);
        const run = teamState.active.docs[runId];
        const stepDocId = getStepDocId(runId, sectionId, stepId);
        const runStep = teamState.active.steps[stepDocId];
        teamState.past.actions[actionId] = {
          run: cloneDeep(run),
          runStep: cloneDeep(runStep),
        };

        try {
          const step = getStepById(run, sectionId, stepId);
          checkCanSignOffStep({
            run,
            step,
            signoffId,
            operator,
            userOperatorRolesSet: new Set(operatorRoles),
            timestamp: completedAt,
            userId,
          });
        } catch (e) {
          return;
        }

        let merged = cloneDeep(recorded);
        if (runStep) {
          updateStepRecorded({
            step: runStep as unknown as RunStep,
            recorded,
          });
          merged = getStepRecorded(runStep);
        }

        updateRunWithStepSignOff({
          run,
          userId,
          sectionId,
          stepId,
          timestamp: completedAt,
          signoffId,
          operator,
          recorded: merged,
        });
        teamState.active.metadata[run._id] = summaryUtil.getRunSummary(run);
      },
    },
    revokeStepSignoff: {
      prepare: ({
        teamId,
        runId,
        userId,
        sectionId,
        stepId,
        userOperatorRoles,
        signoffId,
      }) => {
        const timestamp = new Date().toISOString();
        const actionId = ids.generateLargeId();
        const payload = {
          teamId,
          runId,
          userId,
          sectionId,
          stepId,
          signoffId,
          userOperatorRoles,
          timestamp,
        };
        return {
          payload,
          meta: { actionId, offline: {} },
          error: false,
        };
      },
      reducer: (state, action) => {
        const {
          teamId,
          userId,
          runId,
          sectionId,
          stepId,
          signoffId,
          userOperatorRoles,
          timestamp,
        } = action.payload;
        const { actionId } = action.meta;

        const teamState = getTeamState(state, teamId);
        const run = teamState.active.docs[runId];
        const stepDocId = getStepDocId(runId, sectionId, stepId);
        const runStep = teamState.active.steps[stepDocId];
        teamState.past.actions[actionId] = {
          run: cloneDeep(run),
          runStep: cloneDeep(runStep),
        };

        updateDocWithStepSignoffRevoked({
          run,
          userId,
          sectionId,
          stepId,
          signoffId,
          userOperatorRolesSet: new Set(userOperatorRoles),
          timestamp,
        });

        const step = getStepById(run, sectionId, stepId);
        if (runStep) {
          updateStepDocForRevokeSignoff({
            step,
            stepDoc: runStep as unknown as RunStep,
          });
        }
      },
    },
    failStep: {
      prepare: ({ teamId, runId, userId, sectionId, stepId, recorded }) => {
        const failedAt = new Date().toISOString();
        const actionId = ids.generateLargeId();
        const payload = {
          teamId,
          runId,
          userId,
          sectionId,
          stepId,
          failedAt,
          recorded,
        };
        return {
          payload,
          meta: { actionId, offline: {} },
          error: false,
        };
      },
      reducer: (state, action) => {
        const { teamId, runId, sectionId, stepId, failedAt, recorded, userId } =
          action.payload;
        const { actionId } = action.meta;
        const teamState = getTeamState(state, teamId);
        const run = teamState.active.docs[runId];
        teamState.past.actions[actionId] = { run: cloneDeep(run) };

        updateRunWithStepFailure({
          run,
          userId,
          sectionId,
          stepId,
          failedAt,
          recorded,
        });
        teamState.active.metadata[run._id] = summaryUtil.getRunSummary(run);
      },
    },
    updateBlock: {
      prepare: ({
        teamId,
        runId,
        sectionId,
        stepId,
        contentId,
        userId,
        recorded,
        userOperatorRoles,
        fieldIndex,
      }) => {
        const timestamp = new Date().toISOString();
        const actionId = ids.generateLargeId();
        const payload = {
          teamId,
          runId,
          sectionId,
          actionId,
          stepId,
          contentId,
          userId,
          recorded,
          timestamp,
          userOperatorRoles,
          fieldIndex,
        };
        return {
          payload,
          meta: { actionId, offline: {} },
          error: false,
        };
      },
      reducer: (state, action) => {
        const {
          teamId,
          userId,
          runId,
          sectionId,
          actionId,
          stepId,
          contentId,
          timestamp,
          recorded,
          userOperatorRoles,
          fieldIndex,
        } = action.payload;
        const teamState = getTeamState(state, teamId);

        const stepDocId = getStepDocId(runId, sectionId, stepId);
        let stepDoc: StepDoc | undefined = teamState.active.steps[stepDocId];
        teamState.past.actions[actionId] = { runStep: cloneDeep(stepDoc) };
        if (!stepDoc) {
          const run = teamState.active.docs[runId];
          const step = procedureUtil.getStepByIds(run, sectionId, stepId);
          if (!step) {
            return;
          }
          //@ts-ignore couch will add the _rev field
          teamState.active.steps[stepDocId] = newStepDoc(
            runId,
            sectionId,
            step
          );
          stepDoc = teamState.active.steps[stepDocId];

          // Record the brand new ID, instead of the entire doc.  A rollback will then know to delete the doc entirely
          teamState.past.actions[actionId] = { runStep: stepDoc._id };
        }

        updateBlockRecorded({
          // This function only updates content blocks - safe for now - but not ideal
          step: stepDoc as unknown as RunStep,
          contentId,
          actionId,
          userId,
          timestamp,
          recorded,
          userOperatorRoleSet: new Set(userOperatorRoles),
          fieldIndex,
        });
      },
    },
    skipStep: {
      prepare: ({
        teamId,
        run,
        runId,
        userId,
        sectionId,
        stepId,
        recorded,
      }) => {
        const skippedAt = new Date().toISOString();
        const actionId = ids.generateLargeId();

        const payload = {
          teamId,
          run,
          runId,
          userId,
          sectionId,
          stepId,
          skippedAt,
          recorded,
        };
        return {
          payload,
          meta: { actionId, offline: {} },
          error: false,
        };
      },
      reducer: (state, action) => {
        const {
          teamId,
          runId,
          userId,
          sectionId,
          stepId,
          skippedAt,
          recorded,
        } = action.payload;
        const { actionId } = action.meta;
        const teamState = getTeamState(state, teamId);
        const run = teamState.active.docs[runId];

        teamState.past.actions[actionId] = { run: cloneDeep(run) };

        updateDocWithStepSkipped({
          run,
          userId,
          sectionId,
          stepId,
          skippedAt,
          recorded,
        });
        teamState.active.metadata[run._id] = summaryUtil.getRunSummary(run);
      },
    },
    skipSection: {
      prepare: ({
        teamId,
        run,
        runId,
        userId,
        sectionId,
        skippedAt,
        recordedTelemetrySection,
      }) => {
        const actionId = ids.generateLargeId();
        const payload = {
          teamId,
          run,
          runId,
          userId,
          sectionId,
          skippedAt,
          recordedTelemetrySection,
        };

        return {
          payload,
          meta: { actionId, offline: {} },
          error: false,
        };
      },
      reducer: (state, action) => {
        const {
          teamId,
          runId,
          userId,
          sectionId,
          skippedAt,
          recordedTelemetrySection,
        } = action.payload;
        const { actionId } = action.meta;
        const teamState = getTeamState(state, teamId);
        const run = teamState.active.docs[runId];

        teamState.past.actions[actionId] = { run: cloneDeep(run) };

        updateDocWithSectionSkipped({
          run,
          userId,
          sectionId,
          skippedAt,
          recordedAllSectionSteps: recordedTelemetrySection,
        });
        teamState.active.metadata[run._id] = summaryUtil.getRunSummary(run);
      },
    },
    repeatStep: {
      prepare: ({
        teamId,
        run,
        runId,
        userId,
        recorded,
        stepRepeat,
        sectionId,
        stepId,
        includeRedlines,
      }) => {
        const actionId = ids.generateLargeId();
        const payload = {
          teamId,
          run,
          runId,
          userId,
          recorded,
          stepRepeat,
          sectionId,
          stepId,
          includeRedlines,
        };
        return {
          payload,
          meta: { actionId, offline: {} },
          error: false,
        };
      },
      reducer: (state, action) => {
        const {
          teamId,
          runId,
          userId,
          recorded,
          stepRepeat,
          sectionId,
          stepId,
          includeRedlines,
        } = action.payload;
        const { actionId } = action.meta;
        const teamState = getTeamState(state, teamId);
        const run = teamState.active.docs[runId];
        teamState.past.actions[actionId] = { run: cloneDeep(run) };

        updateDocWithStepRepeated({
          run,
          userId,
          recorded,
          stepRepeat,
          sectionId,
          sourceStepId: stepId,
          includeRedlines,
        });
        teamState.active.metadata[run._id] = summaryUtil.getRunSummary(run);
      },
    },
    repeatSection: {
      prepare: ({
        teamId,
        run,
        runId,
        userId,
        recorded,
        sectionRepeatOptions,
        sectionId,
        includeRedlines,
      }) => {
        const actionId = ids.generateLargeId();
        const payload = {
          teamId,
          run,
          runId,
          userId,
          recorded,
          sectionRepeatOptions,
          sectionId,
          includeRedlines,
        };
        return {
          payload,
          meta: { actionId, offline: {} },
          error: false,
        };
      },
      reducer: (state, action) => {
        const {
          teamId,
          runId,
          userId,
          recorded,
          sectionRepeatOptions,
          sectionId,
          includeRedlines,
        } = action.payload;
        const { actionId } = action.meta;
        const teamState = getTeamState(state, teamId);
        const run = teamState.active.docs[runId];
        teamState.past.actions[actionId] = { run: cloneDeep(run) };

        updateDocWithSectionRepeated({
          run,
          userId,
          recordedAllSectionSteps: recorded,
          sectionRepeatOptions,
          sectionId,
          includeRedlines,
        });
        teamState.active.metadata[run._id] = summaryUtil.getRunSummary(run);
      },
    },
    addParticipant: {
      prepare: ({ teamId, runId, userId }) => {
        const actionId = ids.generateLargeId();
        const createdAt = new Date().toISOString();
        const payload = {
          teamId,
          runId,
          userId,
          createdAt,
        };
        return {
          payload,
          meta: { actionId, offline: {} },
          error: false,
        };
      },
      reducer: (state, action) => {
        const { teamId, runId, userId, createdAt } = action.payload;
        const { actionId } = action.meta;
        const teamState = getTeamState(state, teamId);
        const run = teamState.active.docs[runId];
        teamState.past.actions[actionId] = { run: cloneDeep(run) };

        updateDocWithParticipantAdded(run, userId, createdAt);
        teamState.active.metadata[run._id] = summaryUtil.getRunSummary(run);
      },
    },
    removeParticipant: {
      prepare: ({ teamId, runId, userId }) => {
        const actionId = ids.generateLargeId();
        const createdAt = new Date().toISOString();
        const payload = {
          teamId,
          runId,
          userId,
          createdAt,
        };
        return {
          payload,
          meta: { actionId, offline: {} },
          error: false,
        };
      },
      reducer: (state, action) => {
        const { teamId, runId, userId, createdAt } = action.payload;
        const { actionId } = action.meta;
        const teamState = getTeamState(state, teamId);
        const run = teamState.active.docs[runId];
        teamState.past.actions[actionId] = { run: cloneDeep(run) };

        updateDocWithParticipantRemoved(run, userId, createdAt);
        teamState.active.metadata[run._id] = summaryUtil.getRunSummary(run);
      },
    },
    setOperation: {
      prepare: ({ teamId, runId, newOperation, currentOperation }) => {
        const actionId = ids.generateLargeId();
        const payload = {
          teamId,
          runId,
          operation: newOperation,
        };
        return {
          payload,
          meta: {
            actionId,
            offline: {
              rollback: {
                type: SET_OPERATION_ROLLBACK,
                meta: {
                  runId,
                  teamId,
                  operation: currentOperation,
                },
              },
            },
          },
          error: false,
        };
      },
      reducer: (state, action) => {
        const { teamId, runId, operation } = action.payload;
        const teamState = getTeamState(state, teamId);
        const run = teamState.active.docs[runId];

        /*
         * We purge completed runs from redux when online, so if this run was
         * already completed or not found, we ignore the optimistic UI in redux.
         * The network call will handle the update to the run doc in this case.
         */
        if (!run) {
          return;
        }

        updateRunWithOperation(run, _.pick(operation, 'name', 'key'));
      },
    },
    setOperationRollback: (state, action) => {
      // @ts-ignore redux-offline types conflicting with redux-toolkit
      const { runId, teamId, operation } = action.meta;
      const run = state[teamId].active.docs[runId];

      if (!run) {
        return;
      }

      if (!operation) {
        updateRunWithOperationCleared(run);
      } else {
        updateRunWithOperation(run, _.pick(operation, 'name', 'key'));
      }
    },
    clearOperation: {
      prepare: ({ teamId, runId, currentOperation }) => {
        const actionId = ids.generateLargeId();
        const payload = {
          teamId,
          runId,
        };
        return {
          payload,
          meta: {
            actionId,
            offline: {
              rollback: {
                type: CLEAR_OPERATION_ROLLBACK,
                meta: {
                  runId,
                  teamId,
                  operation: currentOperation,
                },
              },
            },
          },
          error: false,
        };
      },
      reducer: (state, action) => {
        const { teamId, runId } = action.payload;
        const teamState = getTeamState(state, teamId);
        const run = teamState.active.docs[runId];

        updateRunWithOperationCleared(run);
      },
    },
    clearOperationRollback: (state, action) => {
      // @ts-ignore redux-offline types conflicting with redux-toolkit
      const { runId, teamId, operation } = action.meta;
      const run = state[teamId].active.docs[runId];
      if (operation) {
        updateRunWithOperation(run, _.pick(operation, 'name', 'key'));
      }
    },
    updateRunTags: {
      prepare: ({ teamId, runId, newRunTags, currentRunTags }) => {
        const actionId = ids.generateLargeId();
        const payload = {
          teamId,
          runId,
          runTags: newRunTags,
        };
        return {
          payload,
          meta: {
            actionId,
            offline: {
              rollback: {
                type: UPDATE_RUN_TAGS_ROLLBACK,
                meta: {
                  runId,
                  teamId,
                  runTags: currentRunTags,
                },
              },
            },
          },
          error: false,
        };
      },
      reducer: (state, action) => {
        const { teamId, runId, runTags } = action.payload;
        const teamState = getTeamState(state, teamId);
        const run = teamState.active.docs[runId];

        /*
         * We purge completed runs from redux when online, so if this run was
         * already completed or not found, we ignore the optimistic UI in redux.
         * The network call will handle the update to the run doc in this case.
         */
        if (!run) {
          return;
        }

        updateRunWithTags(run, runTags);
        teamState.active.metadata[run._id] = summaryUtil.getRunSummary(run);
      },
    },
    updateRunTagsRollback: (state, action) => {
      // @ts-ignore redux-offline types conflicting with redux-toolkit
      const { runId, teamId, runTags } = action.meta;
      const teamState = getTeamState(state, teamId);
      const run = teamState.active.docs[runId];

      if (!run) {
        return;
      }

      updateRunWithTags(run, runTags);
      teamState.active.metadata[run._id] = summaryUtil.getRunSummary(run);
    },
    saveRedlineStepComment: {
      prepare: ({ teamId, runId, userId, stepId, text, commentId }) => {
        const actionId = ids.generateLargeId();
        const payload = {
          teamId,
          runId,
          userId,
          stepId,
          text,
          commentId,
          updatedAt: new Date().toISOString(),
        };
        return {
          payload,
          meta: { actionId, offline: {} },
          error: false,
        };
      },
      reducer: (state, action) => {
        const { teamId, runId, userId, updatedAt, stepId, text, commentId } =
          action.payload;
        const { actionId } = action.meta;
        const teamState = getTeamState(state, teamId);
        const run = teamState.active.docs[runId];
        teamState.past.actions[actionId] = { run: cloneDeep(run) };

        updateRunWithRedlineStepComment({
          run,
          userId,
          updatedAt,
          stepId,
          commentText: text,
          commentId,
        });
      },
    },
    saveRedlineHeader: {
      prepare: ({
        teamId,
        run,
        userId,
        pending,
        header,
        headerId,
        headerRedlineMetadata,
        isRedline,
      }) => {
        const actionId = ids.generateLargeId();
        const payload = {
          teamId,
          run,
          runId: run._id,
          userId,
          pending,
          header,
          headerId,
          headerRedlineMetadata,
          isRedline,
          createdAt: new Date().toISOString(),
        };
        return {
          payload,
          meta: { actionId, offline: {} },
          error: false,
        };
      },
      reducer: (state, action) => {
        const {
          teamId,
          runId,
          userId,
          header,
          headerId,
          headerRedlineMetadata,
          pending,
          isRedline,
          createdAt,
        } = action.payload;
        const { actionId } = action.meta;
        const teamState = getTeamState(state, teamId);
        const run = teamState.active.docs[runId];
        teamState.past.actions[actionId] = { run: cloneDeep(run) };

        updateRunWithHeaderRedline({
          run,
          userId,
          headerId,
          redlinedHeader: header,
          isPending: pending,
          contentId: headerRedlineMetadata.contentId,
          field: headerRedlineMetadata.field,
          isRedline,
          createdAt,
        });
      },
    },
    updateStepDetail: {
      prepare: ({ teamId, runId, sectionId, stepId, field, value }) => {
        const actionId = ids.generateLargeId();
        const payload = {
          teamId,
          runId,
          sectionId,
          stepId,
          actionId,
          field,
          value,
        };
        return {
          payload,
          meta: { actionId, offline: {} },
          error: false,
        };
      },
      reducer: (state, action) => {
        const { teamId, runId, sectionId, stepId, field, value } =
          action.payload;
        const { actionId } = action.meta;
        const teamState = getTeamState(state, teamId);
        const run = teamState.active.docs[runId];
        teamState.past.actions[actionId] = { run: cloneDeep(run) };

        updateRunWithStepDetail({
          run,
          sectionId,
          stepId,
          field,
          value,
        });
      },
    },
    addStep: {
      prepare: ({
        teamId,
        runId,
        userId,
        sectionId,
        precedingStepId,
        step,
        createdAt,
        runOnly,
      }) => {
        const actionId = ids.generateLargeId();
        const payload = {
          teamId,
          runId,
          userId,
          sectionId,
          precedingStepId,
          step,
          createdAt,
          runOnly,
        };
        return {
          payload,
          meta: { actionId, offline: {} },
          error: false,
        };
      },
      reducer: (state, action) => {
        const {
          teamId,
          runId,
          sectionId,
          userId,
          step,
          precedingStepId,
          createdAt,
          runOnly,
        } = action.payload;
        const { actionId } = action.meta;
        const teamState = getTeamState(state, teamId);
        const run = teamState.active.docs[runId];
        teamState.past.actions[actionId] = { run: cloneDeep(run) };

        updateDocWithAddedStep({
          runDoc: run,
          sectionId,
          precedingStepId,
          step,
          createdAt,
          userId,
          runOnly,
        });
        teamState.active.metadata[run._id] = summaryUtil.getRunSummary(run);
      },
    },
    addFullStepSuggestedEdit: {
      prepare: ({
        teamId,
        runId,
        userId,
        sectionId,
        stepId,
        redline,
        includeInRun,
      }) => {
        const actionId = ids.generateLargeId();
        const payload = {
          teamId,
          runId,
          userId,
          sectionId,
          stepId,
          redline,
          includeInRun,
        };
        return {
          payload,
          meta: { actionId, offline: {} },
          error: false,
        };
      },
      reducer: (state, action) => {
        const {
          teamId,
          runId,
          sectionId,
          userId,
          redline,
          stepId,
          includeInRun,
        } = action.payload;
        const { actionId } = action.meta;
        const teamState = getTeamState(state, teamId);
        const run = teamState.active.docs[runId];
        const stepDocId = getStepDocId(runId, sectionId, stepId);
        const runStep = teamState.active.steps[stepDocId];
        teamState.past.actions[actionId] = {
          run: cloneDeep(run),
          ...(runStep && { runStep: cloneDeep(runStep) }),
        };

        const wasUpdated = updateDocWithFullStepRedline({
          runDoc: run,
          sectionId,
          stepId,
          redline,
          userId,
          includeInRun,
        });
        if (wasUpdated && runStep && includeInRun) {
          updateStepDocForIncludeRedline({
            updatedStep: redline.step,
            redlineId: getRedlineId(redline) ?? '',
            stepDoc: runStep,
          });
        }

        teamState.active.metadata[run._id] = summaryUtil.getRunSummary(run);
      },
    },
    includeFullStepSuggestedEdit: {
      prepare: ({
        teamId,
        runId,
        userId,
        sectionId,
        stepId,
        redline,
        includedAt,
      }) => {
        const actionId = ids.generateLargeId();
        const payload = {
          teamId,
          runId,
          userId,
          sectionId,
          stepId,
          redline,
          includedAt,
        };
        return {
          payload,
          meta: { actionId, offline: {} },
          error: false,
        };
      },
      reducer: (state, action) => {
        const {
          teamId,
          userId,
          runId,
          sectionId,
          stepId,
          redline,
          includedAt,
        } = action.payload;
        const { actionId } = action.meta;
        const teamState = getTeamState(state, teamId);
        const run = teamState.active.docs[runId];
        const stepDocId = getStepDocId(runId, sectionId, stepId);
        const runStep = teamState.active.steps[stepDocId];
        teamState.past.actions[actionId] = {
          run: cloneDeep(run),
          ...(runStep && { runStep: cloneDeep(runStep) }),
        };

        const wasUpdated = includeFullStepRedline({
          runDoc: run,
          sectionId,
          stepId,
          redlineId: getRedlineId(redline) ?? '',
          userId,
          includedAt,
          addAction: true,
        });

        if (wasUpdated && runStep && redline) {
          updateStepDocForIncludeRedline({
            updatedStep: redline.step,
            redlineId: getRedlineId(redline) ?? '',
            stepDoc: runStep,
          });
        }

        teamState.active.metadata[run._id] = summaryUtil.getRunSummary(run);
      },
    },
    editSuggestedEditComment: {
      prepare: ({
        teamId,
        runId,
        userId,
        sectionId,
        stepId,
        redlineId,
        commentId,
        updatedText,
        updatedAt,
      }) => {
        const actionId = ids.generateLargeId();
        const payload = {
          teamId,
          runId,
          userId,
          sectionId,
          stepId,
          redlineId,
          commentId,
          updatedText,
          updatedAt,
        };
        return {
          payload,
          meta: { actionId, offline: {} },
          error: false,
        };
      },
      reducer: (state, action) => {
        const {
          teamId,
          userId,
          runId,
          sectionId,
          stepId,
          redlineId,
          commentId,
          updatedText,
          updatedAt,
        } = action.payload;
        const { actionId } = action.meta;
        const teamState = getTeamState(state, teamId);
        const run = teamState.active.docs[runId];
        teamState.past.actions[actionId] = {
          run: cloneDeep(run),
        };

        updateDocWithSuggestedEditCommentEdit({
          runDoc: run,
          sectionId,
          stepId,
          redlineId,
          userId,
          commentId,
          updatedText,
          updatedAt,
        });

        teamState.active.metadata[run._id] = summaryUtil.getRunSummary(run);
      },
    },
  },

  // These reducers are used for handling actions defined outside of the createSlice function
  extraReducers: (builder) => {
    // Action 'procedures/fetchAllActiveRunsMetadata/pending'
    builder.addCase(fetchAllActiveRunsMetadata.pending, (state, action) => {
      /**
       * For the pending action, payload is undefined and parameters are passed down via meta.arg.
       * See https://github.com/reduxjs/redux-toolkit/issues/776
       */
      const teamId = action.meta.arg.services.runs.getTeamId();

      const teamState = getTeamState(state, teamId);
      teamState.active.loading = true;
    });

    // Action 'procedures/fetchAllActiveRunsMetadata/rejected'
    builder.addCase(fetchAllActiveRunsMetadata.rejected, (state, action) => {
      /**
       * For the rejected action, payload is undefined and parameters are passed down via meta.arg.
       * See https://github.com/reduxjs/redux-toolkit/issues/776
       */
      const teamId = action.meta.arg.services.runs.getTeamId();
      const teamState = getTeamState(state, teamId);

      teamState.active.loading = false;
    });

    // Action 'procedures/fetchAllActiveRunsMetadata/fulfilled'
    builder.addCase(fetchAllActiveRunsMetadata.fulfilled, (state, action) => {
      const { teamId, activeRunsMetadata } = action.payload;
      const metadata = {};
      // Create a key value pair for runs.
      activeRunsMetadata.forEach((procedureMetadata) => {
        metadata[procedureMetadata._id] = procedureMetadata;
      });

      const teamState = getTeamState(state, teamId);

      teamState.active.loading = false;
      teamState.active.metadata = metadata;
    });

    // If all goes well, simply remove the data from the past state object
    builder.addCase(
      DEFAULT_COMMIT,
      (state, action: Action<string> & { meta }) => {
        const teamState = getTeamState(
          state,
          action.meta.offlineAction.payload.teamId
        );
        const actionId = action.meta.offlineAction.meta.actionId;

        if (actionId) {
          delete teamState.past.actions[actionId];
        }
      }
    );

    /**
     * Roll back to past run and/or step state.
     * This is a brute force approach, and could lead to future actions
     * failing due to dependant state being rolled back.
     */
    builder.addCase(
      DEFAULT_ROLLBACK,
      (state, action: Action<string> & { meta }) => {
        const teamState = getTeamState(
          state,
          action.meta.offlineAction.payload.teamId
        );
        const actionId = action.meta.offlineAction.meta.actionId as string;
        const past = teamState.past.actions[actionId];

        if (typeof past.runStep === 'string') {
          // If the original action added a brand new step doc to the store, we roll back by removing it altogether
          delete teamState.active.steps[past.runStep];
        } else if (past.runStep && teamState.active.steps[past.runStep._id]) {
          teamState.active.steps[past.runStep._id] = past.runStep;
        }

        if (past.run && teamState.active.docs[past.run._id]) {
          teamState.active.docs[past.run._id] = past.run;
          teamState.active.metadata[past.run._id] = summaryUtil.getRunSummary(
            past.run
          );
        }
        delete teamState.past.actions[actionId];
      }
    );
  },
});

/**
 * This is the reconciler function used by redux-offline (the config.effect field).
 * This function will be called for every action dispatched with the meta.offline property.
 *
 * It is used to execute the network effects - so essentially our logic to
 * make backend calls should live here.
 *
 * This function will only be called if redux-offline determines the app is online.
 * When offline, the effects will be queued up and this will be called in the order
 * the original actions were dispatched.
 */
export const runsEffect = (
  effect: unknown,
  action: { payload?; type }
): Promise<AxiosResponse | string | void> => {
  const session = superlogin.getSession();

  if (!session) {
    return Promise.reject('No active session');
  }

  const { teamId } = action.payload;

  if (!teamId) {
    return Promise.reject('No active team found');
  }

  /**
   * Create service clients.
   *
   * These effects run asynchronously outside of the auth flow, so we can't
   * easily get the service objects from the DatabaseContext.
   * TODO: Refactor these service objects somehow to avoid creating new ones.
   */
  const runs = new RunService(teamId);
  const externalData = new ExternalDataService(teamId);

  switch (action.type) {
    case addLinkedRun.type: {
      const { run, sectionId, stepId, contentId, linkedRunId } = action.payload;
      return runs.addLinkedRun({
        runId: run._id,
        sectionId,
        stepId,
        contentId,
        linkedRunId,
      });
    }
    case addParticipant.type: {
      const { runId, createdAt } = action.payload;
      return runs.addParticipant(runId, createdAt);
    }
    case addStepComment.type: {
      const {
        runId,
        sectionId,
        stepId,
        contentId,
        rowIndex,
        columnIndex,
        comment,
      } = action.payload;

      const syncCommentAttachments = () => {
        const commentAttachmentIds: Array<string> = [];

        if (comment.attachment) {
          commentAttachmentIds.push(comment.attachment.attachment_id);
        }

        if (comment.attachments) {
          comment.attachments.forEach((attachment) => {
            commentAttachmentIds.push(attachment.attachment_id);
          });
        }

        const service = AttachmentService.getInstance(teamId);
        return service.syncAllAttachments(commentAttachmentIds);
      };

      return syncCommentAttachments()
        .then(() => {
          return runs.addStepComment(runId, comment, {
            sectionId,
            stepId,
            contentId,
            rowIndex,
            columnIndex,
          });
        })
        .catch(() => {
          return Promise.reject();
        });
    }
    case endRun.type: {
      const { runId, recorded, comment, status, endedAt } = action.payload;
      return runs.endRun(runId, recorded, comment, status, endedAt);
    }
    case reopenRun.type: {
      const { run, comment, timestamp } = action.payload;
      return runs.reopenRun({ runId: run._id, comment, timestamp });
    }
    case removeParticipant.type: {
      const { runId, createdAt } = action.payload;
      return runs.removeParticipant(runId, createdAt);
    }
    case signOffStep.type: {
      const {
        runId,
        sectionId,
        stepId,
        signoffId,
        completedAt,
        operator,
        recorded,
      } = action.payload;

      const syncFieldInputAttachments = () => {
        const fieldInputAttachmentIds =
          runUtil.getFieldInputAttachmentIds(recorded);
        const service = AttachmentService.getInstance(teamId);
        return service.syncAllAttachments(fieldInputAttachmentIds);
      };

      return syncFieldInputAttachments().then(() =>
        runs.signOffStep(
          runId,
          sectionId,
          stepId,
          signoffId,
          completedAt,
          operator,
          recorded
        )
      );
    }
    case revokeStepSignoff.type: {
      const { runId, sectionId, stepId, signoffId, timestamp } = action.payload;

      return runs.revokeStepSignoff({
        runId,
        sectionId,
        stepId,
        signoffId,
        timestamp,
      });
    }
    case completeStep.type: {
      const { runId, sectionId, stepId, completedAt, recorded } =
        action.payload;

      const syncFieldInputAttachments = () => {
        const fieldInputAttachmentIds =
          runUtil.getFieldInputAttachmentIds(recorded);
        const service = AttachmentService.getInstance(teamId);
        return service.syncAllAttachments(fieldInputAttachmentIds);
      };

      return syncFieldInputAttachments().then(() =>
        runs.completeStep(runId, sectionId, stepId, completedAt, recorded)
      );
    }
    case failStep.type: {
      const { runId, sectionId, stepId, failedAt, recorded } = action.payload;

      const syncFieldInputAttachments = () => {
        const fieldInputAttachmentIds =
          runUtil.getFieldInputAttachmentIds(recorded);
        const service = AttachmentService.getInstance(teamId);
        return service.syncAllAttachments(fieldInputAttachmentIds);
      };

      return syncFieldInputAttachments().then(() =>
        runs.failStep(runId, sectionId, stepId, failedAt, recorded)
      );
    }
    case updateBlock.type: {
      const {
        runId,
        sectionId,
        stepId,
        contentId,
        actionId,
        recorded,
        timestamp,
        fieldIndex,
      } = action.payload;
      const syncFieldInputAttachments = () => {
        const attachmentIds = runUtil.getBlockRecordedAttachmentIds(recorded);
        const service = AttachmentService.getInstance(teamId);
        return service.syncAllAttachments(attachmentIds);
      };

      return syncFieldInputAttachments().then(() => {
        return runs.updateStepContent({
          runId,
          sectionId,
          stepId,
          contentId,
          actionId,
          recorded,
          timestamp,
          fieldIndex,
        });
      });
    }
    case updateStepDetail.type: {
      const { runId, sectionId, stepId, field, value } = action.payload;

      return runs.updateStepDetail({
        runId,
        sectionId,
        stepId,
        field,
        value,
      });
    }
    case startRun.type: {
      const { run } = action.payload;
      return externalData
        .updateExternalItems(run)
        .then((updated) => runs.startRun(updated))
        .catch(() => {
          // If updating external data failed, fallback to use original run.
          return runs.startRun(run);
        });
    }
    case skipStep.type: {
      const { run, userId, sectionId, stepId, skippedAt, recorded } =
        action.payload;
      return runs.skipStep(run, userId, sectionId, stepId, skippedAt, recorded);
    }
    case skipSection.type: {
      const { run, userId, sectionId, skippedAt, recordedTelemetrySection } =
        action.payload;
      return runs.skipSection(
        run,
        userId,
        sectionId,
        skippedAt,
        recordedTelemetrySection
      );
    }
    case repeatStep.type: {
      const {
        run,
        userId,
        recorded,
        stepRepeat,
        sectionId,
        stepId,
        includeRedlines,
      } = action.payload;
      return runs.repeatStep(
        run,
        userId,
        recorded,
        stepRepeat,
        sectionId,
        stepId,
        includeRedlines
      );
    }
    case repeatSection.type: {
      const {
        run,
        userId,
        recorded,
        sectionRepeatOptions,
        sectionId,
        includeRedlines,
      } = action.payload;
      return runs.repeatSection(
        run,
        userId,
        recorded,
        sectionRepeatOptions,
        sectionId,
        includeRedlines
      );
    }
    case setOperation.type: {
      const { runId, operation } = action.payload;
      return runs.setOperation(runId, operation.name);
    }
    case clearOperation.type: {
      const { runId } = action.payload;
      return runs.clearOperation(runId);
    }
    case updateRunTags.type: {
      const { runId, runTags } = action.payload;
      return runs.updateRunTags(runId, runTags);
    }
    case saveRedlineStepComment.type: {
      const { runId, stepId, text, updatedAt, commentId } = action.payload;
      return runs.addRedlineStepComment({
        runId,
        stepId,
        text,
        updatedAt,
        commentId,
      });
    }
    case saveRedlineHeader.type: {
      const {
        run,
        header,
        pending,
        headerRedlineMetadata,
        isRedline,
        createdAt,
      } = action.payload;
      return runs.addRedlineHeader(
        run,
        header,
        pending,
        headerRedlineMetadata,
        isRedline,
        createdAt
      );
    }
    case addStep.type: {
      const { runId, sectionId, precedingStepId, step, createdAt, runOnly } =
        action.payload;

      const syncFieldInputAttachments = () => {
        const fieldInputAttachmentIds = step.content.flatMap((block) => {
          return runUtil.getFieldInputAttachmentIds(block.recorded);
        });
        const service = AttachmentService.getInstance(teamId);
        return service.syncAllAttachments(fieldInputAttachmentIds);
      };

      return syncFieldInputAttachments().then(() =>
        runs.addStep({
          runId,
          sectionId,
          precedingStepId,
          createdAt,
          step,
          runOnly,
        })
      );
    }
    case addFullStepSuggestedEdit.type: {
      const { runId, sectionId, stepId, redline, includeInRun } =
        action.payload;

      const syncFieldInputAttachments = () => {
        const fieldInputAttachmentIds = redline.step.content.flatMap(
          (block) => {
            return runUtil.getFieldInputAttachmentIds(block.recorded);
          }
        );
        const service = AttachmentService.getInstance(teamId);
        return service.syncAllAttachments(fieldInputAttachmentIds);
      };

      return syncFieldInputAttachments().then(() =>
        runs.addFullStepSuggestedEdit({
          runId,
          sectionId,
          stepId,
          redline,
          includeInRun,
        })
      );
    }
    case includeFullStepSuggestedEdit.type: {
      const { runId, sectionId, stepId, redline, includedAt } = action.payload;

      const syncFieldInputAttachments = () => {
        const fieldInputAttachmentIds = redline.step.content.flatMap(
          (block) => {
            return runUtil.getFieldInputAttachmentIds(block.recorded);
          }
        );
        const service = AttachmentService.getInstance(teamId);
        return service.syncAllAttachments(fieldInputAttachmentIds);
      };

      return syncFieldInputAttachments().then(() =>
        runs.includeFullStepSuggestedEdit({
          runId,
          sectionId,
          stepId,
          redlineId: getRedlineId(redline) ?? '',
          includedAt,
        })
      );
    }
    case editSuggestedEditComment.type: {
      const {
        runId,
        sectionId,
        stepId,
        redlineId,
        commentId,
        updatedText,
        updatedAt,
      } = action.payload;

      return runs.editSuggestedEditComment({
        runId,
        sectionId,
        stepId,
        redlineId,
        commentId,
        updatedText,
        updatedAt,
      });
    }
    default:
      return Promise.reject(`Unrecognized offline action ${action.type}`);
  }
};

// Action creators are generated for each case reducer function
export const {
  addLinkedRun,
  addParticipant,
  addStepComment,
  completeStep,
  endRun,
  reopenRun,
  removeParticipant,
  runUpdated,
  signOffStep,
  revokeStepSignoff,
  failStep,
  skipStep,
  skipSection,
  stepUpdated,
  repeatStep,
  repeatSection,
  startRun,
  setOperation,
  clearOperation,
  updateRunTags,
  saveRedlineStepComment,
  updateBlock,
  saveRedlineHeader,
  addStep,
  addFullStepSuggestedEdit,
  includeFullStepSuggestedEdit,
  updateStepDetail,
  editSuggestedEditComment,
} = runsSlice.actions;

export const selectActiveRunById = (
  state: { runs },
  teamId: string,
  runId: string
): Run | null => {
  // Returns null to identify run does not exist if team is not stored in redux.
  if (!state.runs[teamId]) {
    return null;
  }

  return state.runs[teamId].active.docs[runId];
};

export const selectRunStep = (
  state: { runs },
  teamId: string,
  runId: string,
  sectionId: string,
  stepId: string
): StepDoc | undefined => {
  if (!state.runs?.[teamId]?.active?.steps) {
    return;
  }
  if (!runId || !sectionId || !stepId) {
    return;
  }
  const docId = getStepDocId(runId, sectionId, stepId);
  return state.runs[teamId].active.steps[docId];
};

export const selectActiveRunsLoading = (
  state: { runs? },
  teamId: string
): boolean => {
  // Returns true as default case of runs loading (if team data does not exist, it is being loaded).
  if (!state.runs[teamId]) {
    return true;
  }

  return state.runs[teamId].active.loading;
};

export const selectActiveRunsMetadata = (
  state: { runs? },
  teamId: string
): { [id: string]: RunMetadata } => {
  // Returns an empty object because calling code expects an object returned in any case.
  if (!state.runs[teamId]) {
    return {};
  }

  // Clients with older data will not have this metadata field. Prevent crashes by returning an empty object.
  if (!state.runs[teamId].active.metadata) {
    return {};
  }

  return state.runs[teamId].active.metadata;
};

export default runsSlice.reducer;
