import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Field, Form, Formik } from 'formik';
import { Helmet } from 'react-helmet-async';
import logoWide from '../../images/logo-epsilon3.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Yup from 'yup';
import { AnyObject } from 'yup/lib/types';

type LoginFlowFormInputField = {
  key: string;
  type: string;
  label: string;
  link?: {
    label: string;
    path: string;
  };
};

type LoginFlowFormTypes = {
  heading: string;
  subHeading?: string;
  initialValues: object;
  validationSchema: Yup.ObjectSchema<AnyObject>;
  onSubmit: (values, { setSubmitting }) => Promise<void>;
  successMessage?: string | null;
  errorMessage?: string | null;
  inputFields: Array<LoginFlowFormInputField>;
  children(isSubmitting?: boolean): React.ReactElement;
};

const LoginFlowForm = ({
  heading,
  subHeading,
  initialValues,
  validationSchema,
  onSubmit,
  successMessage,
  errorMessage,
  inputFields,
  children,
}: LoginFlowFormTypes) => {
  const currentYear = useMemo(() => {
    return new Date().getFullYear();
  }, []);

  return (
    <div className="flex flex-col items-center justify-center gap-y-6 bg-gray-800 w-screen h-screen">
      {/* Sets the document title */}
      <Helmet>
        <title>Epsilon3</title>
      </Helmet>

      <img src={logoWide} className="app-logo w-[18rem]" alt="logo" />
      <div className="bg-gray-50 text-slate-600 rounded-lg p-6 w-[24rem] md:w-[32rem]">
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
          {({ errors, touched, isSubmitting }) => (
            <Form>
              <div className="flex flex-col gap-y-4">
                {/* Header */}
                <div className="flex flex-col gap-y-1">
                  <h1>{heading}</h1>
                  {subHeading && <div>{subHeading}</div>}
                </div>
                {/* Success Toast */}
                {successMessage && (
                  <div
                    className="flex flex-row items-center gap-x-1 text-sm p-1 bg-green-100 border border-green-300 rounded"
                    aria-label="login-success-message"
                  >
                    <FontAwesomeIcon className="text-green-500" icon="clock" />
                    <div>{successMessage}</div>
                  </div>
                )}
                {/* Error Toast */}
                {errorMessage && (
                  <div
                    className="flex flex-row items-center gap-x-1 text-sm p-1 bg-red-100 border border-red-300 rounded"
                    aria-label="login-error-message"
                  >
                    <FontAwesomeIcon className="text-red-600" icon="exclamation-triangle" />
                    <div>{errorMessage}</div>
                  </div>
                )}
                {/* Input fields */}
                <div className="flex flex-col gap-y-3">
                  {inputFields.map((inputField) => (
                    <div key={inputField.key} className="flex flex-col gap-y-1">
                      <div className="flex flex-row justify-between">
                        <label className="text-sm" htmlFor={inputField.key}>
                          {inputField.label}
                        </label>
                        {inputField.link && (
                          <Link
                            to={inputField.link.path}
                            className="text-slate-600 underline text-sm hover:brightness-75"
                          >
                            {inputField.link.label}
                          </Link>
                        )}
                      </div>
                      <Field
                        id={inputField.key}
                        name={inputField.key}
                        type={inputField.type}
                        className={`text-slate-600 w-full bg-gray-50 border-1 rounded ${
                          errors[inputField.key] && touched[inputField.key] ? 'border-red-600' : 'border-slate-600'
                        }`}
                      />
                      {errors[inputField.key] && touched[inputField.key] && (
                        <div className="text-sm text-red-600">{errors[inputField.key]}</div>
                      )}
                    </div>
                  ))}
                </div>
                {/* Buttons */}
                <div className="flex flex-col gap-y-4">{children(isSubmitting)}</div>
                {/* Footer */}
                <div className="flex flex-row justify-between text-sm">
                  <div>© {currentYear} Epsilon3®</div>
                  <div className="flex flex-row gap-x-2">
                    <a target="_blank" rel="noreferrer" href="https://epsilon3.io/privacy" className="link">
                      Privacy
                    </a>
                    <a target="_blank" rel="noreferrer" href="https://epsilon3.io/terms" className="link">
                      Terms
                    </a>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default LoginFlowForm;
