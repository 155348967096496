import { Fragment } from 'react';
import ReferenceToken from './Blocks/Display/ReferenceToken';
import { ExpressionReferences, ExpressionToken } from 'shared/lib/types/views/procedures';

interface ReferenceDetailProps {
  tokens: Array<ExpressionToken>;
  displayText: string;
  references?: ExpressionReferences;
}

const FunctionToken = ({ token, references }: { token: ExpressionToken; references?: ExpressionReferences }) => {
  if (!token.function_name || !token.function_params) {
    return null;
  }

  return (
    <div className="font-medium text-sm px-1.5 py-0.5 my-1 rounded-xl bg-blue-200 flex items-center whitespace-nowrap">
      {token.function_name}(
      {token.function_params?.map((paramTokens, index) => (
        <span key={index} className="inline-flex items-center">
          {index > 0 && ', '}
          {paramTokens.map((paramToken, pIndex) => {
            const isReference = paramToken.type === 'reference' && paramToken.reference_id;

            return (
              <>
                {isReference && paramToken.reference_id && (
                  <ReferenceToken
                    key={pIndex}
                    originalReferencedContentId={paramToken.reference_id}
                    originalToken={paramToken}
                    references={references}
                  />
                )}
                {!isReference && (
                  <div key={pIndex} className="py-1 mx-0.5">
                    {paramToken.value}
                  </div>
                )}
              </>
            );
          })}
        </span>
      ))}
      )
    </div>
  );
};

const ReferenceDetail = ({ tokens, displayText, references }: ReferenceDetailProps) => {
  if (!tokens || tokens.length === 0) {
    return null;
  }

  return (
    <div className="break-all max-w-max">
      <div className="flex flex-col">
        <div className="flex flex-col ml-0.5 font-bold">{displayText}</div>
        <div>
          <div className="flex flex-row items-center flex-wrap">
            {tokens.map((token, index) => (
              <Fragment key={index}>
                {token.type === 'reference' && token.reference_id && (
                  <ReferenceToken
                    originalReferencedContentId={token.reference_id}
                    originalToken={token}
                    references={references}
                  />
                )}
                {token.type === 'function' && <FunctionToken token={token} references={references} />}
                {token.type !== 'reference' && token.type !== 'function' && (
                  <div className="py-1 mx-0.5">{token.value}</div>
                )}
              </Fragment>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReferenceDetail;
