import { Draggable, DroppableStateSnapshot } from 'react-beautiful-dnd';
import contentDragHandleVisibilityClasses from './Selection/selectionUtil';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMemo } from 'react';
import Selectable, { Boundary } from './Selection/Selectable';
import { ContentBlock, Step } from 'shared/lib/types/views/procedures';

type DragWrapperReturn = {
  styles: string;
  isSelected: boolean;
};

type SelectableReturn = {
  isSelected: boolean;
};
export interface DragWrapperProps {
  onKeyboard: (event: React.KeyboardEvent<Element>, boundary: Boundary, index: number, step: Step) => void;
  snapshot: DroppableStateSnapshot;
  values: Step;
  content: ContentBlock;
  contentIndex: number;
  subStepKeys: Record<string, string>;
  contentMenu(params: SelectableReturn): React.ReactElement;
  removeContentButton: React.ReactElement;
  children(params: DragWrapperReturn): React.ReactElement;
  canModify?: boolean;
}

const DragWrapper = ({
  onKeyboard,
  snapshot,
  values,
  content,
  contentIndex,
  subStepKeys,
  contentMenu,
  removeContentButton,
  children,
  canModify = true,
}: DragWrapperProps) => {
  const substepLabels = useMemo(() => {
    return <div className="flex flex-row justify-end h-4 whitespace-nowrap text-sm">{subStepKeys[content.id]} </div>;
  }, [content.id, subStepKeys]);

  return (
    <Selectable
      key={content.id}
      boundary={Boundary.ContentBlock}
      block={content}
      onKeyboard={(event) => onKeyboard(event, Boundary.ContentBlock, contentIndex, values)}
    >
      {({ isSelected, styles }) => (
        <Draggable key={content.id} draggableId={content.id} index={contentIndex} isDragDisabled={!canModify}>
          {(provided, draggableSnapshot) => (
            <div
              aria-label="Content Block"
              role="region"
              className="group/content flex w-full"
              ref={provided.innerRef}
              {...provided.draggableProps}
            >
              <div className="w-9 mr-2 mt-1 gap-y-2 flex flex-col text-gray-400">
                <div
                  className={`flex flex-row gap-x-2 justify-end ${contentDragHandleVisibilityClasses(
                    snapshot,
                    draggableSnapshot,
                    isSelected
                  )}`}
                >
                  {contentMenu({ isSelected })}
                  {/* Drag Handle */}
                  {canModify && (
                    <div
                      {...provided.dragHandleProps}
                      className={contentDragHandleVisibilityClasses(snapshot, draggableSnapshot, isSelected)}
                    >
                      <FontAwesomeIcon icon="grip-vertical" />
                    </div>
                  )}
                </div>

                {substepLabels}
              </div>
              {children({ styles, isSelected })}
              {canModify && (
                <div
                  className={`pl-1 justify-end ${
                    isSelected ? 'opacity-100' : 'opacity-0 group-hover/content:opacity-100'
                  } `}
                >
                  {removeContentButton}
                </div>
              )}
            </div>
          )}
        </Draggable>
      )}
    </Selectable>
  );
};

export default DragWrapper;
