import { Link } from 'react-router-dom';
import Select from 'react-select';
import DiffContainer from '../../../components/Diff/DiffContainer';
import diffUtil from '../../../lib/diffUtil';
import sharedDiffUtil from 'shared/lib/diffUtil';
import { toolDetailPath } from '../../../lib/pathUtil';
import { reactSelectStyles } from '../../../lib/styles';
import { ReviewToolCheckInBlock, ReviewToolCheckOutBlock } from 'shared/lib/types/views/procedures';
import useTool from '../../hooks/useTool';
import toDisplay from '../../lib/tools/toDisplay';
import ThumbnailImageDisplay from '../../../components/Attachments/ThumbnailImageDisplay';

interface ReviewToolCheckOutInProps {
  content: ReviewToolCheckOutBlock | ReviewToolCheckInBlock;
  type: 'out' | 'in';
  teamId: string;
}

const ReviewToolCheckOutIn = ({ content, type, teamId }: ReviewToolCheckOutInProps) => {
  const toolId = sharedDiffUtil.getDiffValue<number>(content, 'tool_id', 'new');
  const { tool } = useTool({ toolId });

  if (!tool) {
    return null;
  }

  const diffChangeState =
    content.diff_change_state === '~'
      ? diffUtil.getDiffChangeStateForChangesOnly(content, 'tool_id')
      : diffUtil.getDiffChangeStateForAddedRemovedOnly(content);

  return (
    <div className="mt-3 ml-4">
      <DiffContainer label={`Tool check-${type}`} diffChangeState={diffChangeState} isTextSticky={false} width="fit">
        <div className="flex flex-row">
          <div className="w-64">
            <div className="field-title">Tool for {`${type === 'out' ? 'Check-Out' : 'Check-In'}`}</div>
            <div className="flex pt-2 flex-grow">
              <div>
                <ThumbnailImageDisplay size="sm" attachment={toDisplay.fromToolImageProps(tool)} />
              </div>
              <div className="pl-2 pr-1 truncate text-blue-600">
                <Link to={toolDetailPath(teamId, tool.id)}>{tool.tool_number}</Link>
              </div>
              {tool.name}
            </div>
          </div>
          <div className="w-2" />
          <div className="w-72">
            <div className="field-title">Tool Instance</div>
            <Select
              styles={reactSelectStyles}
              classNamePrefix="react-select"
              isDisabled={true}
              placeholder="Select tool instance"
              aria-label="Select tool instance"
            />
          </div>
        </div>
      </DiffContainer>
    </div>
  );
};

export default ReviewToolCheckOutIn;
