export enum TagStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  DELETED = 'deleted',
}

export enum EntityType {
  PROCEDURE = 'procedure',
  RUN = 'run',
  PART = 'part',
  PURCHASE_ORDER = 'purchase_order',
}

export type CreateTagParams = {
  name: string;
  description: string;
  color: string;
};

export type UpdateTagParams = {
  name: string;
  description: string;
  color: string;
  status: TagStatus;
};

export type ServerTag = {
  id: string;
  team_id: string;
  name: string;
  lower_name: string;
  status: TagStatus;
  description: string;
  color: string;
  created_at: string;
  created_by: string;
  updated_at: string;
  updated_by: string;
  deleted_at: string | null;
};

export type Tag = {
  id: string;
  name: string;
  status: TagStatus;
  description: string;
  color: string;
  createdBy: string;
  createdAt: string;
  updatedBy: string;
  updatedAt: string;
};

export type EntityTagRow = {
  id: string;
  tag_id: string;
  entity_id: string;
  entity_type: EntityType;
  created_by: string;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
};

export type TagUsage = {
  [tagId: string]: { all_time: number; last_30_days: number };
};

export type EntityTagMapping = {
  [entityId: string]: { tagId: string; updatedAt: string }[];
};
