import { useCallback, useMemo, useRef } from 'react';
import Select, { components, ControlProps } from 'react-select';
import { useNavState } from '../../contexts/NavContext';
import { useSettings } from '../../contexts/SettingsContext';
import { HEX_BLUE_200, HEX_SLATE_200, reactSelectStyles } from '../../lib/styles';
import Icon from '../../elements/Icon';
import FieldLabel from '../../elements/internal/FieldLabel';
import { faFolderClosed, faFolderOpen } from '@fortawesome/free-solid-svg-icons';
import { Menu } from 'primereact/menu';
import { getHashColor } from '../../lib/hashUtil';

const NO_PROJECT = '';
const ALL_PROJECTS = '(All)';

const Indicator = ({ label, showText = false }: { label: string; showText?: boolean }) => {
  const indicatorColor = getHashColor(label, true);
  return showText ? (
    <div
      className={`relative mt-[-12px] ml-3 h-4 w-4 font-bold leading-3 pt-[1px] text-xxs rounded-full border border-white ${indicatorColor}`}
    >
      {label.charAt(0).toUpperCase()}
    </div>
  ) : (
    <div className={`relative mt-[5px] ml-[-6px] h-2 w-2 rounded-full border border-white ${indicatorColor}`}></div>
  );
};

const Control = ({ children, ...props }: ControlProps) => {
  const project = props?.getValue()?.[0];
  return (
    <components.Control {...props}>
      <div className="flex flex-row w-full items-center pl-2">
        <Icon element={faFolderClosed} />
        {project && project.value !== NO_PROJECT && <Indicator label={project.label} />}
        {children}
      </div>
    </components.Control>
  );
};

const NavItemProjectSelector = () => {
  const navState = useNavState();
  const { projects } = useSettings();
  const projectMenu = useRef<Menu>(null);

  const compactReactSelect = {
    ...reactSelectStyles,
    control: (base) => ({
      ...base,
      minHeight: '20px',
      backgroundColor: 'rgb(30 41 59)', // slate-800
      borderColor: 'rgb(107 114 128)', // gray-500
    }),
    option: (base, { isSelected, isFocused }) => ({
      ...base,
      color: 'black',
      backgroundColor: isSelected ? HEX_BLUE_200 : isFocused ? HEX_SLATE_200 : 'white',
    }),
    singleValue: (base) => ({
      ...base,
      color: 'white',
    }),
    dropdownIndicator: (base) => ({
      ...base,
      padding: '2px',
    }),
    input: (base) => ({
      ...base,
      padding: 0,
      margin: 0,
      color: 'white',
    }),
  };

  const projectOptions = useMemo(() => {
    if (!projects || !projects.projects) {
      return [];
    }

    const items = Object.values(projects.projects).map((project) => ({
      value: project.id,
      label: project.name,
    }));
    items.sort((a, b) => a.label.localeCompare(b.label)).unshift({ value: NO_PROJECT, label: ALL_PROJECTS });
    return items;
  }, [projects]);

  const setProject = useCallback(
    (projectId, event) => {
      navState.setProjectId(projectId);
      event && projectMenu?.current?.hide(event);
    },
    [navState]
  );

  const itemRenderer = useCallback(
    (item) => {
      const isActive = item.id === navState.projectId;
      return (
        <div
          role="menuitem"
          onClick={(e) => setProject(item.id, e)}
          className={`flex text-sm items-center cursor-pointer hover:bg-gray-200 px-3 py-2 ${
            isActive ? 'bg-blue-200' : ''
          }`}
        >
          {item.id && <Icon element={isActive ? faFolderOpen : faFolderClosed} />}
          <span className="mx-2 truncate max-w-44">{item.label}</span>
        </div>
      );
    },
    [navState.projectId, setProject]
  );

  const projectMenuOptions = useMemo(() => {
    return projectOptions.map((option) => ({
      id: option.value,
      label: option.label,
      template: itemRenderer,
    }));
  }, [itemRenderer, projectOptions]);

  const selectValue = useMemo(() => {
    if (!navState.projectId || !projectOptions.length) {
      return { value: NO_PROJECT, label: ALL_PROJECTS };
    }

    return projectOptions.find((project) => project.value === navState.projectId);
  }, [projectOptions, navState.projectId]);

  const onChangeHandler = useCallback(
    (option) => {
      navState.setProjectId(option?.value);
    },
    [navState]
  );

  return (
    <div aria-label="Nav Project Selector" className="py-1 px-4 text-sm">
      {navState.isCollapsed && (
        <>
          <Menu
            aria-label="Project Context Selector"
            model={projectMenuOptions}
            popup
            ref={projectMenu}
            id="menuUserProfile"
            className="max-h-64 overflow-y-auto unobtrusive-scrollbar"
          />
          <button
            title={`Project ${navState.projectName || ALL_PROJECTS}`}
            onClick={(e) => projectMenu?.current?.toggle(e)}
          >
            <Icon element={faFolderClosed} className="p-1 fa-fw fa-lg" />
            {selectValue?.value && <Indicator label={navState.projectName || NO_PROJECT} showText={true} />}
          </button>
        </>
      )}
      {!navState.isCollapsed && (
        <>
          <FieldLabel label="Project" />
          <Select
            aria-label="Project Context Selector"
            classNamePrefix="react-select"
            onChange={onChangeHandler}
            components={{ Control }}
            placeholder="Select Project"
            options={projectOptions}
            styles={compactReactSelect}
            value={selectValue}
          />
        </>
      )}
    </div>
  );
};

export default NavItemProjectSelector;
