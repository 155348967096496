export enum ViewTab {
  List = 'list',
  Tree = 'tree',
}

export enum UserPreferenceType {
  DefaultView = 'default_view',
  LandingPage = 'landing_page',
  Offline = 'offline',
}

export type DefaultView = {
  _id: 'default_view';
  project_ids: Array<string | null>;
  tags: Array<string | null>;
  run_tags: Array<string | null>;
  search_term?: string;
  view_tab?: ViewTab;
  expanded_project_names?: ReadonlySet<string>;
};

export type LandingPage = {
  page_name: PageName;
};

export type OfflineValue = {
  procedure_ids: Array<string>;
};

export enum PageName {
  Inventory = 'Inventory',
  Issues = 'Issues',
  Operations = 'Operations',
  Procedures = 'Procedures',
  Runs = 'Runs',
  TestPlans = 'Test Plans',
}

type UserPreferenceValue = DefaultView | LandingPage | OfflineValue;

export type UserPreference = {
  name: UserPreferenceType;
  value: UserPreferenceValue;
};

export type UserPreferences = {
  _id: 'user_preferences';
  preferences: {
    [name in UserPreferenceType]?: UserPreferenceValue;
  };
};

export type AccessRole = {
  id: string;
  name: string;
  team_id: string;
};

export type OperatorRole = {
  user_id: string;
  team_id: string;
  operator_role_id: string;
};
