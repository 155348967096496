import { MouseEvent, useEffect, useState } from 'react';

const isTimeToSnow = () => {
  const today = new Date();
  const month = today.getMonth();
  const day = today.getDate();

  // Check if the current date is in December and between 20 and 27 inclusive
  return month === 11 && day >= 20 && day <= 27;
};

const Snow = () => {
  const [manuallyOff, setManuallyOff] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const noSnow = localStorage.getItem('no-snow');
  const active = isTimeToSnow() && !(noSnow || manuallyOff);

  useEffect(() => {
    setTimeout(() => {
      setShowButton((current) => !current);
    }, 5000);
  }, []);

  if (!active) {
    return <></>;
  }

  const catchSnowflake = (event: MouseEvent) => {
    const target = event.target as HTMLElement;
    if (target.className === 'snowflake') {
      target.style.opacity = '0';
      setTimeout(() => {
        target.style.opacity = '100';
      }, 2000);
    }
  };

  const stopSnow = () => {
    setManuallyOff(true);
    localStorage.setItem('no-snow', 'yes');
  };

  const style = `
  .snowflake {
    color: #fff;
    font-size: 1em;
    font-family: Arial;
    text-shadow: 0 0 1px #000;
    cursor: grab;
    padding: 2px;
    transition-property: opacity;
    transition-duration: 500ms;
  }

  @-webkit-keyframes snowflakes-fall{0%{top:-10%}100%{top:100%}}@-webkit-keyframes snowflakes-shake{0%{-webkit-transform:translateX(0px);transform:translateX(0px)}50%{-webkit-transform:translateX(80px);transform:translateX(80px)}100%{-webkit-transform:translateX(0px);transform:translateX(0px)}}@keyframes snowflakes-fall{0%{top:-10%}100%{top:100%}}@keyframes snowflakes-shake{0%{transform:translateX(0px)}50%{transform:translateX(80px)}100%{transform:translateX(0px)}}.snowflake{position:fixed;top:-10%;z-index:9999;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;-webkit-animation-name:snowflakes-fall,snowflakes-shake;-webkit-animation-duration:10s,3s;-webkit-animation-timing-function:linear,ease-in-out;-webkit-animation-iteration-count:infinite,infinite;-webkit-animation-play-state:running,running;animation-name:snowflakes-fall,snowflakes-shake;animation-duration:10s,3s;animation-timing-function:linear,ease-in-out;animation-iteration-count:infinite,infinite;animation-play-state:running,running}.snowflake:nth-of-type(0){left:1%;-webkit-animation-delay:0s,0s;animation-delay:0s,0s}.snowflake:nth-of-type(1){left:10%;-webkit-animation-delay:1s,1s;animation-delay:1s,1s}.snowflake:nth-of-type(2){left:20%;-webkit-animation-delay:6s,.5s;animation-delay:6s,.5s}.snowflake:nth-of-type(3){left:30%;-webkit-animation-delay:4s,2s;animation-delay:4s,2s}.snowflake:nth-of-type(4){left:40%;-webkit-animation-delay:2s,2s;animation-delay:2s,2s}.snowflake:nth-of-type(5){left:50%;-webkit-animation-delay:8s,3s;animation-delay:8s,3s}.snowflake:nth-of-type(6){left:60%;-webkit-animation-delay:6s,2s;animation-delay:6s,2s}.snowflake:nth-of-type(7){left:70%;-webkit-animation-delay:2.5s,1s;animation-delay:2.5s,1s}.snowflake:nth-of-type(8){left:80%;-webkit-animation-delay:1s,0s;animation-delay:1s,0s}.snowflake:nth-of-type(9){left:90%;-webkit-animation-delay:3s,1.5s;animation-delay:3s,1.5s}`;

  return (
    <div onClick={catchSnowflake}>
      <button
        className={`absolute rounded-md bg-gray-200 hover:brightness-90 p-1 bottom-1 left-1/2 z-200 text-sm text-gray-500 transition-opacity duration-200 ${
          showButton ? 'opacity-100' : 'opacity-0'
        }`}
        onClick={stopSnow}
      >
        Turn off Snow
      </button>
      <div className="snowflake">❅</div>
      <div className="snowflake">❆</div>
      <div className="snowflake">❄</div>
      <div className="snowflake">❅</div>
      <div className="snowflake">❆</div>
      <div className="snowflake">❄</div>
      <div className="snowflake">❅</div>
      <div className="snowflake">❆</div>
      <div className="snowflake">❄</div>
      <style>{style}</style>
    </div>
  );
};

export default Snow;
