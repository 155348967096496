import { useQuery } from '@tanstack/react-query';
import { Location, LocationMap } from '../types';
import { useDatabaseServices } from '../../contexts/DatabaseContext';
import { useSettings } from '../../contexts/SettingsContext';
import useItems from './useItems';
import { anyItemsInLocations } from '../lib/locations';
import apm from '../../lib/apm';

const QUERY_KEY = 'locations';

interface LocationsState {
  locations?: LocationMap;
  getLocation: (locationId: string) => Location | undefined;
  getLocationLabel: (locationId: string) => string | undefined;
  isLocationInUse: (nodeAndChildrenIds: string[]) => boolean;
}

const getChildNodeMap = (locations: LocationMap) => {
  return Object.values(locations).reduce((map, location) => {
    if (!map[location.parent]) {
      map[location.parent] = {};
    }
    map[location.parent][location.id] = location;
    return map;
  }, {});
};

const updateComputedValuesRecursive = (prefix, location, childNodeMap) => {
  const code = (prefix ? `${prefix}-` : prefix) + location.code;
  const children = Object.values(childNodeMap[location.id] || {});

  location.full_code = code;
  location.items_enabled = !children.length;

  for (const child of children) {
    updateComputedValuesRecursive(code, child, childNodeMap);
  }
};

const updateComputedValues = (locations: LocationMap) => {
  const childNodeMap = getChildNodeMap(locations);
  // Find root location nodes
  const rootNodes = Object.values(locations).filter(
    (location) => !location.parent
  );
  for (const rootNode of rootNodes) {
    updateComputedValuesRecursive('', rootNode, childNodeMap);
  }
};

const useLocations = (): LocationsState => {
  const { services } = useDatabaseServices();
  const { isManufacturingEnabled } = useSettings();
  const { allItems } = useItems();

  const fetchLocations = async () => {
    if (!isManufacturingEnabled?.()) {
      return null;
    }
    return services.manufacturing
      .getLocations()
      .then((locations) => {
        const defaultLocations = locations ?? {};
        updateComputedValues(defaultLocations);
        return defaultLocations;
      })
      .catch((err) => apm.captureError(err));
  };

  const { data: locations } = useQuery({
    queryKey: [QUERY_KEY],
    queryFn: fetchLocations,
  });

  const getLocation = (locationId) => locations?.[locationId];

  const getLocationLabel = (locationId) => {
    if (!locationId) {
      return;
    }
    const location = getLocation(locationId);
    if (!location) {
      return;
    }
    return `${location.full_code} ${location.name}`;
  };

  const isLocationInUse = (nodeAndChildrenIds: string[]) => {
    return anyItemsInLocations(allItems ?? [], nodeAndChildrenIds);
  };

  return {
    locations,
    getLocation,
    getLocationLabel,
    isLocationInUse,
  };
};

export default useLocations;
