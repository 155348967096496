import { useCallback, useState } from 'react';
import Button, { BUTTON_TYPES } from './Button';
import PhotoCaptureModal from './PhotoCaptureModal';

type PhotoCaptureButtonProps = {
  onPhotoCapture: (photo: File) => void;
};

const PhotoCaptureButton = ({ onPhotoCapture }: PhotoCaptureButtonProps) => {
  const [showModal, setShowModal] = useState(false);

  const onCapture = useCallback(
    (file: File) => {
      setShowModal(false);
      onPhotoCapture(file);
    },
    [onPhotoCapture]
  );

  return (
    <>
      {showModal && <PhotoCaptureModal onPhotoCapture={onCapture} onClose={() => setShowModal(false)} />}
      <Button type={BUTTON_TYPES.PRIMARY} leadingIcon="camera" size="sm" onClick={() => setShowModal(true)} />
    </>
  );
};

export default PhotoCaptureButton;
