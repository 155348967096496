import { useCallback } from 'react';
import { useDispatch, useStore } from 'react-redux';
import couchdbUtil, { CouchDBChanges } from '../lib/couchdbUtil';
import {
  fetchAllProceduresMetadata,
  fetchProceduresMetadata,
  syncAllProcedureData,
} from '../contexts/proceduresSlice';
import { useDatabaseServices } from '../contexts/DatabaseContext';
import { useSettings } from '../contexts/SettingsContext';
import { ProcedureMetadata } from 'shared/lib/types/couch/procedures';
import { stripProcedureId } from 'shared/lib/procedureUtil';

const useMasterProcedureListHelpers = (): {
  syncMasterProcedureList: (changes?: CouchDBChanges | null) => Promise<void>;
} => {
  const { services } = useDatabaseServices();
  const { offlinePreference } = useSettings();
  const dispatch = useDispatch();
  const store = useStore();

  // Given the user's selected procedures, also determine any linked procedures that should be synced
  const getAllOfflineIds = useCallback(
    (metadata: ProcedureMetadata) => {
      if (!offlinePreference) {
        return [];
      }

      const _addOfflineId = (procedureId: string, visited: Set<string>) => {
        if (!procedureId) {
          return;
        }

        const procedure = metadata[procedureId];
        if (!procedure) {
          return;
        }

        if (visited.has(procedureId)) {
          return;
        }

        visited.add(procedureId);
        procedure.linked_procedure_ids?.forEach((linkedId: string) =>
          _addOfflineId(stripProcedureId(linkedId), visited)
        );
      };

      const parentIds = offlinePreference.procedure_ids;
      const allIds = new Set<string>();
      for (const parentId of parentIds) {
        _addOfflineId(parentId, allIds);
      }
      return [...allIds];
    },
    [offlinePreference]
  );

  // Sync master procedure list.
  const syncMasterProcedureList = useCallback(
    async (changes?: CouchDBChanges | null) => {
      if (!services.procedures) {
        return;
      }
      try {
        let results;
        const updates = changes?.results || changes?.data;
        if (updates) {
          const docs = couchdbUtil.getChangedDocs(updates);
          results = await dispatch(
            fetchProceduresMetadata({
              services,
              docs,
            })
            // @ts-ignore dispatch type missing unwrap here
          ).unwrap();
        } else {
          results = await dispatch(
            fetchAllProceduresMetadata({ services })
            // @ts-ignore dispatch type missing unwrap here
          ).unwrap();
        }

        const allOfflineProcedureIds = getAllOfflineIds(
          store.getState()?.procedures[results.teamId]?.metadata ?? {}
        );

        if (allOfflineProcedureIds.length)
          // Sync procedure data for offline use (full procedure and images).
          dispatch(
            syncAllProcedureData({
              teamId: results.teamId,
              proceduresMetadata: results.proceduresMetadata,
              procedureIds: allOfflineProcedureIds,
            })
          );
      } catch {
        // Ignore errors, we may be offline.
      }
    },
    [services, dispatch, getAllOfflineIds, store]
  );

  return { syncMasterProcedureList };
};

export default useMasterProcedureListHelpers;
