import superlogin from './superlogin';
import { API_URL } from '../config';
import {
  CreateTagParams,
  ServerTag,
  UpdateTagParams,
} from 'shared/lib/types/api/settings/tags/models';

class TagService {
  private teamId: string;

  constructor(teamId: string) {
    this.teamId = teamId;
  }

  async getTags(): Promise<ServerTag[]> {
    const url = `${API_URL}/teams/${this.teamId}/tags`;
    return superlogin
      .getHttp()
      .get(url)
      .then((resp) => resp.data.tags);
  }

  async createTag(tag: CreateTagParams): Promise<string> {
    const url = `${API_URL}/teams/${this.teamId}/tags`;
    return superlogin
      .getHttp()
      .post(url, tag)
      .then((resp) => resp.data.tag_id);
  }

  async updateTag(id: string, tag: UpdateTagParams): Promise<void> {
    const url = `${API_URL}/teams/${this.teamId}/tags/${encodeURIComponent(
      id
    )}`;
    return superlogin
      .getHttp()
      .put(url, tag)
      .then((resp) => resp.data);
  }

  async deleteTag(id: string): Promise<void> {
    const url = `${API_URL}/teams/${this.teamId}/tags/${encodeURIComponent(
      id
    )}`;
    return superlogin.getHttp().delete(url);
  }
}

export default TagService;
