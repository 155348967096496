import { useQuery } from '@tanstack/react-query';
import { useCallback, useMemo } from 'react';
import { Part } from 'shared/lib/types/postgres/manufacturing/types';
import { useDatabaseServices } from '../../contexts/DatabaseContext';
import { DatabaseServices } from '../../contexts/proceduresSlice';
import { useSettings } from '../../contexts/SettingsContext';
import {
  getPartByRevisionId as _getPartByRevisionId,
  getLatestReleasedRevisionForPartId,
} from '../lib/parts';

type UseSpecificPartsProps = { partIds: string[] };

interface SpecificPartsHook {
  isLoaded: boolean;
  getLatestPartRevision: (partId: string) => Part | undefined;
  getPartByRevisionId: (revisionId: string) => Part | undefined;
  getPartByRevision: (
    partId: string,
    revision: string | undefined
  ) => Part | undefined;
}

const useSpecificParts = (props: UseSpecificPartsProps): SpecificPartsHook => {
  const { partIds } = props;
  const { services }: { services: DatabaseServices } = useDatabaseServices();
  const { isManufacturingEnabled } = useSettings();

  const fetchParts = async () => {
    if (!isManufacturingEnabled?.()) {
      return null;
    }
    if (partIds?.length === 0) {
      return { parts: [] };
    }
    const parts = await services.manufacturing.getParts({
      partIds,
      includeAllReleasedRevisions: true,
    });
    return { parts };
  };

  const { isLoading, data } = useQuery({
    queryKey: ['parts', partIds],
    queryFn: fetchParts,
  });

  const isLoaded = useMemo(() => !isLoading, [isLoading]);

  const getLatestPartRevision = useCallback(
    (partId: string) =>
      getLatestReleasedRevisionForPartId(partId, data?.parts ?? []),
    [data?.parts]
  );

  const getPartByRevisionId = useCallback(
    (revisionId: string) => _getPartByRevisionId(revisionId, data?.parts ?? []),
    [data?.parts]
  );

  const getPartByRevision = useCallback(
    (partId: string, revision: string | undefined) => {
      const parts = data?.parts ?? [];
      // If revision not provided, return the first match (the latest revision)
      if (!revision) {
        return parts.find((part) => part.id === partId);
      }
      return parts.find((part) => part.id === partId && part.rev === revision);
    },
    [data?.parts]
  );

  return {
    isLoaded,
    getLatestPartRevision,
    getPartByRevisionId,
    getPartByRevision,
  };
};

export default useSpecificParts;
