import { useEffect, useMemo, useRef, useState } from 'react';
import { SortColumn } from 'react-data-grid';
import { useSelector } from 'react-redux';
import { ViewTab } from 'shared/lib/types/postgres/users';
import { Observer } from '../../api/realtime';
import { useAuth } from '../../contexts/AuthContext';
import { useDatabaseServices } from '../../contexts/DatabaseContext';
import { useSettings } from '../../contexts/SettingsContext';
import {
  selectProceduresLoading,
  selectProceduresMetadata,
  selectProceduresSynced,
} from '../../contexts/proceduresSlice';
import useMasterProcedureListHelpers from '../../hooks/useMasterProcedureListHelpers';
import useProcedureListActions from '../../hooks/useProcedureListActions';
import homeUtil from '../../lib/homeUtil';
import projectUtil from '../../lib/projectUtil';
import { getTagNames } from '../../lib/tagsUtil';
import RunBatchProcedureModal from '../BatchSteps/RunBatchProcedureModal';
import LoadingBadge from '../LoadingBadge';
import HomeScreenTableRDG from './HomeScreenTableRDG';
import { getColumns, getRows } from './procedureGridUtils';
import usePersistedView from './usePersistedView';
import procedureUtil from '../../lib/procedureUtil';
import { apm } from '@elastic/apm-rum';
import { useNavState } from '../../contexts/NavContext';
import { RedlineMetadata } from 'shared/lib/types/views/redlines';
import { useRealtimeContext } from '../../contexts/RealtimeContext';
import { selectOfflineInfo } from '../../app/offline';
import useEntityTags from '../../hooks/useEntityTags';
import { EntityType } from 'shared/lib/types/api/settings/tags/models';

const emptyListText = 'No Procedures';
const MAIN_VERTICAL_PADDING = 220;
const PROCEDURES_DEFAULT_SORT: Array<SortColumn> = [
  {
    columnKey: 'procedureTitle',
    direction: 'ASC',
  },
];

interface ProcedureGridProps {
  searchTerm: string;
  viewTab: ViewTab;
  setSearchTerm: (newSearchTerm: string) => void;
}

const headers = getColumns({ state: 'Active' });

const ProceduresGrid = ({ searchTerm, viewTab, setSearchTerm }: ProcedureGridProps) => {
  const { projectId } = useNavState();
  const { auth } = useAuth();
  const { services, currentTeamId } = useDatabaseServices();
  const { expandedProjectNames, setExpandedProjectNames } = usePersistedView();
  const { syncMasterProcedureList } = useMasterProcedureListHelpers();
  const { showBatchRunModal, onStartRun, onStartBatchRun, onCancelBatchRun } = useProcedureListActions();
  const [sortPreference, setSortPreference] = useState<Array<SortColumn>>(PROCEDURES_DEFAULT_SORT);
  const [redlinesMetadata, setRedlinesMetadata] = useState<Array<RedlineMetadata>>([]);
  const [loading, setLoading] = useState(false);
  const proceduresSynced = useSelector((state) => selectProceduresSynced(state, currentTeamId));
  const proceduresMetadata = useSelector((state) => selectProceduresMetadata(state, currentTeamId));
  const isProceduresLoading = useSelector((state) => selectProceduresLoading(state, currentTeamId));
  const { projects, tags, defaultView, isPostgresOnlyEnabled, isGlobalTagsEnabled } = useSettings();
  const { realtimeService } = useRealtimeContext();
  const isMounted = useRef(true);
  const isOnline = useSelector((state) => selectOfflineInfo(state).online);
  const { getTagsForEntity } = useEntityTags({ entityType: EntityType.PROCEDURE });

  useEffect(() => {
    if (!services || !realtimeService) {
      return;
    }

    setLoading(true);
    const refreshProcedures = async (changes) => {
      await syncMasterProcedureList(changes);
      if (!isMounted.current) {
        return;
      }
      setLoading(false);
    };

    const getRedlinesMetadata = () =>
      services.procedures.getRedlinesMetadata().then((redlines) => {
        if (!isMounted.current) {
          return;
        }
        setRedlinesMetadata(redlines);
      });

    let observer: Observer;
    if (isPostgresOnlyEnabled()) {
      observer = realtimeService.onProceduresEvent(refreshProcedures);
    } else {
      observer = services.procedures.onProceduresChanged(refreshProcedures);
    }

    // Manually refresh the list on first load.
    refreshProcedures(null).catch((err) => apm.captureError(err));
    getRedlinesMetadata();

    return () => {
      if (observer) {
        observer.cancel();
      }
    };
  }, [isPostgresOnlyEnabled, services, realtimeService, currentTeamId, syncMasterProcedureList]);

  const projectNameFilter = useMemo(
    () => projectUtil.getProjectNames(new Set(defaultView?.project_ids), projects),
    [defaultView, projects]
  );

  const tagNameFilter = useMemo(() => {
    return getTagNames(new Set(defaultView?.tags), tags);
  }, [defaultView, tags]);

  const rows = useMemo(() => {
    return getRows({
      procedures: procedureUtil.getMasterProcedureList(Object.values(proceduresMetadata)),
      projectId,
      searchTerm,
      context: {
        projects,
        currentTeamId,
        proceduresSynced,
        auth,
        redlinesMetadataMap: homeUtil.getRedlinesMetadataMap(redlinesMetadata),
        allProceduresMetadata: Object.entries(proceduresMetadata).map(([, procedure]) => procedure),
        onStartRun,
        isOnline,
        getTagsForEntity,
        isGlobalTagsEnabled,
      },
    });
  }, [
    auth,
    currentTeamId,
    onStartRun,
    proceduresMetadata,
    proceduresSynced,
    projectId,
    projects,
    redlinesMetadata,
    searchTerm,
    isOnline,
    isGlobalTagsEnabled,
    getTagsForEntity,
  ]);

  return (
    <>
      {loading && isProceduresLoading && (
        <div className="absolute z-200 inset-y-1/2 inset-x-1/2 flex items-center justify-center h-16">
          <LoadingBadge />
        </div>
      )}

      <>
        {showBatchRunModal && <RunBatchProcedureModal onRun={onStartBatchRun} onCancel={onCancelBatchRun} />}
        <HomeScreenTableRDG
          rowCountLabel="procedures"
          headers={headers}
          rows={rows}
          emptyListText={emptyListText}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          projectNamesFilter={projectNameFilter}
          tagNamesFilter={tagNameFilter}
          sortPreference={sortPreference}
          setSortPreference={setSortPreference}
          showParentChildRelation={true}
          mainVerticalPadding={MAIN_VERTICAL_PADDING}
          viewTab={viewTab}
          expandedProjectNames={expandedProjectNames}
          setExpandedProjectNames={setExpandedProjectNames}
        />
      </>
    </>
  );
};

export default ProceduresGrid;
