import React, { useMemo, useCallback, useContext } from 'react';
import procedureUtil, { Summary } from '../lib/procedureUtil';
import procedureVariableUtil from '../lib/procedureVariableUtil';
import { useSettings } from './SettingsContext';
import runUtil from '../lib/runUtil';
import stepConditionals, {
  CONDITIONAL_TYPE,
  CONDITIONAL_STATE,
  CONDITIONAL_TERNARY_STATE,
} from 'shared/lib/stepConditionals';
import signoffUtil from 'shared/lib/signoffUtil';
import {
  Conditional,
  ContentBlock,
  Procedure,
  Section,
  Step,
  V2Variable,
  FieldInputType,
  RepeatedSection,
  ReleaseStepBlock,
  SourceConditionalsMap,
} from 'shared/lib/types/views/procedures';
import { GetReferencedContentContext } from '../hooks/useProcedureAdapter';
import { NAMESPACE_DELIMITER, ReferenceOption } from '../lib/expression';
import { getContentBlock } from 'shared/lib/expressionUtil';
import { getLatestApprovedBlock } from '../hooks/useBlockRedlines';
import revisions from '../lib/revisions';

export const DEFAULT_VALID_REFERENCE_SUBTYPES: ReadonlyArray<FieldInputType> = [
  'text',
  'number',
  'timestamp',
  'list',
  'select',
  'multiple_choice',
] as const;

export const DEFAULT_VALID_REFERENCE_TYPES = ['input', 'table_input', 'expression'] as const;

type ScrollToParams = {
  sectionId: string;
  stepId?: string;
  stepHeaderId?: string | null;
  contentId?: string;
};
type ValidReferenceBlockParams = {
  block?: ContentBlock;
  displaySectionAs?: 'letters' | 'numbers';
  allowedReferenceTypes?: ReadonlyArray<(typeof DEFAULT_VALID_REFERENCE_TYPES)[number]>;
  allowedReferenceSubTypes?: ReadonlyArray<(typeof DEFAULT_VALID_REFERENCE_SUBTYPES)[number]>;
  pendingStep?: Step;
  precedingStepId?: string;
};
type StepOptions = {
  displayAddReferences?: boolean;
  canRemoveSignoffs?: boolean;
  filterProceduresByProject?: boolean;
};
type ProcedureState = {
  procedure: Procedure;
  getAllVariables: () => Array<V2Variable>;
  getAllSections: () => Array<Section>;
  getAllSteps: (sectionId: string) => Array<Step>;
  getInitialConditionals: (
    step: Step,
    block: ContentBlock,
    sourceType: 'step' | 'content' | 'content_binary' | 'content_ternary' | 'duration'
  ) => Array<Conditional>;
  getSectionSummary: (sectionId: string) => Summary | null;
  getStepSummary: (stepId: string, sectionId: string) => Summary | null;
  getContentBlockSummary: (stepId: string, sectionId: string, contentId: string, fieldIndex?: string) => Summary | null;
  getItemPath: (id: string) => null | string;
  getItemPathForDiff: (id: string) => null | string;
  getContentItemPath: (contentId: string) => null | string;
  scrollTo: ({ sectionId, stepId, stepHeaderId, contentId }: ScrollToParams) => void;
  stepIdsToLabelsMap: { [stepId: string]: string };
  getReferencedContentContext: GetReferencedContentContext;
  sourceStepConditionalsMap: SourceConditionalsMap;
  validReferenceBlocks: ({
    block,
    displaySectionAs,
    allowedReferenceTypes,
    allowedReferenceSubTypes,
    pendingStep,
    precedingStepId,
  }: ValidReferenceBlockParams) => Array<ReferenceOption>;
  stepOptions?: StepOptions;
};

const ProcedureContext = React.createContext<undefined | ProcedureState>(undefined);

export const ProcedureContextProvider = ({ procedure, scrollTo, stepOptions = {}, children }) => {
  const { getSetting, getListValues } = useSettings();

  const getAllSections = useCallback(() => procedure.sections, [procedure]);

  const getAllVariables = useCallback(() => procedure.variables ?? [], [procedure]);

  const getSection = useCallback(
    (sectionId) => getAllSections().find((section) => section.id === sectionId),
    [getAllSections]
  );

  const stepIdsToLabelsMap = useMemo(() => {
    const labelsMap = {};

    procedure.sections.forEach((section, sectionIndex) => {
      labelsMap[section.id] = runUtil.displaySectionKey(
        procedure.sections,
        sectionIndex,
        getSetting('display_sections_as', 'letters')
      );
      section.steps.forEach((step, stepIndex) => {
        labelsMap[step.id] = runUtil.displaySectionStepKey(
          procedure.sections,
          sectionIndex,
          stepIndex,
          getSetting('display_sections_as', 'letters')
        );
      });
    });

    return labelsMap;
  }, [procedure, getSetting]);

  const getSectionSummary = useCallback(
    (sectionId) => {
      const allSections = getAllSections();

      return procedureUtil.getContainerSummary(sectionId, allSections);
    },
    [getAllSections]
  );

  const getAllSteps = useCallback(
    (sectionId) => {
      const section = getSection(sectionId);

      if (!section) {
        return null;
      }

      return section.steps;
    },
    [getSection]
  );

  const getStepSummary = useCallback(
    (stepId, sectionId) => {
      const allSteps = getAllSteps(sectionId);

      return procedureUtil.getContainerSummary(stepId, allSteps);
    },
    [getAllSteps]
  );

  const getContentBlockSummary = useCallback(
    (contentId, stepId, sectionId, fieldIndex) => {
      const allSteps = getAllSteps(sectionId);
      let contentBlock;
      let contentBlockIndex;
      let redlines;

      if (!allSteps) {
        return null;
      }

      allSteps.some((step) => {
        if (step.id === stepId) {
          const { matchingContentBlock, matchingContentBlockIndex } = procedureUtil.getContentBlock(contentId, step);
          if (matchingContentBlock) {
            contentBlock = getContentBlock(matchingContentBlock, fieldIndex);
            contentBlockIndex = matchingContentBlockIndex;
            redlines = step.redlines;
            return true;
          }
          return false;
        }

        return false;
      });

      if (!contentBlock) {
        return null;
      }

      const blockRedlines = revisions.getBlockChanges(contentBlock, contentBlockIndex, redlines ?? []);
      const latestBlock = getLatestApprovedBlock({
        block: contentBlock,
        redlines: blockRedlines,
      });

      return {
        name: latestBlock.name,
        id: contentBlock.id,
        index: contentBlockIndex,
      };
    },
    [getAllSteps]
  );

  const getItemPath = useCallback((id) => procedureUtil.getItemPath(procedure, id), [procedure]);

  const getItemPathForDiff = useCallback((id) => procedureUtil.getItemPathForDiff(procedure, id), [procedure]);

  const getContentItemPath = useCallback(
    (contentId) => procedureUtil.getContentItemPath(procedure, contentId),
    [procedure]
  );

  const getReferencedContentContext = useCallback(
    (referencedContentId) => {
      if (!procedure) {
        return null;
      }
      let referencedFromStepKey;
      let referencedContent;
      let referencedFromStep;
      let isVariable = false;
      let isVariableRecorded = false;

      if (procedure.variables) {
        procedure.variables.some((variable) => {
          if (variable.id === referencedContentId) {
            referencedContent = procedureVariableUtil.getFieldInputFromVariable(variable);
            isVariable = true;
            isVariableRecorded = variable.value !== undefined && variable.value !== null;
            return true;
          }
          return false;
        });

        if (referencedContent) {
          return {
            referencedContent,
            isVariable,
            isVariableRecorded,
          };
        }
      }

      let referencedSectionIndex;
      let referencedStepIndex;
      procedure.sections.some((section, sectionIndex) => {
        return section.steps.some((step, stepIndex) => {
          return step.content.some((contentBlock) => {
            if (contentBlock.id === referencedContentId) {
              referencedSectionIndex = sectionIndex;
              referencedStepIndex = stepIndex;
              referencedFromStep = step;
              referencedContent = contentBlock;
              return true;
            }
            return false;
          });
        });
      });

      if (referencedSectionIndex !== undefined && referencedStepIndex !== undefined) {
        referencedFromStepKey = procedureUtil.displaySectionStepKey(
          referencedSectionIndex,
          referencedStepIndex,
          getSetting('display_sections_as', 'letters')
        );
      }
      return {
        referencedFromStepKey,
        referencedContent,
        isVariable,
        isVariableRecorded,
        substepKeyMap: procedureUtil.getSubstepKeyMap({
          step: referencedFromStep,
          formattedStepKey: referencedFromStepKey,
        }),
      };
    },
    [getSetting, procedure]
  );

  /**
   * TODO: De-dupe this from RunContext.js, after inverting parent-child
   * contexts with RunContext and ProcedureContext in Run.js to match Review.tsx
   * and Procedure.js
   */
  const sourceStepConditionalsMap = useMemo(() => stepConditionals.getSourceConditionalsMap(procedure), [procedure]);

  const validReferenceBlocks = useCallback(
    ({
      block,
      displaySectionAs,
      allowedReferenceTypes = DEFAULT_VALID_REFERENCE_TYPES,
      allowedReferenceSubTypes = DEFAULT_VALID_REFERENCE_SUBTYPES,
      pendingStep,
      precedingStepId,
    }: ValidReferenceBlockParams) => {
      const referenceOptions: ReferenceOption[] = [];

      getAllVariables().forEach((variable) => {
        if (
          variable.type === 'input' &&
          allowedReferenceTypes.includes('input') &&
          allowedReferenceSubTypes.includes(variable.inputType)
        ) {
          referenceOptions.push({
            ...variable,
            referenceLabel: 'Variable',
            textToSearch: `Variable${NAMESPACE_DELIMITER}${variable.name}`,
            origin: {
              isVariable: true,
            },
          });
        }
      });

      let currentSectionIndex = 0;
      getAllSections().forEach((section) => {
        let currentStepIndex = 0;
        if (!(section as RepeatedSection).repeat_of) {
          section.steps.forEach((step) => {
            if (!step.repeat_of && step.content) {
              step.content.forEach((content: ReleaseStepBlock) => {
                if (block && block.id === content.id) {
                  // Cannot have self-referential expressions.
                  return;
                }
                const id = procedureUtil.displaySectionStepKey(currentSectionIndex, currentStepIndex, displaySectionAs);
                const substepKeyMap = procedureUtil.getSubstepKeyMap({
                  step,
                  formattedStepKey: id,
                });
                if (
                  (content.type === 'input' &&
                    allowedReferenceTypes.includes('input') &&
                    allowedReferenceSubTypes.includes(content.inputType)) ||
                  (content.type === 'expression' && allowedReferenceTypes.includes('expression'))
                ) {
                  referenceOptions.push({
                    ...content,
                    referenceLabel: substepKeyMap[content.id],
                    textToSearch: `${substepKeyMap[content.id]}${NAMESPACE_DELIMITER}${content.name}`,
                    origin: {
                      sectionId: section.id,
                      stepId: step.id,
                    },
                  });
                } else if (content.type === 'field_input_table') {
                  content.fields.forEach((fieldInputTableBlock: ReleaseStepBlock, index: number) => {
                    if (
                      fieldInputTableBlock.type === 'input' &&
                      allowedReferenceTypes.includes('input') &&
                      allowedReferenceSubTypes.includes(fieldInputTableBlock.inputType)
                    ) {
                      referenceOptions.push({
                        ...fieldInputTableBlock,
                        id: content.id,
                        referenceLabel: substepKeyMap[content.id],
                        textToSearch: `${substepKeyMap[content.id]}${NAMESPACE_DELIMITER}${fieldInputTableBlock.name}`,
                        origin: {
                          sectionId: section.id,
                          stepId: step.id,
                          fieldIndex: index,
                        },
                      });
                    }
                  });
                } else if (content.type === 'table_input' && allowedReferenceTypes.includes('table_input')) {
                  referenceOptions.push({
                    ...content,
                    name: 'TABLE',
                    referenceLabel: substepKeyMap[content.id],
                    textToSearch: `${substepKeyMap[content.id]}${NAMESPACE_DELIMITER}${content.id}`,
                    origin: {
                      sectionId: section.id,
                      stepId: step.id,
                    },
                  });
                }
              });
              if (block && pendingStep && step.id === precedingStepId) {
                const addedStepSubstepKeyMap = procedureUtil.getSubstepKeyMap({
                  step: pendingStep,
                  formattedStepKey: '--',
                });
                pendingStep.content.forEach((content) => {
                  if (block.id === content.id) {
                    // Cannot have self-referential expressions.
                    return;
                  }

                  if (
                    (content.type === 'input' &&
                      content.inputType === 'number' &&
                      allowedReferenceTypes.includes('input')) ||
                    (content.type === 'expression' && allowedReferenceTypes.includes('expression'))
                  ) {
                    referenceOptions.push({
                      ...content,
                      referenceLabel: addedStepSubstepKeyMap[content.id],
                      textToSearch: `${addedStepSubstepKeyMap[content.id]}${NAMESPACE_DELIMITER}${content.name}`,
                      origin: {
                        sectionId: section.id,
                        stepId: step.id,
                      },
                    });
                  } else if (content.type === 'field_input_table') {
                    content.fields.forEach((fieldInputTableBlock: ReleaseStepBlock, index: number) => {
                      if (
                        fieldInputTableBlock.type === 'input' &&
                        allowedReferenceTypes.includes('input') &&
                        allowedReferenceSubTypes.includes(fieldInputTableBlock.inputType)
                      ) {
                        referenceOptions.push({
                          ...fieldInputTableBlock,
                          id: content.id,
                          referenceLabel: addedStepSubstepKeyMap[content.id],
                          textToSearch: `${addedStepSubstepKeyMap[content.id]}${NAMESPACE_DELIMITER}${
                            fieldInputTableBlock.name
                          }`,
                          origin: {
                            sectionId: section.id,
                            stepId: step.id,
                            fieldIndex: index,
                          },
                        });
                      }
                    });
                  } else if (content.type === 'table_input' && allowedReferenceTypes.includes('table_input')) {
                    referenceOptions.push({
                      ...content,
                      name: 'TABLE',
                      referenceLabel: addedStepSubstepKeyMap[content.id],
                      textToSearch: `${addedStepSubstepKeyMap[content.id]}${NAMESPACE_DELIMITER}${content.id}`,
                      origin: {
                        sectionId: section.id,
                        stepId: step.id,
                      },
                    });
                  }
                });
              }
              currentStepIndex++;
            }
          });
          currentSectionIndex++;
        }
      });
      return referenceOptions;
    },
    [getAllVariables, getAllSections]
  );

  const getInitialConditionals = useCallback(
    (step, block, sourceType) => {
      const contentId = block && block.id;
      let states: Array<string> | undefined = [];
      if (sourceType === CONDITIONAL_TYPE.CONTENT) {
        if (block.inputType === 'select' || block.inputType === 'multiple_choice') {
          states = block.options;
        } else if (block.inputType === 'list' && block.list) {
          states = getListValues(block.list);
        }
      } else if (sourceType === CONDITIONAL_TYPE.CONTENT_BINARY) {
        states = [CONDITIONAL_STATE.PASS, CONDITIONAL_STATE.FAIL];
      } else if (sourceType === CONDITIONAL_TYPE.CONTENT_TERNARY) {
        states = [
          CONDITIONAL_TERNARY_STATE.PASS,
          CONDITIONAL_TERNARY_STATE.FAIL_HIGH,
          CONDITIONAL_TERNARY_STATE.FAIL_LOW,
          CONDITIONAL_TERNARY_STATE.FAIL_NO_DATA,
        ];
      } else if (sourceType === CONDITIONAL_TYPE.STEP) {
        if (signoffUtil.isSignoffRequired(step.signoffs)) {
          states = ['completed', 'failed'];
        } else {
          states = ['completed'];
        }
      } else if (sourceType === CONDITIONAL_TYPE.DURATION) {
        states = [`≤ ${step.expected_duration}`, `> ${step.expected_duration}`];
      }
      if (!states) {
        return [];
      }
      return stepConditionals.getInitialConditionals(step.id, sourceType, contentId, states);
    },
    [getListValues]
  );

  const value = {
    procedure,
    getAllVariables,
    getAllSections,
    getAllSteps,
    getInitialConditionals,
    getSectionSummary,
    getStepSummary,
    getContentBlockSummary,
    getItemPath,
    getItemPathForDiff,
    getContentItemPath,
    scrollTo,
    stepIdsToLabelsMap,
    getReferencedContentContext,
    sourceStepConditionalsMap,
    validReferenceBlocks,
    stepOptions,
  };

  return <ProcedureContext.Provider value={value}>{children}</ProcedureContext.Provider>;
};

export const useProcedureContext = () => {
  const context = useContext(ProcedureContext);

  if (context === undefined) {
    throw new Error('useProcedureContext must be used within a useProvider');
  }

  return context;
};
