import ContactSupport from '../../components/ContactSupport';
import { useSettings } from '../../contexts/SettingsContext';
import { Dispatch, SetStateAction, useMemo } from 'react';
import { IssueRow, StatusTab } from '../types';
import { Link } from 'react-router-dom';
import { inventoryItemPath, issuePath, orderPath, partPath, runViewPath } from '../../lib/pathUtil';
import { useDatabaseServices } from '../../contexts/DatabaseContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DateTimeDisplay from '../../components/DateTimeDisplay';
import Label from '../../components/Label';
import { StatusType } from 'shared/lib/types/postgres/issues';
import { ITEM_REFERENCE_TYPE, ORDER_REFERENCE_TYPE, PART_REFERENCE_TYPE, UNLINKED_REFERENCE_TYPE } from '../constants';
import Grid, { GridColumn } from '../../elements/Grid';
import { SortColumn } from 'react-data-grid';
import useLatestParts from '../../manufacturing/hooks/useLatestParts';
import { getScrollToUrlParams } from 'shared/lib/scrollToUtil';
import { isClosedIssue, isOpenIssue, sortStatusColumn } from '../lib/issues';
import { ViewTab } from 'shared/lib/types/postgres/users';
import { useNavState } from '../../contexts/NavContext';
import projectGroupCell from '../../elements/renderers/ProjectGroupCell';
import ProjectRenderer from '../../elements/renderers/ProjectRenderer';
import { groupedRowHeightGetter, projectGrouping } from '../../lib/gridUtils';
import TitleCellRenderer from '../../elements/renderers/TitleCell';
import { getPartIdForRevisionId } from '../../manufacturing/lib/parts';

interface IssuesGridProps {
  issues: Array<IssueRow>;
  selectedStatus: StatusTab;
  verticalPadding?: number;
  setSearchTerm: (searchTerm: string) => void;
  viewTab?: ViewTab;
  expandedProjectNames: ReadonlySet<string>;
  setExpandedProjectNames: Dispatch<SetStateAction<ReadonlySet<string>>>;
}

const IssuesGrid = ({
  issues,
  selectedStatus,
  verticalPadding = 0,
  setSearchTerm,
  viewTab = ViewTab.List,
  expandedProjectNames,
  setExpandedProjectNames,
}: IssuesGridProps) => {
  const { projectId } = useNavState();
  const { currentTeamId } = useDatabaseServices();
  const { isIssuesEnabled } = useSettings();
  const { parts } = useLatestParts();

  const grouping = useMemo(
    () => projectGrouping<IssueRow>({ viewTab, expandedProjectNames, setExpandedProjectNames }),
    [expandedProjectNames, setExpandedProjectNames, viewTab]
  );
  const rowHeightGetter = useMemo(() => groupedRowHeightGetter<IssueRow>({ viewTab }), [viewTab]);

  const revisionIdToPartId = useMemo(() => {
    if (!issues) {
      return {};
    }
    const partIssues = issues.filter((issue) => issue.referenceType === PART_REFERENCE_TYPE);
    const revisionIds = partIssues.map((issue) => issue.referenceId);
    const revisionIdsUnique: string[] = Array.from(new Set(revisionIds));

    const revIdToPartId = {};
    for (const revisionId of revisionIdsUnique) {
      const partId = getPartIdForRevisionId(revisionId, parts ?? []);
      if (partId) {
        revIdToPartId[revisionId] = partId;
      }
    }
    return revIdToPartId;
  }, [issues, parts]);

  const defaultSort = [
    {
      columnKey: 'createdAt',
      direction: 'DESC',
    },
  ] as Array<SortColumn>;

  const columns = useMemo(() => {
    const columns: Array<GridColumn<IssueRow>> = [
      {
        key: 'id',
        name: 'Issue',
        sortable: true,
        width: projectId ? '32%' : '20%',
        renderCell({ row }: { row: IssueRow }) {
          const link = issuePath(currentTeamId, `${row.id}`);
          const from = selectedStatus === StatusTab.Closed ? 'closed' : 'open';
          return <TitleCellRenderer id={row.id} title={row.title} link={link} from={from} />;
        },
        comparator: (a: IssueRow, b: IssueRow) => +a.id - +b.id,
      },
      ...(projectId
        ? []
        : [
            {
              key: 'projectName',
              name: 'Project',
              width: '12%',
              sortable: true,
              renderGroupCell: projectGroupCell,
              renderCell: ({ row }) => ProjectRenderer({ row, setSearchTerm }),
            },
          ]),
      {
        key: 'sub_status',
        name: 'Status',
        width: '10%',
        sortable: true,
        renderCell({ row }: { row: IssueRow }) {
          if (!row.status) {
            return null;
          }
          if (row.status.status === StatusType.Closed && row.subStatus) {
            return <Label text={row.subStatus.label} color={row.subStatus.color} size="xs" />;
          }
          return <Label text={row.status.name} color={row.status.color} size="xs" />;
        },
        comparator: (a: IssueRow, b: IssueRow) => sortStatusColumn(a, b),
      },
      {
        key: 'severity',
        name: 'Severity',
        width: '10%',
        sortable: true,
        renderCell({ row }: { row: IssueRow }) {
          return row.severity && <Label text={row.severity.name} color={row.severity.color} size="xs" />;
        },
        comparator: (a: IssueRow, b: IssueRow) =>
          (a.severity?.id || Number.MAX_SAFE_INTEGER) - (b.severity?.id || Number.MAX_SAFE_INTEGER),
      },
      {
        key: 'assignee',
        name: 'Assignee',
        width: '15%',
        sortable: true,
      },
      {
        key: 'referenceType',
        name: 'Type',
        width: '10%',
        sortable: true,
        renderCell({ row }: { row: IssueRow }) {
          if (row.referenceType === UNLINKED_REFERENCE_TYPE) {
            return <></>;
          }
          return row.referenceType && <div className="capitalize">{row.referenceType}</div>;
        },
      },
      {
        key: 'createdAt',
        name: 'Created At',
        sortable: true,
        renderCell({ row }: { row: IssueRow }) {
          return (
            <span className="text-sm text-gray-600">
              <DateTimeDisplay timestamp={row.createdAt} wrap={false} hasTooltip={true} />
            </span>
          );
        },
      },
      {
        key: 'reference',
        name: 'Reference',
        width: '8%',
        sortable: false,
        headerCellClass: 'flex items-center justify-start',
        cellClass: 'flex items-center justify-center',
        renderCell({ row }: { row: IssueRow }) {
          if (row.referenceType === UNLINKED_REFERENCE_TYPE) {
            return <></>;
          }
          let link = '';
          if (row.runId) {
            link = `${runViewPath(currentTeamId, row.runId)}${
              row.referenceId ? getScrollToUrlParams({ id: row.referenceId, type: 'step' }) : ''
            }`;
          } else if (row.referenceId && row.referenceType === ITEM_REFERENCE_TYPE) {
            link = inventoryItemPath(currentTeamId, row.referenceId);
          } else if (row.referenceId && revisionIdToPartId && row.referenceType === PART_REFERENCE_TYPE) {
            link = partPath(currentTeamId, revisionIdToPartId[row.referenceId], row.referenceId);
          } else if (row.referenceId && row.referenceType === ORDER_REFERENCE_TYPE) {
            link = orderPath(currentTeamId, row.referenceId);
          }
          return (
            <Link to={link} className="text-blue-600 hover:underline">
              <FontAwesomeIcon className="" icon="link" />
            </Link>
          );
        },
      },
    ];
    return columns;
  }, [currentTeamId, projectId, revisionIdToPartId, selectedStatus, setSearchTerm]);

  const rows = useMemo(() => {
    const issueList: IssueRow[] =
      issues?.filter((issue) => {
        if (selectedStatus === StatusTab.Open) {
          return isOpenIssue(issue);
        }
        return isClosedIssue(issue);
      }) ?? [];
    return issueList;
  }, [issues, selectedStatus]);

  const gridKey = useMemo(() => `${selectedStatus}-${projectId}`, [projectId, selectedStatus]);

  if (!(isIssuesEnabled && isIssuesEnabled())) {
    return <ContactSupport />;
  }

  return (
    <Grid<IssueRow>
      gridKey={gridKey}
      columns={columns}
      rows={rows}
      rowHeight={rowHeightGetter}
      usedVerticalSpace={verticalPadding}
      emptyRowMessage="No Issues Found"
      defaultSort={defaultSort}
      rowGrouping={grouping}
    />
  );
};

export default IssuesGrid;
