import React, { useMemo } from 'react';
import Tooltip from '../elements/Tooltip';
import { MAX_LENGTH, MAX_LENGTH_MOBILE } from '../lib/tableTextUtil';
import { getHashColor } from '../lib/hashUtil';

interface TagProps {
  id: string;
  name: string;
  description?: string; // if included, will be displayed in a tooltip
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  clickable?: boolean;
  color?: string; // specific tag color, otherwise, one will be assigned
}

const Tag = ({ id, name, description, onClick, clickable, color }: TagProps) => {
  const tagColor = useMemo(() => {
    if (color) {
      return color;
    }

    return getHashColor(name);
  }, [color, name]);

  const tagCursor = useMemo(() => {
    return clickable || onClick ? 'cursor-pointer' : 'cursor-default';
  }, [clickable, onClick]);

  const truncate = useMemo(() => {
    return name.length > MAX_LENGTH.sm;
  }, [name]);

  const truncateMobile = useMemo(() => {
    return name.length > MAX_LENGTH_MOBILE.sm;
  }, [name]);

  const tooltipContent = useMemo(() => {
    if (description && truncate) {
      return (
        <div className="flex flex-col">
          <div className="font-bold">{name}</div>
          <div>{description}</div>
        </div>
      );
    }
    if (description) {
      return description;
    }
    return truncate ? name : undefined;
  }, [description, truncate, name]);

  const tooltipContentMobile = useMemo(() => {
    if (description && truncateMobile) {
      return (
        <div className="flex flex-col">
          <div className="font-bold">{name}</div>
          <div>{description}</div>
        </div>
      );
    }
    if (description) {
      return description;
    }
    return truncateMobile ? name : undefined;
  }, [description, truncateMobile, name]);

  const displayText = useMemo(() => {
    return truncate ? `${name.slice(0, MAX_LENGTH.sm - 1)}...` : name;
  }, [truncate, name]);

  const displayTextMobile = useMemo(() => {
    return truncateMobile ? `${name.slice(0, MAX_LENGTH_MOBILE.sm - 1)}...` : name;
  }, [truncateMobile, name]);

  return (
    <>
      <Tooltip content={tooltipContent} visible={Boolean(tooltipContent)}>
        <div
          key={id}
          className={`tag hidden lg:inline-block font-medium text-gray-600 text-sm px-1.5 py-0.5 group mr-1 rounded-xl max-w-max ${tagColor} ${tagCursor}`}
          onClick={onClick}
          aria-label={name}
        >
          {displayText}
        </div>
      </Tooltip>

      <Tooltip content={tooltipContentMobile} visible={Boolean(tooltipContentMobile)}>
        <div
          className={`tag lg:hidden font-medium text-gray-600 text-sm px-1.5 py-0.5 group mr-1 rounded-xl max-w-max ${tagColor} ${tagCursor}`}
          onClick={onClick}
          aria-label={name}
        >
          {displayTextMobile}
        </div>
      </Tooltip>
    </>
  );
};

export default Tag;
