import { cloneDeep, isEqual, omit, uniq } from 'lodash';
import {
  isDraft,
  isInReview,
  isReleased,
  isPending,
  PROCEDURE_STATE_DRAFT,
  PROCEDURE_STATE_RELEASED,
  PROCEDURE_STATE_IN_REVIEW,
  setDisabledStepSettings,
  getPendingProcedureIndex,
} from 'shared/lib/procedureUtil';
import idUtil from './idUtil';
import {
  getInitialFormValues,
  BlockTypeList,
} from '../components/Blocks/BlockTypes';
import stepConditionals, {
  CONDITIONAL_TYPE,
} from 'shared/lib/stepConditionals';
import {
  Conditional,
  ContentBlock,
  Draft,
  DraftHeader,
  DraftRedlineComment,
  DraftSection,
  DraftSectionHeader,
  DraftState,
  DraftStep,
  DraftStepHeader,
  V2DraftVariable,
  FieldInputBlock,
  Header,
  Procedure,
  ProcedureLink,
  ProcedureState,
  ReleaseState,
  ReviewComment,
  RunHeader,
  Rule,
  Section,
  SectionHeader,
  Signoff,
  Step,
  StepBlock,
  Dependency,
  Release,
  ReleaseSection,
  ReleaseStep,
  ProcedureMetadata,
  DraftBlockRedlines,
  EndRunSignoff,
  StartRunSignoff,
  ProcedureDiff,
  DiffArrayChangeSymbol,
  TelemetryBlock,
  V2ReleaseVariable,
  PartList,
  StepDiffElement,
  AlertBlock,
  TextBlock,
  ExpressionBlock,
  TableInputBlock,
} from 'shared/lib/types/views/procedures';
import { Mention } from 'shared/lib/types/postgres/util';
import { isEmptyValue } from 'shared/lib/text';
import { CONTENT_TYPE_PROCEDURE_LINK } from '../components/FieldSetProcedureLink';
import { CONDITIONAL_SUPPORTED_INPUT_TYPES } from '../components/StepConditionals/SourceField';
import tableInputUtil from './tableInputUtil';
import * as _ from 'lodash';
import { StepRedline } from 'shared/lib/types/views/redlines';
import sharedDiffUtil, { ARRAY_CHANGE_SYMBOLS } from 'shared/lib/diffUtil';
import {
  ProcedureType,
  StartRunSignoffsGroup,
} from 'shared/lib/types/couch/procedures';
import { ConfigList, VersionDetails } from 'shared/lib/types/couch/settings';
import version, { DEFAULT_VERSION_SETTING } from './version';
import { resetProcedureReviewerGroups } from 'shared/lib/reviewUtil';
import { TestCase } from '../testing/types';
import { getInitialTestPointTable } from '../components/TableInput/TableInputConstants';
import {
  ProcedureContentBlockTypes,
  BlockType,
} from 'shared/lib/types/blockTypes';

export const INITIAL_STEP: DraftStep = {
  id: '',
  name: '',
  signoffs: [],
  content: [],
  dependencies: [],
};

export const INITIAL_HEADER: DraftHeader = {
  id: '',
  name: '',
  content: [],
};

export const INITIAL_SIGNOFF: Signoff = {
  id: '',
  operators: [],
};

export const INITIAL_END_RUN_SIGNOFF: EndRunSignoff = {
  id: '',
  operators: [],
};

export const INITIAL_START_RUN_SIGNOFF: StartRunSignoff = {
  id: '',
  operators: [],
};

export const INITIAL_SECTION_HEADER: DraftHeader = {
  id: '',
  name: '',
  content: [],
};

export const INITIAL_STEP_HEADER: DraftStepHeader = {
  id: '',
  name: '',
  content: [],
};

export const INITIAL_DEPENDENCY: Dependency = {
  id: '',
  dependent_ids: [],
};

export const INITIAL_SECTION: DraftSection = {
  id: '',
  name: '',
  steps: [],
};

export const INITIAL_PROCEDURE: Draft = {
  _id: '',
  _rev: undefined,
  procedure_id: '',
  code: '',
  name: '',
  description: '',
  headers: [],
  version: '',
  archived: false,
  editedUserId: '',
  sections: [],
  owner: '',
  reviewer_groups: [],
  reviewer_actions_history: [],
  end_run_signoffs_groups: [],
  start_run_signoffs_groups: [],
};

export type Summary = {
  name: string;
  id: string;
  index: number;
};

/**
 * Replace BlockTypes.ts with types from views
 * Linear ticket: https://linear.app/epsilon3/issue/EPS-2807/replace-blocktypests-with-types-from-views
 */
export const SUBSTEP_NUMBERED_BLOCKTYPES = new Set<string>([
  'attachment',
  'input',
  'table_input',
  'jump_to',
  'text',
  'procedure_link',
  'commanding',
  'telemetry',
  'requirement',
  'external_item',
  'reference',
  'expression',
  'conditionals',
  'inventory_detail_input',
  'field_input_table',
]);

const PROCEDURE_FIELD_KEYS = ['name'] as const;
export type ProcedureFieldKey = (typeof PROCEDURE_FIELD_KEYS)[number];

const procedureUtil = {
  displaySectionKey: (index: number, style?: 'letters' | 'numbers'): string => {
    if (style === 'numbers') {
      return String(index + 1);
    }

    // by default, style is 'letters'
    let key = '';
    while (index >= 0) {
      key = String.fromCharCode(65 + (index % 26)) + key;
      index = Math.floor(index / 26 - 1);
    }

    return key;
  },

  // All operators included on Start Run signoffs
  _allStartRunSignoffOperators: (
    startRunOperators: Array<StartRunSignoffsGroup> | undefined
  ): Array<string> => {
    const startRunSignoffs = startRunOperators;
    if (!startRunSignoffs) {
      return [];
    }
    return startRunSignoffs.flatMap((signoff) => signoff.operators);
  },

  // returns boolean if user has correct start run signoffs
  userHasStartRunSignoffs: (
    startRunOperators: Array<StartRunSignoffsGroup> | undefined,
    userOperatorRolesSet: Set<string>
  ): boolean => {
    const allStartRunSignoffOperators =
      procedureUtil._allStartRunSignoffOperators(startRunOperators);
    return (
      allStartRunSignoffOperators.length === 0 ||
      allStartRunSignoffOperators.some((signoffRole) =>
        userOperatorRolesSet.has(signoffRole)
      )
    );
  },

  displayStepKey: (stepIndex: number): string => String(stepIndex + 1),

  formatStepKey: ({
    sectionKey,
    stepKey,
    style = 'letters',
  }: {
    sectionKey?: string;
    stepKey?: string;
    style?: 'letters' | 'numbers';
  }): string => {
    if (!stepKey || !sectionKey) {
      return '';
    }

    return {
      letters: `${sectionKey}${stepKey}`,
      numbers: `${sectionKey}.${stepKey}`,
    }[style];
  },

  displaySectionStepKey: (
    sectionIndex: number,
    stepIndex: number,
    style?: 'letters' | 'numbers'
  ): string => {
    const sectionKey = procedureUtil.displaySectionKey(sectionIndex, style);
    const stepKey = procedureUtil.displayStepKey(stepIndex);
    return procedureUtil.formatStepKey({ sectionKey, stepKey, style });
  },

  displaySubStepKey: (
    stepKey: string,
    currentNumber: number,
    blockType: BlockType,
    diffChangeState?: DiffArrayChangeSymbol
  ): { substepKey: string; nextNumber: number } => {
    if (SUBSTEP_NUMBERED_BLOCKTYPES.has(blockType) && stepKey !== undefined) {
      if (diffChangeState === ARRAY_CHANGE_SYMBOLS.REMOVED) {
        // Empty string used for removed blocks, so that removed blocks are aligned with other blocks.
        return {
          substepKey: ' ',
          nextNumber: currentNumber,
        };
      }
      return {
        substepKey: `${stepKey}.${currentNumber}`,
        nextNumber: currentNumber + 1,
      };
    }
    return {
      substepKey: '',
      nextNumber: currentNumber,
    };
  },

  getSubstepKeyMap: ({
    step,
    formattedStepKey,
  }: {
    step: Step | StepDiffElement;
    formattedStepKey: string;
  }): { [contentId: string]: string } => {
    if (!step) {
      return {};
    }
    let currentSubStepKey = 1;
    const numbers = {};
    step.content.forEach((content) => {
      const { substepKey: key, nextNumber } = procedureUtil.displaySubStepKey(
        formattedStepKey,
        currentSubStepKey,
        content.type
      );
      numbers[content.id] = key;
      currentSubStepKey = nextNumber;
    });
    return numbers;
  },

  /**
   * Get all content blocks of a given type in a procedure.
   *
   * @param procedure - A procedure object.
   * @param type - A block type, eg 'text' or 'input'.
   * @returns A list of all blocks of the given type.
   */
  getAllContentType: (
    procedure: Procedure,
    type: string
  ): Array<ContentBlock> => {
    const allContent: Array<ContentBlock> = [];
    procedure.sections.forEach((section) => {
      const sectionContent = procedureUtil.getAllContentTypeInSection(
        section,
        type
      );
      allContent.push(...sectionContent);
    });
    if (procedure.headers) {
      procedure.headers.forEach((header) => {
        header.content.forEach((block) => {
          if (block.type.toLowerCase() !== type) {
            return;
          }
          allContent.push(block);
        });
      });
    }
    return allContent;
  },

  /**
   * Get all content blocks of a given type in a section.
   *
   * @param section - A procedure section
   * @param type - A block type, eg 'text' or 'input'.
   * @returns A list of all blocks of the given type.
   */
  getAllContentTypeInSection: (
    section: Section,
    type: string
  ): Array<StepBlock> => {
    const sectionContent: Array<StepBlock> = [];

    section.headers?.forEach((sectionHeader) => {
      sectionHeader.content.forEach((content) => {
        if (content.type.toLowerCase() !== type) {
          return;
        }
        sectionContent.push(content);
      });
    });

    section.steps.forEach((step) => {
      step.content.forEach((content) => {
        if (content.type.toLowerCase() !== type) {
          return;
        }
        sectionContent.push(content);
      });
    });

    return sectionContent;
  },

  newDependency: (): Dependency => {
    const initialDependency = cloneDeep(INITIAL_DEPENDENCY);
    const id = procedureUtil.generateDependencyId();

    return {
      ...initialDependency,
      id,
    };
  },

  // Returns End Procedure signoff with empty operators fields and unique id
  newEndRunSignoff: (): EndRunSignoff => {
    const initialProcedureSignoff = cloneDeep(INITIAL_END_RUN_SIGNOFF);
    const id = procedureUtil.generateEndRunSignoffId();
    return {
      ...initialProcedureSignoff,
      id,
    };
  },

  // Returns Start Procedure signoff with empty operators fields and unique id
  newStartRunSignoff: (): StartRunSignoff => {
    const initialProcedureSignoff = cloneDeep(INITIAL_START_RUN_SIGNOFF);
    const id = procedureUtil.generateStartRunSignoffId();
    return {
      ...initialProcedureSignoff,
      id,
    };
  },

  // Returns signoff with empty operators fields and unique id
  newSignoff: (): Signoff => {
    const initialSignoff = cloneDeep(INITIAL_SIGNOFF);
    const id = procedureUtil.generateSignoffId();
    return {
      ...initialSignoff,
      id,
    };
  },

  // Returns step with empty initial fields and unique id
  newStep: (): Step => {
    // (3 Mar 2022) TODO: Update when INITIAL_STEP has been typed
    const initialStep = cloneDeep(INITIAL_STEP);
    initialStep.signoffs.push(procedureUtil.newSignoff());
    const id = procedureUtil.generateStepId();

    return {
      ...initialStep,
      id,
    };
  },

  newRunDraftAddedStep: (precedingStepId: string): DraftStep => {
    const step = procedureUtil.newStep() as DraftStep;
    step.precedingStepId = precedingStepId;
    step.created_during_run = true;
    step.repeat_step_enabled = false;

    return step;
  },

  // Returns section with a unique section id and a new initial step
  newSection: (): Section => {
    const initialSection = cloneDeep(INITIAL_SECTION);
    const id = procedureUtil.generateSectionId();
    const steps = [procedureUtil.newStep()];
    return {
      ...initialSection,
      id,
      steps,
    };
  },

  newProcedure: (
    userId: string,
    initalProcedure = INITIAL_PROCEDURE
  ): Procedure => {
    const initialProcedure = cloneDeep(initalProcedure);
    const procedureId = procedureUtil.generateProcedureId();
    initialProcedure._id = getPendingProcedureIndex(procedureId);
    initialProcedure.procedure_id = procedureId;
    initialProcedure.owner = userId;
    const headers = [];
    const sections = [procedureUtil.newSection()];

    return {
      ...initialProcedure,
      headers,
      sections,
    };
  },

  // Creates a new draft doc from existing release
  newDraft: (
    procedure: Release,
    isAutoIdSettingEnabled: boolean,
    versionSetting: VersionDetails = DEFAULT_VERSION_SETTING
  ): Draft => {
    const draft = cloneDeep(procedure) as Draft;
    const procedureId = procedureUtil.getProcedureId(procedure);
    draft.procedure_id = procedureId;
    draft._id = getPendingProcedureIndex(procedureId);
    resetProcedureReviewerGroups(draft);
    delete draft.actions;
    delete draft.comments;
    delete draft._rev;
    if (!isAutoIdSettingEnabled) {
      delete draft.auto_procedure_id_enabled;
    }
    draft.version = version.generateVersion(versionSetting, draft.version);
    return draft;
  },

  /**
   * Creates a new content block with the given block type and subtype. This
   * helper uses `getInitialFormValues` and adds a content id to the block.
   *
   * type: A block type from `Blocks/BlockTypes.js`.
   * subtype: A block subtype from `Blocks/BlockTypes.js`.
   * returns: New content block ready for form rendering with id.
   */
  newInitialBlock: (
    type: BlockTypeList,
    subtype?: string,
    isReadOnly = false
  ): StepBlock | PartList => {
    if (type === ProcedureContentBlockTypes.TableInput) {
      return tableInputUtil.getInitialTableInputContent(
        isReadOnly
      ) as StepBlock;
    }
    const initial = getInitialFormValues(type, subtype) as StepBlock;
    initial.id = procedureUtil.generateContentId();
    return initial;
  },

  newProcedureVariable: (): V2DraftVariable => {
    return {
      version: 2,
      name: '',
      id: procedureUtil.generateVariableId(),
      type: 'input',
      inputType: 'text',
      dateTimeType: 'date',
    };
  },

  // Returns empty initial header object with unique id.
  newHeader: (): DraftHeader => {
    const initialHeader = cloneDeep(INITIAL_HEADER);

    initialHeader.id = procedureUtil.generateHeaderId();

    return initialHeader;
  },

  // Returns empty initial section header object with unique id.
  newSectionHeader: (): DraftSectionHeader => {
    const initialHeader = cloneDeep(INITIAL_SECTION_HEADER);

    initialHeader.id = procedureUtil.generateSectionHeaderId();

    return initialHeader;
  },

  // Returns empty initial step header object with unique id.
  newStepHeader: (): DraftStepHeader => {
    const initialHeader = cloneDeep(INITIAL_STEP_HEADER);

    initialHeader.id = procedureUtil.generateStepHeaderId();

    return initialHeader;
  },

  /**
   * Returns a block copy with new ID.
   * This new ID is looked up in the idsMap if present or freshly generated if not.
   */
  copyBlock: <T extends ContentBlock>(
    block: T,
    idsMap: Record<string, string> = {}
  ): T => {
    if (!idsMap[block.id]) {
      idsMap[block.id] = procedureUtil.generateContentId();
    }
    if (block.type === ProcedureContentBlockTypes.TableInput) {
      block.columns.forEach((column) => {
        if (column.id && !idsMap[column.id]) {
          idsMap[column.id] = procedureUtil.generateTableInputColumnId();
        }
      });
    }
    const cloned = cloneDeep(block);
    cloned.id = idsMap[block.id];

    if (cloned.type === ProcedureContentBlockTypes.TableInput) {
      cloned.columns.forEach((column) => {
        column.id = column.id
          ? idsMap[column.id]
          : procedureUtil.generateTableInputColumnId();
      });
    }
    return cloned;
  },

  /*
   * Copies a single conditional.
   *
   * Note: does not update the conditional target_id, which points to
   *       another step in the procedure. target_id must be updated
   *       outside this function if needed (e.g., when duplicating a
   *       procedure). target_id does not need to be updated in the
   *       following cases:
   *
   *       1. moving a dynamic step from a run into a procedure draft
   *       2. duplicating a step in edit procedure
   */
  copyConditional: (
    conditional: Conditional,
    newStepId: string,
    newContentIdsMap: Record<string, string>
  ): Conditional => {
    const cloned = cloneDeep(conditional);
    cloned.id = procedureUtil.generateStepConditionalId();
    cloned.source_id = newStepId; // Source id is always step id (backwards compat).
    if (
      stepConditionals.isContentConditional(cloned) &&
      cloned.content_id &&
      conditional.content_id
    ) {
      cloned.content_id = newContentIdsMap[conditional.content_id];
    }
    return cloned;
  },

  /**
   * Returns a step copy with all new IDs for the step itself and its content blocks.
   * These new IDs are looked up in the idsMap if present or freshly generated if not.
   */
  copyStep: (step: Step, idsMap: Record<string, string> = {}): Step => {
    if (!idsMap[step.id]) {
      idsMap[step.id] = procedureUtil.generateStepId();
    }
    const clonedStep = cloneDeep(step);
    clonedStep.id = idsMap[step.id];

    if (step.headers) {
      step.headers.forEach((stepHeader, headerIndex) => {
        // @ts-ignore
        clonedStep.headers[headerIndex] = procedureUtil.copyStepHeader(
          stepHeader,
          idsMap
        );
      });
    }
    step.content.forEach((block, contentIndex) => {
      clonedStep.content[contentIndex] = procedureUtil.copyBlock(block, idsMap);
    });
    procedureUtil.copyConditionalsFromStep(step, clonedStep);

    return clonedStep;
  },

  /*
   * Returns map of content ids in source to content ids in dest step.
   *
   * Assumes step have the same number and order of content blocks.
   */
  getContentIdsMap: (srcStep: Step, destStep: Step): Record<string, string> => {
    const contentIdsMap: Record<string, string> = {};
    srcStep.content.forEach((srcBlock, srcBlockIndex) => {
      contentIdsMap[srcBlock.id] = destStep.content[srcBlockIndex].id;
    });
    return contentIdsMap;
  },

  /*
   * Copies a all conditionals for a single step.
   *
   * Note: does not update the conditional target_id, which points to
   *       another step in the procedure. target_id must be updated
   *       outside this function if needed (e.g., when duplicating a
   *       procedure). target_id does not need to be updated in the
   *       following cases:
   *
   *       1. moving a dynamic step from a run into a procedure draft
   *       2. duplicating a step in edit procedure
   */
  copyConditionalsFromStep: (srcStep: Step, destStep: Step): void => {
    if (!srcStep.conditionals) {
      return;
    }
    const contentIdsMap = procedureUtil.getContentIdsMap(srcStep, destStep);
    srcStep.conditionals.forEach((conditional, index) => {
      // @ts-ignore
      destStep.conditionals[index] = procedureUtil.copyConditional(
        conditional,
        destStep.id,
        contentIdsMap
      );
    });
  },

  /**
   * Returns a section copy with all new IDs for the section itself and its steps and content blocks.
   * These new IDs are looked up in the idsMap if present or freshly generated if not.
   */
  copySection: (
    section: Section,
    idsMap: Record<string, string> = {}
  ): Section => {
    if (!idsMap[section.id]) {
      idsMap[section.id] = procedureUtil.generateSectionId();
    }
    const clonedSection = cloneDeep(section);
    clonedSection.id = idsMap[section.id];

    section.headers?.forEach((sectionHeader, sectionHeaderIndex) => {
      const copiedSectionHeader = procedureUtil.copySectionHeader(
        sectionHeader,
        idsMap
      );
      if (clonedSection.headers) {
        clonedSection.headers[sectionHeaderIndex] = copiedSectionHeader;
      }
    });

    const stepConditionalIdsMap: Record<string, string> = {};
    section.steps.forEach((step, stepIndex) => {
      const copiedStep = procedureUtil.copyStep(step, idsMap);
      clonedSection.steps[stepIndex] = copiedStep;
      stepConditionalIdsMap[step.id] = copiedStep.id;
    });
    procedureUtil._updateStepConditionalIds(
      clonedSection,
      stepConditionalIdsMap
    );

    /*
     * If a step dependency references another step in this section,
     * update the dependency to reference the copied step instead
     */
    for (const clonedStep of clonedSection.steps) {
      if (!clonedStep.dependencies) continue;
      for (let i = 0; i < clonedStep.dependencies.length; i++) {
        const dependentIds = clonedStep.dependencies[i].dependent_ids;
        clonedStep.dependencies[i].dependent_ids = dependentIds.map(
          (dependentId) =>
            dependentId in idsMap ? idsMap[dependentId] : dependentId
        );
      }
    }

    return clonedSection;
  },

  // Returns a header copy with new header id, and new content id's.
  copyHeader: (header: DraftHeader): DraftHeader => {
    const cloned = cloneDeep(header);
    cloned.id = procedureUtil.generateHeaderId();

    header.content.forEach((block, index) => {
      cloned.content[index] = procedureUtil.copyBlock(block);
    });

    return cloned;
  },

  /**
   * Returns a section header copy with all new IDs for the header itself and its content blocks.
   * These new IDs are looked up in the idsMap if present or freshly generated if not.
   */
  copySectionHeader: (
    header: DraftSectionHeader,
    idsMap: Record<string, string> = {}
  ): DraftSectionHeader => {
    if (!idsMap[header.id]) {
      idsMap[header.id] = procedureUtil.generateSectionHeaderId();
    }
    const clonedSectionHeader = cloneDeep(header);
    clonedSectionHeader.id = idsMap[header.id];

    header.content?.forEach((block, contentIndex) => {
      clonedSectionHeader.content[contentIndex] = procedureUtil.copyBlock(
        block,
        idsMap
      );
    });

    return clonedSectionHeader;
  },

  /**
   * Returns a step header copy with all new IDs for the header itself and its content blocks.
   * These new IDs are looked up in the idsMap if present or freshly generated if not.
   */
  copyStepHeader: (
    header: DraftStepHeader,
    idsMap: Record<string, string> = {}
  ): DraftStepHeader => {
    if (!idsMap[header.id]) {
      idsMap[header.id] = procedureUtil.generateStepHeaderId();
    }
    const cloned = cloneDeep(header);
    cloned.id = idsMap[header.id];

    header.content.forEach((block, index) => {
      cloned.content[index] = procedureUtil.copyBlock(block, idsMap);
    });

    return cloned;
  },

  // Generate a unique string for a header. Example hdr_db6e439549re2112343f3.
  generateHeaderId: (): string => `hdr_${idUtil.generateUuidEquivalentId()}`,

  // Generate a unique string for a step header. Example sech_db6e439549re2112343f3.
  generateSectionHeaderId: (): string =>
    `sech_${idUtil.generateUuidEquivalentId()}`,

  // Generate a unique string for a step header. Example sh_db6e439549re2112343f3.
  generateStepHeaderId: (): string => `sh_${idUtil.generateUuidEquivalentId()}`,

  // Generated unique string for a step. Example: step_db6e439549
  generateStepId: (): string => `step_${idUtil.generateId()}`,

  // Generated unique string for a section. Example: sctn_e4cc301383
  generateSectionId: (): string => `sctn_${idUtil.generateId()}`,

  // Generated unique string for a content block. Example: ct_ncrXbmEUM7YET1KAZBbE.
  generateContentId: (): string => `ct_${idUtil.generateLargeId()}`,

  // Generated unique string for a procedure variable. Example: var_FxVAATqvcTplmtifIxtc.
  generateVariableId: (): string => `var_${idUtil.generateLargeId()}`,

  // Generated unique string for a step detail. Example: sd_db6e439549re2112343f3.
  generateStepDetailId: (): string => `sd_${idUtil.generateUuidEquivalentId()}`,

  // Generated unique string for a list. Example: ls_db6e439549re2112343f3.
  generateListId: (): string => `ls_${idUtil.generateUuidEquivalentId()}`,

  // Generated unique string for a signoff. Example: so_ib340a6g2a
  generateSignoffId: (): string => `so_${idUtil.generateId()}`,

  // Generate unique string for a table signoff. Example: table_signoff_ib340a6g2a
  generateTableSignoffId: (): string => `table_signoff_${idUtil.generateId()}`,

  // Generated unique string for a End Run Signoff. Example: erso_ib340a6g2a
  generateEndRunSignoffId: (): string => `erso_${idUtil.generateId()}`,

  // Generated unique string for a Start Run Signoff. Example: srso_ib340a6g2a
  generateStartRunSignoffId: (): string => `srso_${idUtil.generateId()}`,

  // Generated unique string for a signoff. Example: snp_db6e439549re2112343f3
  generateSnippetId: (): string => `snp_${idUtil.generateUuidEquivalentId()}`,

  // Generated unique string for a dependency. Example: sdep_ib340a6g2a
  generateDependencyId: (): string => `sdep_${idUtil.generateId()}`,

  generateStepConditionalId: (): string =>
    `sc_${idUtil.generateUuidEquivalentId()}`,

  generateReviewerGroupId: (): string =>
    `rg_${idUtil.generateUuidEquivalentId()}`,

  generateReviewerId: (): string =>
    `reviewer_${idUtil.generateUuidEquivalentId()}`,

  generateTableInputColumnId: (): string =>
    `table_input_column_${idUtil.generateUuidEquivalentId()}`,

  generateTableInputRowId: (): string =>
    `table_input_row_${idUtil.generateUuidEquivalentId()}`,

  generateImageNameId: (): string => `img_${idUtil.generateUuidEquivalentId()}`,

  /**
   * Returns procedure id given a procedure.
   *
   * The procedure id is taken from the procedure_id field of the procedure if that field
   * exists, otherwise we use the couchdb _id (the index)  as the procedure id.
   * This approach is a temporary solution until we implement our own versioning/id
   * scheme.
   */
  getProcedureId: (procedure): string =>
    procedure.procedure_id || procedure._id,

  // Generated unique string for a procedure. Example: 56ncrXbmEUM7YET1KAZBbE.
  generateProcedureId: (): string => idUtil.generateUuidEquivalentId(),

  generateDuplicateProcedureMessage: (procedureName: string): string =>
    `Are you sure you want to make a copy of "${procedureName}"?`,

  // Returns map of old step, section, and content ids to new ones
  _generateNewIdsMap: (procedure: Release | Draft): Record<string, string> => {
    let newIdsMap = {};
    if (procedure.variables) {
      procedure.variables.forEach((variable) => {
        if ('id' in variable) {
          const id = (variable as V2ReleaseVariable).id;
          newIdsMap[id] = procedureUtil.generateVariableId();
        }
      });
    }

    if (procedure.headers) {
      procedure.headers.forEach((header) => {
        newIdsMap[header.id] = procedureUtil.generateHeaderId();
        header.content.forEach((headerBlock) => {
          newIdsMap[headerBlock.id] = procedureUtil.generateContentId();
          if (headerBlock.type === ProcedureContentBlockTypes.TableInput) {
            newIdsMap = {
              ...newIdsMap,
              ...procedureUtil.generateNewIdsMapForTable(headerBlock),
            };
          }
        });
      });
    }

    procedure.sections.forEach((section) => {
      newIdsMap = {
        ...newIdsMap,
        ...procedureUtil.generateNewIdsMapForSection(section),
      };
    });
    return newIdsMap;
  },

  generateNewIdsMapForSection: (
    section: ReleaseSection
  ): Record<string, string> => {
    let newIdsMap = {};
    newIdsMap[section.id] = procedureUtil.generateSectionId();

    section.headers?.forEach((sectionHeader) => {
      newIdsMap[sectionHeader.id] = procedureUtil.generateSectionHeaderId();
      sectionHeader.content.forEach((sectionHeaderBlock) => {
        newIdsMap[sectionHeaderBlock.id] = procedureUtil.generateContentId();
      });
    });

    section.steps.forEach((step) => {
      newIdsMap = {
        ...newIdsMap,
        ...procedureUtil.generateNewIdsMapForStep(step),
      };
    });
    return newIdsMap;
  },

  generateNewIdsMapForStep: (step: ReleaseStep): Record<string, string> => {
    let newIdsMap = {};
    newIdsMap[step.id] = procedureUtil.generateStepId();
    if (step.headers) {
      step.headers.forEach((stepHeader) => {
        newIdsMap[stepHeader.id] = procedureUtil.generateStepHeaderId();
        stepHeader.content.forEach((stepHeaderBlock) => {
          newIdsMap[stepHeaderBlock.id] = procedureUtil.generateContentId();
        });
      });
    }
    step.content.forEach((block) => {
      newIdsMap[block.id] = procedureUtil.generateContentId();

      if (block.type === ProcedureContentBlockTypes.TableInput) {
        newIdsMap = {
          ...newIdsMap,
          ...procedureUtil.generateNewIdsMapForTable(block),
        };
      }
    });

    if (step.conditionals) {
      step.conditionals.forEach((conditional) => {
        newIdsMap[conditional.id] = procedureUtil.generateStepConditionalId();
      });
    }
    return newIdsMap;
  },

  generateNewIdsMapForTable: (
    block: TableInputBlock
  ): Record<string, string> => {
    const newIdsMap = {};

    block.columns.forEach((column) => {
      if (column.id) {
        newIdsMap[column.id] = procedureUtil.generateTableInputColumnId();
      }
    });
    block.row_metadata?.forEach((row) => {
      if (row.id) {
        newIdsMap[row.id] = procedureUtil.generateTableInputRowId();
      }
    });

    return newIdsMap;
  },

  _updateDependencyIds: (
    step: Step,
    newIdsMap: Record<string, string>
  ): void => {
    if (step.dependencies && step.dependencies.length) {
      step.dependencies.forEach((dependency) => {
        const newDependentIds: Array<string> = [];

        dependency.dependent_ids.forEach((id) => {
          const newId = newIdsMap[id];

          if (newId) {
            newDependentIds.push(newId);
          }
        });

        dependency.dependent_ids = newDependentIds;
      });
    }
  },

  _updateConditionalIds: (
    step: Step,
    newIdsMap: Record<string, string>
  ): void => {
    if (!step.conditionals) {
      return;
    }
    step.conditionals.forEach((conditional) => {
      conditional.id = newIdsMap[conditional.id];
      if (conditional.source_id) {
        const newId = newIdsMap[conditional.source_id];
        conditional.source_id = newId;
      }
      if (conditional.target_id) {
        const newId = newIdsMap[conditional.target_id];
        conditional.target_id = newId;
      }
      if (conditional.content_id && newIdsMap[conditional.content_id]) {
        conditional.content_id = newIdsMap[conditional.content_id];
      }
    });
  },

  _updateReferenceIds: (
    block: ExpressionBlock | TextBlock | AlertBlock,
    newIdsMap: Record<string, string>
  ): void => {
    if (!block.tokens || block.tokens.length === 0) {
      return;
    }
    block.tokens.forEach((token) => {
      if (token.reference_id) {
        token.reference_id = newIdsMap[token.reference_id];
      }
      if (token.table_reference) {
        token.table_reference.row_id = newIdsMap[token.table_reference.row_id];
        token.table_reference.column_id =
          newIdsMap[token.table_reference.column_id];
      }
    });
  },

  /**
   * Updates section, step, step header, step header content and step content with new ids.
   *
   * Updates content that contains ids as references (right now only
   * jump links) with the new ids.
   */
  updateProcedureIds: (procedure: Release | Draft): void => {
    const newIdsMap = procedureUtil._generateNewIdsMap(procedure);
    // TODO (Alec): Refactor the id copy code into separate methods for header, step, step header, etc. to remove duplication
    if (procedure.variables) {
      procedure.variables.forEach((variable) => {
        if ('id' in variable) {
          const variableV2 = variable as V2ReleaseVariable;
          variableV2.id = newIdsMap[variableV2.id];
        }
      });
    }

    if (procedure.headers) {
      procedure.headers.forEach((header) => {
        header.id = newIdsMap[header.id];
        header.content.forEach((headerBlock) => {
          headerBlock.id = newIdsMap[headerBlock.id];
          if (headerBlock.type === ProcedureContentBlockTypes.Text) {
            procedureUtil._updateReferenceIds(headerBlock, newIdsMap);
          }
          if (headerBlock.type === ProcedureContentBlockTypes.Alert) {
            procedureUtil._updateReferenceIds(headerBlock, newIdsMap);
          }
        });
      });
    }

    procedure.sections.forEach((section) => {
      section.id = newIdsMap[section.id];

      section.headers?.forEach((sectionHeader) => {
        sectionHeader.id = newIdsMap[sectionHeader.id];
        sectionHeader.content.forEach((sectionHeaderBlock) => {
          sectionHeaderBlock.id = newIdsMap[sectionHeaderBlock.id];
          if (sectionHeaderBlock.type === ProcedureContentBlockTypes.Text) {
            procedureUtil._updateReferenceIds(sectionHeaderBlock, newIdsMap);
          }
          if (sectionHeaderBlock.type === ProcedureContentBlockTypes.Alert) {
            procedureUtil._updateReferenceIds(sectionHeaderBlock, newIdsMap);
          }
        });
      });

      section.steps.forEach((step) => {
        step.id = newIdsMap[step.id];

        // Update dependency ids to the new ids.
        procedureUtil._updateDependencyIds(step, newIdsMap);
        procedureUtil._updateConditionalIds(step, newIdsMap);

        if (step.headers) {
          step.headers.forEach((stepHeader) => {
            stepHeader.id = newIdsMap[stepHeader.id];
            stepHeader.content.forEach((stepHeaderBlock) => {
              stepHeaderBlock.id = newIdsMap[stepHeaderBlock.id];
              if (stepHeaderBlock.type === ProcedureContentBlockTypes.Text) {
                procedureUtil._updateReferenceIds(stepHeaderBlock, newIdsMap);
              }
              if (stepHeaderBlock.type === ProcedureContentBlockTypes.Alert) {
                procedureUtil._updateReferenceIds(stepHeaderBlock, newIdsMap);
              }
            });
          });
        }
        step.content.forEach((block) => {
          block.id = newIdsMap[block.id];
          if (block.type === ProcedureContentBlockTypes.JumpTo) {
            block.jumpToId = newIdsMap[block.jumpToId];
          }
          if (block.type === ProcedureContentBlockTypes.Reference) {
            block.reference = newIdsMap[block.reference];
          }
          if (block.type === ProcedureContentBlockTypes.Expression) {
            procedureUtil._updateReferenceIds(block, newIdsMap);
          }
          if (block.type === ProcedureContentBlockTypes.Text) {
            procedureUtil._updateReferenceIds(block, newIdsMap);
          }
          if (block.type === ProcedureContentBlockTypes.Alert) {
            procedureUtil._updateReferenceIds(block, newIdsMap);
          }
          if (block.type === ProcedureContentBlockTypes.TableInput) {
            block.columns.forEach((column) => {
              column.id = column.id
                ? newIdsMap[column.id]
                : procedureUtil.generateTableInputColumnId();
            });
            block.row_metadata?.forEach((row) => {
              row.id = newIdsMap[row.id];
            });
          }
        });
      });
    });
  },

  _updateContentBlockWithNewIds: (block: StepBlock): void => {
    if (
      block.type === ProcedureContentBlockTypes.PartBuild ||
      block.type === ProcedureContentBlockTypes.PartKit
    ) {
      block.items.forEach((item) => {
        item.id = idUtil.generateUuidEquivalentId();
      });
    }
  },

  // Updates each block in content array with a new id.  Returns mapping of old to new content IDs.
  _updateContentWithNewIds: (
    content: Array<StepBlock>
  ): Map<string, string> => {
    const oldToNewIds = new Map<string, string>();
    content.forEach((block) => {
      const newId = procedureUtil.generateContentId();
      oldToNewIds.set(block.id, newId);
      block.id = newId;
      procedureUtil._updateContentBlockWithNewIds(block);
    });
    return oldToNewIds;
  },

  // Updates step headers with new ids and new content ids
  _updateStepHeadersWithNewIds: (stepHeaders: Array<RunHeader>): void => {
    stepHeaders.forEach((stepHeader) => {
      stepHeader.id = procedureUtil.generateStepHeaderId();
      procedureUtil._updateContentWithNewIds(stepHeader.content);
    });
  },

  _updateConditionalsWithNewIds: (
    step: Step,
    oldToNewContentIds: Map<string, string>
  ): void => {
    step.conditionals?.forEach((conditional) => {
      conditional.id = procedureUtil.generateStepConditionalId();
      conditional.source_id = step.id;
      if (
        stepConditionals.isContentConditional(conditional) &&
        oldToNewContentIds.has(conditional.content_id)
      ) {
        conditional.content_id = oldToNewContentIds.get(
          conditional.content_id
        ) as string;
      }
    });
  },

  _updateDependenciesWithNewIds: (block: Step | Section): void => {
    block.dependencies?.forEach((dependency) => {
      dependency.id = procedureUtil.generateDependencyId();
    });
  },

  // Updates the target ids if the step is in the repeated (or duplicated) section
  _updateStepConditionalIds: (
    section: Section,
    stepIdMap: Record<string, string>
  ): void => {
    section.steps.forEach((step) => {
      if (!step.conditionals) {
        return;
      }
      step.conditionals.forEach((conditional) => {
        // Update the source id to the new step id
        if (conditional.source_type === CONDITIONAL_TYPE.STEP) {
          conditional.source_id = step.id;
        }

        // Update the target only if the target step is in the section
        const target_id = conditional.target_id;
        if (target_id in stepIdMap) {
          conditional.target_id = stepIdMap[target_id];
        }
      });
    });
  },

  //Mapping between Review Parents Comments' Ids and Parent Comment
  parentCommentsMap: (
    reviewComments: Array<ReviewComment>
  ): { [key: string]: ReviewComment } => {
    const parentCommentsMap = {};
    if (!reviewComments) {
      return parentCommentsMap;
    }
    for (const comment of reviewComments) {
      // Check if comment is a parent and does not exist in group
      if (!comment.parent_id && !parentCommentsMap[comment.id]) {
        parentCommentsMap[comment.id] = comment;
      }
    }
    return parentCommentsMap;
  },

  // Mapping between ParentId and child review comments
  parentChildCommentsMap: (reviewComments: Array<ReviewComment>): object => {
    const parentChildMap = {};
    if (!reviewComments) {
      return parentChildMap;
    }
    for (const comment of reviewComments) {
      // Meaning parent here
      if (comment.parent_id) {
        if (!parentChildMap[comment.parent_id]) {
          parentChildMap[comment.parent_id] = [];
        }
        parentChildMap[comment.parent_id].push(comment);
      } else {
        // no children but we still need an empty entry for this parent comment
        if (!parentChildMap[comment.id]) {
          parentChildMap[comment.id] = [];
        }
      }
    }
    return parentChildMap;
  },

  // Returns a mapping of updated step/section ids to the original step/section ids within a section
  updateSectionWithNewIds: (section: Section): { [id: string]: string } => {
    const oldSectionId = section.id;
    section.id = procedureUtil.generateSectionId();
    section.headers?.forEach((sectionHeader) => {
      procedureUtil.updateSectionHeaderWithNewIds(sectionHeader);
    });
    if (section.dependencies) {
      procedureUtil._updateDependenciesWithNewIds(section);
    }
    const newToOldIds = {};
    newToOldIds[section.id] = oldSectionId;
    section.steps.forEach((step) => {
      const oldId = step.id;
      procedureUtil.updateStepWithNewIds(step);
      newToOldIds[step.id] = oldId;
    });
    return newToOldIds;
  },

  // Updates section header with a new section header id and new content ids.
  updateSectionHeaderWithNewIds: (header: SectionHeader): void => {
    header.id = procedureUtil.generateSectionHeaderId();

    if (header.content) {
      procedureUtil._updateContentWithNewIds(header.content);
    }
  },

  // Updates step with a new step id and new content ids.
  updateStepWithNewIds: (step: Step): void => {
    step.id = procedureUtil.generateStepId();
    // TODO (Alex): Update signoff ids too
    if (step.headers) {
      procedureUtil._updateStepHeadersWithNewIds(step.headers);
    }
    let oldToNewContentIds = new Map<string, string>();
    if (step.content) {
      oldToNewContentIds = procedureUtil._updateContentWithNewIds(step.content);
    }
    if (step.conditionals) {
      procedureUtil._updateConditionalsWithNewIds(step, oldToNewContentIds);
    }
    if (step.dependencies) {
      procedureUtil._updateDependenciesWithNewIds(step);
    }
  },

  /**
   * Returns a string path of truthy section id, step id, step header id, and content id.
   * @param sectionId
   * @param stepId
   * @param stepHeaderId
   * @param contentId
   * @returns sectionId:stepId:stepHeaderId
   */
  makePath: (
    sectionId: null | string,
    stepId: null | string,
    stepHeaderId?: null | string,
    contentId?: null | string
  ): null | string => {
    if (!sectionId) {
      return null;
    }
    return [sectionId, stepId, stepHeaderId, contentId]
      .filter((id) => id) // remove falsy values
      .join(':');
  },

  /**
   * Parses string version of section, step, and step header ids
   * @param path of sectionId, stepId, and headerId (optional) separated by ":".
   * @returns object with sectionId, stepId, and headerId (nullable) properties.
   */
  parsePath: (path: string | null): Record<string, string> => {
    if (!path) {
      return {};
    }

    const [sectionId, stepId, stepHeaderId] = path.split(':');

    return {
      sectionId,
      stepId,
      stepHeaderId,
    };
  },

  /**
   * Parses string version of section, step, step header, and content ids.
   * @param path of sectionId, stepId, headerId (optional), and contentId separated by ":".
   * @returns object with sectionId, stepId, headerId (nullable), and contentId properties.
   */
  parseContentPath: (path: string | null): Record<string, string> => {
    if (!path) {
      return {};
    }

    const ids = path.split(':');

    let sectionId, stepId, stepHeaderId, contentId;
    if (ids.length === 3) {
      [sectionId, stepId, contentId] = ids;
    } else {
      [sectionId, stepId, stepHeaderId, contentId] = ids;
    }

    return {
      sectionId,
      stepId,
      stepHeaderId,
      contentId,
    };
  },

  /**
   * Finds path of item with id. For a step it will return sectionId:stepId if there is no step header, and sectionId:stepId:stepHeaderId
   * if there is a step header, since we want to link to the highest part of the step.
   * @param procedure
   * @param id of the item (section or step)
   * @return the path of the item
   */
  getItemPath: (procedure: Procedure, id: string): null | string => {
    const sections = procedure.sections;
    let sectionId;
    let stepId;
    let stepHeaderId;

    sections.some((section) => {
      if (section.id === id) {
        sectionId = section.id;
        return true;
      }

      return section.steps.some((step) => {
        if (step.id === id) {
          sectionId = section.id;
          stepId = step.id;
          stepHeaderId = procedureUtil.getStepHeaderId(step);

          return true;
        }

        return false;
      });
    });

    return procedureUtil.makePath(sectionId, stepId, stepHeaderId);
  },

  /**
   * Finds path of item with id. For a step it will return sectionId:stepId if there is no step header, and sectionId:stepId:stepHeaderId
   * if there is a step header, since we want to link to the highest part of the step.
   * @param procedure
   * @param id of the item (section or step)
   * @return the path of the item
   */
  getItemPathForDiff: (procedure: ProcedureDiff, id: string): null | string => {
    const sections = procedure.sections;
    let sectionId;
    let stepId;
    let stepHeaderId;

    sections.some((section) => {
      const oldSectionId = sharedDiffUtil.getDiffValue(section, 'id', 'old');
      if (oldSectionId === id) {
        sectionId = oldSectionId;
        return true;
      } else if (section.id === `${id}__removed`) {
        sectionId = section.id;
        return true;
      } else if (sharedDiffUtil.getDiffValue(section, 'id', 'new') === id) {
        return true;
      }

      return section.steps.some((step) => {
        const oldStepId = sharedDiffUtil.getDiffValue(step, 'id', 'old');
        const newStepId = sharedDiffUtil.getDiffValue(step, 'id', 'new');
        const newSectionId = sharedDiffUtil.getDiffValue(section, 'id', 'new');
        if (oldStepId === id) {
          stepId = oldStepId;
          sectionId = newSectionId;
          stepHeaderId = procedureUtil.getStepHeaderId(step);
          return true;
        } else if (step.id === `${id}__removed`) {
          stepId = step.id;
          sectionId = newSectionId;
          stepHeaderId = procedureUtil.getStepHeaderId(step);
          return true;
        } else if (newStepId === id) {
          stepId = newStepId;
          sectionId = newSectionId;
          stepHeaderId = procedureUtil.getStepHeaderId(step);
          return true;
        }

        return false;
      });
    });

    return procedureUtil.makePath(sectionId, stepId, stepHeaderId);
  },

  /**
   * Finds path of content item with contentId. For a step it will return sectionId:stepId:contentId if there is no step header,
   * and sectionId:stepId:stepHeaderId:contentId if there is a step header,
   * @param procedure
   * @param contentId of the item (section or step)
   * @return the path of the item
   */
  getContentItemPath: (
    procedure: Procedure,
    contentId: string
  ): null | string => {
    const sections = procedure.sections;
    let sectionId;
    let stepId;
    let stepHeaderId;

    sections.some((section) => {
      return section.steps.some((step) => {
        return step.content.some((contentBlock) => {
          if (contentBlock.id === contentId) {
            sectionId = section.id;
            stepId = step.id;
            return true;
          } else {
            return step.headers?.some((stepHeader) => {
              return stepHeader.content.some((stepHeaderContent) => {
                if (stepHeaderContent.id === contentId) {
                  sectionId = section.id;
                  stepId = step.id;
                  stepHeaderId = procedureUtil.getStepHeaderId(step);
                  return true;
                }
                return false;
              });
            });
          }
        });
      });
    });

    return procedureUtil.makePath(sectionId, stepId, stepHeaderId, contentId);
  },

  getProcedureTitle: (
    procedureCode: string,
    procedureName: string,
    withDash = false
  ): string => {
    if (!procedureCode && !procedureName) {
      return 'Untitled procedure';
    }
    if (!procedureCode || !procedureName) {
      return procedureCode || procedureName;
    }
    if (withDash) {
      return `${procedureCode} - ${procedureName}`;
    }
    return `${procedureCode} ${procedureName}`;
  },

  /**
   * Only show unarchived releases and drafts without corresponding releases.
   *
   * Drafts with archived releases are hidden because we check for parent releases
   * in "procedures", the input which is assumed to also contain archived procedures.
   */
  getMasterProcedureList: (
    procedures: ProcedureMetadata[]
  ): ProcedureMetadata[] => {
    if (!procedures) {
      return [];
    }
    // Build lookup table for performance
    const map = Object.fromEntries(procedures.map((p) => [p._id, p]));
    return procedures.filter((p) => {
      // Filter out archived procedures.
      if (p.archived) {
        return false;
      }
      // Filture out draft or in review versions that have a released version.
      if (isDraft(p) || isInReview(p)) {
        const parent = p.procedure_id !== undefined ? map[p.procedure_id] : '';
        if (parent && isReleased(parent)) {
          return false;
        }
      }
      // Include remaining in master list.
      return true;
    });
  },

  getVersionLabel: (
    version: string | undefined,
    versionState: ReleaseState | DraftState
  ): string => {
    let label = 'Version';
    if (version) {
      label += ` ${version}`;
    }
    if (!versionState || versionState === PROCEDURE_STATE_RELEASED) {
      label += ' (Released)';
    } else if (versionState === PROCEDURE_STATE_DRAFT) {
      label += ' (Draft)';
    } else if (versionState === PROCEDURE_STATE_IN_REVIEW) {
      label += ' (In Review)';
    }
    return label;
  },

  getCurrentState: (
    procedure: ProcedureMetadata,
    procedures: Array<ProcedureMetadata>
  ): ProcedureState => {
    const pending = procedureUtil.getPendingProcedure(procedure, procedures);
    if (!pending) {
      return 'released';
    }
    if (isDraft(pending)) {
      return 'draft';
    }
    return 'in_review';
  },

  getPendingProcedure: (
    procedure: ProcedureMetadata,
    procedures: Array<ProcedureMetadata>
  ): ProcedureMetadata | undefined => {
    if (isPending(procedure)) {
      return procedure;
    }
    return procedures.find((p) => p.procedure_id === procedure._id);
  },

  /**
   * Retrieves all comments that belong to the reference (e.g., a step or section)
   * with the specified reference id.
   *
   * @param comments - array of comment objects.
   * @param referenceId - unique identifier of part of the procedure, e.g., a
   *                      step or section. Example referenceId: "step_abc123".
   *
   * @returns array of all comments matching the given reference id.
   *          Comments in the returned array have the same order as comments
   *          in the original array.
   */
  getCommentsByReferenceId: <T extends DraftRedlineComment | ReviewComment>(
    referenceId: string,
    comments?: Array<T>
  ): Array<T> => {
    if (!comments) {
      return [];
    }
    return comments.filter((comment) => comment.reference_id === referenceId);
  },

  // Returns comments filtered by specified section
  getCommentsBySection: <T extends DraftRedlineComment | ReviewComment>(
    section: Section,
    comments?: Array<T>
  ): Array<T> => {
    if (!comments || !section) {
      return [];
    }
    const sectionComments: Array<T> = [];
    for (const step of section.steps) {
      const stepComments = procedureUtil.getCommentsByReferenceId(
        step.id,
        comments
      );
      sectionComments.push(...stepComments);
    }
    return sectionComments;
  },

  // Returns step redlines filtered by specified section
  getStepRedlinesBySection: (
    stepRedlineMap: Map<string, Array<StepRedline>>,
    section: Section
  ): Map<string, Array<StepRedline>> => {
    if (!section || !stepRedlineMap) {
      return stepRedlineMap;
    }
    const clonedStepRedlineMap = cloneDeep(stepRedlineMap);
    const sectionStepIds = section.steps.map((step) => step.id);
    for (const stepId of stepRedlineMap.keys()) {
      if (!sectionStepIds.includes(stepId)) {
        clonedStepRedlineMap.delete(stepId);
      }
    }
    return clonedStepRedlineMap;
  },

  // Returns step with corresponding id or null if step not found.
  getStepById: (procedure: Procedure, stepId: string): null | Step => {
    for (const section of procedure.sections) {
      for (const step of section.steps) {
        if (step.id === stepId) {
          return step;
        }
      }
    }
    return null;
  },

  // Returns step with corresponding id or null if step not found.
  getStepByIds: (
    procedure: Procedure,
    sectionId: string,
    stepId: string
  ): Step | undefined => {
    const section = (procedure.sections as Array<Section>).find(
      (section) => section.id === sectionId
    );
    if (!section) {
      return;
    }
    return (section.steps as Array<Step>).find((step) => step.id === stepId);
  },

  getBatchIdForStepId: (
    procedure: Procedure,
    stepId: string
  ): string | undefined => {
    const step = procedureUtil.getStepById(procedure, stepId);
    if (step && 'batchProps' in step) {
      return step.batchProps?.batchId;
    }
  },

  // Return Section Index with corresponding id
  getSectionIndexById: (
    procedure: Procedure,
    sectionId: string
  ): null | number => {
    if (!procedure?.sections) {
      return null;
    }
    const index = procedure.sections.findIndex(
      (section) => section.id === sectionId
    );
    if (index === -1) {
      return null;
    }
    return index;
  },

  // Return section header with corresponding id or null if header not found.
  getSectionHeaderById: (
    procedure: Procedure,
    sectionHeaderId: string
  ): null | Header => {
    if (!procedure.sections) {
      return null;
    }

    for (const section of procedure.sections) {
      if (section.headers) {
        for (const sectionHeader of section.headers) {
          if (sectionHeader.id === sectionHeaderId) {
            return sectionHeader;
          }
        }
      }
    }
    return null;
  },

  // Returns map of stepId -> step object for all steps in the procedure.
  getStepIdToStepMap: (procedure: Procedure): Record<string, Step> => {
    const stepMap = {};
    for (const section of procedure.sections) {
      for (const step of section.steps) {
        stepMap[step.id] = step;
      }
    }
    return stepMap;
  },

  /**
   * Returns step definition object, i.e., a step object excluding all fields in the
   * step related to headers and redlines. Also includes include_in_summary field.
   *
   * @param step - Step object.
   * @returns Step definition object.
   *
   */
  _getStepDefinition: (step: DraftStep): DraftStep => {
    const cloned = cloneDeep(step);
    if (cloned.step_field_redlines) {
      delete cloned.step_field_redlines;
    }

    /*
     * Delete headers array if array is empty.
     * A step can have an empty headers array if a header is added then removed.
     */
    if (_.isEmpty(cloned.headers)) {
      delete cloned.headers;
    }

    cloned.content.forEach((block) => {
      delete (block as DraftBlockRedlines).redlines;
      if ('include_in_summary' in block) {
        delete block.include_in_summary;
      }
    });

    return cloned;
  },

  getProcedureDefinition(procedure: Procedure): Procedure {
    const cloned = cloneDeep(procedure);
    cloned.sections.forEach((section, sectionIndex) => {
      section.steps.forEach((step, stepIndex) => {
        const stepDefinition = procedureUtil._getStepDefinition(step);
        cloned.sections[sectionIndex].steps[stepIndex] = stepDefinition;
      });
    });
    return cloned;
  },

  /**
   * Returns true if two steps have the same definition, i.e., they are the same if performing
   * a deep comparison of all step fields, excluding those fields related to metadata and redlines.
   *
   * @param step1 - Step object, including all redlines and metadata.
   * @param step2 - Step object, including all redlines and metadata.
   *
   * @returns True if step content is deeply equal. Does not compare metadata or fields
   *          related to redlines.
   */
  _isStepDefinitionEqual: (step1: Step, step2: Step): boolean => {
    const stepDefinition1 = procedureUtil._getStepDefinition(step1);
    const stepDefinition2 = procedureUtil._getStepDefinition(step2);
    return _.isEqualWith(
      stepDefinition1,
      stepDefinition2,
      (_first, _second, index) => {
        if (index === 'id') {
          return true;
        }
      }
    );
  },

  /**
   * Returns ids of steps that either
   * 1. were added to the updated version of the procedure, or
   * 2. were changed from the original version of the procedure.
   *
   * Only returns ids of steps in the updated procedure, therefore does not return
   * ids of steps that were deleted.
   *
   * Also does not return ids of steps that were moved or reordered.
   *
   * @param original - Original version of the procedure object.
   * @param updated  - Updated version of the procedure object.
   *
   * @returns Array of step ids of steps that have changed between the original
   *          version of the procedure and the updated version.
   */
  getIdsStepsChanged: (
    original: Procedure,
    updated: Procedure
  ): Array<string> => {
    let originalCopy = cloneDeep(original);
    let updatedCopy = cloneDeep(updated);

    // Migrate procedures before comparing if they have changed.
    originalCopy =
      procedureUtil.migrateRequiresPreviousStepToDependencies(originalCopy);
    updatedCopy =
      procedureUtil.migrateRequiresPreviousStepToDependencies(updatedCopy);

    // Map of step id -> step for original procedure.
    const originalProcedureStepMap =
      procedureUtil.getStepIdToStepMap(originalCopy);
    const idsStepsChanged: Array<string> = [];
    for (const section of updatedCopy.sections) {
      for (const updatedStep of section.steps) {
        const originalStep = originalProcedureStepMap[updatedStep.id];
        if (
          !originalStep ||
          !procedureUtil._isStepDefinitionEqual(originalStep, updatedStep)
        ) {
          idsStepsChanged.push(updatedStep.id);
        }
      }
    }
    return idsStepsChanged;
  },

  /**
   * Get all archived procedures
   *
   * @returns An array of archived procedures
   */
  getArchivedProcedures: (procedures: Array<Procedure>): Array<Procedure> => {
    return procedures.filter((procedure) => procedure?.archived);
  },

  stepHeaderHasValues: (step: Step): boolean => {
    return Boolean(
      step.headers &&
        step.headers.length > 0 &&
        (step.headers[0].name || step.headers[0].content.length > 0)
    );
  },

  getStepHeaderId: (step: Step): null | string => {
    // @ts-ignore
    return procedureUtil.stepHeaderHasValues(step) ? step.headers[0].id : null;
  },

  migrateRequiresPreviousStepToDependencies: (
    procedure: Procedure
  ): Procedure => {
    procedure.sections.forEach((section, sectionIndex) => {
      section.steps.forEach((step, stepIndex) => {
        // For legacy procedures that dont have dependencies array.
        if (!step.dependencies) {
          const dependencyObject = procedureUtil.newDependency();

          if (step.requires_previous === true && stepIndex !== 0) {
            const previousStep = section.steps[stepIndex - 1];

            if (previousStep && previousStep.id) {
              dependencyObject.dependent_ids = [previousStep.id];
            }
          }

          step.dependencies = [dependencyObject];
        }

        // remove legacy property for requires previous.
        delete step.requires_previous;
      });
    });

    return procedure;
  },

  migrateContent: (procedure: Procedure): Procedure => {
    procedure.sections.forEach((section) => {
      section.steps.forEach((step) => {
        step.content.forEach((block, contentIndex) => {
          if (block.type === 'table_input') {
            const migratedContent =
              tableInputUtil.cloneAndMigrateContent(block);
            step.content[contentIndex] = migratedContent;
          }
        });
      });
    });

    return procedure;
  },

  // Returns true if field input has rule, e.g. "< 10."
  fieldInputHasRule: (rule: Rule | undefined): boolean => {
    if (!rule) {
      return false;
    }
    if (!isEmptyValue(rule.op) || !isEmptyValue(rule.value)) {
      return true;
    }
    const range = rule.range;
    if (range && (!isEmptyValue(range.min) || !isEmptyValue(range.max))) {
      return true;
    }
    return false;
  },

  /**
   * Updates procedure _id, procedure_id, and state fields to reflect that it is a draft.
   *
   * Deletes redlines and redline_comments to clean up redlined added steps.
   */
  updateDocAsDraft: (procedure: Procedure): void => {
    const procedureId = procedureUtil.getProcedureId(procedure);
    procedure._id = getPendingProcedureIndex(procedureId);
    procedure.procedure_id = procedureId;
    procedure.state = PROCEDURE_STATE_DRAFT;
    // Set default settings to true if they are undefined.
    const procedureSettings = [
      'skip_step_enabled',
      'repeat_step_enabled',
      'step_suggest_edits_enabled',
    ];
    procedureSettings.forEach((setting) => {
      procedure[setting] = procedure[setting] ?? true;
    });
    procedure.sections.forEach((section) =>
      section.steps.forEach((step) => {
        if ('redlines' in step) {
          delete step.redlines;
        }
        if ('redline_comments' in step) {
          delete step.redline_comments;
        }

        setDisabledStepSettings(step);

        step.content.forEach((block) => {
          if (block.type === 'table_input' && !block.row_metadata) {
            block.row_metadata = Array.from({ length: block.rows }, () => ({
              id: procedureUtil.generateTableInputRowId(),
            }));
          }
        });
      })
    );
  },

  // Copies procedure with given id. Name of copied procedure appended with " (Copy)"
  copyProcedureAsDraft: (
    procedure: Release | Draft,
    generatePlaceholderId: (projectId?: string) => string
  ): Draft => {
    const draft = cloneDeep(procedure) as Draft;

    // Update procedure document.
    procedureUtil.updateProcedureIds(draft);
    const procedureId = procedureUtil.generateProcedureId();
    draft._id = procedureId;
    draft.name = `${procedure.name} (Copy)`;
    // reset reviewer groups for new draft.
    draft.reviewer_groups = [];
    delete draft.review_type;

    delete draft._rev;
    delete draft.procedure_id;
    delete draft.procedure_rev_num;
    delete draft.initial_rev_num;
    delete draft.locked_by;
    delete draft.locked_at;
    const placeholder = generatePlaceholderId(draft.project_id);
    draft.code = placeholder ? placeholder : draft.code;

    procedureUtil.updateDocAsDraft(draft);

    return draft;
  },

  getContentBlock: (
    contentBlockId: string,
    step: Step
  ): {
    matchingContentBlock?: StepBlock;
    matchingContentBlockIndex?: number;
  } => {
    const matchingContentBlock = (step.content as Array<StepBlock>).find(
      (contentBlock) => contentBlock.id === contentBlockId
    );
    const matchingContentBlockIndex = step.content.findIndex(
      (contentBlock) => contentBlock.id === contentBlockId
    );
    return {
      matchingContentBlock,
      matchingContentBlockIndex,
    };
  },

  getProcedureLinks: (
    id: string,
    procedures: Array<Procedure>
  ): Array<ProcedureLink> => {
    const links: Array<ProcedureLink> = [];
    procedures.forEach((procedure) => {
      procedure.sections.forEach((section: Section) => {
        section.steps.forEach((step: Step) => {
          step.content.forEach((block: StepBlock) => {
            if (block.type !== CONTENT_TYPE_PROCEDURE_LINK) {
              return;
            }
            const procedureLink = block;
            if (!id || procedureLink.procedure !== id) {
              return;
            }
            links.push({
              procedure: procedure._id,
              code: procedure.code,
              name: procedure.name,
              content: procedureLink,
            });
          });
        });
      });
    });
    return links;
  },

  // Removes any step dependency references to the given list of steps.
  _removeDependencyStepReferences: (
    step: Step,
    removedStepIds: Set<string>
  ): void => {
    if (!step.dependencies || step.dependencies.length === 0) {
      return;
    }
    for (const dependency of step.dependencies) {
      dependency.dependent_ids = dependency.dependent_ids.filter(
        (id) => !removedStepIds.has(id)
      );
    }
    // Remove any step dependencies that are now empty.
    step.dependencies = step.dependencies.filter(
      (dependencies) => dependencies.dependent_ids.length > 0
    );
  },

  // Removes any step conditional references to the given list of steps.
  _removeConditionalStepReferences: (
    step: Step,
    removedStepIds: Set<string>
  ): void => {
    if (!step.conditionals) {
      return;
    }
    for (const conditional of step.conditionals) {
      if (removedStepIds.has(conditional.target_id)) {
        conditional.target_id = '';
      }
    }
  },

  /**
   * Removes any references in a procedure to the given steps.
   *
   * This is useful when removing sections or steps and the resulting procedure
   * object needs to be updated to maintain validity. This method is also
   * synchronous to allow for better control of state management.
   *
   * Currently removes any step refernces in dependencies and conditionals.
   * TODO (June 2022): Add jump to links?
   */
  removeStepReferences: (procedure: Procedure, stepIds: string[]): void => {
    const lookup = new Set(stepIds);
    for (const section of procedure.sections) {
      for (const step of section.steps) {
        // Remove step dependency references.
        procedureUtil._removeDependencyStepReferences(step, lookup);

        // Remove step conditional references.
        procedureUtil._removeConditionalStepReferences(step, lookup);
      }
    }
  },

  removeContentReferences: (step: Step, contentIds: [string]): void => {
    if (!step.conditionals) {
      return;
    }
    step.conditionals = step.conditionals.filter(
      (conditional) =>
        !conditional.content_id || !contentIds.includes(conditional.content_id)
    );
  },

  getDisplayConditionalSupportedFieldInputs: (
    content: Array<FieldInputBlock>
  ): Array<FieldInputBlock> => {
    return content.filter(
      (block) =>
        block.type === 'input' &&
        CONDITIONAL_SUPPORTED_INPUT_TYPES.includes(block.inputType) &&
        block.name
    );
  },

  getDisplayConditionalSupportedBinaryContent: (
    content: Array<FieldInputBlock | TelemetryBlock>
  ): Array<FieldInputBlock | TelemetryBlock> => {
    return content.filter((block) => {
      if (
        block.type === 'input' &&
        block.inputType === 'number' &&
        !isEmptyValue(block.rule?.op) &&
        block.rule?.op !== 'range'
      ) {
        return true;
      }

      if (
        block.type === 'telemetry' &&
        !isEmptyValue(block.rule) &&
        block.rule !== 'range'
      ) {
        return true;
      }
      return false;
    });
  },

  getDisplayConditionalSupportedTernaryContent: (
    content: Array<FieldInputBlock | TelemetryBlock>
  ): Array<FieldInputBlock | TelemetryBlock> => {
    return content.filter((block) => {
      if (
        block.type === 'input' &&
        block.inputType === 'number' &&
        block.rule?.op === 'range'
      ) {
        return true;
      }

      if (block.type === 'telemetry' && block.rule === 'range') {
        return true;
      }
      return false;
    });
  },

  areDraftProceduresEqual(
    firstProcedureDraft: Draft,
    secondProcedureDraft: Draft
  ): boolean {
    const fieldsToOmit = [
      '_rev',
      'locked_at',
      'locked_by',
      'state',
      'editedAt',
      'editedUserId',
      'reviewer_groups',
    ];
    return isEqual(
      omit(firstProcedureDraft, fieldsToOmit),
      omit(secondProcedureDraft, fieldsToOmit)
    );
  },

  getSearchableContent: (procedure: Procedure): string => {
    let text = '';
    if (!procedure) {
      return text;
    }

    if (procedure.description) {
      text += `${procedure.description} `;
    }
    procedure.sections.forEach((section) => {
      text += `${section.name} `;
      section.steps.forEach((step) => {
        text += `${step.name} `;
      });
    });
    return text;
  },

  numNonAddedStepsInSection: (section: DraftSection): number => {
    const nonAddedSteps = section.steps.filter(
      (step) => !('created_during_run' in step)
    );
    return nonAddedSteps.length;
  },

  /**
   * Filters procedures and returns only released, unarchived procedures.
   */
  getActiveReleases: (
    proceduresMetadata: Array<ProcedureMetadata>
  ): Array<ProcedureMetadata> => {
    return proceduresMetadata.filter(
      (metadata) =>
        metadata.state === PROCEDURE_STATE_RELEASED &&
        metadata.archived !== true
    );
  },

  getContainerSummary: (
    containerId: string,
    allContainers: Array<Step | Section> | null
  ): Summary | null => {
    if (!allContainers) {
      return null;
    }
    const matchingContainerIndex = allContainers?.findIndex(
      (container) => container.id === containerId
    );
    if (matchingContainerIndex === -1) {
      return null;
    }

    const matchingContainer = allContainers[matchingContainerIndex];

    return {
      name: matchingContainer.name,
      id: matchingContainer.id,
      index: matchingContainerIndex,
    };
  },

  getValidMentions: (
    originalMentions: Array<Mention>,
    comment: string
  ): Array<Mention> => {
    // Check if any mentioned users were later removed from comment
    return uniq(
      originalMentions.filter((mention) =>
        comment.includes(`@${mention.username}`)
      )
    );
  },

  trimLeadingAndTrailing: (str: string): string =>
    str.replace(/(^\s+|\s+$)/g, ''),

  /**
   * Get the key that represents the type of field (e.g. "name").
   * For example, if fieldObject is { name: 'My step name', editType: 'Redline' },
   * then this function will allow you to extract the field type without relying on indices or order of any kind.
   * It assumes only one valid key exists in fieldObject.
   * @param fieldObject
   */
  getFieldKey: (fieldObject: { [key in ProcedureFieldKey | string]: unknown }):
    | ProcedureFieldKey
    | undefined => {
    return Object.keys(fieldObject).find((key) =>
      PROCEDURE_FIELD_KEYS.includes(key as ProcedureFieldKey)
    ) as ProcedureFieldKey | undefined;
  },

  getNewTestPlan: ({
    userId,
    defaultList,
    testPoints = [],
  }: {
    userId: string;
    defaultList?: ConfigList;
    testPoints: Array<TestCase>;
  }): Procedure => {
    const newTestPlan = procedureUtil.newProcedure(userId);
    newTestPlan.name = 'Untitled test plan';
    newTestPlan.procedure_type = ProcedureType.TestPlan;
    newTestPlan.sections[0].steps[0].content = [];

    if (testPoints.length > 0) {
      const newBlock = getInitialTestPointTable({
        testPoints,
        listName: defaultList?.name,
        listId: defaultList?.id,
      });

      newTestPlan.sections[0].steps[0].content.push(newBlock);
    }

    return newTestPlan;
  },
};

export default procedureUtil;
