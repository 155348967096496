import superlogin, { Session, SuperLoginClient } from 'superlogin-client';
import { API_URL, COUCHDB_URL, NEW_LOGIN_ACTIVE } from '../config';
import axios, { AxiosResponse } from 'axios';
import { E3Session } from 'shared/lib/types/api/util';
import apm from '../lib/apm';
import e3Login from './e3Login';

/*
 * Augment SuperLoginClient interface with types it does not have
 * Documentation: https://www.typescriptlang.org/docs/handbook/declaration-merging.html#module-augmentation
 */

const loginInstance = NEW_LOGIN_ACTIVE ? new e3Login() : superlogin;

declare module 'superlogin-client' {
  interface SuperLoginClient {
    _onLogin: (session: Session) => void;
    _onRegister: (body: {
      token: string;
      password: string;
      confirmPassword: string;
    }) => void;
  }
}

// See https://github.com/micky2be/superlogin-client for defaults
const config = {
  serverUrl: API_URL,
  baseUrl: `${API_URL}/auth`,
  storage: 'local',
};

loginInstance.configure(config);

/**
 * TODO: Consider returning an empty list or throwing an error if (!session || !session.userOrgData), and refactoring dependent code to reflect this change
 * @param session - session object containing userOrgData
 * @returns an array of sorted teamIds by uuid (see couchDB sequential uuid), that the user has access to.
 *    If user has no teams assigned, an empty array will be returned.
 *    If session is null or does not have userOrgData property, null will be returned.
 */
export const getAllTeamIdsFromSession = (
  session: E3Session | null
): string[] | null => {
  if (!session || !session.userOrgData) {
    return null;
  }

  return session.userOrgData
    .map((org) => org.teamIds)
    .flat()
    .sort((a, b) => a.localeCompare(b));
};

export const getSession = (): E3Session | null => {
  return loginInstance.getSession() as unknown as E3Session;
};

export const setSession = (session: E3Session): void => {
  return loginInstance.setSession(session as unknown as Session);
};

/**
 * @param session - session object returned by superlogin.getSession
 * @returns defaultTeamId if it exists in the session profile, or the first teamId that
 *  applies to the user. If no teams apply to the user or session is null, it will return null.
 */
export const getDefaultTeamIdFromSession = (
  session: E3Session | null
): string | null => {
  if (!session) {
    return null;
  }

  const allTeamIds = getAllTeamIdsFromSession(session);
  const defaultTeamId = session.profile && session.profile.default_team_id;

  // If there is a defaultTeamId, return that else return the first teamId that applies to user.
  if (defaultTeamId && allTeamIds?.includes(defaultTeamId)) {
    return defaultTeamId;
  }

  // Return null if there are no teamIds in the user session.
  if (!allTeamIds?.length) {
    return null;
  }

  return allTeamIds[0];
};

/**
 * Request a session cookie from couchdb.
 *
 * Because opening file attachments are a simple GET request in a new browser
 * tab (or even when just inlinging with AJAX request), attaching an
 * 'Authorization' header to <a href> links is hard (impossible?). As a
 * workaround, we just get an auth cookie from couchdb and then all GET requests
 * for file attachments will simply work.
 *
 * @param {E3Session} session - The user's current valid superlogin session object.
 * @returns {Promise<string | AxiosResponse>} Promise that resolves with the network results of calling `_session`.
 */
export const refreshSessionCookie = (
  session: E3Session
): Promise<string | AxiosResponse> => {
  if (!session || !session.token || !session.password) {
    return Promise.reject('Auth session expired or no session');
  }
  /**
   * Previously this used superlogin.getHttp() instead of axios, which
   * eventually causes an infinite loop. superlogin.getHttp() attempts to
   * refresh the current session halfway through the session lifespan
   * (configurable), but does not "restart the clock" after a refresh. Since
   * we refresh the cookie after a session refresh, and since every refresh is
   * after the halfway mark, every checkRefresh results in a refresh, which
   * enters an infinite session refresh loop.
   *
   * This fix simply bypasses superlogin.getHttp() and the refresh mechanism,
   * since we can make a plain request directly to couchdb instead.
   *
   * TODO (aaron): Figure out how to teach superlogin to "reset the clock", as
   * even with this fix every `checkRefresh` refreshes the session after the
   * halfway mark. Look at checkRefresh [0] and the comment [1] in the code.
   * [0] https://github.com/micky2be/superlogin-client/blob/master/src/index.js
   * [1] "try getting the latest refresh date, if not available fall back to issued date"
   */
  return axios.post(
    `${COUCHDB_URL}/_session`,
    {
      name: session.token,
      password: session.password,
    },
    { withCredentials: true }
  );
};

/**
 * Gets user session, renewing if necessary. If a 401 is encountered while the user
 * is authenticated (has a current session object), logout is triggered.
 *
 * Returns: Promise, user session or null if unavailable or renewal failed.
 */
export const checkRefreshSession = async (): Promise<E3Session | undefined> => {
  if (!NEW_LOGIN_ACTIVE) {
    try {
      (loginInstance as SuperLoginClient).checkRefresh();
      // Refresh was successful or not needed, get current user session
      const session = getSession();
      return session || undefined;
    } catch (error) {
      // Halt request and require login if this is an unauthorized error while logged in.
      if (
        error &&
        'response' in error &&
        error.response?.status === 401 &&
        loginInstance.authenticated()
      ) {
        await (loginInstance as SuperLoginClient)
          .logout()
          .catch((err) => apm.captureError(err));
        return undefined;
      }
      // Return original error
      throw error;
    }
  }
  return undefined;
};

// Refresh cookie whenever session changes
const sessionCookieHandler = () => {
  // Get current user session and use it to refresh cookie.
  const session = getSession();
  if (!session) {
    return Promise.reject('No session found');
  }
  refreshSessionCookie(session).catch((err) => apm.captureError(err));
};

/**
 * Update session cookie whenever session changes.
 *
 * These event handlers are run asynchronously, meaning superlogin-client does not
 * wait for their results. This is OK for now since session refresh is set to
 * happen halfway through a session's lifespan and the current session will still
 * be valid while refresh is happening.
 */
if (!NEW_LOGIN_ACTIVE) {
  loginInstance.on('login', sessionCookieHandler);
  loginInstance.on('link', sessionCookieHandler);
  loginInstance.on('register', sessionCookieHandler);
  loginInstance.on('refresh', sessionCookieHandler);
}

export default loginInstance;
