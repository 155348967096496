import { IconProp } from '@fortawesome/fontawesome-svg-core';
import NavItem from './NavItem';
import NavItemWithMenu from './NavItemWithMenu';
import NavItemProfile from './NavItemProfile';

export interface NavItemProps<T extends typeof NavItem | typeof NavItemWithMenu | typeof NavItemProfile> {
  label: string;
  component: T;
  icon: IconProp;
  currentScreen: string;
  hideLabels: boolean;
  to?: string;
  settingsPath?: string;
  onClick?: () => void;
  isNavigationExpanded?: boolean;
}

const BaseNavItem = ({ component: Component, ...rest }: NavItemProps<typeof Component>) => {
  return <Component {...rest} />;
};

export default BaseNavItem;
