import { buffers } from 'redux-saga';
import { actionChannel, call, fork, put, select, take, takeEvery } from 'redux-saga/effects';
import { SYNC_ATTACHMENTS_BATCH_SIZE, SYNC_BATCH_SIZE } from '../config';
import attachmentUtil from '../lib/attachmentUtil';
import { isReleased } from 'shared/lib/procedureUtil';
import couchdbUtil from '../lib/couchdbUtil';
import superlogin from '../api/superlogin';
import AttachmentService from '../attachments/service';
import {
  selectProceduresSynced,
  SYNC_ALL_PROCEDURE_DATA,
  SYNC_PROCEDURE_DATA,
  SYNC_PROCEDURE_DATA_FAILED,
  SYNC_PROCEDURE_DATA_SUCCEEDED,
} from '../contexts/proceduresSlice';
import RevisionsService from '../api/revisions';
import ProcedureService from '../api/procedures';
import apm from '../lib/apm';
import { partition } from 'lodash';

const downloadAttachments = function* (teamId, procedure) {
  try {
    const service = AttachmentService.getInstance(teamId);
    return yield call(attachmentUtil.downloadProcedureAttachments, procedure, service);
  } catch (error) {
    apm.captureError(
      new Error(
        `Error in downloadAttachments when attempting to sync attachments for procedure [${procedure?._id}]: ${error?.message}`
      )
    );
    // Rethrow error.
    throw error;
  }
};

const downloadAllAttachments = function* (teamId, procedures) {
  const service = AttachmentService.getInstance(teamId);
  const procedureErrors = {};
  const attachmentIdMap = {};
  const attachments = [];
  const attachmentIds = new Set();

  // Gather attachment objects for downloading, and add some bookkeeping.
  for (const procedure of procedures) {
    /**
     * In case any errors happen here, mark this procedure as an error but
     * keep trying the rest of the procedures.
     */
    try {
      const procedureAttachments = attachmentUtil.getAttachments(procedure);
      for (const attachment of procedureAttachments) {
        if (!attachmentIds.has(attachment.attachment_id)) {
          attachments.push(attachment);
          attachmentIds.add(attachment.attachment_id);
        }
        attachmentIdMap[attachment.attachment_id] = procedure._id;
      }
    } catch (error) {
      apm.captureError(error);
      procedureErrors[procedure._id] = {
        id: procedure._id,
        message: error.message,
      };
    }
  }

  // Download attachments in batches, tracking which procedures had a failed download.
  for (let i = 0; i < attachments.length; i += SYNC_ATTACHMENTS_BATCH_SIZE) {
    // Slice does not modify original array
    const batch = attachments.slice(i, i + SYNC_ATTACHMENTS_BATCH_SIZE);
    const { errors } = yield call([service, 'downloadAllAttachments'], batch);
    for (const error of errors) {
      const procedureId = attachmentIdMap[error.id];
      apm.captureError(error);
      procedureErrors[procedureId] = {
        id: procedureId,
        message: error.message,
      };
    }
  }

  // Return results.
  return {
    procedures: procedures.filter((procedure) => !procedureErrors[procedure._id]),
    errors: Object.values(procedureErrors),
  };
};

const syncProcedureData = function* (action) {
  const { procedure, teamId } = action.payload;
  /**
   * Skip data sync for procedures that are already synced.
   *
   * TODO: Using 'select' in a redux-saga effect is discouraged since sagas
   * should ideally only depend on their internal state. In the future, we could
   * perform this check before starting this saga.
   */
  const synced = yield select(selectProceduresSynced, teamId);
  const unsynced =
    !synced[procedure._id] ||
    synced[procedure._id].synced !== true ||
    couchdbUtil.isEarlierRev(synced[procedure._id].rev, procedure._rev);
  if (!unsynced) {
    return;
  }

  // Document rev is initial or newer initial or newer procedure doc.
  try {
    const attachmentResults = yield call(downloadAttachments, teamId, procedure);
    if (attachmentResults.errors.length > 0) {
      yield put({
        type: SYNC_PROCEDURE_DATA_FAILED,
        payload: {
          teamId,
          errors: attachmentResults.errors.map((error) => ({
            procedureId: procedure._id,
            attachmentId: error.id,
            message: error.message,
          })),
        },
      });
    } else {
      yield put({
        type: SYNC_PROCEDURE_DATA_SUCCEEDED,
        payload: {
          procedures: [procedure],
          teamId,
        },
      });
    }
  } catch (error) {
    yield put({
      type: SYNC_PROCEDURE_DATA_FAILED,
      payload: {
        teamId,
        errors: [
          {
            procedureId: procedure._id,
            message: error.message,
          },
        ],
      },
    });
  }
};

const syncBatchProcedureData = function* (teamId, proceduresMetadata) {
  const revisionsService = new RevisionsService(teamId, superlogin);
  const procedureService = ProcedureService.getInstance(teamId, revisionsService, superlogin);
  let errors = [];

  // Fetch batch of procedure documents.
  const docs = proceduresMetadata.map((metadata) => ({
    id: metadata._id,
    rev: metadata._rev,
  }));
  try {
    const { procedures, errors: procedureErrors } = yield call([procedureService, 'bulkGetProcedures'], docs);
    errors = errors.concat(procedureErrors);

    // Do not batch sync attachments for pending procedures.
    const [released, pending] = partition(procedures, isReleased);

    // Download attachments for released procedures only.
    const { procedures: syncedReleases, errors: attachmentErrors } = yield call(
      downloadAllAttachments,
      teamId,
      released
    );

    errors = errors.concat(attachmentErrors);

    const synced = syncedReleases.concat(pending);

    // Update redux store with batch results.
    if (synced.length) {
      yield put({
        type: SYNC_PROCEDURE_DATA_SUCCEEDED,
        payload: {
          procedures: synced,
          teamId,
        },
      });
    }
    if (errors.length) {
      yield put({
        type: SYNC_PROCEDURE_DATA_FAILED,
        payload: {
          errors: errors.map((error) => ({
            procedureId: error.id,
            message: error.message,
          })),
          teamId,
        },
      });
    }
  } catch {
    yield put({
      type: SYNC_PROCEDURE_DATA_FAILED,
      payload: {
        /* payload is currently ignored for failed action */
      },
    });
  }
};

const syncAllProcedureData = function* (action) {
  const { proceduresMetadata, teamId, procedureIds } = action.payload;
  const offlineIds = new Set(procedureIds ?? []);
  // Find the procedures that are not found, or that have older revisions.
  const synced = yield select(selectProceduresSynced, teamId);
  const unsynced = proceduresMetadata.filter((metadata) => {
    if (procedureIds && !offlineIds.has(metadata._id)) {
      return false;
    }
    if (!synced[metadata._id]) {
      return true;
    }
    return couchdbUtil.isEarlierRev(synced[metadata._id].rev, metadata._rev);
  });

  // Sync procedures in batches.
  for (let i = 0; i < unsynced.length; i += SYNC_BATCH_SIZE) {
    // Slice does not modify original array
    const batch = unsynced.slice(i, i + SYNC_BATCH_SIZE);
    yield call(syncBatchProcedureData, teamId, batch);
  }
};

const procedureDataSaga = function* () {
  yield takeEvery(SYNC_PROCEDURE_DATA, syncProcedureData);
};

const allProcedureDataSaga = function* () {
  /**
   * Use a channel so the current saga doesn't get cancelled, and queue the
   * latest sync request for subsequent execution.
   *
   * On busy workspaces with a lot of procedures and a lot of editing happening,
   * this saga could take a long time, and also get kicked off many times. We
   * don't want to get stuck never fully syncing because every sync is getting
   * cancelled (eg, autosave kicks off a sync). However, we also don't want to
   * run each and every saga request, for performance and efficiency reasons.
   *
   * Here, we solve this by letting the current sync run to completion, and by
   * queueing up the latest sync request for subsequent execution. We only need to
   * remember the last request since that's the latest version of the truth.
   *
   * See https://redux-saga.js.org/docs/advanced/Channels/
   */
  const channel = yield actionChannel(SYNC_ALL_PROCEDURE_DATA, buffers.sliding(1));
  while (true) {
    const action = yield take(channel);
    yield call(syncAllProcedureData, action);
  }
};

const rootSaga = function* () {
  yield fork(allProcedureDataSaga);
  yield fork(procedureDataSaga);
  // code after fork-effect
};

// Exported for testing.
export { downloadAttachments, downloadAllAttachments, syncAllProcedureData, syncBatchProcedureData, syncProcedureData };
export default rootSaga;
