import { useCallback, useMemo } from 'react';
import {
  ServerTag,
  Tag,
  TagStatus,
} from 'shared/lib/types/api/settings/tags/models';
import { RealtimeData } from 'shared/lib/types/realtimeUpdatesTypes';
import { DatabaseServices } from '../contexts/proceduresSlice';
import useRealtimeUpdates, {
  useRealtimeUpdatesProps,
} from './useRealtimeUpdates';
import { useDatabaseServices } from '../contexts/DatabaseContext';
import realtime from '../lib/realtime';

export const useTags = ({
  includeInactive,
}: {
  includeInactive: boolean;
}): { tags: Tag[] } => {
  const { services }: { services: DatabaseServices } = useDatabaseServices();

  const getInitialTags = useCallback(async () => {
    if (!services) {
      return;
    }
    return services.tags.getTags();
  }, [services]);

  const { realtimeData: serverTags } = useRealtimeUpdates<
    RealtimeData & ServerTag
  >({
    entityType: 'global_tags',
    initialGetter: getInitialTags,
    mergeFunction: realtime.filterOriginalListAndMerge,
  } as useRealtimeUpdatesProps);

  const tags: Tag[] = useMemo(() => {
    return (serverTags as Array<RealtimeData & ServerTag>)
      .filter((tag) => includeInactive || tag.status === TagStatus.ACTIVE)
      .map(
        ({
          created_by,
          updated_by,
          created_at,
          updated_at,
          team_id,
          deleted_at,
          lower_name,
          ...tag
        }) => ({
          ...tag,
          createdBy: created_by,
          updatedBy: updated_by,
          createdAt: created_at,
          updatedAt: updated_at,
        })
      );
  }, [serverTags, includeInactive]);

  return { tags };
};
