import DiffMatchPatch from 'diff-match-patch';
import cloneDeep from 'lodash.clonedeep';
import { isRedlineSupported } from '../components/Blocks/BlockTypes';
import procedureUtil from './procedureUtil';
import getAllIds from './idSearchUtil';
import { difference, identity, omit, partition } from 'lodash';
import { copyStepWithoutActiveContent } from 'shared/lib/runUtil';
import {
  REDLINE_TYPE,
  convertToRedlineAddedStep,
  getRedlineId,
  newStepRedline,
  getRedlineFromDoc,
} from 'shared/lib/redlineUtil';
import { getAllSectionIds, getAllStepIds } from 'shared/lib/procedureUtil';
import { getAddedBlockRedlineMap, getRedlineDocsSortedLatestToEarliest, getStepRedlineMap } from './redlineUtil';

const DMPDiffType = Object.freeze({
  insertion: 1,
  deletion: -1,
  equality: 0,
});

const revisions = {
  isBlockTextEqual: (block1, block2) => block1.text === block2.text,

  isBlockAlertEqual: (block1, block2) => {
    if (block1.text !== block2.text) {
      return false;
    }
    if (block1.subtype !== block2.subtype) {
      return false;
    }
    return true;
  },

  isBlockFieldInputEqual: (block1, block2) => {
    if (block1.name !== block2.name) {
      return false;
    }
    if (block1.inputType !== block2.inputType) {
      return false;
    }
    // "units" are ignored when input type is "checkbox"
    if (block1.inputType !== 'checkbox') {
      if (block1.units !== block2.units) {
        return false;
      }
    }
    if (block1.inputType === 'number' && block1.rule && block2.rule) {
      if (block1.rule.op !== block2.rule.op) {
        return false;
      }
      if (block1.rule.op !== 'range' && block1.rule.value !== block2.rule.value) {
        return false;
      }
      if (
        block1.rule.op === 'range' &&
        (block1.rule.range.min !== block2.rule.range.min || block1.rule.range.max !== block2.rule.range.max)
      ) {
        return false;
      }
      if (
        block1.rule.op === 'range' &&
        (Boolean(block1.rule.range.include_min) !== Boolean(block2.rule.range.include_min) ||
          Boolean(block1.rule.range.include_max) !== Boolean(block2.rule.range.include_max))
      ) {
        return false;
      }
    }
    return true;
  },

  isBlockEqual: (block1, block2) => {
    if (!block1 || !block2 || !block1.type || !block2.type) {
      return false;
    }
    if (block1.type !== block2.type) {
      return false;
    }
    switch (block1.type) {
      case 'text':
        return revisions.isBlockTextEqual(block1, block2);
      case 'alert':
        return revisions.isBlockAlertEqual(block1, block2);
      case 'input':
        return revisions.isBlockFieldInputEqual(block1, block2);
      default:
        return false;
    }
  },

  /*
   * Gets diffs for text content
   *
   * text: String, original text
   * revised: String, revised text
   * returns: Array of diffs in the format [(-1, "Goo"), (1, "Ba"), (0, "d dog")]
   *          (See diff-match-patch API.) Results cleaned up for human display.
   */
  getTextDiffs: (text, revised) => {
    if (typeof text !== 'string' || typeof revised !== 'string') {
      return null;
    }
    const dmp = new DiffMatchPatch();
    const diffs = dmp.diff_main(text, revised);
    dmp.diff_cleanupSemantic(diffs);
    return diffs;
  },

  /*
   * Gets the change history for a given step content block and all step redlines.
   * Filters out any step revisions where the content block did not change.
   *
   * block: Original content block
   * contentIndex: Index of content block.
   * redlines: Array of RedlineStep objects containing all step revisions.
   * returns: Array of RedlineBlock objects where a change has occurred.
   */
  getBlockChanges: (block, contentIndex, redlines) => {
    const changes = [];
    if (!block || contentIndex === -1 || !isRedlineSupported(block.type)) {
      return changes;
    }
    if (!redlines) {
      return changes;
    }
    let previous = { block };
    redlines.forEach((redline, redlineIndex) => {
      const stepOrHeader = revisions._getStepOrHeader(redline); // TODO EPS-2438
      const current = block.original_id
        ? stepOrHeader.content.find((redlineBlock) => redlineBlock.original_id === block.original_id)
        : stepOrHeader.content[contentIndex];
      const createdAt = revisions.createdAt(redline);
      const userId = revisions.createdBy(redline);
      if (!revisions.isBlockEqual(previous.block, current)) {
        changes.push({
          redline_id: getRedlineId(redline),
          createdAt,
          userId,
          block: current,
          pending: Boolean(redline.pending),
          redlineIndex,
          run_only: redline.run_only,
          type: redline.type,
        });
        previous = changes[changes.length - 1];
      }
    });
    return changes;
  },

  // TODO EPS-2438 (update this to also get sectionHeader redlines)
  _getStepOrHeader: (redline) => {
    return redline.step || redline.header;
  },

  /**
   * Gets the change history for a given step field.
   * Filters out any step revisions where the field did not change.
   *
   * @param {Object} step - Step object containing the field.
   * @param {String} field - Name of the field.
   * @param {Array} redlines: Array of RedlineStep objects containing all step revisions.
   *
   * returns: Array of RedlineField objects where a change has occurred.
   */
  getStepFieldChanges: (step, field, redlines) => {
    const changes = [];
    if (!redlines || redlines.length === 0) {
      return changes;
    }
    let previous = step[field];
    redlines.forEach((redline, redlineIndex) => {
      const current = redline.step[field];
      if (previous !== current) {
        changes.push({
          redline_id: getRedlineId(redline),
          createdAt: redline.createdAt,
          userId: redline.userId,
          [field]: current,
          pending: Boolean(redline.pending),
          redlineIndex,
          run_only: redline.run_only,
          type: redline.type,
        });
        previous = changes[changes.length - 1][field];
      }
    });
    return changes;
  },

  /**
   * Gets the change history for a given header field.
   * Filters out any header revisions where the field did not change.
   *
   * @param {Object} header - Header object containing the field.
   * @param {String} field - Name of the field.
   * @param {Array<import('shared/lib/types/views/procedures').RunHeaderRedline> | undefined} redlines: Array of RunHeaderRedline objects containing all step revisions.
   *
   * returns: Array of RedlineField objects where a change has occurred.
   */
  getHeaderFieldChanges: (header, field, redlines) => {
    const changes = [];
    if (!redlines || redlines.length === 0) {
      return changes;
    }
    let previous = header[field];
    redlines.forEach((redline, redlineIndex) => {
      const current = redline.header[field];
      if (previous !== current) {
        changes.push({
          redline_id: getRedlineId(redline),
          created_at: redline.created_at,
          user_id: redline.user_id,
          [field]: current,
          pending: Boolean(redline.pending),
          redlineIndex,
          run_only: redline.run_only,
        });
        previous = changes[changes.length - 1][field];
      }
    });
    return changes;
  },

  /**
   * Gets the change history for a given header field.
   * Filters out any header revisions where the field did not change.
   *
   * @param {Object} sectionHeader - Section header object containing the field.
   * @param {String} field - Name of the field.
   * @param {Array} redlines: Array of RedlineHeader objects containing all step revisions.
   *
   * returns: Array of RedlineField objects where a change has occurred.
   */
  getSectionHeaderFieldChanges: (sectionHeader, field, redlines) => {
    const changes = [];
    if (!redlines || redlines.length === 0) {
      return changes;
    }
    let previous = sectionHeader[field];
    redlines.forEach((redline, redlineIndex) => {
      const current = redline.sectionHeader[field];
      if (previous !== current) {
        changes.push({
          created_at: redline.created_at,
          user_id: redline.user_id,
          [field]: current,
          pending: Boolean(redline.pending),
          redlineIndex,
        });
        previous = changes[changes.length - 1][field];
      }
    });
    return changes;
  },

  getLatestStepRevision: (step) => {
    if (step.redlines && step.redlines.length > 0) {
      return cloneDeep(step.redlines[step.redlines.length - 1].step);
    }
    return cloneDeep(step);
  },

  getLatestHeaderRevision: (header) => {
    if (header.redlines && header.redlines.length > 0) {
      return header.redlines[header.redlines.length - 1].header;
    }
    return header;
  },

  getLatestSectionHeaderRevision: (sectionHeader) => {
    if (sectionHeader.redlines && sectionHeader.redlines.length > 0) {
      return sectionHeader.redlines[sectionHeader.redlines.length - 1].sectionHeader;
    }
    return sectionHeader;
  },

  newStepRedlineForFieldChange: (stepRedline, userId, pending, changedStepFieldEntry, isRedline) => {
    stepRedline = {
      ...stepRedline,
      ...changedStepFieldEntry,
    };

    const changedStepField = Object.keys(changedStepFieldEntry)[0];
    return newStepRedline({
      step: stepRedline,
      userId,
      pending,
      fieldOrBlockMetadata: {
        field: changedStepField,
      },
      isRedline,
    });
  },

  getUnresolvedRedlines: (redlines, procedure) => {
    if (!redlines) {
      return [];
    }
    const resolvedRedlineIds = new Set();
    // Iterate through procedure for resolved redline actions
    procedure.redline_actions &&
      procedure.redline_actions?.forEach((action) => resolvedRedlineIds.add(action.redline_id));

    // Filter only for resolved redline comments, remove any review comments
    procedure.comments
      ?.filter((comment) => comment.type !== 'review_comment')
      .forEach((comment) => comment.resolved && resolvedRedlineIds.add(comment.redline_id));

    // Filter out resolved Redlines and return unresolved Redlines
    return redlines.filter((redline) => !resolvedRedlineIds.has(redline._id));
  },

  /*
   * Outstanding comments are comments that are both unresolved
   * and reference content in the procedure.
   *
   * An unresolved comment is not outstanding if the content
   * it references has been removed from the procedure.
   */
  getOutstandingReviewComments: (procedure) => {
    const unresolved = revisions.getUnresolvedParentReviewComments(procedure.comments || []);
    const orphaned = revisions.getOrphanedReviewComments(procedure);
    const isOrphaned = (comment) => orphaned.some(({ id }) => id === comment.id);
    return unresolved.filter((comment) => !isOrphaned(comment));
  },

  hasOutstandingReviewComments: (procedure) => {
    const outstandingComments = revisions.getOutstandingReviewComments(procedure);
    return outstandingComments.length > 0;
  },

  getReviewComments: (comments) => {
    return comments.filter(({ type }) => type === 'review_comment');
  },

  /*
   * Orphaned comments are comments that reference content that has
   * been removed from the procedure.
   */
  getOrphanedReviewComments: (procedure) => {
    const { comments, ...procedureWithoutComments } = procedure;
    const ids = getAllIds(procedureWithoutComments);
    const reviewComments = revisions.getReviewComments(comments || []);
    return reviewComments.filter(({ reference_id }) => !ids.has(reference_id));
  },

  getUnresolvedRedlineComments: (comments) => {
    if (!comments) {
      return [];
    }
    return comments.filter((comment) => comment.redline_id !== undefined && comment.resolved !== true);
  },

  getUnresolvedParentReviewComments: (comments) => {
    if (!comments) {
      return [];
    }
    return revisions
      .getReviewComments(comments)
      .filter((comment) => !comment.parent_id)
      .filter((comment) => !comment.resolved);
  },

  /**
   * Get all orphaned added steps, defined here as added steps whose preceding
   * step is no longer anywhere in the procedure.
   *
   * @param {import('shared/lib/types/views/procedures').Draft} procedure
   * @param {Map<string, Array<import('shared/lib/types/views/redlines').AddedStepRedline>>} precedingIdToRedlinesMap
   * @returns {{[sectionId: string]: Array<import('shared/lib/types/views/redlines').AddedStepRedline>}}
   */
  _getOrphanedStepsBySection: (procedure, precedingIdToRedlinesMap) => {
    const allStepIds = getAllStepIds(procedure);
    /*
     * Get all step ids that are in the keys of
     * precedingIdToRedlinesMap but not in allStepIds.
     */
    const missingPrecedingStepIds = difference(Array.from(precedingIdToRedlinesMap.keys()), allStepIds);
    return missingPrecedingStepIds.reduce((map, stepId) => {
      const redlines = precedingIdToRedlinesMap.get(stepId);
      if (!redlines) {
        return map;
      }
      redlines.forEach((redline) => {
        const sectionId = redline.section_id;
        if (!map[sectionId]) {
          map[sectionId] = [];
        }

        map[sectionId].push(redline);
      });

      return map;
    }, {});
  },

  /**
   * Merge added steps that have not already been merged and have not yet been resolved.
   *
   * @param {import('shared/lib/types/views/procedures').Draft} procedure
   * @param {Map<string, Array<import('shared/lib/types/views/redlines').AddedStepRedline>>} precedingIdToRedlinesMap - should not include redlines that were accepted or rejected
   * @return {import('shared/lib/types/views/procedures').Draft}
   */
  mergeAddedStepsToProcedure: (procedure, precedingIdToRedlinesMap) => {
    // Create a copy to mutate and use for return value.
    const merged = cloneDeep(procedure);

    // Clear steps in our copy to start clean.
    merged.sections.forEach((section) => {
      section.steps = [];
    });

    // To prevent merging the same unresolved added step multiple times, get a set of existing redline ids over the whole procedure.
    const existingAddedStepRedlineIdSet = revisions._getExistingAddedStepRedlineIdSet(procedure.sections);

    const orphanedAddedStepsBySection = revisions._getOrphanedStepsBySection(procedure, precedingIdToRedlinesMap);

    // Populate all steps, including those added during run (both existing and new added steps).
    procedure.sections.forEach((section, sectionIndex) => {
      section.steps.forEach((step) => {
        // Add original step (could be a regular step or an added step).
        const stepCopy = cloneDeep(step);
        merged.sections[sectionIndex].steps.push(stepCopy);

        // Merge steps that were added during a run, but have not already been merged.
        const addedStepRedlines = precedingIdToRedlinesMap.get(step.id) ?? [];

        const [redlinesInSameSection, redlinesMovedToNewSection] = partition(
          addedStepRedlines,
          (redline) => redline.section_id === section.id
        );

        const addedStepsMovedToNewSection = revisions._getMovedRedlineAddedSteps(
          redlinesMovedToNewSection,
          existingAddedStepRedlineIdSet
        );
        const addedStepsInSameSection = revisions._getAddedStepsFromRedlines(
          redlinesInSameSection,
          existingAddedStepRedlineIdSet
        );

        merged.sections[sectionIndex].steps.push(...addedStepsMovedToNewSection, ...addedStepsInSameSection);
      });

      /*
       * Append orphaned redlines whose preceding step is no longer present,
       * but were created in this same section.
       */
      const orphanedAddedStepRedlinesInSection = orphanedAddedStepsBySection[section.id];
      if (orphanedAddedStepRedlinesInSection) {
        const orphanedAddedSteps = revisions._getOrphanedRedlineAddedSteps(
          orphanedAddedStepRedlinesInSection,
          existingAddedStepRedlineIdSet
        );
        merged.sections[sectionIndex].steps.push(...orphanedAddedSteps);
      }
    });

    /*
     * If there are any redlines created in a section that is no longer present,
     * add all of those redlines to a new section at the end of the draft.
     */
    const allSectionIds = getAllSectionIds(procedure);
    /*
     * Get all section ids that are in the keys of
     * orphanedAddedStepsBySection but not in allSectionIds.
     */
    const missingSectionIds = difference(Object.keys(orphanedAddedStepsBySection), allSectionIds);
    if (missingSectionIds.length > 0) {
      const newSection = procedureUtil.newSection();
      const orphanedSteps = missingSectionIds.flatMap((sectionId) => {
        const orphanedRedlines = orphanedAddedStepsBySection[sectionId];
        if (orphanedRedlines && orphanedRedlines.length > 0) {
          return revisions._getOrphanedRedlineAddedSteps(orphanedRedlines, existingAddedStepRedlineIdSet);
        }
        return [];
      });
      if (orphanedSteps.length > 0) {
        newSection.steps.push(...orphanedSteps);
        merged.sections.push(newSection);
      }
    }

    return merged;
  },

  /**
   * Get a set of redline ids of all existing unresolved added steps.
   *
   * @param {Array<import('shared/lib/types/views/procedures').DraftSection>} sections
   * @return {Set<string>} Set of redline ids
   */
  _getExistingAddedStepRedlineIdSet: (sections) => {
    const existingAddedStepRedlineIds = [];
    sections.forEach((section) => {
      // Identify added steps that have already been merged.
      const existingAddedSteps = section.steps.filter((step) => step.created_during_run);
      // Use redline id instead of step id because step ids in the run version of the added step used to be different from the steps that were merged in.
      const existingAddedStepRedlineIdsForSection = existingAddedSteps
        .map((step) => getRedlineId(step))
        .filter((redlineId) => Boolean(redlineId));
      existingAddedStepRedlineIds.push(...existingAddedStepRedlineIdsForSection);
    });

    return new Set(existingAddedStepRedlineIds);
  },

  /**
   * Get all new added steps that have not already been merged.
   *
   * @param {Array<import('shared/lib/types/views/redlines').AddedStepRedline>} addedStepRedlines
   * @param {Set<string>} existingAddedStepRedlineIdSet
   * @param {(redline: import('shared/lib/types/views/redlines').AddedStepRedline) => import('shared/lib/types/views/procedures').DraftAddedStep} [getStepFromRedline]
   * @return {Array<import('shared/lib/types/views/procedures').RunAddedStep>}
   */
  _getAddedStepsFromRedlines: (
    addedStepRedlines,
    existingAddedStepRedlineIdSet,
    getStepFromRedline = getRedlineFromDoc
  ) => {
    const newRedlineAddedSteps = /** @type {Array<import('shared/lib/types/views/procedures').RunAddedStep>} */ (
      addedStepRedlines
        .filter((redline) => {
          return !existingAddedStepRedlineIdSet.has(redline._id);
        })
        .map((redline) => {
          const addedStep = getStepFromRedline(redline);
          addedStep.source_run_id = redline.run_id;
          return addedStep;
        })
        .map((step) => convertToRedlineAddedStep(step))
    );

    return /** @type {Array<import('shared/lib/types/views/procedures').RunAddedStep>} */ (
      revisions.sortRunRedlines(newRedlineAddedSteps)
    );
  },

  /**
   * Get all added steps that are being added to a section different
   * from the one in which they were created
   *
   * @param {Array<import('shared/lib/types/views/redlines').AddedStepRedline>} orphanedAddedStepRedlines
   * @param {Set<string>} existingAddedStepRedlineIdSet
   * @return {Array<import('shared/lib/types/views/procedures').RunAddedStep>}
   */
  _getMovedRedlineAddedSteps: (orphanedAddedStepRedlines, existingAddedStepRedlineIdSet) => {
    return revisions._getAddedStepsFromRedlines(orphanedAddedStepRedlines, existingAddedStepRedlineIdSet, (redline) => {
      const addedStep = /** @type {import('shared/lib/types/views/procedures').DraftAddedStep}*/ (
        getRedlineFromDoc(redline)
      );
      addedStep.moved = true;
      return addedStep;
    });
  },

  /**
   * Get all orphaned (missing preceding step id or section id) new added steps
   * that have not already been merged.
   *
   * @param {Array<import('shared/lib/types/views/redlines').AddedStepRedline>} orphanedAddedStepRedlines
   * @param {Set<string>} existingAddedStepRedlineIdSet
   * @return {Array<import('shared/lib/types/views/procedures').RunAddedStep>}
   */
  _getOrphanedRedlineAddedSteps: (orphanedAddedStepRedlines, existingAddedStepRedlineIdSet) => {
    return revisions._getAddedStepsFromRedlines(orphanedAddedStepRedlines, existingAddedStepRedlineIdSet, (redline) => {
      const addedStep = /** @type {import('shared/lib/types/views/procedures').DraftAddedStep}*/ (
        getRedlineFromDoc(redline)
      );
      addedStep.orphaned = true;
      return addedStep;
    });
  },

  /**
   *
   * @param {object} params
   * @param {Array<import('shared/lib/types/views/redlines').FullStepRedline> | undefined} params.redlines
   * @param {Set<string>} params.existingBlockIdSet
   * @param {(block: import('shared/lib/types/views/procedures').RunStepBlock) => void} [params.blockModifier]
   */
  _getNewAddedBlocksSorted: ({ redlines, existingBlockIdSet, blockModifier }) => {
    if (!redlines) {
      return [];
    }
    /** @type {Array<import('shared/lib/types/views/redlines').FullStepRedline>} */
    const sortedRedlineDocs = getRedlineDocsSortedLatestToEarliest(redlines);
    return sortedRedlineDocs
      .map((redlineDoc) => {
        const runRedline = getRedlineFromDoc(redlineDoc);
        const addedBlock = runRedline.step.content.find((block) => block.id === redlineDoc.content_id);
        if (!addedBlock || existingBlockIdSet.has(addedBlock.id)) {
          return null;
        }
        addedBlock.added = true;
        if (blockModifier) {
          blockModifier(addedBlock);
        }
        return addedBlock;
      })
      .filter(identity);
  },

  mergeAddedBlocksToProcedure: (procedure, redlines) => {
    if (!redlines || redlines.length === 0) {
      return procedure;
    }

    /*
     * Get existing merged added blocks so we don't merge the same block
     * multiple times
     */
    const existingBlockIdSet = new Set();
    procedure.sections.forEach((section) => {
      section.steps.forEach((step) => {
        step.content.forEach((block) => {
          existingBlockIdSet.add(block.id);
        });
      });
    });

    // Get step-id-to-added-block-array map
    /** @type {Array<import('shared/lib/types/views/redlines').FullStepRedline>} */
    const addedBlockRedlines = redlines.filter((redline) => redline.preceding_content_id !== undefined);
    const stepIdToRedlineMap =
      /** @type {Map<string, Array<import('shared/lib/types/views/redlines').FullStepRedline>>} */ (
        getStepRedlineMap(addedBlockRedlines)
      );

    procedure.sections.forEach((section) => {
      section.steps.forEach((step) => {
        const contentCopy = cloneDeep(step.content);
        if (!stepIdToRedlineMap.has(step.id)) {
          // Go to the next step if there are no redlines for the step.
          return;
        }
        const redlinesInStep = stepIdToRedlineMap.get(step.id);
        const stepContentIdSet = new Set(step.content.map((block) => block.id));
        stepContentIdSet.add(null); // Blocks added at the beginning are never orphaned, and have null as the preceding content id.
        // Split the redlines in to present and orphaned (detached)
        const [presentRedlines, orphanedRedlines] = partition(redlinesInStep, (redline) =>
          stepContentIdSet.has(redline.preceding_content_id)
        );
        const presentMap = getAddedBlockRedlineMap(presentRedlines);
        let initialAddedBlocks = [];
        if (presentMap.has(step.id)) {
          // Block added at beginning of the step.
          initialAddedBlocks = revisions._getNewAddedBlocksSorted({
            redlines: presentMap.get(step.id),
            existingBlockIdSet,
          });
          contentCopy.unshift(...initialAddedBlocks);
        }
        step.content.forEach((block, blockIndex) => {
          if (presentMap.has(block.id)) {
            // Block added anywhere after the first block
            const addedBlocks = revisions._getNewAddedBlocksSorted({
              redlines: presentMap.get(block.id),
              existingBlockIdSet,
            });
            contentCopy.splice(blockIndex + initialAddedBlocks.length + 1, 0, ...addedBlocks);
          }
        });

        // Append any orphaned blocks to the end of the step.
        const orphanedAddedBlocks = revisions._getNewAddedBlocksSorted({
          redlines: orphanedRedlines,
          existingBlockIdSet,
          blockModifier: (block) => {
            block.orphaned = true;
          },
        });
        contentCopy.push(...orphanedAddedBlocks);

        step.content = contentCopy;
      });
    });
    return procedure;
  },

  /**
   * @param {import('shared/lib/types/views/procedures').RunAddedStep} runAddedStep
   * @return {import('shared/lib/types/views/procedures').DraftAddedStep}
   */
  convertToDraftAddedStep: (runAddedStep) => {
    const runAddedStepInactive = copyStepWithoutActiveContent(runAddedStep);
    const blackListedFields = [
      'created_at',
      'createdAt',
      'created_by',
      'createdBy',
      'created_during_run',
      'redline_id',
      'redlineId',
      'orphaned',
      'moved',
      'source_run_id',
    ];

    // @ts-ignore
    return omit(runAddedStepInactive, blackListedFields);
  },

  // Returns copy of procedure without steps added during run.
  getProcedureWithoutRunSteps: (procedure) => {
    const updated = cloneDeep(procedure);
    revisions.stripUnacceptedRedlines(updated);
    return updated;
  },

  stripUnacceptedRedlines: (procedure) => {
    revisions._stripRunSteps(procedure);
    revisions._stripAddedBlocks(procedure);
  },

  _stripRunSteps: (procedure) => {
    procedure.sections.forEach((section) => {
      section.steps = section.steps.filter((step) => {
        return !step.created_during_run;
      });
    });
  },

  _stripAddedBlocks: (procedure) => {
    procedure.sections.forEach((section) => {
      section.steps.forEach((step) => {
        step.content = step.content.filter((block) => {
          return !block.added;
        });
      });
    });
  },

  createdAt: (redline) => {
    // Header redlines use snake case, step redlines use camel case
    return redline.createdAt || redline.created_at;
  },

  createdBy: (redline) => {
    // Header redlines use snake case, step redlines use camel case
    return redline.userId || redline.user_id;
  },

  /**
   * Returns whether a redline has an action in the redline actions array.
   *
   * @param {Object} procedure
   * @param {Object} redline - a redline object that has a redline_id or redlineId
   * @returns {Boolean}
   */
  hasRedlineAction(procedure, redline) {
    return Boolean(
      procedure?.redline_actions &&
        procedure.redline_actions.some((action) => action.redline_id === getRedlineId(redline))
    );
  },

  /**
   * Returns whether a redline has an action in the redline actions array.
   *
   * @param {Object} procedure
   * @param {Object} redline - a redline doc
   * @returns {Boolean}
   */
  hasRedlineActionNew(procedure, redline) {
    return Boolean(
      procedure?.redline_actions && procedure.redline_actions.some((action) => action.redline_id === redline._id)
    );
  },

  /**
   * Get all redline docs (excluding redline comments) for redlines that do not have corresponding redline actions.
   * Redline comment states are not stored in redline_actions, so they are excluded here.
   *
   * Non-comment redlines without redline actions have not been accepted, rejected, or resolved.
   *
   * @param {Array | undefined} redlineActions - The redline_actions array from the top level of the procedure.
   * @param {Array} redlineDocs - An array of redline docs that may have redline actions.
   * @returns {Array} - An array of redline docs that do not have corresponding redline actions.
   */
  getUnactionedNonCommentRedlineDocs(redlineActions, redlineDocs) {
    if (!redlineDocs) {
      return [];
    }

    const nonCommentRedlineDocs = redlineDocs.filter((redlineDoc) => redlineDoc.type !== REDLINE_TYPE.REDLINE_COMMENT);

    if (!redlineActions) {
      return nonCommentRedlineDocs;
    }

    const redlineIds = redlineActions.map((action) => getRedlineId(action));
    const redlineIdSet = new Set(redlineIds);

    return nonCommentRedlineDocs.filter((redlineDoc) => !redlineIdSet.has(redlineDoc._id));
  },

  /**
   * Get the redlined content from a run header redline
   * @param {import('shared/lib/types/views/procedures').RunHeaderBlockRedline} redline
   * @return {
   *   | import('shared/lib/types/views/procedures').ReleaseTextBlock
   *   | import('shared/lib/types/views/procedures').ReleaseAlertBlock
   *   | undefined
   * }
   */
  getHeaderRedlineBlock(redline) {
    const header = redline.header;
    const redlinedBlockId = redline.content_id;
    if (!header || !redlinedBlockId) {
      return;
    }
    return header.content.find((block) => block.id === redlinedBlockId);
  },

  /**
   * Get the redlined content from a run header redline
   * @param {import('shared/lib/types/views/procedures').RunStepBlockRedline} redline
   * @param {string} [sourceContentId] full step redlines only have the source content id in the redline doc, not in the run redline
   * @return {
   *   | import('shared/lib/types/views/procedures').ReleaseTextBlock
   *   | import('shared/lib/types/views/procedures').ReleaseAlertBlock
   *   | import('shared/lib/types/views/procedures').ReleaseFieldInputBlock
   *   | undefined
   * }
   */
  getStepRedlineBlock(redline, sourceContentId) {
    const step = redline.step;
    const redlinedBlockId = sourceContentId ?? redline.source_content_id;
    if (!step || !redlinedBlockId) {
      return;
    }
    return step.content.find((block) => block.id === redlinedBlockId);
  },

  runRedlineComparatorLatestToEarliest: (runRedline1, runRedline2) => {
    const createdAt1 = revisions.createdAt(runRedline1);
    const createdAt2 = revisions.createdAt(runRedline2);

    return -1 * createdAt1.localeCompare(createdAt2);
  },

  /**
   * Sort run redlines by their creation date. Returns the reference to the passed-in array.
   *
   * @param {Array<import('shared/lib/types/views/procedures').RunRedline>} runRedlines
   * @return {Array<import('shared/lib/types/views/procedures').RunRedline>}
   */
  sortRunRedlines(runRedlines) {
    if (!runRedlines) {
      return [];
    }

    return runRedlines.sort(revisions.runRedlineComparatorLatestToEarliest);
  },

  getCommentFromRedlineDoc(redlineDoc) {
    return {
      ...getRedlineFromDoc(redlineDoc),
      reference_id: redlineDoc.step_id,
      source_run_id: redlineDoc.run_id, // needed to distinguish from review comments
    };
  },

  _getRedlineComments(redlineDocs) {
    if (!redlineDocs) {
      return [];
    }

    const redlineCommentDocs = redlineDocs.filter((doc) => {
      return doc.type === REDLINE_TYPE.REDLINE_COMMENT;
    });
    return redlineCommentDocs.map(revisions.getCommentFromRedlineDoc);
  },

  _getRedlineCommentsNotInProcedure(procedure, redlineComments) {
    const procedureComments = procedure.comments ?? [];

    return (redlineComments ?? []).filter((c) => {
      return !revisions._containsRedlineComment(procedureComments, c);
    });
  },

  _containsRedlineComment(comments, redlineComment) {
    return comments.some((c) => c.id === redlineComment.id);
  },

  mergeRedlineCommentsFromDocs(procedure, redlineDocs) {
    const redlineComments = revisions._getRedlineComments(redlineDocs);
    const commentsToAdd = revisions._getRedlineCommentsNotInProcedure(procedure, redlineComments);
    const updated = cloneDeep(procedure);
    updated.comments = updated.comments ?? [];
    updated.comments.push(...commentsToAdd);
    return updated;
  },

  /**
   * @param {import('shared/lib/types/views/procedures').Draft} procedure
   * @return {Map<string, Array<import('shared/lib/types/views/procedures').DraftRedlineComment | import('shared/lib/types/views/procedures').ReviewComment>>} a map of the form {[reference_id]: comment}
   */
  getSortedCommentReferenceMap(procedure) {
    const allComments = procedure.comments ?? [];
    /**
     * @type {Map<string, Array<import('shared/lib/types/views/procedures').DraftRedlineComment | import('shared/lib/types/views/procedures').ReviewComment>>}
     */
    const commentReferenceMap = new Map();
    allComments.forEach((comment) => {
      if (commentReferenceMap.has(comment.reference_id)) {
        commentReferenceMap.get(comment.reference_id)?.push(comment);
      } else {
        commentReferenceMap.set(comment.reference_id, [comment]);
      }
    });
    /**
     * @type {Map<string, Array<import('shared/lib/types/views/procedures').DraftRedlineComment | import('shared/lib/types/views/procedures').ReviewComment>>}
     */
    const sortedCommentReferenceMap = new Map();
    commentReferenceMap.forEach((value, key) => {
      const comments = commentReferenceMap.get(key);
      const sortedComments =
        comments?.sort((commentA, commentB) => commentA.created_at.localeCompare(commentB.created_at)) ?? [];
      sortedCommentReferenceMap.set(key, sortedComments);
    });
    return sortedCommentReferenceMap;
  },

  isRedlineComment(comment) {
    return Boolean(comment) && (comment.source_run_id || getRedlineId(comment));
  },

  /**
   * Get the number of unresolved actions, including redlines, redline comments, and review comments.
   * This is used for the notification badge that identifies how many unresolved redlines, redline comments, and review comments are left.
   */
  getUnresolvedActionsCount(procedure, unactionedNonCommentRedlines, unresolvedRedlineComments) {
    // Get all redline types (including redline comments) that are not resolved.
    const outstandingReviewComments = revisions.getOutstandingReviewComments(procedure);

    return unactionedNonCommentRedlines.length + outstandingReviewComments.length + unresolvedRedlineComments.length;
  },

  /**
   * @param {boolean} isBlueline
   * @param {string} [description]
   * @return {string}
   */
  getSuggestedEditMessage(isBlueline, description) {
    const descriptionDisplay = description ?? 'edit';
    return isBlueline
      ? `This ${descriptionDisplay} is for use in this run only.`
      : `This ${descriptionDisplay} appears in its source run and in the procedure draft.`;
  },
};

export default revisions;
export { DMPDiffType };
